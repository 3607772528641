import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FC, useEffect} from 'react';
import {arrowRight} from 'assets/icons';
import {useDispatch, useSelector} from 'react-redux';

import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import {RootState} from 'redux/rootReducers';
import {StatusColor} from 'utils/statusColor';
import {YELLOW} from 'constants/colors';
import {getSessionsAction} from 'redux/actions/getSessionsAction';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

interface props {
  setSession: React.Dispatch<React.SetStateAction<any>>;
  parkingId?: string;
}

const AdminSessionTable: FC<props> = ({setSession, parkingId}) => {
  const {t, i18n} = useTranslation();

  const dispatch: any = useDispatch();

  const {sessions, currentPage, totalPages, fetchLoading} = useSelector(
    (state: RootState) => state.getSessionsReducer,
  );
  const {isSearching, filteredData, error, searchLoading} = useSelector(
    (state: RootState) => state.searchReducer,
  );

  useEffect(() => {
    if (parkingId) {
      dispatch(getSessionsAction(currentPage, parkingId));
    }
  }, [parkingId]);

  const notCreatedSessions = sessions?.filter(
    (session: {status: string}) => session.status !== 'CREATED',
  );

  const formatDateTime = (dateTime: string) => {
    return moment(dateTime).isValid()
      ? moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss')
      : '--';
  };

  const sessionToRender = isSearching ? filteredData : notCreatedSessions;

  return (
    <Box>
      <TableContainer
        sx={{
          height: 500,
          borderRadius: 4,
          pr: i18n.language === 'en' ? 2 : 0,
          pl: i18n.language === 'ar' ? 2 : 0,
        }}>
        <Table className="sessions-table" sx={{fontFamily: 'Poppins'}}>
          <TableHead sx={{position: 'sticky', top: 0, whiteSpace: 'nowrap'}}>
            <TableRow sx={{background: YELLOW}}>
              <TableCell />
              <TableCell>{t('Car plate')}</TableCell>
              <TableCell>{t('User Phone')}</TableCell>
              <TableCell>{t('Package')}</TableCell>
              <TableCell>{t('Check in time')}</TableCell>
              <TableCell>{t('Total slots')}</TableCell>
              <TableCell>{t('Check in slots')}</TableCell>
              <TableCell>{t('Check out slots')}</TableCell>
              <TableCell>{t('Check out time')}</TableCell>
              <TableCell>{t('Process status')}</TableCell>
              <TableCell>{t('Status')}</TableCell>
              <TableCell>{t('Amount')}</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody sx={{height: sessionToRender.length > 3 ? 500 : 0}}>
            <CustomLoader size={80} loading={fetchLoading || searchLoading}>
              {sessionToRender?.length === 0 ||
              sessionToRender === undefined ||
              parkingId === undefined ? (
                <TableRow className="empty-row">
                  <TableCell colSpan={14} className="no-data">
                    {isSearching
                      ? error
                      : parkingId === undefined
                      ? 'You should select a parking'
                      : 'No data'}
                  </TableCell>
                </TableRow>
              ) : (
                sessionToRender.map((session: any) => {
                  return (
                    <TableRow key={session.id}>
                      <TableCell />
                      <TableCell>{session.carNumber?.split(' ').join('') ?? '--'}</TableCell>
                      <TableCell>
                        {session?.processStatus === 'VIP'
                          ? session.contactPhone
                            ? '+' + session.contactPhone
                            : '--'
                          : session.phone
                          ? '+' + session.phone
                          : '--'}
                      </TableCell>
                      <TableCell>{session.packageName ?? '--'}</TableCell>
                      <TableCell className="whitespace-nowrap">
                        {formatDateTime(session.checkInDate)}
                      </TableCell>
                      <TableCell>{session.slotCount ?? '--'}</TableCell>
                      <TableCell>{session.checkedIn ?? '--'}</TableCell>
                      <TableCell>{session.checkedOut ?? '--'}</TableCell>
                      <TableCell className="whitespace-nowrap">
                        {session.status === 'RUNNING'
                          ? '--'
                          : session.checkOutDate
                          ? formatDateTime(session.checkOutDate)
                          : '--'}
                      </TableCell>
                      <TableCell>{session.processStatus ? session.processStatus : '--'}</TableCell>
                      {StatusColor(session.status)}
                      <TableCell>
                        {session.status === 'CLOSED' ? session.amount + ' SR' : '--'}
                      </TableCell>
                      <TableCell
                        className="cursor-pointer"
                        onClick={() => {
                          setSession(session);
                        }}>
                        <CustomIcon
                          href={arrowRight}
                          className={`${i18n.language === 'ar' && 'rotate-180 relative -z-10'}`}
                        />
                      </TableCell>
                      <TableCell />
                      <TableCell />
                    </TableRow>
                  );
                })
              )}
            </CustomLoader>
          </TableBody>
          {!isSearching && (
            <CustomTablePagination
              totalPages={parkingId ? totalPages : 0}
              currentPage={currentPage}
              filters={[parkingId]}
              dispatchFunc={getSessionsAction}
              currentSession
            />
          )}
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminSessionTable;
