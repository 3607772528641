import {AdminRoutes, GateRoutes, ProtectedRoutes, ShiftRoutes} from 'routes';
import {
  CURRENT_SESSION_PATH,
  EMPLOYEE_SETUP_PATH,
  END_SHIFT_PATH,
  ERROR_ROUTE_PATH,
  FORGET_PATH,
  EMPLOYEE_WALLET_PATH,
  HOME_PATH,
  LOGIN_PATH,
  OTP_PATH,
  PACKAGE_CATEGORY_PATH,
  PACKAGE_HOME_PATH,
  PARKING_SETUP_PATH,
  PROFILE_ADMIN_PATH,
  PROFILE_GATE_PATH,
  REPORTS_PATH,
  RESET_PASSWORD_PATH,
  SESSIONS_PATH,
  SHIFT_DATA_PATH,
  START_SHIFT_PATH,
  USER_SETUP_PATH,
  INVOICE_SCANNED,
} from 'routes/paths';
import {
  CurrentSession,
  EmployeeSetup,
  ForgetPassword,
  Home,
  Login,
  OTP,
  PackageCategory,
  PackageHome,
  ParkingSetup,
  ProfileAdmin,
  ResetPassword,
  Sessions,
  UserList,
  InvoiceScanned,
} from 'screens';
import {Route, Routes} from 'react-router-dom';

import EndShift from 'screens/Gate/EndShift';
import NoShiftRoutes from './NoShiftRoutes';
import ProfileGate from 'screens/Gate/ProfileGate';
import Reports from 'screens/Admin/Reports';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import ShiftData from 'screens/Admin/ShiftData';
import StartShift from 'screens/Gate/StartShift';
import {useSelector} from 'react-redux';
import GateEmployeeWallet from 'screens/Admin/GateEmployeeWallet';

const AppRoutes = () => {
  const {user} = useSelector((state: RootState) => state.authReducer);
  const {shifts} = useSelector((state: RootState) => state.getShiftReducer);

  return (
    <Routes>
      <Route
        path={ERROR_ROUTE_PATH}
        element={<ResponseModal error message="Page not found" errorBoundary />}
      />
      <Route path={LOGIN_PATH} element={<Login />} />
      <Route path={OTP_PATH} element={<OTP />} />
      <Route path={FORGET_PATH} element={<ForgetPassword />} />
      <Route path={RESET_PASSWORD_PATH} element={<ResetPassword />} />
      <Route path={INVOICE_SCANNED} element={<InvoiceScanned />} />
      <Route element={<ProtectedRoutes user={user} shifts={shifts} />}>
        <Route element={<AdminRoutes user={user} />}>
          <Route path={PROFILE_ADMIN_PATH} element={<ProfileAdmin />} />
          <Route path={HOME_PATH} element={<Home />} />
          <Route path={SESSIONS_PATH} element={<Sessions />} />
          <Route path={PARKING_SETUP_PATH} element={<ParkingSetup />} />
          <Route path={PACKAGE_CATEGORY_PATH} element={<PackageCategory />} />
          <Route path={PACKAGE_HOME_PATH} element={<PackageHome />} />
          <Route path={EMPLOYEE_SETUP_PATH} element={<EmployeeSetup />} />
          <Route path={REPORTS_PATH} element={<Reports />} />
          <Route path={USER_SETUP_PATH} element={<UserList />} />
          <Route path={SHIFT_DATA_PATH} element={<ShiftData />} />
          <Route path={EMPLOYEE_WALLET_PATH} element={<GateEmployeeWallet />} />
        </Route>
        <Route element={<GateRoutes user={user} />}>
          <Route path={PROFILE_GATE_PATH} element={<ProfileGate />} />
          <Route element={<ShiftRoutes shifts={shifts} />}>
            <Route path={CURRENT_SESSION_PATH} element={<CurrentSession />} />
            <Route path={END_SHIFT_PATH} element={<EndShift />} />
          </Route>
          <Route element={<NoShiftRoutes shifts={shifts} />}>
            <Route path={START_SHIFT_PATH} element={<StartShift />} />
          </Route>
        </Route>
      </Route>
    </Routes>
  );
};

export default AppRoutes;
