import CustomButton from 'components/CustomButton/CustomButton';
import './styles.css';
import SingleDropdown from 'utils/dropdowns/SingleDropdown';
import {useMutation, useQuery} from 'react-query';
import {getGateEmployeesService} from 'services/user/getGateEmployeesService';
import {GateEmployee} from 'model/GateEmployee';
import {useState} from 'react';
import {getWalletService} from 'services/wallet/getWalletService';
import {updateWalletService} from 'services/wallet/updateWalletService';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {AxiosError} from 'axios';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {useTranslation} from 'react-i18next';

const WalletAdjustmentForm = () => {
  const [responseModal, setResponseModal] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const {t, i18n} = useTranslation();
  const currentLanguage = i18n.language;

  const {handleSubmit, handleChange, setFieldValue, errors, values, isValid} = useFormik({
    initialValues: {
      id: '',
      gateEmployeeName: '',
      nationalId: '--',
      transactionType: '',
      amount: '',
      currentBalance: '--',
    },

    validationSchema: Yup.object().shape({
      amount: Yup.number()
        .typeError(t('Amount must be a number')!)
        .positive(t('Amount must be greater than 0')!)
        .required(t('Amount is required')!)
        .test(
          'amount-cannot-be-greater-than-balance',
          t("Amount can't be greater than current balance")!,
          function (value) {
            const currentBalance = this.parent.currentBalance as number;
            const amount = value;
            if (this.parent.transactionType === 'Debit') {
              return currentBalance >= amount!;
            }
            return true;
          },
        ),
      gateEmployeeName: Yup.string().required(t('Employee name is required')!),
      transactionType: Yup.string().required(t('Transaction type is required')!),
    }),

    onSubmit: () => {
      updateWallet({
        userId: values.id,
        oldBalance: Number(values.currentBalance),
        balance: getBalance()!,
      });
    },
  });

  const getBalance = () => {
    switch (values.transactionType) {
      case 'Debit':
        return Number(values.amount) * -1;
      case 'Credit':
        return Number(values.amount) * 1;
    }
  };

  const {data} = useQuery('gateEmployees', async () => {
    return await getGateEmployeesService({active: true});
  });

  const getEmployeeOptions = () => {
    const updatedGateEmployees = data?.[0].users;

    return updatedGateEmployees?.map((employee: GateEmployee) => {
      return {label: employee.name, value: {id: employee.id, nationalId: employee.nationalId}};
    });
  };

  const {mutate: getWallet} = useMutation(getWalletService, {
    onSuccess: data => {
      setFieldValue('currentBalance', data.balance);
    },
  });

  const {
    mutate: updateWallet,
    isError,
    isLoading,
  } = useMutation(updateWalletService, {
    onSuccess: () => {
      setResponseModal(true);
    },
    onError: (error: AxiosError) => {
      setResponseModal(true);
      setErrorMessage((error.response!.data as Record<string, string>).statusMessage);
    },
  });

  const handleEmployeeSelect = (name: string, value: Record<string, string>) => {
    if (value?.id) {
      setFieldValue('gateEmployeeName', name);
      setFieldValue('nationalId', value.nationalId);
      setFieldValue('id', value.id);
      getWallet(value.id);
    }
  };

  return (
    <div id="wallet-adjustment-form" className="h-[34rem] bg-[var(--accent-white)] rounded-b-2xl">
      <div className="h-12 bg-[var(--main-color)] pl-20 flex items-center text-white text-xl">
        {values.gateEmployeeName}
      </div>
      <form onSubmit={handleSubmit}>
        <div className="input-container">
          <div>
            <label htmlFor="employee">{t('Gate employee')}</label>
            <SingleDropdown
              search
              width="16rem"
              blackArrow
              placeholder={`${t('Select a gate employee')}`}
              value={values.gateEmployeeName}
              options={getEmployeeOptions()}
              handleDropdownSelect={(name, value) => handleEmployeeSelect(name, value)}
              onEmpty={() => {
                setFieldValue('nationalId', '');
                setFieldValue('id', '');
                setFieldValue('currentBalance', '');
              }}
            />
            {errors.gateEmployeeName && (
              <p className="validation-error">{errors.gateEmployeeName}</p>
            )}
          </div>
          <div className="relative">
            <label htmlFor="oldBalance">{t('Current_balance')}</label>
            <input className="!pr-11" type="text" disabled value={values.currentBalance ?? '--'} />
            <span className="absolute right-4 bottom-5 text-[var(--disabled-grey)]">SR</span>
          </div>
          <div>
            <label htmlFor="transaction">{t('Transaction type')}</label>
            <SingleDropdown
              search
              width="16rem"
              blackArrow
              placeholder={`${t('Select a transaction type')}`}
              value={values.transactionType}
              options={[
                {label: 'Credit', value: 'Credit'},
                {label: 'Debit', value: 'Debit'},
              ]}
              handleDropdownSelect={value => {
                setFieldValue('transactionType', value);
              }}
            />
            {errors.transactionType && <p className="validation-error">{errors.transactionType}</p>}
          </div>
        </div>
        <div className="input-container">
          <div>
            <label htmlFor="userId">{t('National_Id')}</label>
            <input type="text" disabled value={values.nationalId ?? '--'} />
          </div>
          <div className="relative ">
            <label htmlFor="amount">{t('Amount')}</label>
            <div className={` ${currentLanguage === 'en' ? 'flex' : 'flex-row-reverse'}`}>
              <input
                placeholder={`${t('Enter an amount value')}`}
                type="text"
                name="amount"
                className="!pr-11"
                value={values.amount}
                onChange={handleChange}
              />
              <span className="absolute right-4 bottom-5">SR</span>
            </div>
            {errors.amount && <p className="validation-error">{errors.amount}</p>}
          </div>
          <div />
        </div>
        <div className="w-f ull flex justify-center mt-16">
          <CustomLoader loading={isLoading} size={60}>
            <CustomButton type="submit" disabled={!isValid} color="Orange" padding="0 8rem">
              {t('Update')}
            </CustomButton>
          </CustomLoader>
        </div>
      </form>
      {responseModal && (
        <ResponseModal
          error={isError}
          message={isError ? errorMessage! : t('Wallet updated successfully')}
          onClose={() => {
            setResponseModal(false);
            location.reload();
          }}
        />
      )}
    </div>
  );
};

export default WalletAdjustmentForm;
