import api from 'api';
import {getRevenuesEndpoint} from 'api/apisURL';

export const getRevenuesService = async (parkingId: string, date?: {from: string; to: string}) => {
  const body = {
    filters: [
      {
        fieldName: 'parkingId',
        operator: 'like',
        values: [parkingId],
      },
      {
        fieldName: 'status',
        operator: 'like',
        values: [
            'CLOSED'
        ]
    }
    ],
  };

  if (date) {
    body.filters.push({
      fieldName: 'checkInDate',
      operator: 'between',
      values: [date.from, date.to],
    });
  }

  const response = await api.post(getRevenuesEndpoint(), body);
  return response;
};
