import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import {Box, CircularProgress, Grid} from '@mui/material';
import {FC, useState} from 'react';
import {useFormik} from 'formik';
import {LoadingButton} from '@mui/lab';
import Popup from 'components/Popup/Popup';
import {useOnline} from 'hooks/useOnline';
import {useTranslation} from 'react-i18next';
import {changePassword, resetError} from 'redux/actions/authAction';
import {useDispatch, useSelector} from 'react-redux';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import PasswordInput from '../PasswordInput/PasswordInput';

interface passwordModalProps {
  openModal: boolean;
  handleClose: () => void;
}

const PasswordModel: FC<passwordModalProps> = ({openModal, handleClose}) => {
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const {error} = useSelector((state: RootState) => state.authReducer);
  const [passwordResponse, setpasswordResponse] = useState<boolean>(false);

  const {isOnline} = useOnline();

  const validationSchema = () => {
    return Yup.object({
      oldPassword: Yup.string()
        .min(8, t('Password must be at least 8 characteres')!)
        .required(t('Required')!),
      newdPassword: Yup.string()
        .required(t('Required')!)
        .min(8, t('Password must be at least 8 characteres')!)
        .test(
          'old-password-not-equal-new-password',
          "Old password can't match the new password",
          function (value) {
            return value !== this.parent.oldPassword;
          },
        ),

      confirmPassword: Yup.string()
        .required(t('Required')!)
        .test('password-match', 'Passwords must match', function (value) {
          return value === this.parent.newdPassword;
        }),
    });
  };

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newdPassword: '',
      confirmPassword: '',
    },
    enableReinitialize: true,
    validationSchema,
    onSubmit: values => {
      dispatch(
        changePassword({
          newPass: values.newdPassword,
          oldpass: values.oldPassword,
          onSave: () => {
            setpasswordResponse(true);
          },
        }),
      );
    },
  });

  const handleCloseModal = () => {
    handleClose();
    formik.resetForm();
  };

  return (
    <>
      <Popup open={openModal} handleCloseModal={handleCloseModal} popupTitle="Reset password">
        <div className="overflow-auto" style={{height: '89%'}}>
          <form onSubmit={formik.handleSubmit} className="modal-container" style={{height: '100%'}}>
            <Box className="form-container" sx={{height: '100%', position: 'relative'}}>
              <Grid
                container
                id="user-employee-setup"
                className="employee-form"
                columnSpacing={{xs: 1, md: '95px'}}>
                <Grid item xs={12} md={6}>
                  <Box className="employee-grid-item">
                    <label className="input-label">{t('Old password')}</label>
                    <PasswordInput
                      value={formik.values.oldPassword}
                      name="oldPassword"
                      id="oldPassword"
                      onChange={formik.handleChange}
                      placeholder={`${t('Enter old password')}`}
                    />
                    <p className="validation-error">
                      {formik.errors.oldPassword &&
                        formik.touched.oldPassword &&
                        formik.errors.oldPassword}
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="employee-grid-item">
                    <label className="input-label">{t('New password')}</label>
                    <PasswordInput
                      value={formik.values.newdPassword}
                      name="newdPassword"
                      id="newdPassword"
                      onChange={formik.handleChange}
                      placeholder={`${t('Enter new password')}`}
                    />

                    <p className="validation-error">
                      {formik.errors.newdPassword &&
                        formik.touched.newdPassword &&
                        formik.errors.newdPassword}
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} my={4}>
                  <Box className="employee-grid-item">
                    <label className="input-label">{t('Confirm new password')}</label>
                    <PasswordInput
                      value={formik.values.confirmPassword}
                      name="confirmPassword"
                      id="confirmPassword"
                      onChange={formik.handleChange}
                      placeholder={`${t('Repeat new password')}`}
                    />
                    <p className="validation-error">
                      {formik.errors.confirmPassword &&
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword}
                    </p>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '5%',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                {/* {errors && <p className="validation-error">{errors}</p>} */}
                {!isOnline && (
                  <p className="validation-error" style={{marginTop: '-9px', marginBottom: '15px'}}>
                    You are offline
                  </p>
                )}
                <LoadingButton
                  type="submit"
                  //   loading={loading}
                  className="popup-loading-button"
                  loadingIndicator={<CircularProgress />}
                  disabled={!isOnline}>
                  {t('Save')}
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </div>
      </Popup>
      {passwordResponse && (
        <ResponseModal
          onClose={() => {
            setpasswordResponse(false);
            dispatch(resetError());
            if (!error) {
              handleClose();
              formik.resetForm();
            }
          }}
          message={error ?? 'Password has been changed'}
          error={error}
        />
      )}
    </>
  );
};

export default PasswordModel;
