import {getOrganiztionsEndPoint} from 'api/apisURL';
import api from '../api';

export const getParkingService = async (pageNumber?: number) => {
  const data = {
    pageNumber: 0,
    pageSize: 10,
    columns: ['string'],
    filters: [],
  };
  const response = await api.post(getOrganiztionsEndPoint(), data);
  return response;
};
