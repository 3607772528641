import {
  CASH_PAYMENT,
  CASH_PAYMENT_FAILED,
  CASH_PAYMENT_LOADING,
  CLEAR_CASH_PAYMENT,
} from 'redux/types';

import {CashPayment} from 'model/CashPayment';
import {Dispatch} from 'redux';
import {cashPaymentService} from 'services/reservation/cashPaymentService';

export const cashPayment =
  (data: CashPayment, onDone: VoidFunction) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: CASH_PAYMENT_LOADING, payload: true});
      const response = await cashPaymentService(data);
      dispatch({type: CASH_PAYMENT, payload: response.data});
    } catch (error) {
      dispatch({type: CASH_PAYMENT_FAILED, payload: error});
    } finally {
      dispatch({type: CASH_PAYMENT_LOADING, payload: false});
      onDone();
    }
  };

export const clearCashPayment = () => {
  return {type: CLEAR_CASH_PAYMENT};
};
