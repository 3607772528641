import api from 'api';
import {transactionsEndpoint} from 'api/apisURL';

interface Params {
  userId: string;
  from: string;
  to: string;
}

export const getTransactionsByDateService = async (params: Params) => {
  const payload = {
    userId: params.userId,
    from: params.from,
    to: params.to,
    pageNumber: 0,
    pageSize: 10000,
  };

  const {data} = await api.post(transactionsEndpoint(), payload);
  return data;
};
