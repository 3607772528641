import {
  CASH_PAYMENT,
  CASH_PAYMENT_FAILED,
  CASH_PAYMENT_LOADING,
  CLEAR_CASH_PAYMENT,
} from 'redux/types';

interface CashPaymentState {
  loading: boolean;
  error: string;
  response: string;
}

const initialState: CashPaymentState = {
  loading: false,
  error: '',
  response: '',
};

export const cashPaymentReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CASH_PAYMENT:
      return {...state, response: action.payload};
    case CASH_PAYMENT_LOADING:
      return {...state, loading: action.payload};
    case CASH_PAYMENT_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case CLEAR_CASH_PAYMENT:
      return {...state, error: '', response: ''};
    default:
      return state;
  }
};
