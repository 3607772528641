import {Dispatch, FC, MouseEvent, SetStateAction, useState} from 'react';
import {calendar, clear} from 'assets/icons';
import CustomButton from 'components/CustomButton/CustomButton';
import {DateRange} from 'react-date-range';
import moment from 'moment';
import {useMutation} from 'react-query';
import {getTransactionsByDateService} from 'services/wallet/getTransactionsByDateService';
import {GateEmployee} from 'model/GateEmployee';
import {getWalletTransactionsService} from 'services/wallet/getWalletTransactionsService';
import {useDispatch} from 'react-redux';
import {
  getEmployeeTransactions,
  getEmployeeTransactionsByDate,
} from 'redux/actions/employeeTransactionActions';
import {useTranslation} from 'react-i18next';

interface Props {
  selectedEmployee: GateEmployee;
  datePickerUpdated: boolean;
  setDatePickerUpdated: Dispatch<SetStateAction<boolean>>;
}

const CalendarPicker: FC<Props> = ({selectedEmployee, datePickerUpdated, setDatePickerUpdated}) => {
  const {t} = useTranslation();

  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<Array<Record<string, unknown>>>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const dispatch = useDispatch();

  const {mutate: getTransactionsByDate} = useMutation(getTransactionsByDateService, {
    onSuccess: data => {
      dispatch(getEmployeeTransactionsByDate(data));
    },
  });

  const {mutate: getAllTransactions} = useMutation(getWalletTransactionsService, {
    onSuccess: data => {
      dispatch(getEmployeeTransactions(data));
    },
  });

  const startDate = moment(selectedRange[0].startDate as Date);
  const endDate = moment(selectedRange[0].endDate as Date);

  const handleChange = (item: {selection: {startDate: Date; endDate: Date; key: string}}) => {
    const {startDate, endDate} = item.selection;

    setSelectedRange([
      {
        startDate,
        endDate,
        key: 'selection',
      },
    ]);
  };

  const handleUpdate = () => {
    const newStartDate = startDate.startOf('day');
    const newEndDate = endDate.endOf('day');
    setDatePickerOpen(false);
    setDatePickerUpdated(true);
    getTransactionsByDate({
      from: newStartDate.format('YYYY-MM-DDTHH:mm:ss'),
      to: newEndDate.format('YYYY-MM-DDTHH:mm:ss'),
      userId: selectedEmployee.id,
    });
  };

  const handleClear = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    setDatePickerUpdated(false);
    setSelectedRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    getAllTransactions({id: selectedEmployee.id});
  };

  return (
    <>
      <div className="w-full text-right">
        <button
          disabled={!selectedEmployee}
          className={`${selectedEmployee ? 'bg-black' : 'bg-gray-400'} rounded-xl h-16 w-48`}
          onClick={() => setDatePickerOpen(!datePickerOpen)}>
          <div
            className={`flex justify-center items-center gap-2 ${
              datePickerUpdated && 'mr-6'
            } relative`}>
            <img src={calendar} alt="calendar" />
            <p className="text-white text-sm">
              {datePickerUpdated
                ? `${moment(selectedRange[0].startDate as Date).format('YY MMM DD')} : ${moment(
                    selectedRange[0].endDate as Date,
                  ).format('MMM DD')}`
                : t('Select period')}
            </p>
            {datePickerUpdated && (
              <div
                className="flex items-center justify-center text-lg bg-[red] absolute w-6 h-16 rounded-r-lg -right-6"
                onClick={handleClear}>
                <img src={clear} alt="clear" />
              </div>
            )}
          </div>
        </button>
      </div>
      {datePickerOpen && (
        <div
          dir="ltr"
          className="absolute right-0 top-12 z-50 bg-[var(--accent-white)] p-2 mt-4 rounded-[20px] border-[var(--main-color)] border-solid border-2">
          <DateRange
            className="pb-2"
            color="#aeaeaea1"
            ranges={selectedRange}
            onChange={handleChange}
          />
          <div className="w-full text-center">
            <CustomButton className="mb-2 px-4 !h-9" color="Orange" onClick={handleUpdate}>
              {t('Update')}
            </CustomButton>
          </div>
        </div>
      )}
    </>
  );
};

export default CalendarPicker;
