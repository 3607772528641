import {useEffect} from 'react';
import {TableBody, TableCell, TableRow} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {EmployeeTableTypes} from 'model/types';
import {RootState} from 'redux/rootReducers';
import {edit} from 'assets/icons';
import {getEmployee} from 'redux/actions/getEmployeeAction';
import {setEmployeeRow} from 'redux/actions/employeeAction';
import moment from 'moment';

const EmployeeBody = () => {
  const dispatch: any = useDispatch();
  const {selectedParking} = useSelector((state: RootState) => state.parkingSetupReducer);
  const {employee, currentPage, loading} = useSelector(
    (state: RootState) => state.getEmployeeReducer,
  );
  const {newEmployee} = useSelector((state: RootState) => state.employeeReducer);

  useEffect(() => {
    dispatch(getEmployee(selectedParking?.id, currentPage));
  }, [newEmployee, selectedParking?.id]);
  const addCodeCoun = (phone: string) => {
    const formattedPhoneNumber = '+' + phone;
    return formattedPhoneNumber;
  };

  return (
    <TableBody sx={{height: employee?.length > 3 ? 500 : 0}}>
      <CustomLoader size={80} loading={loading}>
        {employee?.length === 0 || employee === undefined || selectedParking?.id === undefined ? (
          <TableRow className="empty-row">
            <TableCell colSpan={12} className="no-data">
              {selectedParking?.id === undefined
                ? 'You should select a parking'
                : 'No Employees have been created yet for that parking'}
            </TableCell>
          </TableRow>
        ) : (
          employee.map((employee: EmployeeTableTypes) => {
            return (
              <TableRow key={employee.id}>
                <TableCell></TableCell>
                <TableCell>{employee?.name ?? '--'}</TableCell>
                <TableCell>{employee?.email ?? '--'}</TableCell>
                <TableCell>{addCodeCoun(employee?.phone ?? '--')}</TableCell>
                <TableCell>{employee?.carNumber ?? '--'}</TableCell>
                <TableCell>
                  {employee?.startDate ? moment(employee?.startDate).format('DD/MM/YYYY') : '--'}
                </TableCell>
                <TableCell>{employee?.profileName ?? '--'}</TableCell>
                <TableCell>{employee?.isGateEmployee ? 'Yes' : 'No'}</TableCell>
                <TableCell>{employee?.status ?? '--'}</TableCell>
                <TableCell>
                  <CustomIcon
                    href={edit}
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(setEmployeeRow(employee));
                    }}
                  />
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            );
          })
        )}
      </CustomLoader>
    </TableBody>
  );
};

export default EmployeeBody;
