import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import EmployeeWalletTitle from './EmployeeWalletTitle/EmployeeWalletTitle';
import WalletAdjustmentForm from './WalletAdjustmentForm/WalletAdjustmentForm';
import {useState} from 'react';
import EmployeeWalletTable from './EmployeeWalletTable/EmployeeWalletTable';
import ParkingDropDown from 'utils/dropdowns/ParkingDropDown';
import CalendarPicker from './CalendarPicker/CalendarPicker';
import {GateEmployee} from 'model/GateEmployee';

export type Page = 'wallet adjustment' | 'transaction history';

const GateEmployeeWallet = () => {
  const [currentPage, setCurrentPage] = useState<Page>('wallet adjustment');
  const [datePickerUpdated, setDatePickerUpdated] = useState<boolean>(false);
  const [selectedEmployee, setSelectedEmployee] = useState<GateEmployee | null>(null);

  const renderHeader = () => {
    if (currentPage === 'wallet adjustment') {
      return (
        <div className="flex justify-between mb-5">
          <ParkingDropDown />
          <HeaderDate orange />
        </div>
      );
    } else {
      return (
        <div className="flex flex-col items-end">
          <div className="mb-2.5">
            <HeaderDate orange />
          </div>
          <div className="mb-2.5 relative">
            <CalendarPicker
              selectedEmployee={selectedEmployee!}
              datePickerUpdated={datePickerUpdated}
              setDatePickerUpdated={setDatePickerUpdated}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div id="gate-employee-wallet" className="overflow-hidden">
      <MainViewContainer>
        {renderHeader()}
        <EmployeeWalletTitle currentPage={currentPage} setCurrentPage={setCurrentPage} />
        {currentPage === 'wallet adjustment' ? (
          <WalletAdjustmentForm />
        ) : (
          <EmployeeWalletTable
            selectedEmployee={selectedEmployee!}
            setSelectedEmployee={setSelectedEmployee}
            setDatePickerUpdated={setDatePickerUpdated}
          />
        )}
      </MainViewContainer>
    </div>
  );
};

export default GateEmployeeWallet;
