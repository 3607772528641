import {
  AUTH_LOADING,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  FORGET_PASSWORD_FAILED,
  LOG_IN,
  LOG_IN_FAILED,
  RESET_AUTH,
  RESET_ERROR,
  SEND_OTP,
  SEND_OTP_FAILED,
  SET_USER,
  TRY_AGAIN,
  VERIFY_OTP,
  VERIFY_OTP_FAILED,
} from '../types';

const initialState = {
  loading: false,
  error: null,
  user: false,
  otp: null,
  phoneNumber: null,
  newpassword: null,
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case AUTH_LOADING:
      return {...state, loading: action.payload};
    case LOG_IN:
      return {...state, user: action.payload};
    case SET_USER:
      return {...state, user: action.payload};
    case LOG_IN_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case TRY_AGAIN:
      return {...state, error: null};
    case SEND_OTP:
      return {...state, otp: action.payload.otp, phoneNumber: action.payload.phoneNumber};
    case SEND_OTP_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case VERIFY_OTP:
      return {...state, otp: action.payload};
    case VERIFY_OTP_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case FORGET_PASSWORD_FAILED:
      return {...state, error: action.payload};
    case CHANGE_PASSWORD:
      return {...state, loading: action.payload};
    case CHANGE_PASSWORD_SUCCESS:
      return {...state, newpassword: action.payload};
    case CHANGE_PASSWORD_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case RESET_ERROR:
      return {...state, error: null};
    case RESET_AUTH:
      return initialState;
    default:
      return state;
  }
};
