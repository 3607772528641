import {Doughnut} from 'react-chartjs-2';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducers';
import Styles from './processSlotsDoughnut.styles.module.css';
import {FC, useEffect, useState} from 'react';
import {t} from 'i18next';

interface Props {
  datePickerUpdated: boolean;
}

const ProcessTotalDoughnut: FC<Props> = ({datePickerUpdated}) => {
  const {processChartData, filteredProcessSlots} = useSelector(
    (state: RootState) => state.chartReducer,
  );

  const reqularSlots: number = datePickerUpdated
    ? filteredProcessSlots?.Regular?.first ?? 0
    : processChartData?.Regular?.first ?? 0;

  const unregisteredSlots: number = datePickerUpdated
    ? filteredProcessSlots?.Unregistered?.first ?? 0
    : processChartData?.Unregistered?.first ?? 0;

  const [totalSlots, setTotalSlots] = useState<number>(1);
  useEffect(() => {
    setTotalSlots(reqularSlots + unregisteredSlots);
  }, [reqularSlots, unregisteredSlots]);

  const chartData1 = {
    labels: ['Registered', 'Unregistered'],
    datasets: [
      {
        data: [reqularSlots, unregisteredSlots],
        backgroundColor: ['#FEB700', '#0A0A0A1A'],
        hoverBackgroundColor: ['#FEB700', '#0A0A0A1A'],
      },
    ],
  };

  const chartData2 = {
    labels: ['unregistered', 'Registered'],
    datasets: [
      {
        data: [unregisteredSlots, reqularSlots],
        backgroundColor: ['#4DC37C', '#0A0A0A1A'],
        hoverBackgroundColor: ['#4DC37C', '#0A0A0A1A'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    cutout: 52,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },

    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className={Styles.processTotalContainer}>
      <h2 className={`d-flex justify-between ${Styles.headTitle}`}>
        <span>{t('Process types / Total slots')} </span>
        <span>
          <span className={Styles.greySpan}>{t('Total slots')}: &nbsp;</span>
          {totalSlots} {t('slots')}
        </span>
      </h2>
      <div className="d-flex justify-between px-10 ">
        <div className="d-flex text-center flex-column ">
          <div className={`mt-10 mb-3 relative ${Styles.firstDoughnut} justifycenter`}>
            <Doughnut data={chartData1} options={chartOptions} />
            <p className="centerText">
              <span className="d-block whitespace-nowrap">
                {reqularSlots ?? 0} {t('slots')}
              </span>
            </p>
          </div>
          <span className="font-bold text-15 text-black ">{t('Registered')}</span>
        </div>
        <div className="d-flex text-center flex-column ">
          <div className={`mt-10 mb-3 relative ${Styles.seconDoughnut} justifycenter`}>
            <Doughnut data={chartData2} options={chartOptions} />
            <p className="centerText">
              <span className="d-block whitespace-nowrap">
                {unregisteredSlots ?? 0} {t('slots')}
              </span>
            </p>
          </div>
          <span className="font-bold text-15 text-black">{t('UnRegistered')}</span>
        </div>
      </div>
    </div>
  );
};

export default ProcessTotalDoughnut;
