export const ERROR_ROUTE_PATH = '*';
export const LOGIN_PATH = '/login';
export const OTP_PATH = `${LOGIN_PATH}/otp`;
export const FORGET_PATH = `${LOGIN_PATH}/forgot-password`;
export const RESET_PASSWORD_PATH = `${LOGIN_PATH}/reset-password`;
export const HOME_PATH = '/';
export const PROFILE_ADMIN_PATH = '/profile';
export const PROFILE_GATE_PATH = '/profile-gate';
export const REPORTS_PATH = '/reports';
export const SESSIONS_PATH = '/sessions';
export const CURRENT_SESSION_PATH = '/current-session';
export const UNREGISTERED_USER_PATH = '/unregistered';
export const MULTIPLE_SESSIONS_TABLE_PATH = '/multiple-sessions';
export const PACKAGES_PATH = '/packages';
export const PACKAGE_HOME_PATH = '/package-home';
export const PACKAGE_CATEGORY_PATH = '/package-category';
export const PARKING_SETUP_PATH = '/parking-setup';
export const EMPLOYEE_SETUP_PATH = '/employee-list';
export const START_SHIFT_PATH = '/start-shift';
export const END_SHIFT_PATH = '/end-shift';
export const USER_SETUP_PATH = '/user-list';
export const SHIFT_DATA_PATH = '/shift-data';
export const EMPLOYEE_WALLET_PATH = '/employee-wallet';
export const INVOICE_SCANNED = '/invoice-scanned/:number?/:date?/:phone?/:carPlate?/:value?';
