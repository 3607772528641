import {ChangeEvent, FC, useState} from 'react';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';

interface Props {
  value: string;
  name: string;
  id: string;
  placeholder: string;
  onChange: (e: ChangeEvent) => void;
}

const PasswordInput: FC<Props> = ({value, name, id, placeholder, onChange}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  return (
    <div className="relative">
      <input
        type={isPasswordVisible ? 'text' : 'password'}
        value={value}
        name={name}
        id={id}
        className="normal-popup-input"
        onChange={onChange}
        placeholder={placeholder}
      />
      {isPasswordVisible ? (
        <AiFillEyeInvisible
          className="absolute bottom-[35%] right-3 cursor-pointer"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        />
      ) : (
        <AiFillEye
          className="absolute bottom-[35%] right-3 cursor-pointer"
          onClick={() => setIsPasswordVisible(!isPasswordVisible)}
        />
      )}
    </div>
  );
};

export default PasswordInput;
