import {Box, Table, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import {RootState} from 'redux/rootReducers';
import UserTableBody from './components/UserTableBody/UserTableBody';
import {getUsersAction} from 'redux/actions/getUsersAction';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {YELLOW} from 'constants/colors';

const UserListTable = () => {
  const {loading} = useSelector((state: RootState) => state.getEmployeeReducer);
  const {totalPages, currentPage, users} = useSelector((state: RootState) => state.getUsersReducer);
  const {currentLanguage} = useSelector((state: RootState) => state.changeLanguageReducer);

  const {t} = useTranslation();

  const isArabic = currentLanguage === 'ar';

  return (
    <Box>
      <TableContainer
        sx={{
          height: 500,
          borderRadius: 4,
          paddingRight: users.length > 4 ? (isArabic ? 0 : 2) : 0,
          paddingLeft: users.length > 4 ? (isArabic ? 2 : 0) : 0,
          overflowY: `${loading && 'hidden'}`,
        }}>
        <Table className="sessions-table">
          <TableHead sx={{position: 'sticky', top: 0, whiteSpace: 'nowrap'}}>
            <TableRow sx={{background: YELLOW}}>
              <TableCell></TableCell>
              <TableCell className="table-head">{t('Car_plate')}</TableCell>
              <TableCell className="table-head">{t('Username')}</TableCell>
              <TableCell className="table-head">{t('Phone_number')}</TableCell>
              <TableCell className="table-head">{t('National_ID')}</TableCell>
              <TableCell className="table-head">{t('Status')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <UserTableBody />
          <CustomTablePagination
            totalPages={totalPages}
            currentPage={currentPage}
            dispatchFunc={getUsersAction}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UserListTable;
