import {
  CLEAR_PARKING_STATE,
  CREATE_PARKING,
  CREATE_PARKING_FAILURE,
  CREATE_PARKING_LOADING,
  GET_PARKING_SETUP,
  GET_PARKING_SETUP_FAILED,
  GET_PARKING_SETUP_LOADING,
  PARKING_ROW_SETUP_SELECTED,
  RESET_PARKING_SETUP_ROW,
  SET_PARKING_ID,
  RESET_PARKING_ID,
} from 'redux/types';

import {Dispatch} from 'redux';
import {ParkingSetup} from 'model/parkingSetup';
import {updateParkingService} from 'services/parking/updateParkingService';
import {getParkingListService} from 'services/parking/getParkingListService';

export const parkingSetupRowSelected = (selectedRowValue: ParkingSetup | null) => {
  return {type: PARKING_ROW_SETUP_SELECTED, payload: {selectedRowValue}};
};

export const getParkingSetup = (page?: number | null) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: GET_PARKING_SETUP_LOADING, payload: true});
    const response = await getParkingListService();
    dispatch({type: GET_PARKING_SETUP, payload: response.data[0]});
  } catch (error) {
    dispatch({type: GET_PARKING_SETUP_FAILED, payload: error});
  } finally {
    dispatch({type: GET_PARKING_SETUP_LOADING, payload: false});
  }
};

export const resetSelectedParkingSetupRow = () => {
  return {type: RESET_PARKING_SETUP_ROW, payload: false};
};

export const createParkingAction =
  (data: any, onDone?: VoidFunction) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: CREATE_PARKING_LOADING, payload: true});
      const res = await updateParkingService(data);
      dispatch({type: CREATE_PARKING, payload: res.data});
      onDone?.();
    } catch (error) {
      dispatch({type: CREATE_PARKING_FAILURE, payload: error});
    } finally {
      dispatch({type: CREATE_PARKING_LOADING, payload: false});
    }
  };

export const setParkingID = (selectedParking: any) => (dispatch: Dispatch) => {
  dispatch({type: SET_PARKING_ID, payload: {selectedParking}});
};

export const resetParkingID = () => (dispatch: Dispatch) => {
  dispatch({type: RESET_PARKING_ID, payload: null});
};

export const clearParkingState = () => (dispatch: Dispatch) => {
  dispatch({type: CLEAR_PARKING_STATE, payload: null});
};
