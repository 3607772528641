import {
  GET_USERS_BY_PACKAGE,
  GET_USERS_BY_PACKAGE_FAILED,
  GET_USERS_BY_PACKAGE_LOADING,
} from 'redux/types';

const initialState = {
  users: [],
  loading: false,
  error: '',
};

export const getUsersByPackageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_USERS_BY_PACKAGE:
      return {...state, users: action.payload};
    case GET_USERS_BY_PACKAGE_LOADING:
      return {...state, loading: action.payload};
    case GET_USERS_BY_PACKAGE_FAILED:
      return {...state, error: action.payload};
    default:
      return state;
  }
};
