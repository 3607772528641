import {
  CLEAR_TRANSACTIONS,
  GET_EMPLOYEE_TRANSACTIONS,
  GET_EMPLOYEE_TRANSACTIONS_BY_DATE,
} from 'redux/types';

const initialState = {
  data: [],
  totalPages: 1,
  currentPage: 1,
};

export const employeeTransactionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_EMPLOYEE_TRANSACTIONS:
      return {
        ...state,
        data: action.payload[0].transactions,
        currentPage: action.payload[0].currentPage,
        totalPages: action.payload[0].totalPages,
      };
    case GET_EMPLOYEE_TRANSACTIONS_BY_DATE:
      return {
        ...state,
        data: action.payload.content,
        currentPage: 0,
        totalPages: 0,
      };
    case CLEAR_TRANSACTIONS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
};
