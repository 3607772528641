import {
  CLEAR_ASSIGN_PACKAGE,
  DELETE_ASSIGN_USER,
  DELETE_ASSIGN_USER_FAILED,
  DELETE_ASSIGN_USER_LOADING,
} from 'redux/types';

const initalState = {
  response: null,
  error: '',
  loading: false,
};

export const deleteAssignUserReducer = (state = initalState, action: any) => {
  switch (action.type) {
    case DELETE_ASSIGN_USER:
      return {...state, response: action.payload};
    case DELETE_ASSIGN_USER_LOADING:
      return {...state, loading: action.payload};
    case DELETE_ASSIGN_USER_FAILED:
      return {...state, error: action.payload};
    case CLEAR_ASSIGN_PACKAGE:
      return initalState;
    default:
      return state;
  }
};
