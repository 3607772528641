import {
  CLEAR_PACKAGE_CATEGORY_STATE,
  CREATE_PACKAGE_CATEGORY,
  CREATE_PACKAGE_CATEGORY_FAILED,
  CREATE_PACKAGE_CATEGORY_LOADING,
  DELETE_PACKAGE_CATEGORY,
  DELETE_PACKAGE_CATEGORY_FAILED,
  DELETE_PACKAGE_CATEGORY_LOADING,
  GET_ALL_PACKAGE_CATEGORY,
  GET_ALL_PACKAGE_CATEGORY_FAILED,
  GET_ALL_PACKAGE_CATEGORY_LOADING,
  UPDATE_PACKAGE_CATEGORY,
  UPDATE_PACKAGE_CATEGORY_FAILED,
  UPDATE_PACKAGE_CATEGORY_LOADING,
} from 'redux/types';

const initialState = {
  loading: false,
  errors: '',
  createdcategory: null,
  updatedcategory: null,
  deletedcategory: null,
  categories: null,
};

export const PackageCategoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CREATE_PACKAGE_CATEGORY:
      return {...state, createdcategory: action.payload};
    case CREATE_PACKAGE_CATEGORY_LOADING:
      return {...state, loading: action.payload};
    case CREATE_PACKAGE_CATEGORY_FAILED:
      return {...state, errors: action.payload.response.data.statusMessage};
    case UPDATE_PACKAGE_CATEGORY:
      return {...state, updatedcategory: action.payload};
    case UPDATE_PACKAGE_CATEGORY_LOADING:
      return {...state, loading: action.payload};
    case UPDATE_PACKAGE_CATEGORY_FAILED:
      return {...state, errors: action.payload.response.data.statusMessage};
    case DELETE_PACKAGE_CATEGORY:
      return {...state, deletedcategory: action.payload};
    case DELETE_PACKAGE_CATEGORY_LOADING:
      return {...state, loading: action.payload};
    case DELETE_PACKAGE_CATEGORY_FAILED:
      return {...state, errors: action.payload.response.data.statusMessage};
    case GET_ALL_PACKAGE_CATEGORY:
      return {...state, categories: action.payload};
    case GET_ALL_PACKAGE_CATEGORY_LOADING:
      return {...state, loading: action.payload};
    case GET_ALL_PACKAGE_CATEGORY_FAILED:
      return {...state, errors: action.payload.response.data.statusMessage};

    case CLEAR_PACKAGE_CATEGORY_STATE:
      return {
        ...state,
        createdcategory: null,
        updatedcategory: null,
        deletedcategory: null,
        errors: null,
        loading: false,
      };
    default:
      return state;
  }
};
