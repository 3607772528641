// import {ar, en} from 'assets/strings';
// import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import {initReactI18next} from 'react-i18next';

// export const initI18n = async () =>
//   await i18n
//     .use(initReactI18next)
//     .use(LanguageDetector)
//     .init({
//       resources: {
//         en: {
//           translation: en,
//         },
//         ar: {
//           translation: ar,
//         },
//       },
//       detection: {
//         order: ['path', 'localStorage', 'cookie', 'htmlTag'],
//         caches: ['localStorage', 'cookie'],
//       },
//       fallbackLng: 'en',
//       react: {
//         useSuspense: false, // we can replace it with a loader component
//       },
//     });


import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import englishTranslations from '../assets/strings/en.json';
import arabicTranslations from '../assets/strings/ar.json';
import getPreferredLanguage from '../assets/strings/languageUtils';


const preferredLanguage = getPreferredLanguage();

i18n
  // .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // Specify the supported languages
    // supportedLngs: ['en', 'ar'],
    fallbackLng: 'en', // Default language in case the user's preferred language is not available
    // detection: {
    //   order: ['localStorage', 'navigator'], // Detect language from localStorage or the browser's navigator language
    // },
    resources: {
      en: {
        translation: englishTranslations,
      },
      ar: {
        translation: arabicTranslations,
      },
    },
    lng: preferredLanguage, // Set the initial language based on the user's preference from localStorage
});