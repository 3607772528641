import {Doughnut} from 'react-chartjs-2';

import Styles from '../ProcessAmountPieChart/ProcessAmountPieChart.styles.module.css';
import {RootState} from 'redux/rootReducers';
import {useSelector} from 'react-redux';
import {COLORS_ARRAY} from 'constants/colors';
import {t} from 'i18next';
import {FC} from 'react';

interface Props {
  datePickerUpdated: boolean;
}

export const PackagesAmountDoughnut: FC<Props> = ({datePickerUpdated}) => {
  const {packageChartData, filteredPackageAmount} = useSelector(
    (state: RootState) => state.chartReducer,
  );
  const chartPackagesArray: any = datePickerUpdated
    ? filteredPackageAmount && Object.keys(filteredPackageAmount).length > 0
      ? Object.entries(filteredPackageAmount)
      : []
    : packageChartData && Object.keys(packageChartData).length > 0
    ? Object.entries(packageChartData)
    : [];

  const totalAmount = chartPackagesArray
    .map((pck: Array<{second: any}>) => {
      return pck[1].second;
    })
    .reduce((accumulator: any, currentValue: any) => {
      return accumulator + currentValue;
    }, 0);

  const getLabels = () => {
    const labels = [];
    for (let i = 0; i < chartPackagesArray?.length; i++) {
      labels.push(chartPackagesArray[i][0]);
    }
    return labels;
  };
  getLabels();

  function getSecondValues(array: string | any[]) {
    const result = [];

    for (let i = 0; i < chartPackagesArray.length; i++) {
      if (array.length > i) {
        result.push(array[i][1]?.second || 0);
      } else {
        result.push(0);
      }
    }

    return result;
  }

  const chartData = {
    labels: getLabels(),
    datasets: [
      {
        data: getSecondValues(chartPackagesArray),
        backgroundColor: COLORS_ARRAY,
        hoverBackgroundColor: COLORS_ARRAY,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    cutout: 67,
    elements: {
      arc: {
        borderWidth: 0,
      },
    },

    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className={Styles.processAmount}>
      <h2 className={Styles.processAmountTitle}>{t('Packages / Total amount')}</h2>
      <div className={Styles.chartContainer}>
        <Doughnut data={chartData} options={chartOptions} height={190} />
        <p className={Styles.centerText}>
          <span style={{whiteSpace: 'nowrap', display: 'block'}}>{t('Total amount')}</span>
          <span>
            {totalAmount.toFixed(2)} {t('SR')}
          </span>
        </p>
      </div>
      <div dir="ltr" className={Styles.footer} style={{marginTop: '15px'}}>
        <p
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            padding: '0 20px',
          }}>
          <span style={{color: '#B9B9B9', fontSize: '13.5px'}}>{t('Package')}</span>
          <span style={{color: '#B9B9B9', fontSize: '13.5px'}}>{t('Amount')}</span>
        </p>
        <span
          style={{
            borderBottom: 'solid 1px  #B9B9B9',
            padding: '3px 0 5px',
            display: 'block',
            width: '100%',
          }}
        />
        <div style={{overflowY: 'scroll', margin: 0, height: '120px'}}>
          {chartPackagesArray?.map((pkg: any, idx: number) => (
            <div className={Styles.label} key={idx} style={{paddingRight: '7px'}}>
              <span>
                <span className="circle" style={{backgroundColor: COLORS_ARRAY[idx]}} />
                {pkg[0]}
              </span>
              <span>{((pkg[1].second / totalAmount) * 100).toFixed(2)}%</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
