import {GateEmployeeTransaction} from 'model/GateEmployee';
import {
  CLEAR_TRANSACTIONS,
  GET_EMPLOYEE_TRANSACTIONS,
  GET_EMPLOYEE_TRANSACTIONS_BY_DATE,
} from 'redux/types';

export const getEmployeeTransactions = (transactions: GateEmployeeTransaction[]) => {
  return {type: GET_EMPLOYEE_TRANSACTIONS, payload: transactions};
};

export const getEmployeeTransactionsByDate = (transactions: GateEmployeeTransaction[]) => {
  return {type: GET_EMPLOYEE_TRANSACTIONS_BY_DATE, payload: transactions};
};

export const clearTransactions = () => {
  return {type: CLEAR_TRANSACTIONS, payload: null};
};
