import {SessionsTableTypes} from 'model/types';
import {FINISH_SEARCH, GET_FILTERED_DATA, GET_FILTERED_DATA_LOADING, SEARCH} from 'redux/types';

const initialState = {
  query: '',
  isSearching: false,
  filteredData: [],
  error: '',
  searchLoading: false,
};

export const searchReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SEARCH:
      return {...state, query: action.payload, isSearching: true};
    case FINISH_SEARCH:
      return {...state, isSearching: false};
    case GET_FILTERED_DATA: {
      const filteredData = action.payload.filter(
        (data: SessionsTableTypes) => data.phone === state.query,
      );
      if (filteredData.length === 0) {
        return {...state, filteredData, error: 'Phone number not found'};
      }
      return {...state, filteredData};
    }
    case GET_FILTERED_DATA_LOADING:
      return {...state, searchLoading: action.payload};
    default:
      return initialState;
  }
};
