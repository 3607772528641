import * as Yup from 'yup';
import {Col, Container, Row} from 'react-bootstrap';
import Box from '@mui/material/Box';
import {ChangeEvent, Dispatch, FC, SetStateAction} from 'react';
import {RootState} from 'redux/rootReducers';
import {useFormik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import {updateUser} from 'redux/actions/usersAction';
import './styles.css';
import {useTranslation} from 'react-i18next';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {emailRegExpValidation} from 'utils/generalValidations';
interface Props {
  setResetPassModel?: Dispatch<SetStateAction<boolean>>;
  image?: File | string | null;
  isGate?: boolean;
}

const ProfileInfo: FC<Props> = ({setResetPassModel, isGate, image}) => {
  const dispatch: any = useDispatch();
  const {loading, newUser} = useSelector((state: RootState) => state.usersReducer);
  const {user} = useSelector((state: RootState) => state.authReducer);
  const {t} = useTranslation();
  const {i18n} = useTranslation();

  const currentLanguage = i18n.language;

  function base64ToBinary(base64String: string): Blob {
    const binaryString = atob(base64String);
    const length = binaryString.length;
    const binaryArray = new Uint8Array(length);

    for (let i = 0; i < length; i++) {
      binaryArray[i] = binaryString.charCodeAt(i);
    }

    // Convert the Uint8Array to a Blob
    return new Blob([binaryArray]);
  }

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    values,
    errors,
    touched,
    isValid,
    dirty,
  } = useFormik({
    initialValues: {
      name: newUser ? newUser.name : user.name ?? '',
      email: newUser ? newUser.email : user.email ?? '',
      phone: newUser ? newUser.phone.substring(3) : user.phone.substring(3) ?? '',
      nationalId: newUser ? newUser.nationalId : user.nationalId ?? '',
      id: newUser ? newUser.id : user.id ?? '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().max(30, 'Name must be less than 30 char').required('Name is required'),
      email: Yup.string()
        .matches(emailRegExpValidation, 'Email is not valid')
        .required('Email is required'),
      nationalId: Yup.string()
        .matches(/^\d{10}$/, 'National ID should be 10 digits')
        .required('National ID is required'),
    }),
    onSubmit: values => {
      const formData = new FormData();
      const phone = 966 + values.phone;
      formData.append(
        'updateUserRequestApiDto',
        JSON.stringify({
          ...values,
          phone,
        }),
      );
      if (image) {
        if (typeof image === 'string') {
          const binaryBlob = base64ToBinary(image);
          formData.append('picture', binaryBlob);
        } else {
          formData.append('picture', image);
        }
      }
      dispatch(updateUser(formData));
    },
  });

  const handleNationalIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (/^[0-9]*$/.test(e.target.value)) {
      setFieldValue('nationalId', e.target.value);
    }
  };

  return (
    <Container className="mt-4" fluid>
      <form onSubmit={handleSubmit}>
        <Row className={` ${currentLanguage === 'en' ? 'flex-row-reverse' : 'flex'}`}>
          <Col sm={12} md={6} lg={3}>
            <div className="input-box">
              <Box className="box">
                <label className="label">{t('Name')}</label>
                <input
                  disabled={isGate}
                  className="input"
                  placeholder="Enter name..."
                  value={values.name}
                  name="name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.name && touched.name && (
                  <p className="validation-error">{errors.name as string}</p>
                )}
              </Box>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="input-box">
              <Box className="box">
                <label className="label">{t('E-Mail')}</label>
                <input
                  className="input"
                  placeholder="Enter email..."
                  value={values.email}
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.email && touched.email && (
                  <p className="validation-error">{errors.email as string}</p>
                )}
              </Box>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="input-box">
              <Box className="box">
                <label className="label">{t('Phone Number')}</label>
                <div className="containPhoneCode !border-none !rounded-[15px]">
                  <span>+966</span>
                  <input
                    disabled
                    className="input"
                    placeholder="Enter phone number..."
                    value={values.phone}
                    name="phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </div>
                {errors.phone && touched.phone && (
                  <p className="validation-error">{errors.phone as string}</p>
                )}
              </Box>
            </div>
          </Col>
          <Col sm={12} md={6} lg={3}>
            <div className="input-box">
              <Box className="box">
                <label className="label">{t('National_Id')}</label>
                <input
                  className="input"
                  value={values.nationalId}
                  name="nationalId"
                  onChange={handleNationalIdChange}
                  onBlur={handleBlur}
                />
                {errors.nationalId && touched.nationalId && (
                  <p className="validation-error">{errors.nationalId as string}</p>
                )}
              </Box>
            </div>
          </Col>
        </Row>
        <Box className="profile-btns mt-24">
          <CustomLoader loading={loading} size={80}>
            <CustomButton
              disabled={!((isValid && dirty) || image !== newUser.profilePicture)}
              type="submit"
              className="submitButton">
              {t('Save')}
            </CustomButton>
          </CustomLoader>
          {setResetPassModel && (
            <p
              onClick={() => {
                setResetPassModel?.(true);
              }}
              style={{textDecoration: 'underline', cursor: 'pointer'}}
              className="mt-3 ">
              Do you want to reset your password ?
            </p>
          )}
        </Box>
      </form>
    </Container>
  );
};

export default ProfileInfo;
