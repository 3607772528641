import {Navigate, Outlet} from 'react-router-dom';

import {FC} from 'react';
import {HOME_PATH} from 'routes/paths';
import {RouteProps} from 'routes';

const GateRoutes: FC<RouteProps> = ({user}) => {
  return user?.isGateEmployee ? <Outlet /> : <Navigate to={HOME_PATH} />;
};

export default GateRoutes;
