import {
  CLEAR_PARKING_STATE,
  CREATE_PARKING,
  CREATE_PARKING_FAILURE,
  CREATE_PARKING_LOADING,
  GET_PARKING_SETUP,
  GET_PARKING_SETUP_FAILED,
  GET_PARKING_SETUP_LOADING,
  PARKING_ROW_SETUP_SELECTED,
  RESET_PARKING_SETUP_ROW,
  SET_PARKING_ID,
  RESET_PARKING_ID,
} from 'redux/types';

const initialState = {
  parkingsSetup: [],
  selectedRowValue: null,
  loading: false,
  error: '',
  newParking: null,
  totalPages: 1,
  currentPage: 1,
  selectedParking: undefined,
};

export const parkingSetupReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PARKING_ROW_SETUP_SELECTED:
      return {
        ...state,
        selectedRowValue: action.payload.selectedRowValue,
      };
    case GET_PARKING_SETUP:
      return {
        ...state,
        parkingsSetup: action.payload.parkings,
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case GET_PARKING_SETUP_LOADING:
      return {...state, loading: action.payload};
    case GET_PARKING_SETUP_FAILED:
      return {...state, error: action.payload};
    case RESET_PARKING_SETUP_ROW:
      return {...state, selectedRowValue: null};
    case CREATE_PARKING:
      return {...state, newParking: action.payload};
    case CREATE_PARKING_LOADING:
      return {...state, loading: action.loading};
    case CREATE_PARKING_FAILURE:
      return {...state, error: action.payload.response.data.statusMessage};
    case SET_PARKING_ID:
      return {...state, selectedParking: action.payload.selectedParking};
    case RESET_PARKING_ID:
      return {...state, selectedParking: undefined};
    case CLEAR_PARKING_STATE:
      return {...state, selectedRowValue: null, error: '', newParking: null};
    default:
      return state;
  }
};
