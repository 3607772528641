import {MutableRefObject, forwardRef, useRef, useState} from 'react';

import {DateRange} from 'react-date-range';
import styles from 'utils/dropdowns/Dropdown.module.css';
import {useOutsideClick} from 'hooks/useOutsideClick';
import {whiteDownArrow} from 'assets/icons';
import {useTranslation} from 'react-i18next';

interface Props {
  placeholder: string;
  value: string;
  handleRangeSelect?: (range: {startDate: Date; endDate: Date}) => void;
  disabled?: boolean;
  width?: string;
  height?: string;
  backgroundColor?: string;
  handleSave?: () => void;
  handleReset?: () => void;
}

const CalendarDropdown = forwardRef<HTMLDivElement, Props>(
  (
    {
      placeholder,
      value,
      handleRangeSelect,
      disabled,
      width,
      height,
      backgroundColor,
      handleSave,
      handleReset,
    },
    ref,
  ) => {
    const {t, i18n} = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedRange, setSelectedRange] = useState<Array<Record<string, unknown>>>([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    const [saved, setSaved] = useState<boolean>(false);
    const divRef = useRef<HTMLDivElement>(null);

    const scrollToBottom = () => {
      const scrollHeight = (ref as MutableRefObject<HTMLDivElement | null>).current?.scrollHeight;
      const height = (ref as MutableRefObject<HTMLDivElement | null>).current?.clientHeight;
      const maxScrollTop = scrollHeight! - height!;
      (ref as MutableRefObject<HTMLDivElement | null>).current?.scrollTo({
        top: maxScrollTop,
        behavior: 'smooth',
      });
    };

    useOutsideClick(divRef, () => {
      setIsOpen(false);
    });

    const handleDisabled = () => {
      if (disabled) setIsOpen(false);
    };

    return (
      <div
        style={{height, width, backgroundColor}}
        ref={divRef}
        onClick={() => {
          setIsOpen(true);
          scrollToBottom();
          handleDisabled();
        }}
        className={`${styles.dropdownContainer} ${styles.textField} cursor-pointer`}>
        <input
          readOnly
          style={{backgroundColor}}
          disabled={disabled}
          className={disabled ? 'cursor-default' : 'cursor-pointer'}
          type="text"
          value={!saved ? '' : value}
          placeholder={placeholder}
          onClick={() => {
            setIsOpen(true);
            scrollToBottom();
            handleDisabled();
          }}
        />
        <img
          src={whiteDownArrow}
          alt="whiteArrow"
          className={`${disabled ? 'cursor-default' : 'cursor-pointer'} ${
            isOpen && styles.rotateIcon
          } ${i18n.language === 'ar' && '!right-auto left-[8%]'}`}
          onClick={() => {
            setIsOpen(!isOpen);
            handleDisabled();
          }}
        />
        <div
          className={`${styles.dropdownMenu} ${isOpen && styles.dropdownOpened} ${
            styles.dropdownCalendar
          }`}>
          <>
            <DateRange
              fixedHeight
              ranges={selectedRange}
              onChange={(item: {selection: {startDate: Date; endDate: Date; key: string}}) => {
                if (!saved) setSelectedRange([item.selection]);
              }}
            />
            <div className={styles.calendarButtons}>
              <button
                type="button"
                onClick={() => {
                  setSaved(false);
                  setSelectedRange([
                    {startDate: new Date(), endDate: new Date(), key: 'selection'},
                  ]);
                  handleRangeSelect!({
                    startDate: new Date(),
                    endDate: new Date(),
                  });
                  handleReset?.();
                }}>
                {t('Reset')}
              </button>
              <button
                type="button"
                disabled={saved}
                onClick={e => {
                  e.stopPropagation();
                  setIsOpen(false);
                  setSaved(true);
                  handleRangeSelect!({
                    startDate: selectedRange?.[0].startDate as Date,
                    endDate: selectedRange?.[0].endDate as Date,
                  });
                  handleSave?.();
                }}>
                {t('Save')}
              </button>
            </div>
          </>
        </div>
      </div>
    );
  },
);

CalendarDropdown.displayName = 'CalendarDropdown';

export default CalendarDropdown;
