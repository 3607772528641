import api from 'api';
import {getTransactionsEndpoint} from 'api/apisURL';

export const getTransactionsService = async (
  pageNumber: number,
  date?: {from: string; to: string},
  getAll?: boolean,
) => {
  const payload = {
    filters: [] as object[],
    pageNumber: date ?? getAll ? 1 : pageNumber,
    pageSize: date ?? getAll ? 10000 : 10,
  };

  if (date) {
    payload.filters.push({
      fieldName: 'checkInDate',
      operator: 'between',
      values: [date.from, date.to],
    });
  }

  const response = await api.post(getTransactionsEndpoint(), payload);
  return response;
};
