import api from 'api';
import {getCurrentSessionsEndPoint} from 'api/apisURL';

export const getCurrentSessions = async (
  page?: number,
  parkingId?: string,
  otp?: string,
  status?: string[],
) => {
  const payload: any = {
    filters: [],
    pageNumber: page ?? undefined,
    pageSize: page ? 10 : undefined,
  };

  if (parkingId) {
    payload.filters.push({
      fieldName: 'parkingId',
      operator: 'like',
      values: [parkingId],
    });
  }

  if (status) {
    if (status.length > 1) {
      payload.filters.push({
        fieldName: 'status',
        operator: 'in',
        values: status,
      });
    } else {
      payload.filters.push({
        fieldName: 'status',
        operator: 'like',
        values: status,
      });
    }

    if (otp) {
      payload.filters.push({
        fieldName: 'otp',
        operator: 'like',
        values: [otp],
      });
    }
  }
  const responde = await api.post(getCurrentSessionsEndPoint(), payload);
  return responde;
};

export const getAllSessions = async () => {
  const response = await api.post(getCurrentSessionsEndPoint(), {
    filters: [],
    pageNumber: 1,
    pageSize: 1000,
  });
  return response;
};
