import axios, {InternalAxiosRequestConfig} from 'axios';

import {logoutAction} from 'redux/actions/authAction';
import {notification} from 'antd';
import store from 'redux/store';

// production
const apiURL = 'https://parking.tarqia.net:44445';

// qa
// const apiURL = 'http://172.105.16.29:44444';

// dev
// const apiURL = 'http://172.105.16.29:55555';

const instance = axios.create({
  baseURL: apiURL,
});

instance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = store.getState().authReducer.user?.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  async error => {
    return await Promise.reject(error);
  },
);
const checkAuth = (notificationParam: any) => {
  if (window.location.pathname === '/login' || window.location.pathname === '/profile') {
    return;
  }

  store.dispatch(logoutAction());
};
// errors Handling
instance.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const notificationParam = {
      message: '',
      description: '',
    };

    switch (error.response.status) {
      case 401:
        checkAuth(notificationParam);
        break;
      case 400:
        notificationParam.message = 'Bad Request';
        notificationParam.description = 'Check that data is being sent';
        break;
      case 404:
        notificationParam.message = 'Not Found';
        break;
      case 500:
        notificationParam.message = 'Internal Server Error';
        break;
      case 508:
        notificationParam.message = 'Time Out';
        notificationParam.description = 'The server terminated an operation';
        break;
      default:
        break;
    }

    if (notificationParam.message) notification.error(notificationParam);

    return await Promise.reject(error);
  },
);
export default instance;
