import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import ParkingDropDown from 'utils/dropdowns/ParkingDropDown';
import {RootState} from 'redux/rootReducers';
import ShiftTable from './ShiftTable/ShiftTable';
import {useSelector} from 'react-redux';

const ShiftData = () => {
  const {shifts, loading} = useSelector((state: RootState) => state.getShiftReducer);

  return (
    <div id="shift-data">
      <MainViewContainer>
        <div className="flex justify-between">
          <ParkingDropDown className="my-3" />
          <HeaderDate orange />
        </div>
        <ShiftTable data={shifts} loading={loading} />
      </MainViewContainer>
    </div>
  );
};

export default ShiftData;
