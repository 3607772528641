import {GET_SHIFT, GET_SHIFT_ERROR, GET_SHIFT_LOADING} from 'redux/types';

const initialState = {
  shifts: null,
  currentPage: 1,
  totalPages: 1,
  error: null,
  loading: false,
};

export const getShiftReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SHIFT:
      return {
        ...state,
        shifts: action.payload.shifts,
        error: null,
        totalPages: action.payload?.totalPages,
        currentPage: action.payload?.currentPage,
      };
    case GET_SHIFT_ERROR:
      return {...state, error: action.payload};
    case GET_SHIFT_LOADING:
      return {...state, loading: action.payload};
    default:
      return state;
  }
};
