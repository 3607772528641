import {
  CLEAR_UPDATE_PACKAGE,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_FAILED,
  UPDATE_PACKAGE_LOADING,
} from 'redux/types';

const initalState = {
  response: '',
  error: '',
  loading: false,
};

export const updatePackageReducer = (state = initalState, action: any) => {
  switch (action.type) {
    case UPDATE_PACKAGE:
      return {...state, response: action.payload};
    case UPDATE_PACKAGE_LOADING:
      return {...state, loading: action.payload};
    case UPDATE_PACKAGE_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case CLEAR_UPDATE_PACKAGE:
      return initalState;
    default:
      return state;
  }
};
