import api from 'api';
import {UpdateusersEndPoint} from 'api/apisURL';

const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
};

export const updateUserService = async (EdittedData: any) => {
  const responde = await api.put(UpdateusersEndPoint(), EdittedData, config);
  return responde;
};
