import {Col, Row} from 'react-bootstrap';

import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import ShiftCard from 'components/ShiftCard/ShiftCard';

const StartShift = () => {
  return (
    <div>
      <MainViewContainer>
        <Row className="special-row">
          <Col className="flex justify-end">
            <HeaderDate orange />
          </Col>
        </Row>
        <Row>
          <div className="flex justify-center items-center">
            <ShiftCard type="start" />
          </div>
        </Row>
      </MainViewContainer>
    </div>
  );
};

export default StartShift;
