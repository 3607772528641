import {mouseClick} from 'assets/icons';
import './styles.css';
import {useMutation, useQuery} from 'react-query';
import {getGateEmployeesService} from 'services/user/getGateEmployeesService';
import {GateEmployee, GateEmployeeTransaction} from 'model/GateEmployee';
import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {getWalletTransactionsService} from 'services/wallet/getWalletTransactionsService';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducers';
import {clearTransactions, getEmployeeTransactions} from 'redux/actions/employeeTransactionActions';
import {useTranslation} from 'react-i18next';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';

interface Props {
  selectedEmployee: GateEmployee;
  setSelectedEmployee: Dispatch<SetStateAction<GateEmployee | null>>;
  setDatePickerUpdated: Dispatch<boolean>;
}

const EmployeeWalletTable: FC<Props> = ({
  selectedEmployee,
  setSelectedEmployee,
  setDatePickerUpdated,
}) => {
  const {t} = useTranslation();

  const {
    data: walletTransactions,
    currentPage,
    totalPages,
  } = useSelector((state: RootState) => state.employeeTransactionsReducer);

  const dispatch = useDispatch();

  const {data, isLoading: isEmployeesLoading} = useQuery('gateEmployees', async () => {
    return await getGateEmployeesService();
  });

  const {mutate, isLoading: isTransactionsLoading} = useMutation(getWalletTransactionsService, {
    onSuccess: data => {
      dispatch(getEmployeeTransactions(data));
    },
  });

  useEffect(() => {
    return () => {
      dispatch(clearTransactions());
      setSelectedEmployee(null);
    };
  }, []);

  const gateEmployees = data?.[0]?.users;

  const renderEmployeesRows = () => {
    return (
      <CustomLoader multiTable loading={isEmployeesLoading} size={80}>
        {gateEmployees?.map((gateEmployee: GateEmployee) => {
          return (
            <tr
              key={gateEmployee?.id}
              className="cursor-pointer"
              onClick={() => handleEmployeeClick(gateEmployee)}>
              <td />
              <td className={selectedEmployee?.id === gateEmployee?.id ? 'item-selected' : ''}>
                {gateEmployee.name}
              </td>
              <td
                className={`whitespace-normal ${
                  selectedEmployee?.id === gateEmployee?.id ? 'item-selected' : ''
                }`}>
                {gateEmployee?.nationalId ?? '--'}
              </td>
              <td></td>
            </tr>
          );
        })}
      </CustomLoader>
    );
  };

  const handleEmployeeClick = (gateEmployee: GateEmployee) => {
    setSelectedEmployee(gateEmployee);
    mutate({id: gateEmployee?.id});
    setDatePickerUpdated(false);
  };

  const renderTransactionHeaders = () => {
    if (selectedEmployee && walletTransactions) {
      return (
        <>
          <th>{t('Transaction type')}</th>
          <th>{t('Date, time')}</th>
          <th>{t('Balance before')} </th>
          <th>{t('Amount')}</th>
          <th>{t('Balance after')}</th>
        </>
      );
    }
  };

  const getTransactionDate = (date: string) => {
    const dateToFormat = moment.utc(date).local();

    if (dateToFormat.isValid()) {
      return dateToFormat.format('DD MMM. h:mm a');
    }

    return '--';
  };

  const renderTransactionsBody = () => {
    if (walletTransactions || isTransactionsLoading) {
      if (walletTransactions?.length < 1) {
        return (
          <tbody className="h-[664px]">
            <td className="!text-[var(--disabled-grey)] text-2xl">{t('No transactions found')}</td>
          </tbody>
        );
      }
      return (
        <CustomLoader multiTable loading={isTransactionsLoading} size={80} height={664}>
          <tbody className="custom-table-body transactions-body-right">
            {walletTransactions?.map((transaction: GateEmployeeTransaction, index: number) => {
              return (
                <tr key={index}>
                  <td />
                  <td>{transaction.trxType ?? '--'}</td>
                  <td>{getTransactionDate(transaction.trxDate)}</td>
                  <td>{(transaction.balanceBefore ?? '--') + ' SR'}</td>
                  <td>{(Math.abs(transaction.balance) ?? '--') + ' SR'}</td>
                  <td>{(transaction.balanceAfter ?? '--') + ' SR'}</td>
                  <td />
                </tr>
              );
            })}
          </tbody>
          <CustomTablePagination
            totalPages={totalPages}
            currentPage={currentPage}
            onChange={currentPage => mutate({id: selectedEmployee?.id, pageNumber: currentPage})}
          />
        </CustomLoader>
      );
    }

    return (
      <div className="flex flex-col gap-10 items-center justify-center h-[664px]">
        <img src={mouseClick} alt="mouse-click" />
        <p className="text-[var(--disabled-grey)]">
          {t('Please select employee to show Transactions history')}
        </p>
      </div>
    );
  };

  return (
    <div id="multiple-sessions-table">
      <div className="col-lg-4 col-md-12">
        <table dir="ltr" className="custom-table-container first-table ">
          <thead className="head-container transactions-header-left">
            <tr className="custom-table-head">
              <th />
              <th>{t('Employee Name')}</th>
              <th>{t('National Id')}</th>
              <th />
            </tr>
          </thead>
          <tbody className="custom-table-body transactions-body-left">
            {gateEmployees?.length > 0 ? (
              renderEmployeesRows()
            ) : (
              <div className="!text-[var(--disabled-grey)] text-2xl translate-y-56">
                {t('No transactions found')}
              </div>
            )}
          </tbody>
        </table>
      </div>
      <div className="col-lg-8 col-md-12 bg-black">
        <table dir="ltr" className="custom-table-container second-table">
          <thead className="head-container transactions-header-right">
            <tr className="custom-table-head">
              <th />
              {renderTransactionHeaders()}
              <th />
            </tr>
          </thead>
          {renderTransactionsBody()}
        </table>
      </div>
    </div>
  );
};

export default EmployeeWalletTable;
