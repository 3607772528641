import {Revenue} from 'model/Reports/Revenue';

export const getRevenueCells = (report: Revenue) => {
  const {year, month, packageName, revenue} = report;

  return [
    year ?? '--',
    month ? month.charAt(0).toUpperCase() + month.slice(1).toLowerCase() : '--',
    packageName ?? '--',
    revenue ? revenue + ' SR' : '--',
  ];
};
