import './styles.css';

import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FC, useEffect, useState} from 'react';
import {arrowRight} from 'assets/icons';
import {useDispatch, useSelector} from 'react-redux';

import CustomCheckbox from 'components/CustomCheckBox/CustomCheckBox';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import {SessionsTableTypes} from 'model/types';
import {StatusColor} from 'utils/statusColor';
import {YELLOW} from 'constants/colors';
import {getSessionsAction} from 'redux/actions/getSessionsAction';
import moment from 'moment';

interface props {
  setSession: React.Dispatch<React.SetStateAction<any>>;
  checkedRow?: SessionsTableTypes | undefined;
  setCheckedRow?: (row: SessionsTableTypes | undefined) => void;
}

const GateSessionsTable: FC<props> = ({setSession, setCheckedRow, checkedRow}) => {
  const dispatch: any = useDispatch();
  const {user} = useSelector((state: RootState) => state.authReducer);

  const {sessions, currentPage, totalPages, fetchLoading} = useSelector(
    (state: RootState) => state.getSessionsReducer,
  );
  const {updatedSession} = useSelector((state: RootState) => state.sessionsReducer);
  const {isSearching, filteredData, error, searchLoading} = useSelector(
    (state: RootState) => state.searchReducer,
  );

  const [reservationResponse, setReservationResponse] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getSessionsAction(currentPage, user?.parkingId, ['RUNNING', 'CLOSED']));
  }, [updatedSession]);

  const handleCheck = (row: any) => {
    if (checkedRow === row) {
      setCheckedRow?.(undefined);
    } else {
      setCheckedRow?.(row);
    }
  };

  const handleChange = () => setCheckedRow?.(undefined);

  const notCreatedSessions = sessions.filter(
    (session: {status: string}) => session.status !== 'CREATED',
  );

  const formatDateTime = (dateTime: string) => {
    return moment(dateTime).isValid()
      ? moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss')
      : '--';
  };

  const sessionToRender = isSearching ? filteredData : notCreatedSessions;

  return (
    <Box>
      <TableContainer
        className="our-table-container"
        sx={{
          height: 500,
          borderRadius: 4,
          paddingRight: 0,
          overflow: `${(fetchLoading || searchLoading) && 'hidden'}`,
        }}>
        <Table className="sessions-table" sx={{fontFamily: 'Poppins'}}>
          <TableHead sx={{position: 'sticky', top: 0}}>
            <TableRow sx={{background: YELLOW}}>
              <TableCell />
              <TableCell>User phone</TableCell>
              <TableCell>Package</TableCell>
              <TableCell>Car plate</TableCell>
              <TableCell>Check in time</TableCell>
              <TableCell>Total slots</TableCell>
              <TableCell>Check in slots</TableCell>
              <TableCell>Check out slots</TableCell>
              <TableCell>Check out time</TableCell>
              <TableCell>Process status</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody sx={{height: sessionToRender.length > 3 ? 500 : 0}}>
            <CustomLoader size={80} loading={fetchLoading || searchLoading}>
              {sessionToRender?.length === 0 || sessionToRender === undefined ? (
                <TableRow className="empty-row">
                  <TableCell colSpan={14} className="no-data">
                    {isSearching ? error : 'No data'}
                  </TableCell>
                </TableRow>
              ) : (
                sessionToRender.map((session: any) => {
                  return (
                    <TableRow key={session.id}>
                      <TableCell />
                      <TableCell>
                        {session?.processStatus === 'VIP'
                          ? session.contactPhone
                            ? '+' + session.contactPhone
                            : '--'
                          : session.phone
                          ? '+' + session.phone
                          : '--'}
                      </TableCell>
                      <TableCell>{session.packageName ?? '--'}</TableCell>
                      <TableCell>{session.carNumber?.split(' ').join('') ?? '--'}</TableCell>
                      <TableCell>{formatDateTime(session.checkInDate)}</TableCell>
                      <TableCell>{session.slotCount ?? '--'}</TableCell>
                      <TableCell>{session.checkedIn ?? '--'}</TableCell>
                      <TableCell>{session.checkedOut ?? '--'}</TableCell>
                      <TableCell>
                        {session.status === 'RUNNING'
                          ? '--'
                          : session.checkOutDate
                          ? formatDateTime(session.checkOutDate)
                          : '--'}
                      </TableCell>
                      <TableCell>{session.processStatus ? session.processStatus : '--'}</TableCell>
                      {/* return tableCell with diff colors depend on status */}
                      {StatusColor(session.status)}

                      <TableCell>{session.status === 'CLOSED' ? session.amount : '--'}</TableCell>
                      <TableCell>
                        <CustomCheckbox
                          className={`normal ${
                            (session.status === 'CLOSED' || session.status === 'CREATED') &&
                            'cursor-not-allowed'
                          }`}
                          checkClick={e => {
                            handleCheck(session);
                          }}
                          isChecked={checkedRow === session}
                          disabled={session.status === 'CLOSED' || session.status === 'CREATED'}
                        />
                      </TableCell>
                      <TableCell sx={{pl: '2%'}}>
                        <CustomIcon
                          href={arrowRight}
                          className="cursor-pointer mr-5"
                          onClick={() => {
                            setSession(session);
                          }}
                        />
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  );
                })
              )}
            </CustomLoader>
          </TableBody>
          {!isSearching && (
            <CustomTablePagination
              totalPages={totalPages}
              currentPage={currentPage}
              filters={[user?.parkingId, ['RUNNING', 'CLOSED']]}
              dispatchFunc={getSessionsAction}
              onChange={handleChange}
              currentSession
            />
          )}
        </Table>
      </TableContainer>
      {reservationResponse && (
        <ResponseModal
          message="Reservation created successfully!"
          onClose={() => setReservationResponse(false)}
        />
      )}
    </Box>
  );
};

export default GateSessionsTable;
