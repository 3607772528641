import './styles.css';
import {Button} from '@mui/material';
import React, {FC} from 'react';

interface Props {
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  margin?: string;
  width?: string;
  color?: 'Orange' | 'Black' | 'Transparent' | 'darkOrange' | 'white';
  onClick?: () => void;
  mr?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  px?: string;
  hover?: string;
  mb?: string;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  height?: string;
  fontSize?: string;
  position?: 'static' | 'relative' | 'absolute' | 'sticky' | 'fixed';
  bottom?: string;
  border?: string;
  borderRadius?: string;
  padding?: string;
}

const CustomButton: FC<Props> = ({
  className,
  children,
  onClick,
  mr,
  disabled,
  margin,
  width,
  color,
  height = '52.75px',
  startIcon,
  endIcon,
  mb,
  fontSize = '16px',
  type,
  hover = '#F79E1B',
  px,
  position = 'static',
  bottom,
  border,
  borderRadius = '12px',
  padding,
}) => {
  return (
    <Button
      className={`${className} custom-button-styling`}
      onClick={onClick}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      variant="contained"
      disableElevation
      disableTouchRipple
      type={type}
      sx={{
        backgroundColor:
          color === 'Transparent'
            ? 'transparent'
            : color === 'Orange'
            ? '#F79E1B'
            : color === 'darkOrange'
            ? '#FF6333'
            : color === 'white'
            ? 'white'
            : '#0A0A0A',
        marginTop: `${margin && margin}`,
        padding: `${padding && padding}`,
        width: `${width && width}`,
        px: `${px && px}`,
        borderRadius,
        fontSize,
        color: color === 'Transparent' ? '#F79E1B' : color === 'white' ? 'black' : '#fff',
        textTransform: 'none',
        marginRight: `${mr && mr}`,
        marginBottom: `${mb && mb}`,
        position,
        bottom,
        height,
        border,
        '&:hover': {
          backgroundColor: hover,
        },
        '&:disabled': {
          backgroundColor: '#B9B9B9',
          color: '#fff',
        },
      }}>
      {children}
    </Button>
  );
};

export default CustomButton;
