import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {Button, DialogContentText, DialogTitle} from '@mui/material';

interface Props {
  question: string;
  open: boolean;
  handleClose: VoidFunction;
  confirmedFunc: VoidFunction;
}

const CustomDialog = ({question, open, handleClose, confirmedFunc}: Props) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <DialogContentText>{question}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            confirmedFunc();
            handleClose();
          }}
          color="primary">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;
