import { ORGANIZATION_SELECTED } from "redux/types"

const initialState = {
    data: {},
}

export const organizationReducer = (state = initialState, action: any) => {
    switch(action.type) {
        case ORGANIZATION_SELECTED:
            return {...state, data: action.payload};
        default:
            return state;    
    }
}