import api from 'api';
import {getWalletTransactionsEndpoint} from 'api/apisURL';

export const getWalletTransactionsService = async (params: {id?: string; pageNumber?: number}) => {
  const payload = {
    filters: [
      {
        fieldName: 'userId',
        operator: 'like',
        values: [params.id],
      },
    ],
    pageNumber: params.pageNumber,
    pageSize: 10,
  };

  const {data} = await api.post(getWalletTransactionsEndpoint(), payload);
  return data;
};
