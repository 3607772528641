import {
  ASSIGN_USER,
  EDIT_USER_ROLE,
  GET_REALTIME_USER,
  GET_SELECTED_USER,
  GET_USERS,
  GET_USERS_FAILURE,
  RESET_USER,
  USER_LOADING,
} from 'redux/types';

import {UserSetupTableTypes} from 'model/types';

const initialState = {
  users: [] as any,
  currentUser: {},
  currentUserIndex: 0,
  totalPages: 0,
  totalRecords: 0,
  currentPage: 0,
  loading: false,
  errors: '',
};

export const getUsersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case USER_LOADING: {
      return {...state, loading: action.payload};
    }
    case GET_REALTIME_USER: {
      // Extract the received user data from the action payload.
      const newUser = action.payload;

      // Add a "(new)" suffix to the user's status property.
      newUser.status = `${newUser.status} (new)`;

      // Return a new state object with the received user added to the `users` array.
      return {...state, users: [...state.users, newUser]};
    }
    case GET_USERS:
      return {
        ...state,
        users: action.payload.users,
        totalPages: action.payload.totalPages,
        records: action.payload.totalRecords,
        currentPage: action.payload.currentPage,
        errors: '',
      };
    case GET_USERS_FAILURE:
      return {...state, errors: action.payload.response.data.statusMessage};
    case GET_SELECTED_USER:
      return {...state, currentUser: action.payload.user, currentUserIndex: action.payload.index};
    case ASSIGN_USER: {
      const newUsers = state.users.map((user: UserSetupTableTypes, index: number) => {
        if (index === state.currentUserIndex) {
          return {...user, role: action.payload};
        }
        return user;
      });
      return {...state, users: newUsers};
    }
    case EDIT_USER_ROLE: {
      const newUsers = state.users.map((user: UserSetupTableTypes, index: number) => {
        if (index === state.currentUserIndex) {
          return {...user, role: action.payload};
        }
        return user;
      });
      return {...state, users: newUsers};
    }

    case RESET_USER:
      return {...state, currentUser: null, currentUserIndex: null};
    default:
      return state;
  }
};
