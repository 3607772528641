import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef} from 'react';
import {Box} from '@mui/material';
import {RootState} from 'redux/rootReducers';
import {profile} from 'assets/icons';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

interface AvatarProps {
  image?: File | string | null;
  setImage?: Dispatch<SetStateAction<File | string | null>>;
}

const ProfileAvatar: FC<AvatarProps> = ({image, setImage}) => {
  const {user} = useSelector((state: RootState) => state.authReducer);
  const {newUser} = useSelector((state: RootState) => state.usersReducer);
  const inputRef = useRef<HTMLInputElement>(null);
  const {t} = useTranslation();

  const handleEditPhoto = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedImage = e.target.files?.[0];
    if (selectedImage && setImage) {
      setImage(selectedImage);
    }
  };

  useEffect(() => {
    const imageDisplay = document.getElementById('imageDisplay') as HTMLImageElement;
    if (image) {
      if (typeof image === 'object') {
        const reader = new FileReader();
        reader.onload = (e: any) => {
          imageDisplay.src = e.target.result as string;
        };

        reader.readAsDataURL(image);
      } else {
        imageDisplay.src = 'data:image/jpeg;base64,' + image;
      }
    }
  }, [image]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}>
        <div className="flex flex-col items-center">
          <div className="rounded-full bg-[var(--accent-white)] w-20 h-20 flex items-center justify-center">
            <img
              id="imageDisplay"
              src={profile}
              alt="profile"
              className={`${image && 'w-16 h-16'} rounded-full`}
            />
          </div>
          <a
            className="mt-2 text-[var(--main-color)] text-xs cursor-pointer inline-block"
            onClick={() => inputRef.current?.click()}>
            {t('Edit_photo')}
          </a>
          <input
            type="file"
            accept="image/*"
            className="hidden"
            ref={inputRef}
            onChange={handleEditPhoto}
          />
        </div>
        <h5 className="text-2xl font-bold -mt-4">{newUser ? newUser.name : user.name}</h5>
      </Box>
    </>
  );
};

export default ProfileAvatar;
