import {
  CLEAR_SESSION_ERROR as CLEAR_SESSION,
  CREATE_SESSION,
  CREATE_SESSION_FAILURE,
  CREATE_SESSION_LOADING,
  GET_RUNNING_PHONE_NUMBER,
  GET_RUNNING_PHONE_NUMBER_NOT_EXISTS,
  PHONE_NOT_REGISTERED,
  PHONE_REGISTERED,
  UPDATE_SESSION,
  UPDATE_SESSION_FAILURE,
  UPDATE_SESSION_LOADING,
} from 'redux/types';
import {checkPhoneNumberService, getRunningPhoneNumberService} from 'services/phoneNumberServices';

import {CreateSessionService} from 'services/reservation/CreateSessionService';
import {Dispatch} from 'redux';
import {updateSessionService} from 'services/reservation/updateSessionService';

export const CreateSessionAction = (newSession: any) => async (dispatch: any) => {
  dispatch({type: CREATE_SESSION_LOADING, payload: true});
  try {
    const response = await CreateSessionService(newSession);
    dispatch({type: CREATE_SESSION, payload: response.data});
  } catch (error) {
    dispatch({type: CREATE_SESSION_FAILURE, payload: error});
  } finally {
    dispatch({type: CREATE_SESSION_LOADING, payload: false});
  }
};

export const updateSessionAction = (EdittedData: any) => async (dispatch: any) => {
  try {
    dispatch({type: UPDATE_SESSION_LOADING, payload: true});
    const response = await updateSessionService(EdittedData);
    dispatch({type: UPDATE_SESSION, payload: response.data});
  } catch (error) {
    dispatch({type: UPDATE_SESSION_FAILURE, payload: error});
  } finally {
    dispatch({type: UPDATE_SESSION_LOADING, payload: false});
  }
};

export const clearSession = () => {
  return {type: CLEAR_SESSION};
};

export const checkPhoneNumber = (phone: string) => async (dispatch: Dispatch) => {
  const response = await checkPhoneNumberService(phone);
  if (response.data[0].users.length === 0 && phone.length > 0) {
    dispatch({type: PHONE_NOT_REGISTERED, payload: 'Phone not registered'});
  } else
    dispatch({
      type: PHONE_REGISTERED,
      payload: {registeredUser: response.data[0].users[0], error: ''},
    });
};

export const getRunningPhoneNumber = (phone: string) => async (dispatch: Dispatch) => {
  const response = await getRunningPhoneNumberService(phone);
  if (response.data[0].reservations.length === 0 && phone.length > 0) {
    dispatch({
      type: GET_RUNNING_PHONE_NUMBER_NOT_EXISTS,
      payload: 'No running session found for this user',
    });
  } else {
    dispatch({
      type: GET_RUNNING_PHONE_NUMBER,
      payload: {unregisteredUser: response.data[0].reservations[0], error: ''},
    });
  }
};
