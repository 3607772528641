import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {Dispatch, FC, RefObject, SetStateAction, useState} from 'react';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {Invoice} from 'model/Reports/Invoice';
import InvoiceModal from 'components/InvoiceModal/InvoiceModal';
import {Report} from 'model/Reports/Report';
import ReportsReceiptModal from './components/ReportsReceiptModal/ReportsReceiptModal';
import {Revenue} from 'model/Reports/Revenue';
import {RootState} from 'redux/rootReducers';
import {Transaction} from 'model/Reports/Transaction';
import {Utility} from 'model/Reports/Utility';
import {YELLOW} from 'constants/colors';
import {getInvoiceCells} from 'utils/reports/getTableCells/getInvoiceCells';
import {getReceiptCells} from 'utils/reports/getTableCells/getReceiptCells';
import {getRevenueCells} from 'utils/reports/getTableCells/getRevenueCells';
import {getTransactionCells} from 'utils/reports/getTableCells/getTransactionCells';
import {getUtilityCells} from 'utils/reports/getTableCells/getUtilityCells';
import styles from './ReportsTable.module.css';
import {useSelector} from 'react-redux';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import {getInvoices, getReceipts, getTransactions} from 'redux/actions/reportsAction';

interface Props {
  searchValue: string;
  type: string;
  headNames: string[];
  data: Report[];
  invoiceModalOpen?: boolean;
  setInvoiceModalOpen?: Dispatch<SetStateAction<boolean>>;
  receiptModalOpen?: boolean;
  setReceiptModalOpen?: Dispatch<SetStateAction<boolean>>;
  tableContainerRef: RefObject<HTMLDivElement>;
  tableRef: RefObject<HTMLTableElement>;
}

const ReportsTable: FC<Props> = ({
  searchValue,
  type,
  headNames,
  data,
  invoiceModalOpen,
  setInvoiceModalOpen,
  receiptModalOpen,
  setReceiptModalOpen,
  tableContainerRef,
  tableRef,
}) => {
  const {
    invoiceLoading,
    receiptLoading,
    transactionLoading,
    utilityLoading,
    revenueLoading,
    invoiceTotalPages,
    invoiceCurrentPage,
    receiptTotalPages,
    receiptCurrentPage,
    transactionTotalPages,
    transactionCurrentPage,
  } = useSelector((state: RootState) => state.reportsReducer);
  const [selectedRowValue, setSelectedRowValue] = useState<Invoice>();

  const loading =
    invoiceLoading || receiptLoading || transactionLoading || utilityLoading || revenueLoading;

  const handleRowClick = (invoice: Invoice) => {
    setSelectedRowValue(invoice);
    switch (type) {
      case 'invoice':
        setInvoiceModalOpen?.(true);
        break;
      case 'receipt':
        setReceiptModalOpen?.(true);
        break;
    }
  };

  const headerWidth = `${100 / headNames.length}%`;
  const statusName = (status?: string | number) => {
    if (status === 'UNINVOICED') {
      return 'Unpaid';
    } else if (status === 'INVOICED') {
      return 'Paid';
    } else return status;
  };

  const renderTableHead = () => {
    return (
      <TableHead sx={{position: 'sticky', top: 0, whiteSpace: 'nowrap'}}>
        <TableRow sx={{background: YELLOW}}>
          <TableCell />
          {headNames.map((headName, index) => (
            <TableCell sx={{width: headerWidth}} key={index} className="table-head">
              {headName}
            </TableCell>
          ))}
          <TableCell />
        </TableRow>
      </TableHead>
    );
  };

  const renderTableBody = () => {
    const generateCells = (report: Report) => {
      switch (type) {
        case 'invoice':
          return getInvoiceCells(report as Invoice).map((cellName, index) => (
            <TableCell key={index} className={styles.invoiceCell}>
              {statusName(cellName)}
            </TableCell>
          ));
        case 'receipt':
          return getReceiptCells(report as Invoice).map((cellName, index) => (
            <TableCell key={index} className={styles.invoiceCell}>
              {cellName}
            </TableCell>
          ));
        case 'transaction':
          return getTransactionCells(report as Transaction).map((cellName, index) => {
            return <TableCell key={index}>{cellName}</TableCell>;
          });
        case 'utilization':
          return getUtilityCells(report as Utility).map((cellName, index) => (
            <TableCell key={index}>{cellName}</TableCell>
          ));
        case 'revenue':
          return getRevenueCells(report as Revenue).map((cellName, index) => (
            <TableCell key={index}>{cellName}</TableCell>
          ));
      }
    };

    return (
      <TableBody sx={{height: data?.length > 3 ? 500 : 0}}>
        <CustomLoader size={80} loading={loading}>
          {data?.length === 0 || data === undefined ? (
            <TableRow className="empty-row">
              <TableCell colSpan={12} className="no-data">
                No Data
              </TableCell>
            </TableRow>
          ) : (
            data.map((report, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowClick(report as Invoice)}
                className={`${styles.tableRow} ${
                  type === 'invoice' || type === 'receipt' ? 'cursor-pointer' : ''
                }`}>
                <TableCell sx={{width: 60}} />
                {generateCells(report)}
                <TableCell sx={{width: 60}} />
              </TableRow>
            ))
          )}
        </CustomLoader>
      </TableBody>
    );
  };

  return (
    <Box>
      <TableContainer
        ref={tableContainerRef}
        className="table-container"
        sx={{
          height: 500,
          borderRadius: '0 0 20px 20px',
          overflowY: `${loading && 'hidden'}`,
        }}>
        <Table ref={tableRef} className="sessions-table" sx={{fontFamily: 'Poppins'}}>
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
      {type !== 'utilization' && type !== 'revenue' && (
        <CustomTablePagination
          isTable={false}
          totalPages={
            searchValue
              ? 0
              : type === 'invoice'
              ? invoiceTotalPages
              : type === 'transaction'
              ? transactionTotalPages
              : receiptTotalPages
          }
          currentPage={
            searchValue
              ? 0
              : type === 'invoice'
              ? invoiceCurrentPage
              : type === 'transaction'
              ? transactionCurrentPage
              : receiptCurrentPage
          }
          dispatchFunc={
            type === 'invoice'
              ? getInvoices
              : type === 'transaction'
              ? getTransactions
              : getReceipts
          }
        />
      )}
      <InvoiceModal
        handleClose={() => setInvoiceModalOpen?.(false)}
        isOpen={invoiceModalOpen!}
        reports
        invoiceData={selectedRowValue}
      />
      <ReportsReceiptModal
        receiptData={selectedRowValue}
        handleClose={() => setReceiptModalOpen?.(false)}
        isOpen={receiptModalOpen!}
      />
    </Box>
  );
};

export default ReportsTable;
