import React, {FC} from 'react';
import Styles from './graphContainer.styles.module.css';

interface GraphContainerProps {
  children: React.ReactNode;
  padding?: string;
  borderRadius?: string;
  margin?: string;
}

const GraphContainer: FC<GraphContainerProps> = ({
  children,
  padding = '15px',
  borderRadius = '16px',
  margin,
}) => {
  return (
    <div style={{padding, height: '100%', borderRadius, margin}} className={Styles.graphContainer}>
      {children}
    </div>
  );
};

export default GraphContainer;
