import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import './lineChartDatePicker.css';

import React, {useEffect, useState} from 'react';

import CustomButton from 'components/CustomButton/CustomButton';
import DatePicker from 'react-datepicker';
import {getPaymentChartAction} from 'redux/actions/chartAction';
import moment from 'moment';
import {useDispatch} from 'react-redux';

const LineChartDatePicker = (props: {
  [x: string]: any;
  startIcon?: any;
  endIcon?: any;
  text?: string;
  color?: 'Black' | 'Orange' | 'Transparent' | 'darkOrange' | 'white' | undefined;
  width?: string;
}) => {
  const {startIcon, endIcon, color, width, ...rest} = props;
  const dispatch: any = useDispatch();

  const [selectedYear, setSelectedYear] = useState(moment().startOf('year').toDate());
  const [dateRange, setDateRange] = useState({
    start: moment().startOf('year').format('YYYY-MM-DD[T]HH:mm:ss'),
    end: moment().endOf('year').format('YYYY-MM-DD[T]HH:mm:ss'),
  });

  const handleYearChange = (date: any) => {
    setSelectedYear(date);
    const start = moment(date).startOf('day').format('YYYY-MM-DD[T]00:00:00');
    const end = moment(date).endOf('year').subtract(1, 'day').format('YYYY-MM-DD[T]23:59:59');
    setDateRange({start, end});
  };
  useEffect(() => {
    dispatch(getPaymentChartAction(dateRange.start, dateRange.end));
  }, [dateRange]);

  return (
    <div className="lineChartDatePicker">
      <DatePicker
        className="datePicker"
        showYearPicker
        yearDropdownItemNumber={10}
        selected={selectedYear}
        onChange={e => {
          handleYearChange(e);
        }}
        customInput={
          <CustomButton
            className="date-picker-button"
            width={width}
            color={color ?? 'Black'}
            startIcon={startIcon}
            endIcon={endIcon}>
            {moment(selectedYear).format('YYYY') ?? 'Select year'}
          </CustomButton>
        }
        {...rest}
      />
    </div>
  );
};

export default LineChartDatePicker;
