import {Navigate, Outlet} from 'react-router-dom';

import {CURRENT_SESSION_PATH} from 'routes/paths';
import {FC} from 'react';
import {RouteProps} from 'routes';

const AdminRoutes: FC<RouteProps> = ({user}) => {
  return !user?.isGateEmployee ? <Outlet /> : <Navigate to={CURRENT_SESSION_PATH} />;
};

export default AdminRoutes;
