import {
   
    TableCell
   
  } from '@mui/material';
export const StatusColor=(status:string)=>{

  return  status==="CLOSED"?<TableCell style={{color:"red"}}>{status ?? '--'}</TableCell>
  :
  <TableCell style={{color:"#299e58"}}>{status ?? '--'}</TableCell>
    
}