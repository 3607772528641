import './styles.css';

import {Box, TableBody, TableCell, TableRow, Tooltip} from '@mui/material';
import {Delete, arrowRight, view} from 'assets/icons';
import {FC, useContext, useEffect, useState} from 'react';
import {
  clearDeletePackage,
  clearUpdatePackage,
  deletePackage,
  getPackages,
} from 'redux/actions/packageActions';
import {useDispatch, useSelector} from 'react-redux';

import CustomButton from 'components/CustomButton/CustomButton';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {PackageContext} from '../..';
import PackageModal from './PackageModal/PackageModal';
import {PackagesTableTypes} from 'model/types';
import {Parking} from 'model/servicesModels';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import {ThunkDispatchType} from 'redux/types';
import moment from 'moment';
import {useOnline} from 'hooks/useOnline';
import {useTranslation} from 'react-i18next';
import {GREEN, ORANGE, RED} from 'constants/colors';

interface Props {
  parking: Parking | undefined;
}

const PackageTableBody: FC<Props> = ({parking}) => {
  const {i18n, t} = useTranslation();

  const {isOnline} = useOnline();

  const [selectedPackage, setSelectedPackage] = useState();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [deleteResponseModal, setDeleteResponseModal] = useState<boolean>(false);
  const [updateResponseModal, setUpdateResponseModal] = useState<boolean>(false);
  const [packageModal, setPackageModal] = useState<'Edit Package' | 'Package Details'>();

  const {response: deleteResponse, error: errorDelete} = useSelector(
    (state: RootState) => state.deletePackageReducer,
  );
  const {response: updateResponse, error: errorUpdate} = useSelector(
    (state: RootState) => state.updatePackageReducer,
  );
  const {packages, loading} = useSelector((state: RootState) => state.getPackageReducer);
  const dispatch: ThunkDispatchType = useDispatch();

  const {setPackage} = useContext(PackageContext);

  useEffect(() => {
    if (deleteResponse || errorDelete) {
      setDeleteResponseModal(true);
      if (deleteResponse) dispatch(getPackages(parking?.id ?? ''));
    }
  }, [deleteResponse, errorDelete]);

  useEffect(() => {
    if (updateResponse || errorUpdate) {
      setUpdateResponseModal(true);
      if (updateResponse) dispatch(getPackages(parking?.id ?? ''));
    }
  }, [updateResponse, errorUpdate]);

  const determineDateStatus = (data: {fromDt: Date; toDt: Date; status: string}) => {
    const startDate = moment(data.fromDt);
    const endDate = moment(data.toDt);

    if (moment().isBefore(startDate, 'day')) {
      return 'Not activated yet';
    } else if (moment().isAfter(endDate, 'day')) {
      return 'Expired';
    }
    return 'Active';
  };

  const renderUserSetupRow = (row: any) => {
    return (
      <TableRow key={row.pkg.id} id="pkg-table-body" className="user-row">
        <TableCell />
        <TableCell>{row.pkg.categoryName ?? '--'}</TableCell>
        <TableCell>{row.pkg.name ?? '--'}</TableCell>
        <TableCell>
          {(moment(row.pkg.fromDt) || moment(row.pkg.toDt)).isValid()
            ? `${moment(row.pkg.fromDt).format('D MMM YYYY')} - ${moment(row.pkg.toDt).format(
                'D MMM YYYY',
              )}`
            : row.pkg.categoryType === 'default' || row.pkg.categoryType === 'employee'
            ? 'Unlimited'
            : '--'}
        </TableCell>
        <TableCell
          sx={{
            color: `${
              determineDateStatus(row.pkg) === 'Active'
                ? GREEN
                : determineDateStatus(row.pkg) === 'Expired'
                ? RED
                : ORANGE
            }`,
          }}>
          {determineDateStatus(row.pkg)}
        </TableCell>
        <TableCell>{row.pkg.slots ?? '--'}</TableCell>
        <TableCell>{row.pkg.allocatedSlots ?? '--'}</TableCell>
        <TableCell>
          {row.pkg.categoryName === 'default category' ? '--' : row.usersCount ?? 0}
        </TableCell>
        <Tooltip
          title={
            row.pkg.categoryName === 'default category'
              ? "You can't assign users to the default package"
              : 'Assign package to users'
          }
          placement="left">
          <TableCell
            className={`arrow-right ${
              row.pkg.categoryName === 'default category' ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
            onClick={
              row.pkg.categoryName === 'default category'
                ? () => {}
                : () => {
                    setPackage(row);
                  }
            }>
            <CustomIcon
              href={arrowRight}
              className={`${i18n.language === 'ar' && 'rotate-180 relative -z-10'}`}
            />
          </TableCell>
        </Tooltip>
        <TableCell />
        <TableCell>
          <Box display="flex" gap={3}>
            <div
              className="img-container"
              onClick={() => {
                setPackageModal('Package Details');
                setSelectedPackage(row);
              }}>
              <img src={view} alt="view" />
            </div>
            <div
              className="img-container"
              onClick={() => {
                dispatch(deletePackage(row.pkg.id));
              }}>
              <img src={Delete} alt="delete" />
            </div>
          </Box>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <>
      <TableBody sx={{whiteSpace: 'nowrap'}}>
        <CustomLoader size={80} loading={loading}>
          {packages.length === 0 || packages === undefined || !isOnline ? (
            <TableRow className="empty-row">
              <TableCell colSpan={12} className="no-data" sx={{textAlign: 'center'}}>
                {!isOnline ? (
                  <p className="text-2xl">
                    {t('Check your internet connection')} <br />
                    <span>
                      <CustomButton
                        className="mt-4"
                        color="Orange"
                        onClick={() => location.reload()}>
                        {t('Refresh')}
                      </CustomButton>
                    </span>
                  </p>
                ) : !parking?.id ? (
                  t('Select A Parking To View Packages')
                ) : (
                  `${t('No Packages For')} ${parking?.name}`
                )}
              </TableCell>
            </TableRow>
          ) : (
            packages.map((row: PackagesTableTypes) => {
              return renderUserSetupRow(row);
            })
          )}
        </CustomLoader>
      </TableBody>
      {deleteResponseModal && (
        <ResponseModal
          error={errorDelete}
          message={!errorDelete ? t('Package deleted successfully!') : errorDelete}
          onClose={() => {
            setDeleteResponseModal(false);
            dispatch(clearDeletePackage());
          }}
        />
      )}
      {updateResponseModal && (
        <ResponseModal
          error={errorUpdate}
          message={!errorUpdate ? t('Package edited successfully!') : errorUpdate}
          onClose={() => {
            setUpdateResponseModal(false);
            setIsSubmitted(false);
            dispatch(clearUpdatePackage());
          }}
        />
      )}

      {packageModal && (
        <PackageModal
          handleClose={() => setPackageModal(undefined)}
          open={!!packageModal}
          popupTitle={packageModal}
          packageData={selectedPackage}
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
          parking={parking}
        />
      )}
    </>
  );
};

export default PackageTableBody;
