import {
  ASSIGN_PACKAGE,
  ASSIGN_PACKAGE_FAILED,
  ASSIGN_PACKAGE_LOADING,
  CLEAR_ASSIGN_PACKAGE,
  CLEAR_ASSIGN_PACKAGE_ERROR,
} from 'redux/types';

const initialState = {
  response: null,
  loading: false,
  error: '',
};

export const assignPackageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case ASSIGN_PACKAGE:
      return {...state, response: action.payload, error: action.payload.errorCode};
    case ASSIGN_PACKAGE_LOADING:
      return {...state, loading: action.payload};
    case ASSIGN_PACKAGE_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case CLEAR_ASSIGN_PACKAGE_ERROR:
      return {...state, error: ''};
    case CLEAR_ASSIGN_PACKAGE:
      return initialState;
    default:
      return state;
  }
};
