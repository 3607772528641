import {Invoice} from 'model/Reports/Invoice';

export const getReceiptCells = (report: Invoice) => {
  const {receiptRef, name, phone, carNumber} = report;

  return [
    `'${receiptRef}'` ?? '--',
    name ?? '--',
    phone ? '\u00A0+' + phone : '--',
    carNumber ?? '--',
  ];
};
