import {INVOICE_FAILURE, INVOICE_LOADING, INVOICE_SUCCESS} from 'redux/types';

import {invoiceService} from 'services/reservation/invoiceService';

export const invoiceAction = (data: string, getAmount: VoidFunction) => async (dispatch: any) => {
  dispatch({type: INVOICE_LOADING, loading: true});
  try {
    const response = await invoiceService(data);
    dispatch({type: INVOICE_SUCCESS, payload: response.data});
    dispatch({type: INVOICE_LOADING, loading: false});
    getAmount();
  } catch (error) {
    dispatch({type: INVOICE_FAILURE, payload: error});
    dispatch({type: INVOICE_LOADING, loading: false});
  }
};
