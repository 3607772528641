import {Col, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';

import EmployeeSetupModal from './components/EmployeeSetupModal/EmployeeSetupModal';
import EmployeeSetupTable from './components/EmployeeTable/EmployeeTable';
import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import ParkingDropDown from 'utils/dropdowns/ParkingDropDown';
import {RootState} from 'redux/rootReducers';
import {getPackages} from 'redux/actions/packageActions';
import {setEmployeeRow} from 'redux/actions/employeeAction';
import styles from './styles.module.css';

const index = () => {
  const dispatch: any = useDispatch();
  const {selectedParking} = useSelector((state: RootState) => state.parkingSetupReducer);
  const {selectedRowValue} = useSelector((state: RootState) => state.employeeReducer);
  const [openEmployeeModal, setOpenEmployeeModal] = useState<boolean>(false);
  const [selectParkingErrorMSG, setSelectParkingErrorMSG] = useState<string | null>(null);

  const handleCloseEmployeeModal = () => {
    setOpenEmployeeModal(false);
    dispatch(setEmployeeRow(null));
  };

  useEffect(() => {
    if (selectedRowValue) {
      setOpenEmployeeModal(true);
    }
  }, [selectedRowValue]);

  useEffect(() => {
    if (selectedParking?.id) {
      setSelectParkingErrorMSG(null);
    }
  }, [selectedParking]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (selectedParking?.id) {
        dispatch(getPackages(selectedParking?.id));
      }
    }, 0);
    return () => clearTimeout(timeoutId);
  }, [selectedParking]);

  return (
    <div id={styles.employeeContainer}>
      <MainViewContainer>
        <Row className="special-row">
          <Col className="flex justify-end">
            <HeaderDate orange />
          </Col>
        </Row>
        <Row style={{marginBottom: '25px'}}>
          <Col>
            <ParkingDropDown />
            <p className="validation-error">{selectParkingErrorMSG}</p>
          </Col>
        </Row>
        <Row>
          <EmployeeSetupTable setOpenEmployeeModal={setOpenEmployeeModal} />
        </Row>
      </MainViewContainer>
      <EmployeeSetupModal
        openEmployeeModal={openEmployeeModal}
        handleClose={handleCloseEmployeeModal}
        parkingId={selectedParking?.id}
      />
    </div>
  );
};

export default index;
