import {useTranslation} from 'react-i18next';
import './styles.css';

import React, {FC} from 'react';

interface props {
  orange?: boolean;
}

const HeaderDate: FC<props> = ({orange}) => {
  const now: Date = new Date();
  const {t} = useTranslation();

  const days = [
    `${t('Sunday')}`,
    `${t('Monday')}`,
    `${t('Tuesday')}`,
    `${t('Wednesday')}`,
    `${t('Thursday')}`,
    `${t('Friday')}`,
    `${t('Saturday')}`,
  ];
  const months = [
    `${t('January')}`,
    `${t('February')}`,
    `${t('March')}`,
    `${t('April')}`,
    `${t('May')}`,
    `${t('June')}`,
    `${t('July')}`,
    `${t('August')}`,
    `${t('September')}`,
    `${t('October')}`,
    `${t('November')}`,
    `${t('December')}`,
  ];
  const day = days[now.getDay()];
  const month = months[now.getMonth()];

  return (
    <div id="header-date">
      <h1>{day}</h1>
      <h1 className={`${orange ? 'orange' : ''}`}>
        {now.getDate() + ' ' + month}, {now.getFullYear()}
      </h1>
    </div>
  );
};

export default HeaderDate;
