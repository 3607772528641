import './styles.css';

import {FC} from 'react';
import {CircularProgress} from '@mui/material';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducers';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import {info} from 'assets/icons';
import {GREY} from 'constants/colors';

interface Props {
  title: string;
  icon: any;
  subTitle: string;
  loading: boolean;
  width?: string;
  description?: string;
}

export const CustomHeaderCard: FC<Props> = ({
  title,
  icon,
  subTitle,
  loading,
  width = ' 98%',
  description,
}) => {
  const {currentLanguage} = useSelector((state: RootState) => state.changeLanguageReducer);

  return (
    <div style={{width: '25%'}}>
      <div className="flex flex-col">
        <div
          className={`card-item ${
            currentLanguage === 'en' ? 'flex' : 'flex-row-reverse justify-evenly'
          }  `}
          style={{width}}>
          <span>{icon}</span>
          <div className="card-body">
            <p>{title}</p>
            {loading ? (
              <CircularProgress size="1.2rem" sx={{marginTop: '3px'}} />
            ) : (
              <p className={`${subTitle === '-- Spots' ? 'empty' : 'seconde-text'}`}>{subTitle}</p>
            )}
          </div>
        </div>
        {description && (
          <span
            className="text-[11.5px] mt-2 flex flex-row gap-1 items-center"
            style={{color: GREY}}>
            <CustomIcon href={info} width={12} height={12} />
            {description}
          </span>
        )}
      </div>
    </div>
  );
};
