import {
  RESET_SET_PARKING_AS_FULL,
  SET_PARKING_AS_FREE,
  SET_PARKING_AS_FREE_FAILED,
  SET_PARKING_AS_FREE_LOADING,
  SET_PARKING_AS_FULL,
  SET_PARKING_AS_FULL_FAILED,
  SET_PARKING_AS_FULL_LOADING,
} from 'redux/types';

const initialState = {
  updatedData: {},
  loading: false,
  error: '',
};

export const setParkingAsFullReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case RESET_SET_PARKING_AS_FULL:
      return initialState;
    case SET_PARKING_AS_FULL:
      return {...state, updatedData: action.payload, error: ''};
    case SET_PARKING_AS_FULL_LOADING:
      return {...state, loading: action.payload};
    case SET_PARKING_AS_FULL_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case SET_PARKING_AS_FREE:
      return {...state, updatedData: action.payload, error: ''};
    case SET_PARKING_AS_FREE_LOADING:
      return {...state, loading: action.payload};
    case SET_PARKING_AS_FREE_FAILED:
      return {...state, error: action.payload.response?.data.statusMessage};
    default:
      return state;
  }
};
