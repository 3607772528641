import {CURRENT_SESSION_PATH} from 'routes/paths';
import {Link, useLocation} from 'react-router-dom';
import {Nav, Navbar} from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import {RootState} from 'redux/rootReducers';
import {useSelector} from 'react-redux';

import './styles.css';

const GateNavbar = () => {
  const location = useLocation();
  const {user} = useSelector((state: RootState) => state.authReducer);

  return (
    <Navbar id="gate-nav" className="down-nav" expand="lg" variant="dark">
      <Container fluid className="px-5 gateNavContainer">
        <Navbar.Brand className="brand">{user.name}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="centerLinks">
            <Nav.Link
              id="current-session-link"
              as={Link}
              to={CURRENT_SESSION_PATH}
              active={location.pathname === CURRENT_SESSION_PATH}>
              Current sessions
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default GateNavbar;
