import {MutableRefObject, RefObject, forwardRef, useRef, useState} from 'react';

import CustomCheckbox from 'components/CustomCheckBox/CustomCheckBox';
import styles from 'utils/dropdowns/Dropdown.module.css';
import {useOutsideClick} from 'hooks/useOutsideClick';
import {whiteDownArrow} from 'assets/icons';
import {useTranslation} from 'react-i18next';

interface Props {
  placeholder: string;
  value: string;
  options: string[];
  handleMultiSelect?: (checked: boolean, option: string) => void;
  disabled?: boolean;
  weekDays?: number[];
  width?: string;
  height?: string;
  backgroundColor?: string;
  ref: RefObject<HTMLDivElement>;
}

const MultiSelectDropdown = forwardRef<HTMLDivElement, Props>(
  (
    {
      placeholder,
      value,
      options,
      handleMultiSelect,
      disabled,
      weekDays,
      width,
      height,
      backgroundColor,
    },
    ref,
  ) => {
    const {i18n} = useTranslation();

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const divRef = useRef<HTMLDivElement>(null);

    useOutsideClick(divRef, () => {
      setIsOpen(false);
    });

    const scrollToBottom = () => {
      (ref as MutableRefObject<HTMLDivElement | null>)?.current?.scrollTo({
        top: 600,
        behavior: 'smooth',
      });
    };

    const handleDisabled = () => {
      if (disabled) setIsOpen(false);
    };

    return (
      <div
        style={{width, height, backgroundColor}}
        ref={divRef}
        onClick={() => {
          setIsOpen(true);
          scrollToBottom();
          handleDisabled();
        }}
        className={`${styles.dropdownContainer} ${styles.textField} cursor-pointer`}>
        <input
          style={{backgroundColor}}
          readOnly
          disabled={disabled}
          className={disabled ? 'cursor-default' : 'cursor-pointer'}
          type="text"
          value={value}
          placeholder={placeholder}
          onClick={() => {
            setIsOpen(true);
            scrollToBottom();
            handleDisabled();
          }}
        />
        <img
          src={whiteDownArrow}
          alt="whiteArrow"
          className={`${disabled ? 'cursor-default' : 'cursor-pointer'} ${
            isOpen && styles.rotateIcon
          } ${i18n.language === 'ar' && '!right-auto left-[8%]'}`}
          onClick={() => {
            setIsOpen(!isOpen);
            handleDisabled();
          }}
        />
        <div className={`${styles.dropdownMenu} ${isOpen && styles.dropdownOpened}`}>
          {options.map((option, index) => {
            return (
              <ul key={index}>
                <li>
                  <div className={styles.checkboxContainer}>
                    <CustomCheckbox
                      className="regular"
                      isChecked={weekDays?.includes(index + 1)}
                      checkClick={e => handleMultiSelect!(e.target.checked, option)}
                    />
                    <p>{option}</p>
                  </div>
                </li>
              </ul>
            );
          })}
        </div>
      </div>
    );
  },
);

MultiSelectDropdown.displayName = 'MultiSelectDropdown';

export default MultiSelectDropdown;
