import './styles.css';

import {Box, Typography} from '@mui/material';
import {SideMenu, arabicSideMenu, orangeLeftArrow} from 'assets/icons';

import Button from '@mui/material/Button';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import {Link} from 'react-router-dom';
import {PROFILE_ADMIN_PATH} from 'routes/paths';
import React from 'react';
import {RootState} from 'redux/rootReducers';
import SideBarItems from './components/AdminSideBarItems';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {noProfilePicture} from 'assets/images';

export const AdminSideBar = () => {
  const {i18n} = useTranslation();

  const currentLanguage = i18n.language;
  const {t} = useTranslation();

  const {newUser} = useSelector((state: RootState) => state.usersReducer);
  const {user} = useSelector((state: RootState) => state.authReducer);
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpen(open);
  };

  const list = () => (
    <Box
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
      id="menu-content">
      <Box>
        <CustomIcon href={orangeLeftArrow} className="cursor-pointer" />
      </Box>
      <Box className="user-content">
        <div className="rounded-full bg-[var(--black)] w-20 h-20 flex items-center justify-center">
          <img
            id="imageDisplay"
            src={
              newUser.profilePicture
                ? `data:image/jpeg;base64, ${newUser.profilePicture}`
                : noProfilePicture
            }
            alt="profile"
            className="w-16 h-16 rounded-full"
          />
          {/* <CustomIcon
            href={`data:image/jpeg;base64, ${newUser.profilePicture}`}
            width={68}
            height={68}
          /> */}
        </div>
        <Link className="edit-profile-text cursor-pointer" to={PROFILE_ADMIN_PATH}>
          {t('Edit-profile')}
        </Link>
        <Typography sx={{fontWeight: 600, color: '#fff', fontSize: '14px'}}>
          {newUser ? newUser.name : user.name}
        </Typography>
        <p className="more-details">
          {newUser ? newUser.email : user.email}
          <br />
          {t('info')}
        </p>
        <span className="line"></span>
      </Box>
      <SideBarItems />
    </Box>
  );

  return (
    <div id="menu-container">
      <Button
        className={`${
          currentLanguage === 'en' ? 'drawer-button-left' : 'drawer-button-right'
        } drawer-button`}
        onClick={toggleDrawer(true)}>
        <CustomIcon
          href={currentLanguage === 'en' ? SideMenu : arabicSideMenu}
          width={56}
          height={172}
          className="cursor-pointer"
        />
      </Button>

      <SwipeableDrawer
        anchor={`${currentLanguage === 'en' ? 'left' : 'right'}`}
        style={{right: `${currentLanguage === 'en' ? '0' : 'auto'}`}}
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        id="parent"
        className={`${currentLanguage === 'en' ? 'left' : 'right'}`}>
        {list()}
      </SwipeableDrawer>
    </div>
  );
};

export default AdminSideBar;
