import './styles.css';

import React, {FC, useRef} from 'react';

interface props {
  value?: string;
  isChecked?: boolean;
  checkClick?: React.ChangeEventHandler<HTMLInputElement>;
  checkAll?: React.ChangeEventHandler<HTMLInputElement>;
  name?: string;
  className?: string;
  disabled?: boolean;
  backgroundColor?: string;
  height?: string;
  width?: string;
}

const CustomCheckbox: FC<props> = ({
  value,
  isChecked,
  checkClick,
  checkAll,
  name,
  backgroundColor,
  className,
  disabled,
  height = '24px',
  width = '24px',
}) => {
  const ref = useRef<HTMLInputElement>(null);

  return (
    <input
      ref={ref}
      disabled={disabled}
      value={value}
      className={`custome-checkbox cursor-pointer my-check ${className}`}
      type="checkbox"
      onChange={checkAll ?? checkClick}
      checked={isChecked}
      name={name ?? ''}
      style={{backgroundColor: backgroundColor ?? '', height, width}}
    />
  );
};

export default CustomCheckbox;
