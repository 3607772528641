import {Box} from '@mui/material';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import ProfileAvatar from 'components/ProfileAvatar/ProfileAvatar';
import ProfileInfo from 'components/ProfileInfo/ProfileInfo';

const ProfileGate = () => {
  return (
    <div className="profile-container">
      <MainViewContainer>
        <Box
          className="profile-box"
          sx={{
            mt: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box className="my-0 mx-auto">
            <ProfileAvatar />
          </Box>
          <ProfileInfo isGate />
        </Box>
      </MainViewContainer>
    </div>
  );
};

export default ProfileGate;
