import {
  assignPackageEndpoint,
  createPackageEndpoint,
  deleteAssignedUserEndpoint,
  getAllUsersByPackageIdEndpoint,
  getPackageCategoiresEndpoint,
  getPackagesEndpoint,
  packageWithIdEndpoint,
} from 'api/apisURL';

import {CreatePackage} from 'model/CreatePackage';
import api from 'api';

export const createPackageService = async (data: CreatePackage) => {
  const response = await api.post(createPackageEndpoint(), data);
  return response;
};

export const getPackagesService = async (parkingId: string) => {
  const response = await api.get(getPackagesEndpoint(parkingId));
  return response;
};

export const getPackageCategoriesService = async () => {
  const response = await api.get(getPackageCategoiresEndpoint());
  return response;
};

export const assignPackageService = async (packageId: string, data: any) => {
  const response = await api.put(assignPackageEndpoint(packageId), data);
  return response;
};

export const getUsersByPackageService = async (packageId: string) => {
  const response = await api.get(getAllUsersByPackageIdEndpoint(packageId));
  return response;
};

export const deleteAssignedUserService = async (packageId: string, deletedUser: any) => {
  const response = await api.put(deleteAssignedUserEndpoint(packageId), {
    toBeRemovedUserIds: [deletedUser],
  });
  return response;
};

export const deletePackageService = async (packageId: string) => {
  const response = await api.delete(packageWithIdEndpoint(packageId));
  return response;
};

export const updatePackageService = async (packageId: string, data: any) => {
  const response = await api.put(packageWithIdEndpoint(packageId), data);
  return response;
};
