import './styles.css';

import {CircularProgress, TableRow} from '@mui/material';
import {FC, ReactNode} from 'react';

import {YELLOW} from 'constants/colors';

interface CustomLoaderProps {
  center?: boolean;
  size: number;
  color?: string;
  loading: boolean;
  children: ReactNode;
  mt?: number;
  multiTable?: boolean;
  height?: number;
}

const CustomLoader: FC<CustomLoaderProps> = ({
  center,
  size,
  color = YELLOW,
  loading,
  children,
  mt,
  multiTable,
  height,
}) => {
  return loading ? (
    <TableRow className="empty-row loading" data-testid="loading-indicator">
      <td
        colSpan={12}
        className={`no-data ${center && 'power-off-modal'} ${multiTable && 'multi-table-loading'}`}
        style={{height}}>
        <CircularProgress sx={{color, mt}} size={size} />
      </td>
    </TableRow>
  ) : (
    <>{children}</>
  );
};

export default CustomLoader;
