import {Box, Table, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FC, useEffect} from 'react';
import {getParkingSetup, resetSelectedParkingSetupRow} from 'redux/actions/parkingSetupAction';
import {useDispatch, useSelector} from 'react-redux';

import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import ParkingSetupBody from './components/ParkingSetupBody/ParkingSetupBody';
import {RootState} from 'redux/rootReducers';
import {YELLOW} from 'constants/colors';
import { useTranslation } from 'react-i18next';

interface props {
  setChoosenId: React.Dispatch<React.SetStateAction<string | null>>;
  setTable: React.Dispatch<React.SetStateAction<any>>;
  responseModal?: boolean;
}

const ParkingSetupTable: FC<props> = ({setChoosenId, setTable, responseModal}) => {
  const dispatch: any = useDispatch();
  const {loading, currentPage, totalPages} = useSelector(
    (state: RootState) => state.parkingSetupReducer,
  );
  const {updatedData} = useSelector((state: RootState) => state.setParkingAsFullReducer);
  const {parkingsSetup} = useSelector((state: RootState) => state.parkingSetupReducer);
  const {t} = useTranslation();

  useEffect(() => {
    dispatch(resetSelectedParkingSetupRow());
    dispatch(getParkingSetup(currentPage));
  }, [dispatch, updatedData]);

  return (
    <Box>
      <TableContainer
        sx={{
          height: 500,
          borderRadius: 4,
          paddingRight: parkingsSetup.length > 4 ? 1 : 0,
          overflowY: `${loading && 'hidden'}`,
        }}>
        <Table className="sessions-table" sx={{fontFamily: 'Poppins'}}>
          <TableHead sx={{position: 'sticky', top: 0, whiteSpace: 'nowrap'}}>
            <TableRow sx={{background: YELLOW}}>
              <TableCell className="table-head" />
              <TableCell className="table-head">{t("Organization name")}</TableCell>
              <TableCell className="table-head">{t("City")}</TableCell>
              <TableCell className="table-head">{t("Emergency count")}</TableCell>
              <TableCell className="table-head">{t("Mark_as_full_time")}</TableCell>
              <TableCell className="table-head">{t("Parking name")}</TableCell>
              <TableCell className="table-head">{t("Total slots")}</TableCell>
              <TableCell className="table-head">{t("Total no. of gates")}</TableCell>
              <TableCell className="table-head" />
              <TableCell className="table-head" />
              <TableCell className="table-head" />
            </TableRow>
          </TableHead>
          <ParkingSetupBody setChoosenId={setChoosenId} setTable={setTable} />
          <CustomTablePagination
            totalPages={totalPages}
            currentPage={currentPage}
            dispatchFunc={getParkingSetup}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ParkingSetupTable;
