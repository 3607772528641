import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './styles.css';
import {CircularProgress} from '@mui/material';
import {Col, Row} from 'react-bootstrap';
import {LIGHT_GREY, WHITE, YELLOW} from 'constants/colors';
import {
  getPackageChartAction,
  getProcessChartAction,
  updatePackageAmount,
  updatePackageSlots,
  updateProcessAmount,
  updateProcessSlots,
} from 'redux/actions/chartAction';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useState} from 'react';
import Cards from 'components/Cards/Cards';
import {DateRange} from 'react-date-range';
import GraphContainer from 'screens/Admin/Home/components/GraphContainer/GraphContainer';
import HeaderDate from 'components/HeaderDate/HeaderDate';
import LineChart from './components/LineChart/LineChart';
import {LoadingButton} from '@mui/lab';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import PackageTotalDoughnut from './components/PackageSlotsDoughnut/PackageSlotsDoughnut';
import {PackagesAmountDoughnut} from './components/PackagesAmountDoughnut/PackagesAmountDoughnut';
import ParkingDropDown from 'utils/dropdowns/ParkingDropDown';
import {ProcessAmountPieChart} from './components/ProcessAmountPieChart/ProcessAmountPieChart';
import ProcessTotalDoughnut from './components/ProcessSlotsDoughnut/ProcessSlotsDoughnut';
import {RootState} from 'redux/rootReducers';
import {blackCalendar, clear} from 'assets/icons';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import {t} from 'i18next';

const Home = () => {
  const {i18n} = useTranslation();
  const currentLanguage = i18n.language;
  const {packageChartLoading, processChartLoading} = useSelector(
    (state: RootState) => state.chartReducer,
  );
  const dispatch: any = useDispatch();
  const {selectedParking} = useSelector((state: RootState) => state.parkingSetupReducer);
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [datePickerUpdated, setDatePickerUpdated] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<any>([
    {
      startDate: null,
      endDate: null,
      key: 'selection',
    },
  ]);

  const handleChangeFilter = (item: {selection: {startDate: Date; endDate: Date; key: string}}) => {
    // setSelectedRange([item.selection]);
    const {startDate, endDate} = item.selection;
    const newEndDate = new Date(endDate);
    newEndDate.setHours(23, 59, 59, 999);
    setSelectedRange([
      {
        startDate,
        endDate: newEndDate,
        key: 'selection',
      },
    ]);
  };

  const {startDate, endDate} = selectedRange[0];
  const start = startDate && moment.utc(startDate).local().format('YYYY-MM-DD HH:mm:ss');
  const end =
    endDate &&
    (startDate === endDate
      ? moment(endDate).local().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      : moment.utc(endDate).local().format('YYYY-MM-DD HH:mm:ss'));

  const SubmitFilter = () => {
    setDatePickerOpen(false);
    setDatePickerUpdated(true);

    dispatch(updateProcessSlots(selectedParking?.id, start, end));
    dispatch(updateProcessAmount(selectedParking?.id, start, end));
    dispatch(updatePackageSlots(selectedParking?.id, start, end));
    dispatch(updatePackageAmount(selectedParking?.id, start, end));
  };

  const handleClear = (e: any) => {
    e.stopPropagation();
    setDatePickerUpdated(false);
    setSelectedRange([
      {
        startDate: null,
        endDate: null,
        key: 'selection',
      },
    ]);
    dispatch(getProcessChartAction(selectedParking?.id, undefined, undefined));
    dispatch(getPackageChartAction(selectedParking?.id, undefined, undefined));
  };

  useEffect(() => {
    dispatch(getProcessChartAction(selectedParking?.id, start, end));
    dispatch(getPackageChartAction(selectedParking?.id, start, end));
  }, [selectedParking?.id]);

  return (
    <div id="home">
      <MainViewContainer className={`${currentLanguage === 'en' ? 'englishHome' : 'arabicHome'}`}>
        <Row className="special-row">
          <HeaderDate orange />
        </Row>
        <Row className="special-row">
          <Col lg={11}>
            <div className="flex justify-between align-center">
              <ParkingDropDown width="19%" height="56px" />
              <Cards ParkingID={selectedParking?.id} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={7}
            style={{
              paddingLeft: `calc(var(--bs-gutter-x) * ${currentLanguage === 'en' ? 0.5 : 0.6})`,
              paddingRight: `calc(var(--bs-gutter-x) * ${currentLanguage === 'en' ? 0.6 : 0.5})`,
            }}>
            <div style={{marginBottom: '25px'}}>
              <GraphContainer padding="15px 25px 15px 25px">
                <LineChart />
              </GraphContainer>
            </div>
          </Col>
          <Col
            lg={5}
            style={{
              paddingLeft: `calc(var(--bs-gutter-x) * ${currentLanguage === 'en' ? 0.6 : 0.5})`,
              paddingRight: `calc(var(--bs-gutter-x) * ${currentLanguage === 'en' ? 0.5 : 0.6})`,
            }}>
            <div
              style={{
                display: 'flex',
                height: '100%',
                backgroundColor: LIGHT_GREY,
                borderRadius: '16px 16px 0 0',
                padding: '15px 30px 15px 30px',
                justifyContent: 'space-between',
                position: 'relative',
              }}>
              <div style={{width: '47%', marginTop: '76px'}}>
                <ProcessAmountPieChart datePickerUpdated={datePickerUpdated} />
              </div>
              <div style={{width: '47%', marginTop: '76px'}}>
                <div style={{display: 'flex'}}>
                  <button
                    style={{height: '52.7px', position: 'absolute', top: '15px', right: '30px'}}
                    className="bg-white text-black text-bold rounded-xl px-6"
                    onClick={() => setDatePickerOpen(!datePickerOpen)}>
                    <div className={`flex justify-center items-center gap-2 relative`}>
                      <img src={blackCalendar} alt="calendar" />
                      <p className="text-black text-md " style={{whiteSpace: 'nowrap'}}>
                        {selectedRange[0].startDate
                          ? selectedRange[0].startDate === selectedRange[0].end
                            ? `${moment(selectedRange[0].startDate as Date).format('YY MMM DD')}`
                            : `${moment(selectedRange[0].startDate as Date).format(
                                'YY MMM DD',
                              )} : ${moment(selectedRange[0].endDate as Date).format('MMM DD')}`
                          : `${t('Select period')}`}
                      </p>
                      {selectedRange[0].startDate && (
                        <div
                          className="flex items-center justify-center text-lg bg-[red] absolute w-6 h-12 rounded-r-lg -right-10"
                          onClick={handleClear}>
                          <img src={clear} alt="clear" />
                        </div>
                      )}
                    </div>
                  </button>
                  {datePickerOpen && (
                    <div
                      dir="ltr"
                      className="absolute top-[14%] right-0 z-10 bg-[var(--accent-white)] p-2 rounded-[20px] border-[var(--main-color)] border-solid border-2">
                      <DateRange
                        color="#aeaeaea1"
                        ranges={selectedRange}
                        onChange={handleChangeFilter}
                      />
                      <div className="w-full text-center">
                        <LoadingButton
                          className="mb-2 mt-4 px-5 !h-9 py-3"
                          loading={packageChartLoading ?? processChartLoading}
                          style={{
                            backgroundColor: YELLOW,
                            color: WHITE,
                            textTransform: 'capitalize',
                            fontSize: '16px',
                            borderRadius: '14px',
                          }}
                          loadingIndicator={<CircularProgress />}
                          onClick={() => {
                            SubmitFilter();
                          }}>
                          {t('Update')}
                        </LoadingButton>
                      </div>
                    </div>
                  )}
                </div>
                <PackagesAmountDoughnut datePickerUpdated={datePickerUpdated} />
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col
            lg={7}
            style={{
              paddingLeft: `calc(var(--bs-gutter-x) * ${currentLanguage === 'en' ? 0.5 : 0})`,
              paddingRight: `calc(var(--bs-gutter-x) * ${currentLanguage === 'en' ? 0 : 0.5})`,
            }}>
            <GraphContainer padding="30px 60px 45px" borderRadius=" 14px 0 0 14px">
              <ProcessTotalDoughnut datePickerUpdated={datePickerUpdated} />
            </GraphContainer>
          </Col>
          <Col
            lg={5}
            style={{
              paddingLeft: `calc(var(--bs-gutter-x) * ${currentLanguage === 'en' ? 0 : 0.5})`,
              paddingRight: `calc(var(--bs-gutter-x) * ${currentLanguage === 'en' ? 0.5 : 0})`,
            }}>
            <GraphContainer padding="30px 20px 45px 60px" borderRadius="0 0 14px 0 ">
              <PackageTotalDoughnut datePickerUpdated={datePickerUpdated} />
            </GraphContainer>
          </Col>
        </Row>
      </MainViewContainer>
    </div>
  );
};

export default Home;
