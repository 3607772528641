import {GET_PROFILE_TYPES, GET_PROFILE_TYPES_ERROR, GET_PROFILE_TYPES_LOADING} from 'redux/types';
import {Dispatch} from 'redux';
import {getProfileTypesService} from 'services/getProfileTypesService';

export const getProfileTypesAction = () => async (dispatch: Dispatch) => {
  try {
    dispatch({type: GET_PROFILE_TYPES_LOADING, payload: true});
    const response = await getProfileTypesService();
    dispatch({type: GET_PROFILE_TYPES, payload: response.data});
  } catch (error) {
    dispatch({type: GET_PROFILE_TYPES_ERROR, error});
  } finally {
    dispatch({type: GET_PROFILE_TYPES_LOADING, payload: false});
  }
};
