import './App.css';

import {QueryClient, QueryClientProvider} from 'react-query';
import {useDispatch, useSelector} from 'react-redux';

import AppRoutes from 'routes/AppRoutes';
import DarkOverlay from 'components/DarkOverlay/DarkOverlay';
import {ErrorBoundary} from 'react-error-boundary';

import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import {setParkingID} from 'redux/actions/parkingSetupAction';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

const App = () => {
  const {i18n} = useTranslation();
  const {isSearching} = useSelector((state: RootState) => state.searchReducer);
  const {parkingsSetup} = useSelector((state: RootState) => state.parkingSetupReducer);
  const dispatch: any = useDispatch();
  const queryClient = new QueryClient();

  useEffect(() => {
    if (parkingsSetup.length === 1) {
      dispatch(setParkingID({id: parkingsSetup[0].id, name: parkingsSetup[0].name}));
    }
  }, [parkingsSetup[0]?.id]);

  return (
    <div className={`app ${isSearching && 'search'} ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
      <ErrorBoundary
        fallback={<ResponseModal error errorBoundary message="Something went wrong" />}>
        <QueryClientProvider client={queryClient}>
          <AppRoutes />
        </QueryClientProvider>
      </ErrorBoundary>
      <DarkOverlay />
    </div>
  );
};

export default App;
