import './styles.css';
import {useTranslation} from 'react-i18next';
import {logo} from 'assets/icons';

const AuthHeading = () => {
  const {t} = useTranslation();
  const parkingPanel = t('Parking Panel').split(' ');
  const parking = parkingPanel[0];
  const panel = parkingPanel[1];
  return (
    <div className="custom-auth-heading">
      <div className="custom-auth-logo">
        <img src={logo} alt="logo" className="w-[320px] h-[200px]" />
      </div>
      <h2 className="custom-auth-title">
        {parking} <span>{panel}</span>
      </h2>
    </div>
  );
};

export default AuthHeading;
