import {Transaction} from 'model/Reports/Transaction';

export const getTransactionCells = (report: Transaction) => {
  const {carNumber, contactName, packageName, phone, status} = report;

  return [
    carNumber ?? '--',
    contactName ?? '--',
    packageName ?? '--',
    phone ? '\u00A0+' + phone : '--',
    status ?? '--',
  ];
};
