import {Autocomplete, Paper, TextField} from '@mui/material';
import {BLACK, LIGHT_GREY, WHITE, YELLOW} from 'constants/colors';
import {FC, useEffect, useRef, useState} from 'react';
import {getParkingSetup, setParkingID} from 'redux/actions/parkingSetupAction';
import {orangeUpperArrow, search, whiteBigFownArrow} from 'assets/icons';
import {useDispatch, useSelector} from 'react-redux';

import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {RootState} from 'redux/rootReducers';
import styles from 'utils/dropdowns/Dropdown.module.css';
import {useOutsideClick} from 'hooks/useOutsideClick';

const specificParkingOptions = () => {
  const options: Array<{value: any; label: any}> = [];
  const {parkingsSetup} = useSelector((state: RootState) => state.parkingSetupReducer);

  parkingsSetup.map((parking: any) => options.push({value: parking.id, label: parking.name}));
  return options;
};

interface props {
  className?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  activeBackground?: string;
  borderRadius?: string;
  placeHolder1?: string;
  placeHolder2?: string;
}

const ParkingDropDown: FC<props> = ({
  className,
  width = '250px',
  height = '100%',
  backgroundColor = BLACK,
  activeBackground = LIGHT_GREY,
  borderRadius = '14px',
  placeHolder1 = 'Select parking',
  placeHolder2 = 'Start typing to show data',
}) => {
  const {selectedParking} = useSelector((state: RootState) => state.parkingSetupReducer);

  const options = specificParkingOptions();

  const defaultProps = {
    options,
    getOptionLabel: (option: any) => option?.label ?? 'Not found',
  };
  const dispatch: any = useDispatch();
  const {loading} = useSelector((state: RootState) => state.parkingSetupReducer);
  const [trigger, setTrigger] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  useOutsideClick(ref, () => {
    setTrigger(false);
  });

  useEffect(() => {
    dispatch(getParkingSetup());
  }, []);

  return (
    <div
      className={className}
      ref={ref}
      style={{
        width,
        height,
        backgroundColor: `${trigger ? activeBackground : backgroundColor}`,
        fontFamily: 'Poppins',
        color: trigger ? YELLOW : WHITE,
        borderRadius,
        // whiteSpace: 'nowrap',
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: '60px',
          padding: '0 12px',
        }}>
        <p>{selectedParking?.name ?? placeHolder1} </p>
        {!trigger && options.length > 1 ? (
          <CustomIcon
            href={whiteBigFownArrow}
            className="cursor-pointer"
            onClick={() => {
              dispatch(getParkingSetup());
              setTrigger(true);
            }}
          />
        ) : trigger ? (
          <CustomIcon
            href={orangeUpperArrow}
            className="cursor-pointer"
            onClick={() => {
              setTrigger(false);
            }}
          />
        ) : (
          <></>
        )}
      </div>
      {trigger && (
        <Autocomplete
          {...defaultProps}
          filterOptions={(options, {inputValue}) =>
            options?.filter(option =>
              option?.label?.toLowerCase().startsWith(inputValue.toLowerCase()),
            )
          }
          sx={{position: 'absolute', zIndex: '999999', width}}
          open={true}
          PaperComponent={({children}) => (
            <Paper sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <CustomLoader loading={loading} size={40}>
                <div
                  style={{
                    width: '100%',
                  }}>
                  {children}
                </div>
              </CustomLoader>
            </Paper>
          )}
          renderOption={(props, option) => {
            return (
              <li
                {...props}
                key={option.value}
                className={`${styles.sarchedOption} ${
                  option === selectedParking?.name ? styles.activeSearchedOption : ''
                } ${styles.sarchedOption}`}
                style={{
                  listStyleType: 'none',
                  padding: '10px 20px',
                  color: BLACK,
                  cursor: 'pointer',
                  fontSize: '13px',
                  fontWeight: 'bold',
                }}>
                {option?.label}
              </li>
            );
          }}
          onChange={(event, newValue: any) => {
            dispatch(setParkingID({id: newValue.value, name: newValue.label}));
          }}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={`${placeHolder2}`}
              sx={{color: WHITE}}
              InputProps={{
                ...params.InputProps,
                style: {
                  padding: '5px 16px 5px 14px',
                  background: WHITE,
                  fontSize: '13px',
                },
                endAdornment: <CustomIcon href={search} className="cursor-pointer" />,
              }}
            />
          )}
        />
      )}
    </div>
  );
};

export default ParkingDropDown;
