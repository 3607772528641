import {useParams} from 'react-router-dom';
import moment from 'moment';
import {GREY} from 'constants/colors';

const InvoiceScanned = () => {
  const {number, date, phone, carPlate, value} = useParams();

  return (
    <div
      className="flex justify-center align-center"
      style={{height: '100vh', alignItems: 'center'}}>
      <div
        style={{width: '94%'}}
        className="h-5/7 print-container  flex flex-col items-center !py-7 mx-auto my-[4vh] !border border-solid border-[var(--disabled-grey)] rounded-xl">
        <h3 color={GREY} style={{fontWeight: 'bold', marginBottom: '20px'}}>
          Invoice Data
        </h3>
        <hr className="w-full text-[var(--disabled-grey)] !border border-solid" />
        <div className="list-container text-sm flex justify-between w-full mt-[4%] !p-3">
          <ul className="whitespace-nowrap font-bold ">
            <li>Invoice number</li>
            <li>Invoice date</li>
            <li>User Mobile</li>
            <li>Car Plate</li>
            <li>Invoice value</li>
          </ul>
          <hr className="h-[30vh] text-[var(--disabled-grey)] !border border-solid mt-2" />
          <ul className="whitespace-nowrap w-50%">
            <li>{number === 'null' ? '--' : number}</li>
            <li>{date === 'null' ? '--' : moment(date).format('D MMM, YYYY - h:mm a')}</li>
            <li>{phone === 'null' ? '--' : `+${phone}`}</li>
            <li>{carPlate === 'null' ? '--' : carPlate}</li>
            <li>
              <p>{value === 'null' ? '--' : value} SR</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default InvoiceScanned;
