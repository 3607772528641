import {
  GET_EMPLOYEE,
  GET_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_LOADING,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_LOADING,
} from 'redux/types';

const initialState = {
  employee: [],
  editedEmployee: {},
  totalPages: 0,
  currentPage: 0,
  loading: false,
  error: '',
};

export const getEmployeeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_EMPLOYEE_LOADING:
      return {...state, loading: action.payload};
    case GET_EMPLOYEE:
      return {
        ...state,
        employee: action.payload[0].users,
        totalPages: action.payload[0].totalPages,
        currentPage: action.payload[0].currentPage,
      };
    case GET_EMPLOYEE_FAILURE:
      return {...state, error: action.payload};
    case UPDATE_EMPLOYEE:
      return {...state, editedEmployee: action.payload};
    case UPDATE_EMPLOYEE_LOADING:
      return {...state, loading: action.payload};
    case UPDATE_EMPLOYEE_FAILURE:
      return {...state, error: action.payload};
    default:
      return state;
  }
};
