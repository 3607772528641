import {GET_ORGANIZATIONS, GET_ORGANIZATIONS_FAILURE, GET_ORGANIZATIONS_LOADING} from 'redux/types';
import {getParkingService} from 'services/getOrganizationsService';

export const getOrganiztionsAction = (pageNumber?: number) => async (dispatch: any) => {
  try {
    dispatch({type: GET_ORGANIZATIONS_LOADING, payload: true});
    const response = await getParkingService(pageNumber);
    dispatch({type: GET_ORGANIZATIONS, payload: response.data[0].organizations});
  } catch (error) {
    dispatch({type: GET_ORGANIZATIONS_FAILURE, error});
  } finally {
    dispatch({type: GET_ORGANIZATIONS_LOADING, payload: false});
  }
};
