import {
  GET_EMPLOYEE,
  GET_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_LOADING,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_LOADING,
} from 'redux/types';

import {Dispatch} from 'redux';
import {EmployeeSetup} from 'model/EmployeeSetup';
import {editEmployeeService} from 'services/user/userServices';
import {getEmployeeService} from 'services/user/getEmployeeService';

export const getEmployee = (parkingId?: string, page?: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: GET_EMPLOYEE_LOADING, payload: true});
    const res = await getEmployeeService(parkingId, page);
    dispatch({type: GET_EMPLOYEE, payload: res.data});
  } catch (err) {
    dispatch({type: GET_EMPLOYEE_FAILURE, payload: err});
  } finally {
    dispatch({type: GET_EMPLOYEE_LOADING, payload: false});
  }
};

export const editEmployee =
  (data: EmployeeSetup, parkingId?: string, page?: number) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: UPDATE_EMPLOYEE_LOADING, payload: true});
      const res = await editEmployeeService(data);
      dispatch({type: UPDATE_EMPLOYEE, payload: res.data});
    } catch (error) {
      dispatch({type: UPDATE_EMPLOYEE_FAILURE, payload: error});
    } finally {
      dispatch({type: UPDATE_EMPLOYEE_LOADING, payload: false});
      const res = await getEmployeeService(parkingId, page);
      dispatch({type: GET_EMPLOYEE, payload: res.data});
    }
  };
