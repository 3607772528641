import {
  AUTH_LOADING,
  FORGET_PASSWORD,
  FORGET_PASSWORD_FAILED,
  LOG_IN,
  LOG_IN_FAILED,
  LOG_OUT,
  RESET_AUTH,
  RESET_ERROR,
  SEND_OTP,
  SEND_OTP_FAILED,
  SET_USER,
  TRY_AGAIN,
  VERIFY_OTP,
  VERIFY_OTP_FAILED,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_FAILED,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_LOADING,
} from '../types';

import {Dispatch} from 'redux';
import {forgetPasswordService} from 'services/auth/forgetPassword';
import {loginService} from 'services/auth/loginService';
import {sendOTPService} from 'services/auth/sendOTPService';
import {verifyOTPService} from 'services/auth/verifyOTPService';
import {changePasswordService} from 'services/auth/changePassword';

interface LoginParam {
  phone: string;
  password: string;
  onError: () => void;
}

export const login = (param: LoginParam) => async (dispatch: any) => {
  try {
    dispatch({type: AUTH_LOADING, payload: true});
    const response = await loginService(param.phone, param.password);
    dispatch({type: LOG_IN, payload: response.data});
    dispatch({type: AUTH_LOADING, payload: false});
  } catch (error) {
    dispatch({type: LOG_IN_FAILED, payload: error});
    dispatch({type: AUTH_LOADING, payload: false});
    param.onError();
  }
};
export const tryAgain = (dispatch: any) => {
  dispatch({type: TRY_AGAIN});
};

export const setUser = (user: any) => (dispatch: any) => {
  dispatch({type: SET_USER, payload: user});
};

export const logoutAction = () => (dispatch: any) => {
  dispatch({type: LOG_OUT});
};

export const sendOTP =
  (phoneNumber: string, onSuccess?: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: AUTH_LOADING, payload: true});
      const response = await sendOTPService(phoneNumber);
      if (response.status === 200) dispatch({type: SEND_OTP, payload: {otp: '1234', phoneNumber}});
      onSuccess?.();
    } catch (error) {
      dispatch({type: SEND_OTP_FAILED, payload: error});
    } finally {
      dispatch({type: AUTH_LOADING, payload: false});
    }
  };

export const verifyOTP =
  (otp: string, phone: string, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: AUTH_LOADING, payload: true});
      const response = await verifyOTPService(otp, phone);
      if (response.status === 200) dispatch({type: VERIFY_OTP, payload: null});
      onSuccess();
    } catch (error: any) {
      dispatch({type: VERIFY_OTP_FAILED, payload: error});
    } finally {
      dispatch({type: AUTH_LOADING, payload: false});
    }
  };

export const forgetPassword =
  (newPassword: string, phone: string, onSuccess: () => void) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: AUTH_LOADING, payload: true});
      const response = await forgetPasswordService(newPassword, phone);
      if (response.status === 200) dispatch({type: FORGET_PASSWORD});
      onSuccess();
    } catch (error: any) {
      dispatch({type: FORGET_PASSWORD_FAILED, payload: error.message});
    } finally {
      dispatch({type: AUTH_LOADING, payload: false});
    }
  };

export const changePassword =
  (param: {oldpass: string; newPass: string; onSave: () => void}) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: CHANGE_PASSWORD, payload: true});
      const response = await changePasswordService(param.oldpass, param.newPass);
      dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: response.data});
    } catch (error) {
      dispatch({type: CHANGE_PASSWORD_FAILED, payload: error});
    } finally {
      dispatch({type: CHANGE_PASSWORD_LOADING, payload: false});
      param.onSave();
    }
  };

export const resetAuth = () => {
  return {type: RESET_AUTH};
};

export const resetError = () => {
  return {type: RESET_ERROR};
};
