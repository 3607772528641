import api from 'api';
import {getParkingEndPoint} from 'api/apisURL';

export const getParkingService = async (id?: string) => {
  const data = {
    filters: [
      {
        fieldName: 'id',
        operator: 'like',
        values: [id],
      },
    ],
  };
  const response = await api.post(getParkingEndPoint(), data);
  return response;
};
