import {Dispatch, FC, MouseEvent, SetStateAction, useState} from 'react';
import {calendar, clear} from 'assets/icons';
import {useDispatch, useSelector} from 'react-redux';

import CustomButton from 'components/CustomButton/CustomButton';
import {DateRange} from 'react-date-range';
import {RootState} from 'redux/rootReducers';
import {ThunkDispatchType} from 'redux/types';
import {getInvoices, getReceipts, getRevenues, getTransactions} from 'redux/actions/reportsAction';
import moment from 'moment';
import {useTranslation} from 'react-i18next';

interface Props {
  type: string;
  datePickerUpdated: boolean;
  setDatePickerUpdated: Dispatch<SetStateAction<boolean>>;
}

const CalendarPicker: FC<Props> = ({type, datePickerUpdated, setDatePickerUpdated}) => {
  const {t, i18n} = useTranslation();

  const dispatch: ThunkDispatchType = useDispatch();
  const {user} = useSelector((state: RootState) => state.authReducer);

  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false);
  const [selectedRange, setSelectedRange] = useState<Array<Record<string, unknown>>>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);

  const startDate = moment(selectedRange[0].startDate as Date);
  const endDate = moment(selectedRange[0].endDate as Date);

  const handleChange = (item: {selection: {startDate: Date; endDate: Date; key: string}}) => {
    const {startDate, endDate} = item.selection;
    setSelectedRange([
      {
        startDate,
        endDate,
        key: 'selection',
      },
    ]);
  };

  const handleUpdate = () => {
    setDatePickerOpen(false);
    setDatePickerUpdated(true);
    const newStartDate = startDate.startOf('day');
    const newEndDate = endDate.endOf('day');
    switch (type) {
      case 'invoice':
        dispatch(
          getInvoices(1, {
            from: newStartDate.format('YYYY-MM-DD HH:mm:ss'),
            to: newEndDate.format('YYYY-MM-DD HH:mm:ss'),
          }),
        );
        break;
      case 'receipt':
        dispatch(
          getReceipts(1, {
            from: newStartDate.format('YYYY-MM-DD HH:mm:ss'),
            to: newEndDate.format('YYYY-MM-DD HH:mm:ss'),
          }),
        );
        break;
      case 'transaction':
        dispatch(
          getTransactions(1, {
            from: newStartDate.format('YYYY-MM-DD HH:mm:ss'),
            to: newEndDate.format('YYYY-MM-DD HH:mm:ss'),
          }),
        );
        break;
    }
  };

  const handleClear = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    setDatePickerUpdated(false);
    setSelectedRange([
      {
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
      },
    ]);
    switch (type) {
      case 'invoice':
        dispatch(getInvoices(1));
        break;
      case 'receipt':
        dispatch(getReceipts(1));
        break;
      case 'transaction':
        dispatch(getTransactions(1));
        break;
      case 'revenue':
        dispatch(getRevenues(user.parkingId));
        break;
    }
  };

  return (
    <>
      <div className="text-right">
        <button
          className="bg-black rounded-xl h-16 w-48"
          onClick={() => setDatePickerOpen(!datePickerOpen)}>
          <div
            className={`flex justify-center items-center gap-2 ${
              datePickerUpdated && 'mr-6'
            } relative`}>
            <img src={calendar} alt="calendar" />
            <p className="text-white text-sm">
              {datePickerUpdated
                ? startDate.format('YY MMM DD') === endDate.format('YY MMM DD')
                  ? `${startDate.format('YY MMM DD')}`
                  : `${startDate.format('YY MMM DD')} : ${endDate.format('MMM DD')}`
                : t('Select period')}
            </p>
            {datePickerUpdated && (
              <div
                className="flex items-center justify-center text-lg bg-[red] absolute w-6 h-16 rounded-r-lg -right-6"
                onClick={handleClear}>
                <img src={clear} alt="clear" />
              </div>
            )}
          </div>
        </button>
      </div>
      {datePickerOpen && (
        <div
          dir="ltr"
          className={`absolute ${
            i18n.language === 'en' ? 'right-0' : 'left-0'
          } top-12 z-10 bg-[var(--accent-white)] p-2 mt-4 rounded-[20px] border-[var(--main-color)] border-solid border-2`}>
          <DateRange
            className="pb-2"
            color="#aeaeaea1"
            ranges={selectedRange}
            onChange={handleChange}
          />
          <div className="w-full text-center">
            <CustomButton className="mb-2 px-4 !h-9" color="Orange" onClick={handleUpdate}>
              {t('Update')}
            </CustomButton>
          </div>
        </div>
      )}
    </>
  );
};

export default CalendarPicker;
