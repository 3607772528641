import './styles.css';
import {FC, useEffect} from 'react';
import {availableSpots, car, inUseSpots, refresh} from 'assets/icons';
import {useDispatch, useSelector} from 'react-redux';
import CustomButton from 'components/CustomButton/CustomButton';
import {CustomHeaderCard} from 'components/CustomHeaderCard/CustomHeaderCard';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import {RootState} from 'redux/rootReducers';
import {clearParkingState} from 'redux/actions/parkingSetupAction';
import {getParking, getReservedSlotsAction} from 'redux/actions/parkingAction';
import {useTranslation} from 'react-i18next';

interface props {
  ParkingID?: string;
}

const Cards: FC<props> = ({ParkingID}) => {
  const dispatch: any = useDispatch();
  const {t} = useTranslation();
  const {parkings, fetchLoading, newParking, reserverdSlots, fetchReservedSlotsLoading} =
    useSelector((state: RootState) => state.parkingReducer);
  const parking = parkings ? parkings[0] : null;

  const handleRefresh = () => {
    window.location.reload();
    if (ParkingID) {
      dispatch(getParking(ParkingID));
    } else {
      dispatch(clearParkingState());
    }
  };

  useEffect(() => {
    if (ParkingID) {
      dispatch(getParking(ParkingID));
      dispatch(getReservedSlotsAction(ParkingID));
    } else {
      dispatch(clearParkingState());
    }
  }, [newParking, ParkingID]);

  return (
    <div id="cards">
      <div className="cards-container">
        <CustomHeaderCard
          icon={<CustomIcon href={car} />}
          title={t('Total spots')}
          subTitle={`${parking?.capacity ?? '--'} ${t('Spots')}`}
          loading={fetchLoading}
        />
        <CustomHeaderCard
          icon={<CustomIcon href={inUseSpots} />}
          title={t('In use spots')}
          subTitle={`${parking?.allocatedSlots ?? '--'} ${t('Spots')}`}
          loading={fetchLoading}
        />
        <CustomHeaderCard
          icon={<CustomIcon href={availableSpots} />}
          title={t('Reserved spots')}
          subTitle={`${reserverdSlots?.reservedNotUsed ?? '--'} ${t('Spots')}`}
          loading={fetchReservedSlotsLoading}
          description="Unused reserved slots across all packages"
        />
        <CustomHeaderCard
          icon={<CustomIcon href={availableSpots} />}
          title={t('Free spots')}
          subTitle={`${reserverdSlots?.freeNotReservedSlots ?? '--'} ${t('Spots')}`}
          loading={fetchReservedSlotsLoading}
          description="Free slots unallocated to any package"
        />
      </div>
      <div className="btn-container">
        <CustomButton
          hover="#0A0A0A"
          width="100%"
          height="56px"
          color="Black"
          onClick={handleRefresh}>
          <CustomIcon href={refresh} />
        </CustomButton>
      </div>
    </div>
  );
};

export default Cards;
