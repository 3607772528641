import {END_SHIFT_PATH, LOGIN_PATH, START_SHIFT_PATH} from './paths';
import {Navigate, Outlet} from 'react-router-dom';

import AdminSideBar from 'components/AdminSideBar/AdminSideBar';
import {FC} from 'react';
import GateNavbar from 'components/GateNavbar/GateNavbar';
import {RouteProps} from 'routes';
import UpperNavbar from 'components/UpperNavbar/UpperNavbar';
import {useLocation} from 'react-router';

const ProtectedRoutes: FC<RouteProps> = ({user}) => {
  const {pathname} = useLocation();

  if (!user) {
    return <Navigate to={LOGIN_PATH} />;
  }

  const shouldRenderGateNavbar =
    user.isGateEmployee && pathname !== START_SHIFT_PATH && pathname !== END_SHIFT_PATH;
  const shouldRenderAdminSideBar = !user.isGateEmployee;

  return (
    <>
      <UpperNavbar />
      {shouldRenderGateNavbar && <GateNavbar />}
      {shouldRenderAdminSideBar && <AdminSideBar />}
      <Outlet />
    </>
  );
};

export default ProtectedRoutes;
