import './styles.css';

import {Box, Modal, Typography} from '@mui/material';
import {FC, ReactNode} from 'react';
import {arrowLeft, close} from 'assets/icons';

import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import {Parking} from 'model/servicesModels';

interface Props {
  handleCloseModal?: () => void;
  open: boolean;
  backButton?: boolean;
  setCount?: (value: number) => void;
  popupTitle: string;
  selectedRow?: Parking;
  children: ReactNode;
  clickHandler?: () => void;
  mobile?: boolean;
}

const Popup: FC<Props> = ({
  handleCloseModal,
  open,
  backButton,
  popupTitle,
  selectedRow,
  children,
  clickHandler,
  mobile,
}) => {
  return (
    <Modal open={open} onClose={handleCloseModal}>
      <Box className={`popup-contianer ${mobile ? 'mobile-container' : 'large-contianer'}`}>
        <Box className="head">
          <Box display="flex" alignItems="center" gap={2}>
            {backButton && (
              <CustomIcon
                className="cursor-pointer"
                width={9}
                height={18}
                href={arrowLeft}
                onClick={clickHandler}
              />
            )}
            <Typography>{popupTitle}</Typography>
          </Box>
          {popupTitle === 'Gate Setup' && (
            <Typography mr={8}>{selectedRow?.gate_count} Gates</Typography>
          )}
          <CustomIcon href={close} onClick={handleCloseModal} className="cursor-pointer mt-2" />
        </Box>
        {children}
      </Box>
    </Modal>
  );
};

export default Popup;
