import {
  ASSIGN_USER,
  EDIT_USER_ROLE,
  GET_REALTIME_USER,
  GET_SELECTED_USER,
  GET_USERS,
  GET_USERS_FAILURE,
  RESET_USER,
  USER_LOADING,
} from 'redux/types';

import {UserSetupTableTypes} from 'model/types';
import {getUsersService} from 'services/user/getUsersService';

export const getRealTimeUser = (user: Record<string, any>) => {
  return {type: GET_REALTIME_USER, payload: user};
};

export const getUsersAction = (page?: number, phone?: string) => async (dispatch: any) => {
  try {
    dispatch({type: USER_LOADING, payload: true});
    const response = await getUsersService(page, phone);
    dispatch({type: GET_USERS, payload: response.data[0]});
  } catch (error) {
    dispatch({type: GET_USERS_FAILURE, payload: error});
  } finally {
    dispatch({type: USER_LOADING, payload: false});
  }
};

export const getSelectedUser = (user: UserSetupTableTypes, index: number) => {
  return {type: GET_SELECTED_USER, payload: {user, index}};
};

export const assignUser = (role: string) => {
  return {type: ASSIGN_USER, payload: role};
};

export const resetUser = () => {
  return {type: RESET_USER};
};

export const editUserRole = (role: string) => {
  return {type: EDIT_USER_ROLE, payload: role};
};
