import {
  RESET_SET_PARKING_AS_FULL,
  SET_PARKING_AS_FREE,
  SET_PARKING_AS_FREE_FAILED,
  SET_PARKING_AS_FREE_LOADING,
  SET_PARKING_AS_FULL,
  SET_PARKING_AS_FULL_FAILED,
  SET_PARKING_AS_FULL_LOADING,
} from 'redux/types';

import {Dispatch} from 'redux';
import {SetParkingAsFull} from 'model/setParkingAsFull';
import {setParkingAsFullService} from 'services/parking/setParkingAsFullService';
import {setParkingAsFreeService} from 'services/parking/setParkingAsFreeService';

export const setParkingAsFull = (data: SetParkingAsFull) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: SET_PARKING_AS_FULL_LOADING, payload: true});
    const response = await setParkingAsFullService(data);
    dispatch({type: SET_PARKING_AS_FULL, payload: response.data});
  } catch (error) {
    dispatch({type: SET_PARKING_AS_FULL_FAILED, payload: error});
  } finally {
    dispatch({type: SET_PARKING_AS_FULL_LOADING, payload: false});
  }
};

export const setParkingAsFree = (id: string, cb: any) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: SET_PARKING_AS_FREE_LOADING, payload: true});
    await setParkingAsFreeService(id);
    dispatch({type: SET_PARKING_AS_FREE, payload: 'free'});
    cb();
  } catch (error) {
    dispatch({type: SET_PARKING_AS_FREE_FAILED, payload: error});
  } finally {
    dispatch({type: SET_PARKING_AS_FREE_LOADING, payload: false});
  }
};

export const resetSetParkingAsFull = () => {
  return {type: RESET_SET_PARKING_AS_FULL};
};
