import {
  GET_PROCESS_CHART_DATA,
  GET_PROCESS_CHART_DATA_FAILURE,
  GET_PROCESS_CHART_DATA_LOADING,
  GET_PACKAGE_CHART_DATA,
  GET_PACKAGE_CHART_DATA_FAILURE,
  GET_PACKAGE_CHART_DATA_LOADING,
  GET_PAYMENT_CHART_DATA_LOADING,
  GET_PAYMENT_CHART_DATA,
  GET_PAYMENT_CHART_DATA_FAILURE,
  UPDATE_PROCESS_SLOTS_LOADING,
  UPDATE_PROCESS_SLOTS,
  UPDATE_PROCESS_SLOTS_FAILURE,
  UPDATE_PROCESS_AMOUNT,
  UPDATE_PROCESS_AMOUNT_FAILURE,
  UPDATE_PROCESS_AMOUNT_LOADING,
  UPDATE_PACKAGE_SLOTS,
  UPDATE_PACKAGE_SLOTS_FAILURE,
  UPDATE_PACKAGE_SLOTS_LOADING,
  UPDATE_PACKAGE_AMOUNT,
  UPDATE_PACKAGE_AMOUNT_FAILURE,
  UPDATE_PACKAGE_AMOUNT_LOADING,
} from 'redux/types';
import {Dispatch} from 'redux';
import {PackageAndProcesChartService} from 'services/Charts/PackageAndProcesChartService';
import {PaymentChartService} from 'services/Charts/PaymentChartService';

export const getProcessChartAction =
  (parkingId: string, startDate: string | undefined, endDate: string | undefined) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_PROCESS_CHART_DATA_LOADING, payload: true});
      const res = await PackageAndProcesChartService(parkingId, startDate, endDate, 'process');
      dispatch({type: GET_PROCESS_CHART_DATA, payload: res.data});
    } catch (err) {
      dispatch({type: GET_PROCESS_CHART_DATA_FAILURE, payload: err});
    } finally {
      dispatch({type: GET_PROCESS_CHART_DATA_LOADING, payload: false});
    }
  };

export const getPackageChartAction =
  (parkingId: string, startDate: string | undefined, endDate: string | undefined) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_PACKAGE_CHART_DATA_LOADING, payload: true});
      const res = await PackageAndProcesChartService(parkingId, startDate, endDate, 'package');
      dispatch({type: GET_PACKAGE_CHART_DATA, payload: res.data});
    } catch (err) {
      dispatch({type: GET_PACKAGE_CHART_DATA_FAILURE, payload: err});
    } finally {
      dispatch({type: GET_PACKAGE_CHART_DATA_LOADING, payload: false});
    }
  };

export const updateProcessSlots =
  (parkingId: string, startDate: string | undefined, endDate: string | undefined) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: UPDATE_PROCESS_SLOTS_LOADING, payload: true});
      const res = await PackageAndProcesChartService(
        parkingId,
        startDate,
        endDate,
        'process',
        'checkInDate',
      );
      dispatch({type: UPDATE_PROCESS_SLOTS, payload: res.data});
    } catch (err) {
      dispatch({type: UPDATE_PROCESS_SLOTS_FAILURE, payload: err});
    } finally {
      dispatch({type: UPDATE_PROCESS_SLOTS_LOADING, payload: false});
    }
  };

export const updateProcessAmount =
  (parkingId: string, startDate: string | undefined, endDate: string | undefined) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: UPDATE_PROCESS_AMOUNT_LOADING, payload: true});
      const res = await PackageAndProcesChartService(
        parkingId,
        startDate,
        endDate,
        'process',
        'checkOutDate',
      );
      dispatch({type: UPDATE_PROCESS_AMOUNT, payload: res.data});
    } catch (err) {
      dispatch({type: UPDATE_PROCESS_AMOUNT_FAILURE, payload: err});
    } finally {
      dispatch({type: UPDATE_PROCESS_AMOUNT_LOADING, payload: false});
    }
  };

export const updatePackageSlots =
  (parkingId: string, startDate: string | undefined, endDate: string | undefined) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: UPDATE_PACKAGE_SLOTS_LOADING, payload: true});
      const res = await PackageAndProcesChartService(
        parkingId,
        startDate,
        endDate,
        'package',
        'checkInDate',
      );
      dispatch({type: UPDATE_PACKAGE_SLOTS, payload: res.data});
    } catch (err) {
      dispatch({type: UPDATE_PACKAGE_SLOTS_FAILURE, payload: err});
    } finally {
      dispatch({type: UPDATE_PACKAGE_SLOTS_LOADING, payload: false});
    }
  };

export const updatePackageAmount =
  (parkingId: string, startDate: string | undefined, endDate: string | undefined) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: UPDATE_PACKAGE_AMOUNT_LOADING, payload: true});
      const res = await PackageAndProcesChartService(
        parkingId,
        startDate,
        endDate,
        'package',
        'checkOutDate',
      );
      dispatch({type: UPDATE_PACKAGE_AMOUNT, payload: res.data});
    } catch (err) {
      dispatch({type: UPDATE_PACKAGE_AMOUNT_FAILURE, payload: err});
    } finally {
      dispatch({type: UPDATE_PACKAGE_AMOUNT_LOADING, payload: false});
    }
  };

export const getPaymentChartAction =
  (startDate: string | undefined, endDate: string | undefined) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_PAYMENT_CHART_DATA_LOADING, payload: true});
      const res = await PaymentChartService(startDate, endDate);
      dispatch({type: GET_PAYMENT_CHART_DATA, payload: res.data});
    } catch (err) {
      dispatch({type: GET_PAYMENT_CHART_DATA_FAILURE, payload: err});
    } finally {
      dispatch({type: GET_PAYMENT_CHART_DATA_LOADING, payload: false});
    }
  };
