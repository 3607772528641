import {getParkingEndPoint} from 'api/apisURL';
import api from 'api';

export const getParkingListService = async () => {
  const payload = {
    filters: [
      {
        fieldName: 'Organization_ID',
        operator: 'like',
        values: ['8d1208fc-f401-496c-9cb8-483fef121234'],
      },
    ],
  };

  const response = await api.post(getParkingEndPoint(), payload);
  return response;
};
