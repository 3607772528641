import {
  GET_INVOICES,
  GET_INVOICES_LOADING,
  GET_RECEIPTS,
  GET_RECEIPTS_LOADING,
  GET_REVENUES,
  GET_REVENUES_LOADING,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_LOADING,
  GET_UTILITES,
  GET_UTILITES_LOADING,
} from 'redux/types';

import {Invoice} from 'model/Reports/Invoice';
import {Revenue} from 'model/Reports/Revenue';
import {Transaction} from 'model/Reports/Transaction';
import {Utility} from 'model/Reports/Utility';

interface ReportsState {
  invoiceTotalPages: number;
  invoiceCurrentPage: number;
  invoices: Invoice[];
  invoiceLoading: boolean;
  receiptTotalPages: number;
  receiptCurrentPage: number;
  receipts: Invoice[];
  receiptLoading: boolean;
  transactionTotalPages: number;
  transactionCurrentPage: number;
  transactions: Transaction[];
  transactionLoading: boolean;
  utilites: Utility[];
  utilityLoading: boolean;
  revenues: Revenue[];
  revenueLoading: boolean;
}

interface ReportsAction {
  type: string;
  payload: any;
}

const initialState: ReportsState = {
  invoiceTotalPages: 1,
  invoiceCurrentPage: 1,
  invoices: [],
  invoiceLoading: false,
  receiptTotalPages: 1,
  receiptCurrentPage: 1,
  receipts: [],
  receiptLoading: false,
  transactionTotalPages: 1,
  transactionCurrentPage: 1,
  transactions: [],
  transactionLoading: false,
  utilites: [],
  utilityLoading: false,
  revenues: [],
  revenueLoading: false,
};

export const reportsReducer = (state = initialState, action: ReportsAction): ReportsState => {
  switch (action.type) {
    case GET_INVOICES:
      return {
        ...state,
        invoices: action.payload[0].invoices,
        invoiceCurrentPage: action.payload[0].currentPage,
        invoiceTotalPages: action.payload[0].totalPages,
      };
    case GET_INVOICES_LOADING:
      return {...state, invoiceLoading: action.payload};
    case GET_RECEIPTS:
      return {
        ...state,
        receipts: action.payload[0].invoices,
        receiptCurrentPage: action.payload[0].currentPage,
        receiptTotalPages: action.payload[0].totalPages,
      };
    case GET_RECEIPTS_LOADING:
      return {...state, receiptLoading: action.payload};
    case GET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload.reservations,
        transactionCurrentPage: action.payload.currentPage,
        transactionTotalPages: action.payload.totalPages,
      };
    case GET_TRANSACTIONS_LOADING:
      return {...state, transactionLoading: action.payload};
    case GET_UTILITES:
      return {...state, utilites: action.payload};
    case GET_UTILITES_LOADING:
      return {...state, utilityLoading: action.payload};
    case GET_REVENUES:
      return {...state, revenues: action.payload};
    case GET_REVENUES_LOADING:
      return {...state, revenueLoading: action.payload};
    default:
      return state;
  }
};
