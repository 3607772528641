import api from 'api';
import {getInvoicesEndpoint} from 'api/apisURL';

export const getInvoicesService = async (
  pageNumber: number,
  date?: {from: string; to: string},
  getAll?: boolean,
) => {
  const payload = {
    filters: [
      {
        fieldName: 'invoiceRef',
        operator: 'notEq',
        values: ['null'],
      },
    ],
    pageNumber: date ?? getAll ? 1 : pageNumber,
    pageSize: date ?? getAll ? 10000 : 10,
  };

  if (date) {
    payload.filters.push({
      fieldName: 'invoiceCreationDate',
      operator: 'between',
      values: [date.from, date.to],
    });
  }

  const response = await api.post(getInvoicesEndpoint(), payload);
  return response;
};
