import {Box, Button, Stack, Typography} from '@mui/material';
import {HOME_PATH, LOGIN_PATH} from 'routes/paths';
import {errorIcon, successIcon} from 'assets/icons';

import {FC} from 'react';
import {RootState} from 'redux/rootReducers';
import Style from './ResponseModal.module.css';
import {useCountdown} from 'hooks/useCountdown';
import {useSelector} from 'react-redux';

interface Props {
  message: string;
  options?: boolean;
  handleYes?: VoidFunction;
  handleNo?: VoidFunction;
  error?: boolean;
  errorBoundary?: boolean;
  onClose?: VoidFunction;
  errorMsg?: string;
  optionIcon?: string;
}
const ResponseModal: FC<Props> = ({
  message,
  options,
  handleYes,
  handleNo,
  error,
  errorBoundary,
  onClose,
  optionIcon,
}) => {
  const {user} = useSelector((state: RootState) => state.authReducer);
  useCountdown(2, () => onClose?.());

  if (options) {
    return (
      <Box
        id="response-modal"
        className={Style.successModal}
        bgcolor={errorBoundary ? 'white' : ''}>
        <div className={Style.modal}>
          <Stack className={Style.succesContent} gap={options ? 2 : 0}>
            <img src={error ? errorIcon : optionIcon} />
            <Typography
              className="!text-sm"
              sx={
                error
                  ? {color: 'red !important'}
                  : {whiteSpace: 'nowrap', color: 'white !important'}
              }>
              {message}
            </Typography>
            (
            <Stack direction="row" mt={3} gap={2}>
              <Button
                onClick={handleYes}
                sx={{
                  background: 'var(--green)',
                  px: '11vw',
                  color: 'white',
                  textTransform: 'capitalize',
                  borderRadius: 4,
                }}>
                Yes
              </Button>
              <Button
                onClick={handleNo}
                sx={{
                  background: 'var(--red)',
                  px: '11vw',
                  color: 'white',
                  textTransform: 'capitalize',
                  borderRadius: 4,
                }}>
                No
              </Button>
            </Stack>
            )
          </Stack>
        </div>
      </Box>
    );
  }

  return (
    <Box id="response-modal" className={Style.successModal} bgcolor={errorBoundary ? 'white' : ''}>
      <div className={Style.modal}>
        <Stack className={Style.succesContent} gap={options ? 2 : 0}>
          <img src={error ? errorIcon : successIcon} />
          <Typography sx={error ? {color: 'red !important'} : {}}>{message}</Typography>
          {errorBoundary && (
            <a href={user ? HOME_PATH : LOGIN_PATH} className={Style.back}>
              {user ? 'Back to home' : 'Back to login'}
            </a>
          )}
        </Stack>
      </div>
    </Box>
  );
};
export default ResponseModal;
