import {INVOICE_FAILURE, INVOICE_LOADING, INVOICE_SUCCESS} from 'redux/types';

const initalState = {
  amount: null,
  error: null,
  loading: false,
};

export const invoiceReducer = (state = initalState, action: any) => {
  switch (action.type) {
    case INVOICE_SUCCESS:
      return {...state, amount: action.payload};
    case INVOICE_LOADING:
      return {...state, loading: action.payload};
    case INVOICE_FAILURE:
      return {...state, error: action.payload.response.data.statusMessage};
    default:
      return state;
  }
};
