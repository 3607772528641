import Styles from './packageSlotsDoughnut.styles.module.css';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducers';
import {Doughnut} from 'react-chartjs-2';
import {COLORS_ARRAY} from 'constants/colors';
import {t} from 'i18next';
import {useTranslation} from 'react-i18next';
import {FC} from 'react';

interface Props {
  datePickerUpdated: boolean;
}

const PackageTotalDoughnut: FC<Props> = ({datePickerUpdated}) => {
  const {i18n} = useTranslation();
  const currentLanguage = i18n.language;
  const {packageChartData, filteredPackageSlots} = useSelector(
    (state: RootState) => state.chartReducer,
  );
  const chartPackagesArray: any = datePickerUpdated
    ? filteredPackageSlots && Object.keys(filteredPackageSlots).length > 0
      ? Object.entries(filteredPackageSlots)
      : []
    : packageChartData && Object.keys(packageChartData).length > 0
    ? Object.entries(packageChartData)
    : [];

  const totalSlots = chartPackagesArray
    .map((pck: Array<{first: any}>) => {
      return pck[1].first;
    })
    .reduce((accumulator: any, currentValue: any) => {
      return accumulator + currentValue;
    }, 0);

  const getLabels = () => {
    const labels = [];
    for (let i = 0; i < chartPackagesArray?.length; i++) {
      labels.push(chartPackagesArray[i][0]);
    }
    return labels;
  };
  getLabels();

  function getChartValues(array: string | any[]) {
    const result = [];

    for (let i = 1; i <= array.length; i++) {
      if (array.length >= i) {
        result.push(array[i - 1][1]?.first || 0);
      } else {
        result.push(0);
      }
    }

    return result;
  }

  const chartData = {
    labels: getLabels(),
    datasets: [
      {
        data: getChartValues(chartPackagesArray),
        backgroundColor: COLORS_ARRAY,
        hoverBackgroundColor: COLORS_ARRAY,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    cutout: 0,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };
  return (
    <div className={`d-flex justify-between ${Styles.packageTotalContainer}`}>
      <div className={`d-flex w-4/5 flex-column ${currentLanguage === 'en' ? 'pr-1' : 'pl-1'}`}>
        <h2 className="font-bold text-black text-15 whitespace-nowrap mb-10">
          {t('Packages / Total slots')}
        </h2>
        <p className="d-flex flex-row items-center justify-between px-3 mt-3">
          <span style={{color: '#B9B9B9', fontSize: '14px'}}>{t('Package')}</span>
          <span style={{color: '#B9B9B9', fontSize: '14px'}}>{t('NO of slots')}</span>
        </p>
        <span
          style={{
            borderBottom: 'solid 1px  #B9B9B9',
            padding: '3px 0 5px',
            display: 'block',
            width: '100%',
          }}
        />
        <div style={{overflowY: 'scroll', margin: 0, height: '120px'}}>
          {chartPackagesArray?.map((pkg: any, idx: number) => (
            <div className="d-flex justify-between px-2 mt-3 mb-3" key={idx}>
              <span className="d-flex flex-row items-center gap-1">
                <span className="circle " style={{backgroundColor: COLORS_ARRAY[idx]}} />
                {pkg[0]}
              </span>
              <span>{pkg[1].first} </span>
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex flex-column items-center justify-center gap-3" style={{width: '69%'}}>
        <div style={{width: '100%', height: '100%'}}>
          <Doughnut
            data={chartData}
            options={chartOptions}
            width={200}
            style={{innerWidth: '100%'}}
          />
        </div>
        <p className="font-bold" style={{fontSize: '13.5px'}}>
          {t('Total slots')}: {totalSlots} {t('slots')}
        </p>
      </div>
    </div>
  );
};

export default PackageTotalDoughnut;
