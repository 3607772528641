import api from 'api';
import {getUsersEndPoint} from 'api/apisURL';

export const getEmployeeService = async (parkingId?: string, page?: number) => {
  const data = {
    filters: [
      {
        fieldName: 'userType',
        operator: 'like',
        values: ['EMPLOYEE'],
      },
      {
        fieldName: 'parkingId',
        operator: 'like',
        values: [parkingId],
      },
    ],
    pageNumber: page,
    pageSize: 10,
  };
  const response = await api.post(getUsersEndPoint(), data);
  return response;
};
