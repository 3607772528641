import {Col, Row} from 'react-bootstrap';

import AdminSessionTable from './components/AdminSessionTable/AdminSessionTable';
import Cards from 'components/Cards/Cards';
import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import MultipleSessionsTables from './components/MultipleSessionsTables/MultipleSessionsTables';
import ParkingDropDown from 'utils/dropdowns/ParkingDropDown';
import {RootState} from 'redux/rootReducers';
import {useSelector} from 'react-redux';
import {useState} from 'react';

const Sessions = () => {
  const {selectedParking} = useSelector((state: RootState) => state.parkingSetupReducer);
  const [session, setSession] = useState<any>();

  return (
    <div id="sessions">
      <MainViewContainer>
        <Row className="special-row">
          <Col xs={9}>
            <ParkingDropDown />
          </Col>
          <Col className="card-btn-container">
            <HeaderDate orange />
          </Col>
        </Row>
        <Row className="special-row">
          <Col xs={11}>
            <Cards ParkingID={selectedParking?.id} />
          </Col>
        </Row>
        <Row className="special-row">
          {session?.id ? (
            <MultipleSessionsTables
              parkingId={selectedParking?.id}
              session={session}
              setSession={setSession}
            />
          ) : (
            <AdminSessionTable parkingId={selectedParking?.id} setSession={setSession} />
          )}
        </Row>
      </MainViewContainer>
    </div>
  );
};

export default Sessions;
