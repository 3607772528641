import {
  CLEAR_USER_STATE,
  CREATE_USER,
  CREATE_USER_FAILED,
  CREATE_USER_LOADING,
  SET_USER_DATA,
  SET_USER_PAYLOAD,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_LOADING,
} from 'redux/types';

import {createUserService} from 'services/user/createUserService';
import {updateUserService} from 'services/user/updateUserService';

export const setUserPayload = (data: any) => async (dispatch: any) => {
  await dispatch({type: SET_USER_PAYLOAD, payload: data});
};
export const resetUserPayload = () => (dispatch: any) => {
  dispatch({type: SET_USER_PAYLOAD});
};
export const createUser = (data: any) => async (dispatch: any) => {
  dispatch({type: CREATE_USER_LOADING, loading: true});
  try {
    const response = await createUserService(data);
    dispatch({type: CREATE_USER_LOADING, loading: false});
    dispatch({type: CREATE_USER, payload: response.data});
  } catch (error) {
    dispatch({type: CREATE_USER_FAILED, payload: error});
    dispatch({type: CREATE_USER_LOADING, loading: false});
  }
};
export const updateUser = (data: any) => async (dispatch: any) => {
  dispatch({type: UPDATE_USER_LOADING, loading: true});
  try {
    const response = await updateUserService(data);
    dispatch({type: UPDATE_USER_LOADING, loading: false});
    dispatch({type: UPDATE_USER, payload: response.data});
  } catch (error) {
    dispatch({type: UPDATE_USER_FAILED, payload: error});
    dispatch({type: CREATE_USER_LOADING, loading: false});
  }
};
export const clearUserState = () => (dispatch: any) => {
  dispatch({type: CLEAR_USER_STATE});
};

export const setUser = (data: any) => async (dispatch: any) => {
  dispatch({type: SET_USER_DATA, payload: data});
};
