import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {FC, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import CustomLoader from 'components/CustomLoader/CustomLoader';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import {RootState} from 'redux/rootReducers';
import {YELLOW} from 'constants/colors';
import {checkShiftDataBalance} from 'utils/checkShiftDataBalance';
import {getShiftAction} from 'redux/actions/getShiftAction';
import moment from 'moment';

interface props {
  data: any[];
  loading: boolean;
}

const ShiftTable: FC<props> = ({data, loading}) => {
  const dispatch: any = useDispatch();
  const {user} = useSelector((state: RootState) => state.authReducer);
  const {totalPages, currentPage} = useSelector((state: RootState) => state.getShiftReducer);
  const {selectedParking} = useSelector((state: RootState) => state.parkingSetupReducer);

  useEffect(() => {
    dispatch(getShiftAction(user.parkingId));
  }, []);

  return (
    <Box>
      <TableContainer
        sx={{
          height: 500,
          borderRadius: 4,
          pr: 2,
          overflowY: `${loading && 'hidden'}`,
        }}>
        <Table className="sessions-table" sx={{fontFamily: 'Poppins'}}>
          <TableHead sx={{position: 'sticky', top: 0, whiteSpace: 'nowrap'}}>
            <TableRow sx={{background: YELLOW}}>
              <TableCell />
              <TableCell>Employee name</TableCell>
              <TableCell>Shift type</TableCell>
              <TableCell>Gate name</TableCell>
              <TableCell>Start date, time</TableCell>
              <TableCell>End date, time</TableCell>
              <TableCell>Starting cash balance</TableCell>
              <TableCell>Cash transaction</TableCell>
              <TableCell>Closing cash balance</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody sx={{height: data?.length > 3 ? 500 : 0}}>
            <CustomLoader size={80} loading={loading}>
              {data?.length === 0 || data === undefined ? (
                <TableRow className="empty-row">
                  <TableCell colSpan={12} className="no-data">
                    No Data
                  </TableCell>
                </TableRow>
              ) : (
                data?.map((shift: any, index: number) => {
                  const startDate = moment(shift.startDate);
                  const endDate = moment(shift.endDate);
                  return (
                    <TableRow data-testid="table-row" key={index}>
                      <TableCell />
                      <TableCell>{shift.empName ?? '--'}</TableCell>
                      <TableCell>{shift.type ?? '--'}</TableCell>
                      <TableCell>{shift.gateNumber ?? '--'}</TableCell>
                      <TableCell>
                        {startDate.isValid() ? startDate.format('DD MMM. h:mm A') : '--'}
                      </TableCell>
                      <TableCell>
                        {endDate.isValid() ? endDate.format('DD MMM. h:mm A') : '--'}
                      </TableCell>
                      <TableCell>{`${shift.startAmount} SR` ?? '--'}</TableCell>
                      <TableCell>{`${shift.cashAmount} SR` ?? '--'}</TableCell>
                      {checkShiftDataBalance(
                        shift.startAmount,
                        shift.cashAmount,
                        shift.endAmount,
                        shift.active,
                      )}
                      <TableCell />
                    </TableRow>
                  );
                })
              )}
            </CustomLoader>
          </TableBody>
          <CustomTablePagination
            totalPages={totalPages}
            currentPage={currentPage}
            filters={[selectedParking?.id, undefined]}
            dispatchFunc={getShiftAction}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ShiftTable;
