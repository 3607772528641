import {RefObject, useEffect} from 'react';

export const useOutsideClick = (ref: RefObject<HTMLDivElement>, onClickOutside: VoidFunction) => {
  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      onClickOutside();
    }
  };
};
