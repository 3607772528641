import api from 'api';
import {getShiftsEndpoint as getShiftEndpoint} from 'api/apisURL';

interface PayloadType {
  filters: Array<{fieldName: string; operator: string; values: string[]}>;
  pageNumber?: number;
  pageSize?: number;
}

export const getShiftsService = async (
  parkingId: string,
  empId?: string,
  pageNumber?: number,
  pageSize?: number,
) => {
  const payload: PayloadType = {
    filters: [
      {
        fieldName: 'parkId',
        operator: 'like',
        values: [parkingId],
      },
    ],
  };
  if (empId) {
    payload.filters.push(
      {
        fieldName: 'empId',
        operator: 'like',
        values: [empId],
      },
      {
        fieldName: 'active',
        operator: 'like',
        values: ['true'],
      },
    );
  } else {
    payload.pageNumber = pageNumber ?? 1;
    payload.pageSize = pageSize ?? 10;
  }

  const response = await api.post(getShiftEndpoint(), payload);
  return response;
};
