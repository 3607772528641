import './styles.css';

import {Col, Row} from 'react-bootstrap';
import {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Box} from '@mui/system';
import Cards from 'components/Cards/Cards';
import CustomButton from 'components/CustomButton/CustomButton';
import EndSessionModal from './EndSessionModal/EndSessionModal';
import GateSessionsTable from './GateSessionsTable/GateSessionsTable';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import MultipleSessionsTables from 'screens/Admin/Sessions/components/MultipleSessionsTables/MultipleSessionsTables';
import Popup from 'components/Popup/Popup';
import ReceiptModal from 'screens/Gate/CurrentSession/ReceiptModal/ReceiptModal';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import {SessionsTableTypes} from 'model/types';
import {clearCashPayment} from 'redux/actions/cashPaymentAction';
import {invoiceAction} from 'redux/actions/invoiceAction';
import {updateSessionAction} from 'redux/actions/sessionsAction';
import moment from 'moment';

const index: FC = () => {
  const {user} = useSelector((state: RootState) => state.authReducer);

  const [session, setSession] = useState<any>();
  const [receiptNumber, setReceiptNumber] = useState<number>();
  const [checkedSessionRow, setCheckedSessionRow] = useState<SessionsTableTypes | undefined>();
  const [endSessionModal, setEndSessionModal] = useState<boolean>(false);
  const [receiptModal, setReceiptModal] = useState<boolean>(false);
  const [responseModal, setResponseModal] = useState<boolean>(false);
  const [receiptObject, SetReceiptObject] = useState({
    receivedAmount: 0,
    checkOutDateTime: '--',
  });
  const dispatch: any = useDispatch();
  const {response, error} = useSelector((state: RootState) => state.cashPaymentReducer);

  useEffect(() => {
    if (error) {
      setResponseModal(true);
    } else if (response) {
      setReceiptModal(true);
      dispatch(
        updateSessionAction({
          id: checkedSessionRow?.id,
          status: 'CLOSED',
          checkOutDate: moment().subtract(5, 'minutes').toISOString(),
        }),
      );
    }

    return () => dispatch(clearCashPayment());
  }, [error, response]);

  const tableSelected = () => {
    if (session?.id) {
      return <MultipleSessionsTables session={session} setSession={setSession} gate />;
    } else {
      return (
        <GateSessionsTable
          checkedRow={checkedSessionRow}
          setCheckedRow={setCheckedSessionRow}
          setSession={setSession}
        />
      );
    }
  };

  const getAmount = () => {
    const processStatus = checkedSessionRow?.processStatus?.toLowerCase();

    if (processStatus === 'regular' || processStatus === 'unregistered') {
      setEndSessionModal(true);
    }
  };

  const handleEndSession = () => {
    if (checkedSessionRow) {
      dispatch(invoiceAction(checkedSessionRow.id, getAmount));
    }
  };

  return (
    <Box id="current-session">
      <MainViewContainer>
        <Row className="special-row">
          <Col xs={9}>
            <Cards ParkingID={user.parkingId} />
          </Col>
        </Row>
        <Row className="special-row">
          <Col xs={9}>
            <Box gap={3} display="flex">
              <CustomButton
                width="15vw"
                onClick={handleEndSession}
                disabled={checkedSessionRow?.status !== 'RUNNING'}>
                End session
              </CustomButton>
            </Box>
          </Col>
        </Row>
        <Row>
          <div className="double-tables">{tableSelected()}</div>
        </Row>
        <Popup
          open={endSessionModal}
          handleCloseModal={() => setEndSessionModal(false)}
          popupTitle="End session">
          <EndSessionModal
            setReceiptNumber={setReceiptNumber}
            SetReceiptObject={SetReceiptObject}
            rowData={checkedSessionRow!}
            handleDone={() => setEndSessionModal(false)}
          />
        </Popup>
        {responseModal && (
          <ResponseModal error={error} message={error} onClose={() => setResponseModal(false)} />
        )}

        <ReceiptModal
          receiptNumber={receiptNumber}
          handleClose={() => setReceiptModal(false)}
          isOpen={receiptModal}
          checkedSessionRow={checkedSessionRow}
          receiptObject={receiptObject}
        />
      </MainViewContainer>
    </Box>
  );
};

export default index;
