import {useEffect} from 'react';
import {TableBody, TableCell, TableRow} from '@mui/material';
import {getUsersAction} from 'redux/actions/getUsersAction';
import {useDispatch, useSelector} from 'react-redux';

import CustomLoader from 'components/CustomLoader/CustomLoader';
import {RootState} from 'redux/rootReducers';
import {UserSetupTableTypes} from 'model/types';

const UserTableBody = () => {
  const dispatch: any = useDispatch();
  const {users, currentPage, loading} = useSelector((state: RootState) => state.getUsersReducer);

  useEffect(() => {
    dispatch(getUsersAction(currentPage));
  }, []);

  return (
    <TableBody sx={{height: users?.length > 3 ? 500 : 0}}>
      <CustomLoader size={80} loading={loading}>
        {users?.length === 0 || users === undefined ? (
          <TableRow className="empty-row">
            <TableCell colSpan={12} className="no-data">
              There are no users
            </TableCell>
          </TableRow>
        ) : (
          users.map((user: UserSetupTableTypes) => {
            return (
              <TableRow key={user.id}>
                <TableCell></TableCell>
                <TableCell>{user?.carNumber ?? '--'}</TableCell>
                <TableCell>{user?.name ?? '--'}</TableCell>
                <TableCell>{user?.phone ? `+${user.phone}` : '--'}</TableCell>
                <TableCell>{user?.nationalId ?? '--'}</TableCell>
                <TableCell>{user?.status ?? '--'}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            );
          })
        )}
      </CustomLoader>
    </TableBody>
  );
};

export default UserTableBody;
