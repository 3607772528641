import './styles.css';

import {Box, Grid} from '@mui/material';

import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import {Delete} from 'assets/icons';
import {FC} from 'react';
import {Field} from 'formik';
import {TextField} from 'formik-material-ui';
import { useTranslation } from 'react-i18next';

interface props {
  formik: any;
  names: string;
  remove: any;
  index: number;
}

const GateRow: FC<props> = ({names, remove, index, formik}) => {
  const {t} = useTranslation();

  return (
    <Grid container id="gate-container">
      <Grid item xs={12}>
        <Box display="flex" justifyContent="end" mb={2}>
          <CustomIcon
            href={Delete}
            width={36}
            height={36}
            className={`${
              formik?.values?.gates?.length > 1 ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
            onClick={() => {
              if (formik?.values?.gates?.length > 1) {
                remove(index);
                formik?.setFieldValue('gate_count', formik.values?.gates?.length - 1);
              }
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.4}>
        <Box className="employee-grid-item">
          <label className="input-label">{t("Gate")}{index + 1}-{t("St. name")}</label>
          <Field
            name={`${names}.streetData`}
            component={TextField}
            id="gate-row-input"
            onChange={formik.handleChange}
            placeholder={`${t("Enter street name")}`}
            disabled={false}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={5.4}>
        <Box className="employee-grid-item">
          <label className="input-label">{t("Gate name")}</label>
          <Field
            name={`${names}.gateName`}
            onChange={formik.handleChange}
            component={TextField}
            id="gate-row-input"
            placeholder={`${t("Enter gate name")}`}
            disabled={false}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default GateRow;
