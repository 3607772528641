import './styles.css';

import {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {Col} from 'react-bootstrap';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import {RootState} from 'redux/rootReducers';
import {arrowLeft} from 'assets/icons';
import {getSessionsAction} from 'redux/actions/getSessionsAction';
import {getUsersAction} from 'redux/actions/getUsersAction';
import {useTranslation} from 'react-i18next';

interface props {
  session: any;
  setSession: React.Dispatch<React.SetStateAction<any>>;
  gate?: boolean;
  parkingId?: string;
}

const MultipleSessionsTables: FC<props> = ({session, setSession, gate, parkingId}) => {
  const {t} = useTranslation();
  const {currentLanguage} = useSelector((state: RootState) => state.changeLanguageReducer);
  const isArabic = currentLanguage === 'ar';

  const dispatch: any = useDispatch();
  const {sessions, fetchLoading, currentPage, totalPages} = useSelector(
    (state: RootState) => state.getSessionsReducer,
  );

  const [card, setCard] = useState<any>(null);
  const users = useSelector((state: RootState) => state.getUsersReducer);

  const selectedSession = card ?? session;

  const handleSelect = (session: any) => {
    setCard(session);
    dispatch(getUsersAction(undefined, session.phone));
  };

  useEffect(() => {
    dispatch(getSessionsAction(currentPage, parkingId, ['RUNNING', 'CLOSED']));
  }, []);

  useEffect(() => {
    const phoneList = session.phone ? [session.phone] : null;
    dispatch(getUsersAction(undefined, phoneList?.[0]));
  }, [session.phone]);

  return (
    <div id="multiple-sessions-table">
      <Col md={12} lg={6}>
        <table className="first-table custom-table-container">
          <thead className="head-container">
            <tr
              className={`custom-table-head ${isArabic && '!rounded-tl-none !rounded-tr-2xl'}`}
              style={gate ? {borderRadius: 0} : {}}>
              <th></th>
              <th>
                <CustomIcon
                  href={arrowLeft}
                  height={19}
                  className={`cursor-pointer ${isArabic && 'rotate-180'}`}
                  onClick={() => {
                    setSession(null);
                  }}
                />
              </th>
              {!isArabic && (
                <>
                  <th>{t('Phone number')}</th>
                  <th>{t('Package')}</th>
                  <th>{t('Process status')}</th>
                </>
              )}
              {isArabic && (
                <>
                  <th>{t('Process status')}</th>
                  <th>{t('Package')}</th>
                  <th>{t('Phone number')}</th>
                </>
              )}
              <th></th>
            </tr>
          </thead>
          <tbody className="custom-table-body">
            <CustomLoader size={80} loading={fetchLoading}>
              {sessions.map((session: any) => {
                return (
                  <tr
                    dir="ltr"
                    key={session.id}
                    onClick={() => handleSelect(session)}
                    className="cursor-pointer">
                    <td></td>
                    <td className={selectedSession.id === session.id ? 'item-selected' : ''}></td>
                    <td className={selectedSession.id === session.id ? 'item-selected' : ''}>
                      {session.processStatus === 'VIP'
                        ? `+${session.contactPhone}`
                        : session.phone
                        ? `+${session.phone}`
                        : '-'}
                    </td>
                    <td className={selectedSession.id === session.id ? 'item-selected' : ''}>
                      {session.packageName ?? '--'}
                    </td>

                    <td className={selectedSession.id === session.id ? 'item-selected' : ''}>
                      {session.processStatus ?? '--'}
                    </td>
                    <td></td>
                  </tr>
                );
              })}
            </CustomLoader>
          </tbody>
          <CustomTablePagination
            totalPages={totalPages}
            currentPage={currentPage}
            filters={gate && [parkingId, ['RUNNING', 'CLOSED']]}
            dispatchFunc={getSessionsAction}
          />
        </table>
      </Col>
      <Col md={12} lg={6}>
        <table className="custom-table-container second-table">
          <thead className="head-container">
            <tr
              className={`custom-table-head ${isArabic && '!rounded-tl-2xl !rounded-tr-none'}`}
              style={gate ? {borderRadius: 0} : {}}>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <CustomLoader multiTable size={80} loading={users.loading}>
            <div className="contact-container">
              <div key={selectedSession?.id} className="contact-item">
                <p>{`${
                  selectedSession?.processStatus === 'VIP'
                    ? `Contact name: ${selectedSession?.contactName ?? '--'}`
                    : `${t('Username')}: ${
                        selectedSession.processStatus === 'Regular'
                          ? users.users[0]?.name ?? '--'
                          : '--'
                      }`
                }`}</p>
              </div>
            </div>
          </CustomLoader>
        </table>
      </Col>
    </div>
  );
};

export default MultipleSessionsTables;
