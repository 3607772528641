import * as Yup from 'yup';

import {Delete, arrowDown, orangeArrow} from 'assets/icons';
import {ChangeEvent, Dispatch, FC, SetStateAction, useEffect, useRef, useState} from 'react';
import {createPackage, updatePackage} from 'redux/actions/packageActions';
import {useDispatch, useSelector} from 'react-redux';

import {Box} from '@mui/material';
import CalendarDropdown from 'utils/dropdowns/CalendarDropdown';
import CustomButton from 'components/CustomButton/CustomButton';
import {LIGHT_GREY} from 'constants/colors';
import MultiSelectDropdown from 'utils/dropdowns/MultiSelectDropdown';
import {Parking} from 'model/servicesModels';
import Popup from 'components/Popup/Popup';
import ReactDatePicker from 'react-datepicker';
import {RootState} from 'redux/rootReducers';
import SingleDropdown from 'utils/dropdowns/SingleDropdown';
import {ThunkDispatchType} from 'redux/types';
import moment from 'moment';
import styles from './PackageModal.module.css';
import {useFormik} from 'formik';
import {useOnline} from 'hooks/useOnline';
import {useTranslation} from 'react-i18next';

interface Props {
  handleClose: () => void;
  open: boolean;
  popupTitle: string;
  packageData?: any;
  isSubmitted?: boolean;
  setIsSubmitted?: Dispatch<SetStateAction<boolean>>;
  parking: Parking | undefined;
}

interface PeakHoursCard {
  fromVal: number | string;
  toVal: number | string;
  type: string;
  rate: string;
}

interface PeakWeekDays {
  fromVal: number;
  toVal: number;
  type: 'week_day';
  rate: string;
}

const PackageModal: FC<Props> = ({
  handleClose,
  open,
  popupTitle,
  packageData,
  isSubmitted,
  setIsSubmitted,
  parking,
}) => {
  const {t, i18n} = useTranslation();

  const [isCalendarReset, setIsCalendarReset] = useState<boolean>(true);

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const [selectedDays, setSelectedDays] = useState<PeakWeekDays[]>(() => {
    if (popupTitle !== 'Create Package' && packageData.customRates?.length > 0) {
      const rates = packageData.customRates?.filter(
        (rate: PeakWeekDays) => rate.type === 'week_day',
      );
      if (rates.length > 0) {
        return rates.map((card: PeakWeekDays) => {
          return {
            fromVal: card.fromVal === null ? '' : card.fromVal,
            toVal: card.toVal === null ? '' : card.toVal,
            type: 'week_day',
            rate: card.rate === null ? '' : card.rate.toString(),
          };
        });
      }
      return [];
    }
    return [];
  });
  const [peakHoursCards, setPeakHoursCards] = useState<PeakHoursCard[]>(() => {
    if (popupTitle !== 'Create Package' && packageData.customRates?.length > 0) {
      const hourCards = packageData.customRates?.filter(
        (card: PeakHoursCard) => card.type === 'hour',
      );
      if (hourCards.length > 0) {
        return hourCards.map((card: PeakHoursCard) => {
          return {
            fromVal: card.fromVal === null ? '' : card.fromVal.toString(),
            toVal: card.toVal === null ? '' : card.toVal.toString(),
            type: 'hour',
            rate: card.rate === null ? '' : card.rate.toString(),
          };
        });
      }
      return [{fromVal: '', toVal: '', type: 'hour', rate: ''}];
    }
    return [{fromVal: '', toVal: '', type: 'hour', rate: ''}];
  });
  const [generalSetup, setGeneralSetup] = useState<boolean>(false);
  const {categories} = useSelector((state: RootState) => state.PackageCategoryReducer);
  const {response: responseCreate} = useSelector((state: RootState) => state.createPackageReducer);
  const {response: responseEdit} = useSelector((state: RootState) => state.updatePackageReducer);

  const dispatch: ThunkDispatchType = useDispatch();

  const {isOnline} = useOnline();

  const modalRef = useRef<HTMLDivElement>(null);
  const customRateDayRef = useRef<HTMLInputElement>(null);
  const customRateDateRef = useRef<HTMLInputElement>(null);
  const customRateHourRef = useRef<HTMLInputElement>(null);
  const toPeakHoursRef = useRef<ReactDatePicker>(null);

  const {
    values,
    isValid,
    touched,
    errors,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
  } = useFormik({
    initialValues: {
      pkg: {
        name: popupTitle === 'Create Package' ? '' : packageData.pkg.name,
        categoryName: popupTitle === 'Create Package' ? '' : packageData.pkg.categoryName,
        categoryId: popupTitle === 'Create Package' ? '' : packageData.pkg.categoryId,
        categoryType: popupTitle === 'Create Package' ? '' : packageData.pkg.categoryType,
        parkingId: parking?.id ?? '',
        fromDt: popupTitle === 'Create Package' ? '' : packageData.pkg.fromDt,
        toDt: popupTitle === 'Create Package' ? '' : packageData.pkg.toDt,
        rate: popupTitle === 'Create Package' ? '' : packageData.pkg.rate,
        slots: popupTitle === 'Create Package' ? '' : packageData.pkg.slots,
        maxStayDiscountPercentage:
          popupTitle === 'Create Package' ? '' : packageData.pkg.maxStayDiscountPercentage,
        maxFreeMinutes: popupTitle === 'Create Package' ? '' : packageData.pkg.maxFreeMinutes,
        status: popupTitle === 'Create Package' ? 'pending' : packageData.pkg.status,
        minStayInMinutes:
          popupTitle === 'Create Package' ? '' : packageData.pkg.minStayInMinutes / 60,
        maxStayInMinutes:
          popupTitle === 'Create Package' ? '' : packageData.pkg.maxStayInMinutes / 60,
      },
      customRates: popupTitle === 'Create Package' ? [] : packageData.customRates,
    },
    validationSchema: Yup.object().shape({
      pkg: Yup.object().shape({
        name: Yup.string()
          .required(t('Required')!)
          .max(25, t("Package name length can't be greater than 25 character")!),
        rate: Yup.number()
          .typeError(t('Regular rate must be a number')!)
          .min(0, t('Regular rate must be greater than or equal zero')!)
          .required(t('Regular rate is required')!)
          .test(
            'regular-greater-than-custom',
            t("Regular rate can't be greater than custom rate")!,
            function (value) {
              const customRate = this.parent.customRate;
              return !customRate || !value || value < customRate;
            },
          ),
        maxStayInMinutes: Yup.number()
          .typeError(t('Maximum hour must be a number')!)
          .min(0.0000000000001, t('Maximum hour must be a positive number')!)
          .required(t('Maximum hour is required')!)
          .test(
            'maximum-hour-greater',
            t('Maximum hour must be greater than minimum hour')!,
            function (value) {
              const minStayInMinutes = this.parent.minStayInMinutes;
              return !minStayInMinutes || !value || value > minStayInMinutes;
            },
          ),
        minStayInMinutes: Yup.number()
          .typeError(t('Minimum hour must be a number')!)
          .min(0, t('Minimum hour must be a positive number')!)
          .required(t('Minimum hour is required')!),
        maxStayDiscountPercentage: Yup.number()
          .typeError('Invalid number')
          .min(0, 'Minimum value is 0')
          .max(100, 'Maximum value is 100'),
        maxFreeMinutes: Yup.number()
          .required('Required')
          .typeError('Invalid number')
          .min(0, 'Invalid number')
          .test(
            'is-less-than-min-stay',
            "Allowed Time to stay without payment can't be greater than the minimum charge time",
            function (value: any) {
              const minStayInMinutes = this.parent.minStayInMinutes as number;
              return !value || !minStayInMinutes || value < minStayInMinutes;
            },
          ),
        slots: Yup.number()
          .typeError(t('Count of slots must be a number')!)
          .required(t('Required')!)
          .integer(t('Count of slots must be an integer')!)
          .positive(t('Count of slots must be greater than zero')!),
        fromDt: Yup.string()
          .test('conditional-required', t('From date is required')!, function (value) {
            const categoryType = this.parent.categoryType;
            if (categoryType === 'employee' || categoryType === 'default') {
              return true;
            }
            return !!value;
          })
          .nullable(),
        toDt: Yup.string()
          .test('conditional-required', t('To date is required')!, function (value) {
            const categoryType = this.parent.categoryType;
            if (categoryType === 'employee' || categoryType === 'default') {
              return true;
            }
            return !!value;
          })
          .when(['fromDt'], (fromDt, schema) => {
            return fromDt
              ? schema.test(
                  'is-after-fromDt',
                  "End date can't precede the start date",
                  function (value: moment.MomentInput) {
                    return moment(value).isSameOrAfter(moment(fromDt));
                  },
                )
              : schema;
          })

          .nullable(),
      }),
    }),
    onSubmit: () => {
      const peakHoursIsEmpty = peakHoursCards.some(obj => !obj.fromVal && !obj.toVal);
      const filteredPeakDates = peakDates.find((obj: PeakHoursCard) => obj.type === 'day');
      if (values.customRates) {
        const customRates = peakHoursIsEmpty
          ? [filteredPeakDates, ...selectedDays].filter(item => item !== undefined)
          : [filteredPeakDates, ...selectedDays, ...peakHoursCards].filter(
              item => item !== undefined,
            );
        setFieldValue('customRates', customRates);
      } else {
        const customRates = peakHoursIsEmpty
          ? [...selectedDays]
          : [...selectedDays, ...peakHoursCards];
        setFieldValue('customRates', customRates);
      }
      setIsSubmitted?.(true);
    },
  });

  const handleDiscountChange = (e: {
    target: {name: any; value: any};
    preventDefault: () => void;
  }) => {
    const {name, value} = e.target;

    // Check the number of decimal places
    const decimalPlaces = (value.split('.')[1] || '').length;

    if (decimalPlaces > 2) {
      // If more than 2 decimal places, prevent input
      e.preventDefault();
    } else {
      // Update the form field value
      setFieldValue(name, value);
    }
  };

  useEffect(() => {
    if (!values.customRates) {
      setFieldValue('customRates', []);
    }
    if (isSubmitted) {
      const {pkg, customRates} = values;
      const {name} = pkg;

      switch (popupTitle) {
        case 'Create Package': {
          dispatch(
            createPackage({
              pkg: {
                ...pkg,
                name: name.trim(),
                maxStayInMinutes: (Number(values.pkg.maxStayInMinutes) * 60).toString(),
                maxStayDiscountPercentage:
                  values.pkg.maxStayDiscountPercentage === ''
                    ? (0).toString()
                    : values.pkg.maxStayDiscountPercentage,
                maxFreeMinutes:
                  values.pkg.maxFreeMinutes === ''
                    ? (0).toString()
                    : (Number(values.pkg.maxFreeMinutes) * 60).toString(),
                minStayInMinutes: (Number(values.pkg.minStayInMinutes) * 60).toString(),
              },
              customRates,
            }),
          );
          break;
        }
        case 'Edit Package': {
          dispatch(updatePackage(packageData.pkg.id, values));
          break;
        }
      }
      if (responseCreate || responseEdit) handleClose();
    }
  }, [values]);

  useEffect(() => {
    if (responseCreate || responseEdit) handleClose();
  }, [responseCreate, responseEdit]);

  useEffect(() => {
    if (values.pkg.categoryType === 'default' || values.pkg.categoryType === 'employee') {
      setFieldValue('pkg.fromDt', null);
      setFieldValue('pkg.toDt', null);
    }
  }, [values.pkg.categoryType]);

  const scrollToBottom = () => {
    const scrollHeight = modalRef.current?.scrollHeight;
    const height = modalRef.current?.clientHeight;
    const maxScrollTop = scrollHeight! - height!;
    modalRef.current?.scrollTo({
      top: maxScrollTop,
      behavior: 'smooth',
    });
  };
  const peakDates = values?.customRates?.filter((obj: PeakHoursCard) => obj?.type === 'day');
  const peakDatesIndex = values?.customRates?.findIndex(
    (item: PeakHoursCard) => item.type === 'day',
  );

  const renderPeakHoursCard = () => {
    return peakHoursCards.map((peakHoursCard, index) => (
      <div key={index} className={styles.peakHoursCard}>
        {popupTitle !== 'Package Details' && (
          <div
            className={`${styles.imgContainer} cursor-default`}
            onClick={() => {
              setPeakHoursCards(prev => prev.filter((_, i) => i !== index));
            }}>
            <img src={Delete} alt="delete" />
          </div>
        )}
        <div className={styles.inputGroup}>
          <div className={styles.inputContainer}>
            <label
              htmlFor="peakHours"
              className={
                !peakHoursCard?.fromVal && popupTitle === 'Package Details'
                  ? styles.disabledLabel
                  : ''
              }>
              {t('Peak hours')} {index + 1}
            </label>
            <div className={styles.multiInputContainer}>
              <div className={styles.fieldContainer}>
                <ReactDatePicker
                  popperPlacement="top"
                  disabled={popupTitle === 'Package Details'}
                  className={styles.multiInput}
                  placeholderText={
                    popupTitle === 'Create Package' || popupTitle === 'Edit Package'
                      ? t('From')!
                      : ''
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="From"
                  dateFormat="h:mm"
                  value={
                    moment(peakHoursCard?.fromVal, 'H').isValid()
                      ? moment(peakHoursCard?.fromVal, 'H').add({hour: 3}).format('h:mm A')
                      : ''
                  }
                  onChange={date => {
                    const cards = [...peakHoursCards];
                    cards[index].fromVal = cards[index].fromVal =
                      moment(date).subtract({hour: 3}).format('H') ?? '';
                    setPeakHoursCards(cards);
                    toPeakHoursRef.current?.setFocus();
                  }}
                />
              </div>
              <div className={styles.fieldContainer}>
                <ReactDatePicker
                  ref={toPeakHoursRef}
                  popperPlacement="top"
                  disabled={popupTitle === 'Package Details'}
                  className={styles.multiInput}
                  onChange={date => {
                    const cards = [...peakHoursCards];
                    cards[index].toVal = moment(date).subtract({hour: 3}).format('H') ?? '';
                    setPeakHoursCards(cards);
                    customRateHourRef.current?.focus();
                  }}
                  placeholderText={
                    popupTitle === 'Create Package' || popupTitle === 'Edit Package' ? t('To')! : ''
                  }
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={60}
                  timeCaption="To"
                  dateFormat="h:mm"
                  value={
                    moment(peakHoursCard?.toVal, 'H').isValid()
                      ? moment(peakHoursCard?.toVal, 'H').add({hour: 3}).format('h:mm A')
                      : ''
                  }
                />
              </div>
            </div>
            {(moment(peakHoursCard?.fromVal).isAfter(moment(peakHoursCard?.toVal)) ||
              moment(peakHoursCard?.fromVal).isSame(moment(peakHoursCard?.toVal))) && (
              <p className="validation-error">End time must follow the start time</p>
            )}

            {(![...peakHoursCards][index].fromVal || ![...peakHoursCards][index].toVal) &&
              someValuesNotEmpty() &&
              popupTitle === 'Create Package' && (
                <p className="validation-error">{t('Peak hours is required')}</p>
              )}
          </div>
          <div className={styles.helperDiv} />
          <div className={styles.inputContainer}>
            <label
              htmlFor="customRate"
              className={
                !peakHoursCard?.rate && popupTitle === 'Package Details' ? styles.disabledLabel : ''
              }>
              {t('Custom rate')}
            </label>
            <div className={styles.fieldContainer}>
              <input
                ref={customRateHourRef}
                readOnly={popupTitle === 'Package Details'}
                className={styles.inputField}
                type="text"
                value={peakHoursCard?.rate}
                placeholder={
                  popupTitle === 'Create Package' || popupTitle === 'Edit Package'
                    ? t('Enter the rate of peak hours')! + ' ' + (index + 1)
                    : ''
                }
                name="customRate"
                onChange={event => {
                  const newValue = event.target.value;
                  if (/^\d+(\.\d{0,2})?$/.test(newValue) || newValue === '') {
                    const cards = [...peakHoursCards];
                    cards[index].rate = newValue;
                    setPeakHoursCards(cards);
                  }
                }}
              />
              <span
                className={`${styles.postfix} ${
                  i18n.language === 'ar' && '!right-auto left-[8%]'
                }`}>
                SR
              </span>
            </div>
            {![...peakHoursCards][index].rate &&
              someValuesNotEmpty() &&
              popupTitle === 'Create Package' && (
                <p className="validation-error">{t('Custom rate is required')}</p>
              )}
          </div>
        </div>
      </div>
    ));
  };

  const areAllValuesNotEmpty = () => {
    if (generalSetup && someValuesNotEmpty()) {
      return peakHoursCards.every(item => item.fromVal && item.rate && item.toVal);
    }

    return true;
  };

  const someValuesNotEmpty = () => {
    if (generalSetup) {
      return peakHoursCards.some(item => item.fromVal || item.rate || item.toVal);
    }

    return true;
  };

  const handleRegularRate = (e: ChangeEvent<HTMLInputElement>) => {
    let cleanedValue = e.target.value;
    if (e.target.value !== '0' && !e.target.value.startsWith('0.')) {
      cleanedValue = e.target.value.replace(/^0*/, '');
    }
    const parts = cleanedValue.split('.');
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].slice(0, 2);
    }
    const newValue = parts.join('.');
    e.target.value = newValue;
    setFieldValue('pkg.rate', newValue);
  };

  const arePeakWeekDaysOrCustomRateEmpty = () => {
    const peakWeekDay = selectedDays.length > 0;
    const customRate = selectedDays[0]?.rate;

    if (!peakWeekDay) {
      return !!customRate;
    } else if (!customRate) {
      return !!peakWeekDay;
    }

    if (peakWeekDay && customRate) {
      return false;
    }

    return true;
  };

  const arePeakDatesOrCustomRateEmpty = () => {
    const hasPeakDates = peakDates[0]?.fromVal !== peakDates[0]?.toVal;
    const customRate = peakDates[0]?.rate;

    if (!hasPeakDates) {
      return !!customRate;
    } else if (!customRate) {
      return !!hasPeakDates;
    }

    if (hasPeakDates && customRate) {
      return false;
    }

    return true;
  };

  return (
    <Popup handleCloseModal={() => handleClose()} open={open} popupTitle={`${t(popupTitle)}`}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div
          className={`${styles.modalContainer} ${
            popupTitle === 'Package Details' ? 'h-[65vh]' : 'h-[50vh]'
          }`}
          ref={modalRef}>
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label htmlFor="packageCategory">{t('Package category')}</label>
              {popupTitle === 'Package Details' ? (
                <input
                  readOnly={popupTitle === 'Package Details'}
                  className={styles.inputField}
                  type="text"
                  value={values.pkg.categoryName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              ) : (
                <SingleDropdown
                  emptyText="No package category found"
                  disabled={popupTitle === 'Package Details'}
                  placeholder={
                    popupTitle === 'Create Package' || popupTitle === 'Edit Package'
                      ? t('Select package category')
                      : ''
                  }
                  value={values.pkg.categoryName}
                  options={categories}
                  handleCategorySelect={(name, id, type) => {
                    setFieldValue('pkg.categoryName', name);
                    setFieldValue('pkg.categoryId', id);
                    setFieldValue('pkg.categoryType', type);
                  }}
                  packageCategory
                  backgroundColor={LIGHT_GREY}
                  color="black"
                  fontWeight="normal"
                  height="60px"
                  width="18vw"
                  borderRadius={20}
                  blackArrow
                />
              )}
              {!values.pkg.categoryName && !values.pkg.categoryType && (
                <p className="validation-error">{t('Select a package category')}</p>
              )}
            </div>
            <div className={styles.helperDiv} />
            <div className={styles.inputContainer}>
              <label htmlFor="pkg.name">{t('Package name')}</label>
              <input
                readOnly={popupTitle === 'Package Details'}
                className={styles.inputField}
                type="text"
                name="pkg.name"
                value={values.pkg.name}
                onChange={e => {
                  setFieldValue('pkg.name', e.target.value.replace(/^\s+/g, ''));
                }}
                onBlur={handleBlur}
              />
              {errors.pkg?.name && touched.pkg?.name && (
                <p className="validation-error">{errors.pkg.name as string}</p>
              )}
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label htmlFor="pkg.rate">{t('Regular rate')}</label>
              <div className={styles.fieldContainer}>
                <input
                  readOnly={popupTitle === 'Package Details'}
                  className={styles.inputField}
                  type="number"
                  step="0.01"
                  name="pkg.rate"
                  value={values.pkg?.rate}
                  onChange={handleRegularRate}
                  onBlur={handleBlur}
                />
                <span
                  className={`${styles.postfix} ${
                    i18n.language === 'ar' && '!right-auto left-[8%]'
                  }`}>
                  SR
                </span>
              </div>
              {errors.pkg?.rate && touched.pkg?.rate && (
                <p className="validation-error">{errors.pkg.rate as string}</p>
              )}
            </div>
            <div className={styles.helperDiv} />

            <div className={styles.inputContainer}>
              <label htmlFor="pkg.slots">{t('Count of slots')}</label>
              <input
                readOnly={popupTitle === 'Package Details'}
                className={styles.inputField}
                type="text"
                name="pkg.slots"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.pkg.slots}
              />
              {errors.pkg?.slots && touched.pkg?.slots && (
                <p className="validation-error">{errors.pkg.slots as string}</p>
              )}
            </div>
          </div>

          <div className={styles.inputGroup}>
            <div className={`${styles.inputContainer}`}>
              <label htmlFor="pkg.maxStayInMinutes">{t('Maximum hour per day')}</label>
              <div className="relative">
                <input
                  readOnly={popupTitle === 'Package Details'}
                  className={`${styles.inputField}`}
                  type="text"
                  name="pkg.maxStayInMinutes"
                  value={values.pkg.maxStayInMinutes}
                  onChange={e => {
                    const newValue = e.target.value;
                    if (/^\d+(\.\d{0,2})?$/.test(newValue) || newValue === '') {
                      setFieldValue('pkg.maxStayInMinutes', newValue);
                    }
                  }}
                  onBlur={handleBlur}
                />
                <span className={styles.inputLabelUnit}>hrs</span>
              </div>
              {errors.pkg?.maxStayInMinutes && touched.pkg?.maxStayInMinutes && (
                <p className="validation-error !h-full !whitespace-normal">
                  {errors.pkg.maxStayInMinutes}
                </p>
              )}
            </div>
            <div className={styles.helperDiv} />
            <div className={styles.inputContainer}>
              <label
                className={`${!values.pkg.maxStayInMinutes && styles.disabledLabel}`}
                htmlFor="pkg.maxStayDiscountPercentage">
                Discount
              </label>
              <div className="relative">
                <input
                  placeholder="Enter discount "
                  disabled={!values.pkg.maxStayInMinutes}
                  readOnly={popupTitle === 'Package Details'}
                  className={styles.inputField}
                  type="text"
                  name="pkg.maxStayDiscountPercentage"
                  onChange={handleDiscountChange}
                  onBlur={handleBlur}
                  value={values.pkg.maxStayDiscountPercentage}
                />
                <span className={styles.inputLabelUnit}>%</span>
              </div>
              {errors.pkg?.maxStayDiscountPercentage && touched.pkg?.maxStayDiscountPercentage && (
                <p className="validation-error">{errors.pkg.maxStayDiscountPercentage as string}</p>
              )}
            </div>
          </div>

          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label htmlFor="pkg.minStayInMinutes">Minimum charge time</label>
              <div className="relative">
                <input
                  readOnly={popupTitle === 'Package Details'}
                  className={styles.inputField}
                  type="text"
                  name="pkg.minStayInMinutes"
                  value={values.pkg.minStayInMinutes}
                  onChange={e => {
                    const newValue = e.target.value;
                    if (/^\d+(\.\d{0,2})?$/.test(newValue) || newValue === '') {
                      setFieldValue('pkg.minStayInMinutes', newValue);
                    }
                  }}
                  onBlur={handleBlur}
                />
                <span className={styles.inputLabelUnit}>hrs</span>
              </div>
              {errors.pkg?.minStayInMinutes && touched.pkg?.minStayInMinutes && (
                <p className="validation-error !h-full !whitespace-normal">
                  {errors.pkg.minStayInMinutes}
                </p>
              )}
            </div>

            <div className={styles.helperDiv} />
            <div className={styles.inputContainer}>
              <label htmlFor="pkg.maxFreeMinutes">Allowed Time to stay without payment</label>
              <div className="relative">
                <input
                  placeholder="maximum time to stay for free"
                  readOnly={popupTitle === 'Package Details'}
                  className={styles.inputField}
                  type="text"
                  name="pkg.maxFreeMinutes"
                  onChange={handleDiscountChange}
                  onBlur={handleBlur}
                  value={
                    popupTitle === 'Package Details'
                      ? values.pkg.maxFreeMinutes / 60
                      : values.pkg.maxFreeMinutes
                  }
                />
                <span className={styles.inputLabelUnit}>hrs</span>
              </div>
              {errors.pkg?.maxFreeMinutes && touched.pkg?.maxFreeMinutes && (
                <p className="validation-error !whitespace-normal">
                  {errors.pkg.maxFreeMinutes as string}
                </p>
              )}
            </div>
          </div>
          <div className={styles.inputGroup}>
            <div className={styles.inputContainer}>
              <label htmlFor="validityDate">{t('Validity date')}</label>
              {values.pkg.categoryType === 'default' || values.pkg.categoryType === 'employee' ? (
                <div className={styles.inputContainer}>
                  <input type="text" className={styles.inputField} value="Unlimited" />
                </div>
              ) : (
                <div className={styles.multiInputContainer}>
                  <div
                    className={
                      i18n.language === 'en' ? styles.fieldContainerEn : styles.fieldContainerAr
                    }>
                    <ReactDatePicker
                      popperPlacement="top"
                      className={styles.multiInput}
                      onChange={value => {
                        setFieldValue('pkg.fromDt', moment(value).format('YYYY-MM-DDTHH:mm:ss'));
                      }}
                      onBlur={() => {
                        setFieldTouched('pkg.fromDt', true);
                      }}
                      placeholderText={t('From')!}
                      selected={values.pkg.fromDt ? moment(values.pkg.fromDt).toDate() : null}
                      value={values.pkg.fromDt?.slice(0, 10)}
                    />
                    {popupTitle !== 'Package Details' && <img src={arrowDown} alt="arrow" />}
                  </div>
                  <div
                    className={
                      i18n.language === 'en' ? styles.fieldContainerEn : styles.fieldContainerAr
                    }>
                    <ReactDatePicker
                      popperPlacement="top"
                      className={styles.multiInput}
                      disabled={
                        popupTitle === 'Package Details' ||
                        values.pkg.categoryType === 'default' ||
                        values.pkg.categoryType === 'employee'
                      }
                      onChange={value => {
                        setFieldValue(
                          'pkg.toDt',
                          moment(value).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
                        );
                      }}
                      selected={values.pkg.toDt ? moment(values.pkg.toDt).toDate() : null}
                      onBlur={() => {
                        setFieldTouched('pkg.toDt', true);
                      }}
                      placeholderText={t('To')!}
                      value={
                        values.pkg.categoryType === 'default' ||
                        values.pkg.categoryType === 'employee'
                          ? ''
                          : values.pkg.toDt?.slice(0, 10)
                      }
                    />
                    {popupTitle !== 'Package Details' && <img src={arrowDown} alt="arrow" />}
                  </div>
                </div>
              )}
              {(errors.pkg?.fromDt ?? errors.pkg?.toDt) && (
                <p className="validation-error">
                  {(errors.pkg?.fromDt as string) ?? (errors.pkg?.toDt as string)}
                </p>
              )}
            </div>
          </div>
          <Box display="flex" gap={1}>
            <p className={styles.generalSetup}>{t('General setup')}</p>
            <img
              src={orangeArrow}
              alt="arrow"
              onClick={() => {
                setGeneralSetup(!generalSetup);
                setTimeout(() => {
                  modalRef.current?.scrollTo({
                    top: 500,
                    behavior: 'smooth',
                  });
                }, 50);
              }}
              className={`${styles.generalSetupImg} ${
                generalSetup && styles.rotateIcon
              } cursor-pointer`}
            />
          </Box>
          {generalSetup && (
            <>
              <div className={styles.inputGroup}>
                <div className={styles.inputContainer}>
                  <label
                    htmlFor="pkg.peakWeekDays"
                    className={
                      selectedDays.length === 0 && popupTitle === 'Package Details'
                        ? styles.disabledLabel
                        : ''
                    }>
                    {t('Peak week days')}
                  </label>
                  {popupTitle === 'Package Details' ? (
                    <input
                      disabled={popupTitle === 'Package Details'}
                      type="text"
                      className={styles.inputField}
                      value={selectedDays
                        .map(day => daysOfWeek[day?.fromVal - 1]?.slice(0, 3))
                        .filter(Boolean)
                        .join(', ')}
                    />
                  ) : (
                    <MultiSelectDropdown
                      options={daysOfWeek}
                      weekDays={selectedDays.map(day => day?.fromVal)}
                      value={selectedDays
                        .map(day => daysOfWeek[day?.fromVal - 1]?.slice(0, 3))
                        .filter(Boolean)
                        .join(', ')}
                      handleMultiSelect={(checked, weekDay) => {
                        if (checked) {
                          const dayIndex = daysOfWeek.indexOf(weekDay) + 1;
                          if (
                            dayIndex >= 0 &&
                            !selectedDays.map(day => day?.fromVal).includes(dayIndex)
                          ) {
                            setSelectedDays([
                              ...selectedDays,
                              {
                                fromVal: dayIndex,
                                toVal: dayIndex,
                                rate: '',
                                type: 'week_day',
                              },
                            ]);
                          }
                        } else {
                          setSelectedDays(
                            selectedDays.filter(
                              o => o?.fromVal !== daysOfWeek.indexOf(weekDay) + 1,
                            ),
                          );
                        }
                        customRateDayRef?.current?.focus();
                        customRateDayRef?.current?.scrollIntoView();
                      }}
                      placeholder="Sunday"
                      height="60px"
                      width="18vw"
                      backgroundColor={LIGHT_GREY}
                    />
                  )}
                </div>
                <div className={styles.helperDiv} />
                <div className={styles.inputContainer}>
                  <label
                    className={selectedDays.length === 0 ? styles.disabledLabel : ''}
                    htmlFor="customRate">
                    {t('Custom rate')}
                  </label>
                  <div className={styles.fieldContainer}>
                    <input
                      placeholder={
                        popupTitle === 'Create Package' || popupTitle === 'Edit Package'
                          ? t('Enter the rate of peak days')!
                          : ''
                      }
                      step="0.2"
                      ref={customRateDayRef}
                      disabled={popupTitle === 'Package Details' || selectedDays.length === 0}
                      className={styles.inputField}
                      value={selectedDays?.length > 0 ? selectedDays[0].rate : ''}
                      type="text"
                      onChange={e => {
                        const newValue = e.target.value;
                        if (/^\d+(\.\d{0,2})?$/.test(newValue) || newValue === '') {
                          const updatedSelectedDays = selectedDays.map(day => ({
                            ...day,
                            rate: newValue,
                          }));
                          setSelectedDays(updatedSelectedDays);
                        }
                      }}
                    />
                    <span
                      className={`${styles.postfix} ${
                        i18n.language === 'ar' && '!right-auto left-[8%]'
                      }`}>
                      SR
                    </span>
                  </div>
                  {selectedDays.length > 0 && !selectedDays[0]?.rate && (
                    <p className="validation-error">Custom rate is required</p>
                  )}
                </div>
              </div>
              <div className={styles.inputGroup}>
                <div className={styles.inputContainer}>
                  <label
                    htmlFor="peakDates"
                    className={
                      peakDates.length === 0 && popupTitle === 'Package Details'
                        ? styles.disabledLabel
                        : ''
                    }>
                    {t('Peak dates')}
                  </label>
                  {popupTitle === 'Package Details' ? (
                    <input
                      disabled={popupTitle === 'Package Details'}
                      className={styles.inputField}
                      value={
                        peakDates?.length > 0
                          ? `${moment(peakDates[0]?.fromVal).format('DD MMM')} - ${moment(
                              peakDates[0]?.toVal,
                            ).format('DD MMM, YYYY')}`
                          : ''
                      }
                      type="text"
                    />
                  ) : (
                    <CalendarDropdown
                      ref={modalRef}
                      value={
                        peakDates?.length > 0
                          ? `${moment(peakDates[0]?.fromVal).format('DD MMM')} - ${moment(
                              peakDates[0]?.toVal,
                            ).format('DD MMM, YYYY')}`
                          : ''
                      }
                      placeholder="1Aug -1 Sep, 2023"
                      height="60px"
                      width="18vw"
                      backgroundColor={LIGHT_GREY}
                      handleSave={() => {
                        customRateDateRef.current?.scrollIntoView();
                        customRateDateRef.current?.focus();
                        setIsCalendarReset(false);
                      }}
                      handleReset={() => setIsCalendarReset(true)}
                      handleRangeSelect={peakDates => {
                        const newCustomRate = {
                          fromVal: moment(
                            peakDates.startDate,
                            'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                          ).valueOf(),
                          toVal: moment(
                            peakDates.endDate,
                            'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ',
                          ).valueOf(),
                          rate: '',
                          type: 'day',
                        };
                        setFieldValue('customRates', [newCustomRate, ...values.customRates]);
                      }}
                    />
                  )}
                </div>
                <div className={styles.helperDiv} />
                <div className={styles.inputContainer}>
                  <label
                    className={isCalendarReset ? styles.disabledLabel : ''}
                    htmlFor="customRate">
                    {t('Custom rate')}
                  </label>
                  <div className={styles.fieldContainer}>
                    <input
                      placeholder={
                        popupTitle === 'Create Package' || popupTitle === 'Edit Package'
                          ? t('Enter the rate of peak dates')!
                          : ''
                      }
                      step="0.2"
                      disabled={isCalendarReset}
                      ref={customRateDateRef}
                      value={
                        values.customRates
                          ? values.customRates.find((item: PeakHoursCard) => item.type === 'day')
                              ?.rate ?? ''
                          : ''
                      }
                      onChange={e => {
                        const newValue = e.target.value;
                        if (/^\d+(\.\d{0,2})?$/.test(newValue) || newValue === '') {
                          setFieldValue(`customRates[${peakDatesIndex}].rate`, newValue);
                        }
                      }}
                      readOnly={popupTitle === 'Package Details'}
                      className={styles.inputField}
                      type="text"
                      name={`customRates[${peakDatesIndex}].rate`}
                    />
                    <span
                      className={`${styles.postfix} ${
                        i18n.language === 'ar' && '!right-auto left-[8%]'
                      }`}>
                      SR
                    </span>
                  </div>
                  {peakDates[0]?.fromVal &&
                    peakDates[0]?.toVal &&
                    !peakDates[0]?.rate &&
                    !isCalendarReset && <p className="validation-error">Custom rate is required</p>}
                </div>
              </div>
              {renderPeakHoursCard()}
            </>
          )}
        </div>
        {!isOnline && (
          <p className="validation-error text-center pt-2 pb-1">Check your internet connection</p>
        )}
        {popupTitle !== 'Package Details' && (
          <>
            {generalSetup && (
              <CustomButton
                disabled={popupTitle === 'Package Details'}
                className={styles.btn}
                onClick={() => {
                  setPeakHoursCards(prev => [
                    ...prev,
                    {fromVal: '', toVal: '', type: 'hour', rate: ''},
                  ]);
                  setTimeout(scrollToBottom, 50);
                }}>
                {t('Add new custom rate')}
              </CustomButton>
            )}
            <CustomButton
              type="submit"
              disabled={
                popupTitle === 'Package Details' ||
                !isValid ||
                !isOnline ||
                !areAllValuesNotEmpty() ||
                arePeakWeekDaysOrCustomRateEmpty() ||
                arePeakDatesOrCustomRateEmpty() ||
                (!values.pkg.categoryName && !values.pkg.categoryType)
              }
              className={`${styles.btn} ${!generalSetup && 'translate-y-10'}`}
              color="Orange">
              {t('Done')}
            </CustomButton>
          </>
        )}
      </form>
    </Popup>
  );
};

export default PackageModal;
