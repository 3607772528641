import {TableCell} from '@mui/material';

export const checkShiftDataBalance = (
  startAmount: number,
  cashAmount: number,
  endAmount: number,
  active: boolean,
) => {
  const shiftTotalBalance = startAmount + cashAmount;

  if (active) {
    return <TableCell>--</TableCell>;
  }

  if (shiftTotalBalance === endAmount) {
    return <TableCell style={{color: '#299e58'}}>{`${endAmount} SR` ?? '--'}</TableCell>;
  } else if (shiftTotalBalance >= endAmount) {
    return <TableCell style={{color: 'red'}}>{`${endAmount} SR` ?? '--'}</TableCell>;
  } else {
    return <TableCell style={{color: '#299e58'}}>{`${endAmount} SR` ?? '--'}</TableCell>;
  }
};
