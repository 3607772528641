import {CLEAR_PACKAGES, GET_PACKAGES, GET_PACKAGES_FAILED, GET_PACKAGES_LOADING} from 'redux/types';

interface GetPackageState {
  packages: [];
  loading: boolean;
  error: string;
}

interface GetPackageAction {
  type: string;
  payload: [] | boolean | string;
}

const initialState: GetPackageState = {
  packages: [],
  loading: false,
  error: '',
};

export const getPackageReducer = (
  state = initialState,
  action: GetPackageAction,
): GetPackageState => {
  switch (action.type) {
    case GET_PACKAGES:
      return {...state, packages: action.payload as []};
    case GET_PACKAGES_LOADING:
      return {...state, loading: action.payload as boolean};
    case GET_PACKAGES_FAILED:
      return {...state, error: action.payload as string};
    case CLEAR_PACKAGES:
      return {...state, packages: []};
    default:
      return state;
  }
};
