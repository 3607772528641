import api from 'api';
import {getUsersEndPoint} from 'api/apisURL';

export const getUsersService = async (page?: number, phone?: string) => {
  const payload: any = {
    filters: [
      {
        fieldName: 'userType',
        operator: 'like',
        values: ['CUSTOMER'],
      },
    ],
    pageNumber: '1',
    pageSize: '10',
  };
  if (phone !== undefined) {
    payload.filters = [
      {
        fieldName: 'phone',
        operator: 'like',
        values: [phone],
      },
    ];
  } else if (page !== undefined) {
    payload.pageNumber = page;
    payload.pageSize = 10;
  }

  const responde = await api.post(getUsersEndPoint(), payload);
  return responde;
};
