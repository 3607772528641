import 'bootstrap/dist/css/bootstrap.min.css';
import 'index.css';

import store, {persistor} from 'redux/store';

import App from 'App';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import ReactDOM from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import "./i18n/initI18n";


const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Router>
        <App />
      </Router>
    </PersistGate>
  </Provider>,
);
