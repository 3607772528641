import {
  createPackageCategoryEndpoint,
  getAllPackageCategories,
  getPackageCategoiresEndpoint,
  updatePackageCategoryEndpoint,
} from 'api/apisURL';

import api from 'api';

export const getAllPackageCategoriesService = async () => {
  const response = await api.get(getAllPackageCategories());
  return response;
};

export const getPackageCategoriesService = async (page?: number) => {
  let payload: object = {filters: []};
  if (page) {
    payload = {
      filters: [],
      pageNumber: page,
      pageSize: 10,
    };
  }
  const response = await api.post(getPackageCategoiresEndpoint(), payload);
  return response;
};

export const createPackageCategoryService = async (data: any) => {
  const response = await api.post(createPackageCategoryEndpoint(), data);
  return response;
};

export const updateCategoryService = async (parkingId: string, data: any) => {
  const response = await api.put(updatePackageCategoryEndpoint(parkingId), data);
  return response;
};

export const deleteCategoryService = async (parkingId: string) => {
  const response = await api.delete(updatePackageCategoryEndpoint(parkingId));
  return response;
};
