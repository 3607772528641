import {FC} from 'react';

interface IconProps {
  className?: string;
  width?: number;
  height?: number;
  onClick?: (() => void) | undefined | any;
  onMouseEnter?: (() => void) | undefined | any;
  href: string;
  margin?: string;
}

export const CustomIcon: FC<IconProps> = ({
  className,
  height = 24,
  width = 24,
  onClick,
  href,
  onMouseEnter,
  margin,
}) => {
  return (
    <svg
      style={{margin}}
      onClick={onClick}
      width={width}
      height={height}
      color="#f79e1b"
      className={className}
      onMouseEnter={onMouseEnter}>
      <image href={href} />
    </svg>
  );
};
