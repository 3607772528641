import {
  CLEAR_PACKAGE_CATEGORY_STATE,
  CREATE_PACKAGE_CATEGORY,
  CREATE_PACKAGE_CATEGORY_FAILED,
  CREATE_PACKAGE_CATEGORY_LOADING,
  DELETE_PACKAGE_CATEGORY,
  DELETE_PACKAGE_CATEGORY_FAILED,
  DELETE_PACKAGE_CATEGORY_LOADING,
  GET_ALL_PACKAGE_CATEGORY,
  GET_ALL_PACKAGE_CATEGORY_LOADING,
  ThunkDispatchType,
  UPDATE_PACKAGE_CATEGORY,
  UPDATE_PACKAGE_CATEGORY_FAILED,
  UPDATE_PACKAGE_CATEGORY_LOADING,
} from 'redux/types';
import {
  createPackageCategoryService,
  deleteCategoryService,
  getAllPackageCategoriesService,
  updateCategoryService,
} from 'services/package/PackageCategory';

export const createPackageCategory =
  (data: any, onSave: VoidFunction) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch({type: CREATE_PACKAGE_CATEGORY_LOADING, payload: true});
      const response = await createPackageCategoryService(data);
      dispatch({type: CREATE_PACKAGE_CATEGORY, payload: response.data});
    } catch (error: any) {
      dispatch({type: CREATE_PACKAGE_CATEGORY_FAILED, payload: error});
    } finally {
      dispatch({type: CREATE_PACKAGE_CATEGORY_LOADING, payload: false});
      onSave();
    }
  };

export const updatePackageCategory =
  (id: string, data: any, onSave: VoidFunction) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch({type: UPDATE_PACKAGE_CATEGORY_LOADING, payload: true});
      const response = await updateCategoryService(id, data);
      dispatch({type: UPDATE_PACKAGE_CATEGORY, payload: response.data});
    } catch (error: any) {
      dispatch({type: UPDATE_PACKAGE_CATEGORY_FAILED, payload: error});
    } finally {
      dispatch({type: UPDATE_PACKAGE_CATEGORY_LOADING, payload: false});
      onSave();
    }
  };

export const deletePackageCategory =
  (id: string, onSave: VoidFunction) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch({type: DELETE_PACKAGE_CATEGORY_LOADING, payload: true});
      const response = await deleteCategoryService(id);
      dispatch({type: DELETE_PACKAGE_CATEGORY, payload: response.data});
    } catch (error) {
      dispatch({type: DELETE_PACKAGE_CATEGORY_FAILED, payload: error});
    } finally {
      dispatch({type: DELETE_PACKAGE_CATEGORY_LOADING, payload: false});
      onSave();
    }
  };

export const getAllPackageCategories = () => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch({type: GET_ALL_PACKAGE_CATEGORY_LOADING, payload: true});
    const response = await getAllPackageCategoriesService();
    dispatch({type: GET_ALL_PACKAGE_CATEGORY, payload: response.data});
  } catch (error) {
    dispatch({type: DELETE_PACKAGE_CATEGORY_FAILED, payload: error});
  } finally {
    dispatch({type: DELETE_PACKAGE_CATEGORY_LOADING, payload: false});
  }
};

export const clearpackageCategoryState = () => (dispatch: ThunkDispatchType) => {
  dispatch({type: CLEAR_PACKAGE_CATEGORY_STATE, payload: null});
};
