import './styles.css';

import {Col, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useRef, useState} from 'react';

import {Box} from '@mui/material';
import CategoryItem from './components/CategoryItem/CategoryItem';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import {clearpackageCategoryState} from 'redux/actions/packagecategoryAction';
import {getPackageCategoriesAction} from 'redux/actions/getPackageCategoriesAction';
import packageCategoryType from 'model/packageCategoryType';
import {useTranslation} from 'react-i18next';

const PackageCategoryScreen = () => {
  const {t} = useTranslation();

  const dispacth: any = useDispatch();
  const {packageCategories} = useSelector((state: RootState) => state.getPackageCategoriesReducer);
  const {loading, currentPage, totalPages} = useSelector(
    (state: RootState) => state.getPackageCategoriesReducer,
  );
  const {errors} = useSelector((state: RootState) => state.PackageCategoryReducer);
  const [categoryResponse, setCategoryResponse] = useState<boolean>(false);
  const [sucessMSG, setSuccessMSG] = useState<string | null>(null);

  const newCategoryRef = useRef<HTMLInputElement>(null);

  const categoryObject: packageCategoryType = {
    id: String(Math.floor(Math.random() * 100000)),
    name: '',
    type: 'normal',
    active: true,
    new: true,
  };

  const [categories, setCategories] = useState<packageCategoryType[]>([categoryObject]);
  function scrollToBottom() {
    const scrollHeight = newCategoryRef.current?.scrollHeight;
    const height = newCategoryRef.current?.clientHeight;
    const maxScrollTop = scrollHeight! - height!;
    newCategoryRef.current?.scrollTo({
      top: maxScrollTop,
      behavior: 'smooth',
    });
  }

  const addCategory = () => {
    const updatedCategories: packageCategoryType[] = categories?.map(category => {
      return {...category, active: false};
    });
    setCategories([...updatedCategories, categoryObject]);
    setTimeout(scrollToBottom, 50);
  };

  const removeCategory = (index: number) => {
    const newCards = categories && [...categories];
    newCards.splice(index, 1);
    setCategories(newCards);
  };
  const onEdit = (id: string) => {
    const newCards: packageCategoryType[] = categories.map(category => {
      if (category.id === id) {
        return {...category, active: true};
      }
      return {...category, active: false};
    });
    setCategories(newCards);
  };

  const deActive = (id: string) => {
    const newCards: packageCategoryType[] = categories.map(category => {
      if (category.id === id) {
        return {...category, active: false};
      }
      return {...category, active: false};
    });
    setCategories(newCards);
  };

  useEffect(() => {
    dispacth(getPackageCategoriesAction(currentPage));
  }, [currentPage]);
  useEffect(() => {
    if (packageCategories) {
      setCategories(packageCategories);
    } else {
      setCategories([categoryObject]);
    }
  }, [packageCategories]);

  const AddCatDiabled = categories.some(cat => cat.active);

  return (
    <div id="package-category">
      <MainViewContainer>
        <Row className="special-row" style={{alignItems: 'end'}}>
          <Col xs={9}>
            <CustomButton color="Black" px="35px" disabled={AddCatDiabled} onClick={addCategory}>
              {t('New package category')}
            </CustomButton>
          </Col>
          <Col className="card-btn-container">
            <HeaderDate orange />
          </Col>
        </Row>
        <Row className="special-row">
          <Col>
            <Box className="main-packages-container">
              <CustomLoader loading={loading} size={80}>
                <Box className="packages-container" ref={newCategoryRef}>
                  {categories?.map((packageCategory, index) => (
                    <CategoryItem
                      key={packageCategory.id}
                      initialValues={packageCategory}
                      onRemove={() => removeCategory(index)}
                      categories={categories}
                      onEdit={onEdit}
                      setSuccessMSG={setSuccessMSG}
                      setCategoryResponse={setCategoryResponse}
                      index={index}
                      deActive={deActive}
                    />
                  ))}
                  <Box className="back-text">
                    <p>{t('Please start typing to save and show data')}</p>
                  </Box>
                </Box>
              </CustomLoader>
              <CustomTablePagination
                totalPages={totalPages}
                currentPage={currentPage}
                dispatchFunc={getPackageCategoriesAction}
                bgColor="white"
              />
            </Box>
          </Col>
        </Row>
      </MainViewContainer>
      {categoryResponse && (
        <ResponseModal
          onClose={() => {
            setCategoryResponse(false);
            dispacth(clearpackageCategoryState());
            setSuccessMSG('');
          }}
          message={errors || sucessMSG}
          error={errors}
        />
      )}
    </div>
  );
};

export default PackageCategoryScreen;
