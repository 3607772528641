import './styles.css';

import React, {FC, useState} from 'react';
import {Box} from '@mui/material';
import {AiFillEye, AiFillEyeInvisible} from 'react-icons/ai';

interface props extends React.InputHTMLAttributes<HTMLInputElement> {
  placeHolder: string;
  error?: boolean;
  margin?: string;
  _direction?: 'right' | 'left';
}

const CustomInput: FC<React.InputHTMLAttributes<HTMLInputElement> & props> = ({
  name,
  value,
  type,
  onChange,
  onBlur,
  placeHolder,
  error,
  margin,
  _direction,
  disabled = false,
}) => {
  const [viewPassword, setViewPassword] = useState<boolean>(true);

  // Define the conditional style based on _direction prop
  const conditionalStyle = {
    right: _direction === 'right' ? '5px' : 'auto',
    left: _direction === 'left' ? '5px' : 'auto',
  };

  const renderViewPasswordButton = () => {
    return (
      <>
        {viewPassword ? (
          <AiFillEye
            onClick={() => setViewPassword(!viewPassword)}
            className="view-password-btn cursor-pointer"
            style={{...conditionalStyle}}
          />
        ) : (
          <AiFillEyeInvisible
            onClick={() => setViewPassword(!viewPassword)}
            className="view-password-btn cursor-pointer"
            style={{...conditionalStyle}}
          />
        )}
      </>
    );
  };

  return (
    <Box width="100%" display="flex" alignItems="center" position="relative">
      <input
        disabled={disabled}
        style={{margin}}
        type={type === 'password' && viewPassword ? 'password' : 'text'}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        placeholder={placeHolder}
        className={`${error && 'error'} custom-input`}
        autoComplete="off"
      />
      {name === 'password' && renderViewPasswordButton()}
    </Box>
  );
};

export default CustomInput;
