export const ORANGE = '#FF8C44';
export const YELLOW = '#F79E1B';
export const DARK_BLUE = '#141F66';
export const BLUE = '#2538B8';
export const LIGHT_BLUE = '#00B8B8';
export const LIGHT_GREY = '#F7F8FA';
export const GREY = '#B9B9B9';
export const RED = '#FF0000';
export const GREEN = '#299e58';
export const WHITE = '#FFFFFF';
export const BLACK = '#0A0A0A';
export const COLORS_ARRAY = [
  '#FEB700',
  '#4DC37C',
  '#0A6EBD',
  '#BC202E',
  '#EB6A75',
  '#ECACD3',
  '#82A5FF',
  '#00AAC9',
  '#00C5E9',
  '#277A48',
  '#6983C4',
  '#71C4C6',
  '#5A9D9E',
  '#0F0F12',
  '#FF0000',
  '#00FF00',
  '#0000FF',
  '#FFFF00',
  '#FF00FF',
  '#00FFFF',
  '#FF8000',
  '#FF0080',
  '#8000FF',
  '#80FF00',
  '#0080FF',
  '#FF80FF',
  '#80FFFF',
  '#C0C0C0',
  '#808080',
  '#800000',
  '#808000',
  '#008000',
  '#800080',
  '#008080',
  '#000080',
  '#A52A2A',
  '#D2691E',
  '#8B4513',
  '#2E8B57',
  '#228B22',
  '#FF4500',
  '#FF6347',
  '#FF8C00',
  '#FFA500',
  '#FFFF00',
  '#FFD700',
  '#B8860B',
  '#DAA520',
  '#ADFF2F',
  '#32CD32',
  '#00FF7F',
  '#3CB371',
  '#00FA9A',
  '#20B2AA',
  '#00FFFF',
  '#87CEEB',
  '#4682B4',
  '#6495ED',
  '#1E90FF',
  '#7B68EE',
  '#9400D3',
  '#8A2BE2',
  '#6A5ACD',
  '#9370DB',
  '#4B0082',
  '#8B008B',
  '#800080',
  '#BA55D3',
  '#DDA0DD',
  '#FF69B4',
  '#FF1493',
  '#C71585',
  '#DB7093',
  '#CD5C5C',
  '#F08080',
  '#E9967A',
  '#FFA07A',
  '#FF7F50',
  '#FF4500',
  '#FF6347',
  '#FFD700',
  '#FFFF00',
  '#7CFC00',
  '#32CD32',
  '#008000',
  '#ADFF2F',
  '#00FF00',
  '#00FF7F',
  '#20B2AA',
  '#00FFFF',
  '#00CED1',
  '#5F9EA0',
  '#008B8B',
  '#4682B4',
  '#4169E1',
  '#0000FF',
  '#0000CD',
  '#00008B',
  '#000080',
  '#8A2BE2',
  '#800080',
  '#9400D3',
  '#8B008B',
  '#4B0082',
];
