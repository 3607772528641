import {
  CLEAR_DELETE_PACKAGE,
  DELETE_PACKAGE,
  DELETE_PACKAGE_FAILED,
  DELETE_PACKAGE_LOADING,
} from 'redux/types';

const initialState = {
  response: '',
  error: '',
  loading: false,
};

export const deletePackageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case DELETE_PACKAGE:
      return {...state, response: action.payload};
    case DELETE_PACKAGE_LOADING:
      return {...state, loading: action.payload};
    case DELETE_PACKAGE_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case CLEAR_DELETE_PACKAGE:
      return initialState;
    default:
      return state;
  }
};
