import {
  ASSIGN_PACKAGE,
  ASSIGN_PACKAGE_FAILED,
  ASSIGN_PACKAGE_LOADING,
  CLEAR_ASSIGN_PACKAGE,
  CLEAR_ASSIGN_PACKAGE_ERROR,
  CLEAR_DELETE_PACKAGE,
  CLEAR_PACKAGES,
  CLEAR_PACKAGE_ERROR,
  CLEAR_PACKAGE_PARKING,
  CLEAR_UPDATE_PACKAGE,
  CREATE_PACKAGE,
  CREATE_PACKAGE_FAILED,
  CREATE_PACKAGE_LOADING,
  DELETE_ASSIGN_USER,
  DELETE_ASSIGN_USER_FAILED,
  DELETE_ASSIGN_USER_LOADING,
  DELETE_PACKAGE,
  DELETE_PACKAGE_FAILED,
  DELETE_PACKAGE_LOADING,
  GET_PACKAGES,
  GET_PACKAGES_FAILED,
  GET_PACKAGES_LOADING,
  GET_USERS_BY_PACKAGE,
  GET_USERS_BY_PACKAGE_FAILED,
  GET_USERS_BY_PACKAGE_LOADING,
  SELECT_PACKAGE_PARKING,
  ThunkDispatchType,
  UPDATE_PACKAGE,
  UPDATE_PACKAGE_FAILED,
  UPDATE_PACKAGE_LOADING,
} from 'redux/types';
import {
  assignPackageService,
  createPackageService,
  deleteAssignedUserService,
  deletePackageService,
  getPackagesService,
  getUsersByPackageService,
  updatePackageService,
} from 'services/package/packageServices';

import {CreatePackage} from 'model/CreatePackage';
import {ParkingSetup} from 'model/parkingSetup';
import axios, {AxiosError} from 'axios';

export const createPackage = (data: CreatePackage) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch({type: CREATE_PACKAGE_LOADING, payload: true});
    const response = await createPackageService(data);
    dispatch({type: CREATE_PACKAGE, payload: response.data});
  } catch (error) {
    dispatch({type: CREATE_PACKAGE_FAILED, payload: error});
  } finally {
    dispatch({type: CREATE_PACKAGE_LOADING, payload: false});
  }
};

export const getPackages = (parkingId: string) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch({type: GET_PACKAGES_LOADING, payload: true});
    const response = await getPackagesService(parkingId);
    dispatch({type: GET_PACKAGES, payload: response.data});
  } catch (error) {
    dispatch({type: GET_PACKAGES_FAILED, payload: error});
  } finally {
    dispatch({type: GET_PACKAGES_LOADING, payload: false});
  }
};

export const selectPackageParking = (parking: ParkingSetup) => {
  return {type: SELECT_PACKAGE_PARKING, payload: parking};
};

export const clearPackageParking = () => {
  return {type: CLEAR_PACKAGE_PARKING};
};

export const clearPackageSetup = () => {
  return {type: CLEAR_PACKAGE_ERROR};
};

export const clearPackages = () => {
  return {type: CLEAR_PACKAGES};
};

export const assignPackage =
  (packageId: string, data: any) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch({type: ASSIGN_PACKAGE_LOADING, payload: true});
      const response = await assignPackageService(packageId, data);
      dispatch({type: ASSIGN_PACKAGE, payload: response.data});
    } catch (error) {
      dispatch({type: ASSIGN_PACKAGE_FAILED, payload: error});
    } finally {
      dispatch({type: ASSIGN_PACKAGE_LOADING, payload: false});
    }
  };

export const getUsersByPackage = (packageId: string) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch({type: GET_USERS_BY_PACKAGE_LOADING, payload: true});
    const response = await getUsersByPackageService(packageId);
    dispatch({type: GET_USERS_BY_PACKAGE, payload: response.data});
  } catch (error) {
    dispatch({type: GET_USERS_BY_PACKAGE_FAILED, payload: error});
  } finally {
    dispatch({type: GET_USERS_BY_PACKAGE_LOADING, payload: false});
  }
};

export const clearAssignPackageError = () => {
  return {type: CLEAR_ASSIGN_PACKAGE_ERROR};
};

export const deleteAssignUser =
  (packageId: string, deletedUser: any, onDelete: VoidFunction) =>
  async (dispatch: ThunkDispatchType) => {
    try {
      dispatch({type: DELETE_ASSIGN_USER_LOADING, payload: true});
      const response = await deleteAssignedUserService(packageId, deletedUser);
      dispatch({type: DELETE_ASSIGN_USER, payload: response.data});
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          const errorResponse = axiosError.response.data;
          dispatch({type: DELETE_ASSIGN_USER_FAILED, payload: errorResponse});
        } else {
          dispatch({type: DELETE_ASSIGN_USER_FAILED, payload: axiosError.message});
        }
      } else {
        dispatch({type: DELETE_ASSIGN_USER_FAILED, payload: error.message});
      }
    } finally {
      dispatch({type: DELETE_ASSIGN_USER_LOADING, payload: false});
      onDelete();
    }
  };

export const clearAssignPackage = () => {
  return {type: CLEAR_ASSIGN_PACKAGE};
};

export const deletePackage = (packageId: string) => async (dispatch: ThunkDispatchType) => {
  try {
    dispatch({type: DELETE_PACKAGE_LOADING, payload: true});
    const response = await deletePackageService(packageId);
    dispatch({type: DELETE_PACKAGE, payload: response.status});
  } catch (error) {
    dispatch({type: DELETE_PACKAGE_FAILED, payload: error});
  } finally {
    dispatch({type: DELETE_PACKAGE_LOADING, payload: false});
  }
};

export const clearDeletePackage = () => {
  return {type: CLEAR_DELETE_PACKAGE};
};

export const updatePackage =
  (packageId: string, data: any) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch({type: UPDATE_PACKAGE_LOADING, payload: true});
      const response = await updatePackageService(packageId, data);
      dispatch({type: UPDATE_PACKAGE, payload: response.status});
    } catch (error) {
      dispatch({type: UPDATE_PACKAGE_FAILED, payload: error});
    } finally {
      dispatch({type: UPDATE_PACKAGE_LOADING, payload: false});
    }
  };

export const clearUpdatePackage = () => {
  return {type: CLEAR_UPDATE_PACKAGE};
};
