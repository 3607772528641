import {cloneElement, FC, useState} from 'react';

import './styles.css';

interface props {
  text: string | null;
  children: any;
  click?: boolean;
}

const InfromingTextBox: FC<props> = ({text, children}) => {
  const [showHoverBox, setShowHoverBox] = useState(false);
  const [position, setPosition] = useState({x: 0, y: 0});

  const handleMouseEnter = (event: {clientX: any; clientY: any}) => {
    setShowHoverBox(true);
    setPosition({x: event.clientX, y: event.clientY});
  };
  const handleMouseLeave = () => {
    setShowHoverBox(false);
  };

  return (
    <div onMouseLeave={handleMouseLeave}>
      {cloneElement(children, {
        onMouseEnter: handleMouseEnter,
      })}
      {showHoverBox && text && (
        <span
          id="informing-text"
          style={{
            position: 'fixed',
            top: position.y + 10,
            left: position.x - 20,
          }}>
          {text}
        </span>
      )}
    </div>
  );
};

export default InfromingTextBox;
