import CustomButton from 'components/CustomButton/CustomButton';
import {FC} from 'react';
import {Invoice} from 'model/Reports/Invoice';
import Popup from 'components/Popup/Popup';
import {RootState} from 'redux/rootReducers';
import moment from 'moment';
import './styles.css';
import {usePdfGenerator} from 'hooks/usePdfGenerator';
import {useSelector} from 'react-redux';
import {download, print} from 'assets/icons';
import {useTranslation} from 'react-i18next';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  receiptData?: Invoice;
}

const ReceiptModal: FC<Props> = ({handleClose, isOpen, receiptData}) => {
  const {t, i18n} = useTranslation();
  const {pdfRef, generatePDF} = usePdfGenerator('Receipt');
  const {parkings} = useSelector((state: RootState) => state.parkingReducer);

  const handlePrint = () => window.print();

  const checkIn = moment.utc(receiptData?.checkInDate).local();
  const checkOut = moment.utc(receiptData?.collectionDate).local();

  const getDuration = () => {
    if (checkIn.isValid() && checkOut.isValid()) {
      const duration = moment.duration(checkOut.diff(checkIn));

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes() - hours * 60);
      const seconds = Math.floor(duration.asSeconds() - (hours * 60 * 60 + minutes * 60));

      const formattedDuration = `${hours}hrs ${minutes}min ${seconds}sec`;
      return formattedDuration;
    }
    return '--';
  };

  return (
    <Popup handleCloseModal={handleClose} open={isOpen} popupTitle={t('Receipt details')}>
      <div
        ref={pdfRef}
        className="print-container w-4/6 flex flex-col items-center px-4 !py-3 mx-auto my-[2vh] !border border-solid border-[var(--disabled-grey)] rounded-xl">
        <p className="w-full text-center font-bold ml-10 mb-2">
          Receipt No {receiptData?.receiptRef ?? '--'} - {parkings?.[0].organizationName} -{' '}
          {parkings?.[0].name}
        </p>
        <hr className="w-full text-[var(--disabled-grey)] !border border-solid" />
        <div className="list-container text-sm flex justify-between w-full py-2">
          <ul className="whitespace-nowrap font-bold list-titles">
            <li>{t('Contact name')}</li>
            <li>{t('Phone number')}</li>
            <li>{t('Duration')}</li>
            <li>{t('Payment method')}</li>
            <li>{t('Check in date - time')}</li>
            <li>{t('Check out date - time')}</li>
          </ul>
          <ul className="whitespace-nowrap list-values">
            <li>{receiptData?.name ?? '--'}</li>
            <li>{receiptData?.phone ? '+' + receiptData.phone : '--'}</li>
            <li>{getDuration()}</li>
            <li>{receiptData?.paymentType ?? '--'}</li>
            <li>{checkIn.isValid() ? checkIn.format('DD MMM, YYYY - hh:mm a') : '--'}</li>
            <li>{checkOut.isValid() ? checkOut.format('DD MMM, YYYY - hh:mm a') : '--'}</li>
          </ul>
        </div>
        <hr className="w-full text-[var(--disabled-grey)] border-2 border-dashed" />
        <div className="details-container text-sm flex justify-between w-full !px-3 py-2">
          <ul className="whitespace-nowrap font-bold list-titles">
            <li>{t('Due amount')}</li>
            <li>{t('Received')}</li>
            {/* <li>{t('Exchange')}</li> */}
            <li>{t('Served by')}</li>
          </ul>
          <ul className="whitespace-nowrap list-values">
            <li className="font-bold">{receiptData?.invoiceMount} SR</li>
            <li>{receiptData?.invoiceMount} SR</li>
            {/* <li>0 SR</li> */}
            <li>{receiptData?.servedBy ?? '--'}</li>
          </ul>
        </div>
        <p className="text-sm my-2 italic font-bold">{t('Thank you for using our park')}</p>
      </div>
      <div className="receipt-buttons w-full flex flex-col items-center gap-2">
        <>
          <CustomButton
            className={`w-1/3 ${i18n.language === 'ar' && 'gap-2'}`}
            startIcon={<img src={print} alt="print" />}
            color="Orange"
            onClick={handlePrint}>
            {t('Print')}
          </CustomButton>
          <CustomButton
            startIcon={<img src={download} alt="download" />}
            className={`w-1/3 border !border-[var(--main-color)] bg-white !text-[var(--main-color)] ${
              i18n.language === 'ar' && 'gap-2'
            }`}
            color="Orange"
            onClick={() => {
              generatePDF();
            }}>
            {t('Download as a PDF')}
          </CustomButton>
        </>
      </div>
    </Popup>
  );
};
export default ReceiptModal;
