import {calendar, clear} from 'assets/icons';
import CustomButton from 'components/CustomButton/CustomButton';
import moment from 'moment';
import {Dispatch, FC, SetStateAction, useState, MouseEvent} from 'react';
import DatePicker from 'react-datepicker';
import {useDispatch, useSelector} from 'react-redux';
import {getRevenues, getUtilities} from 'redux/actions/reportsAction';
import {RootState} from 'redux/rootReducers';
import {ThunkDispatchType} from 'redux/types';

interface Props {
  type: string;
  datePickerUpdated: boolean;
  setDatePickerUpdated: Dispatch<SetStateAction<boolean>>;
}

const MonthPicker: FC<Props> = ({type, datePickerUpdated, setDatePickerUpdated}) => {
  const [datePickerIsOpen, setDatePickerIsOpen] = useState<boolean>(false);

  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);

  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  const [startDate, setStartDate] = useState<Date>(firstDayOfMonth);
  const [endDate, setEndDate] = useState<Date>(firstDayOfMonth);

  const dispatch: ThunkDispatchType = useDispatch();
  const {user} = useSelector((state: RootState) => state.authReducer);

  const handleStartDateChange = (date: Date) => {
    if (date) {
      setStartDate(date);
    }
  };

  const handleEndDateChange = (date: Date) => {
    if (date) {
      setEndDate(date);
    }
  };

  const isDateValid = () => {
    return moment(endDate).add('month', 1).isAfter(startDate);
  };

  const handleUpdate = () => {
    switch (type) {
      case 'utilization':
        dispatch(
          getUtilities(user.parkingId, {
            from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(endDate).add(1, 'month').format('YYYY-MM-DD HH:mm:ss'),
          }),
        );
        break;
      case 'revenue':
        dispatch(
          getRevenues(user.parkingId, {
            from: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
            to: moment(endDate).add(1, 'month').format('YYYY-MM-DD HH:mm:ss'),
          }),
        );
        break;
    }
    setDatePickerUpdated(true);
    setDatePickerIsOpen(false);
  };

  const handleClear = (e: MouseEvent<HTMLImageElement>) => {
    e.stopPropagation();
    setStartDate(firstDayOfMonth);
    setEndDate(firstDayOfMonth);
    setDatePickerUpdated(false);
    switch (type) {
      case 'utilization':
        dispatch(getUtilities(user.parkingId));
        break;
      case 'revenue':
        dispatch(getRevenues(user.parkingId));
        break;
    }
  };

  const renderButtonText = () => {
    const startDateString = moment(startDate).format('YY MMM');
    const endDateString = moment(endDate).format('YY MMM');

    if (datePickerUpdated && startDateString !== endDateString) {
      return `${startDateString} : ${endDateString}`;
    } else if (datePickerUpdated && startDateString === endDateString) {
      return startDateString;
    }
    return 'Select period';
  };

  return (
    <>
      <div className="text-right">
        <button
          className="bg-black rounded-xl h-16 w-48"
          onClick={() => setDatePickerIsOpen(!datePickerIsOpen)}>
          <div
            className={`flex justify-center items-center gap-2 ${
              datePickerUpdated && 'mr-6'
            } relative`}>
            <img src={calendar} alt="calendar" />
            <p className="text-white text-sm">{renderButtonText()}</p>
            {datePickerUpdated && (
              <div
                className="flex items-center justify-center text-lg bg-[red] absolute w-6 h-16 rounded-r-lg -right-6"
                onClick={handleClear}>
                <img src={clear} alt="clear" />
              </div>
            )}
          </div>
        </button>
      </div>
      {datePickerIsOpen && (
        <div
          className={`absolute right-0 top-12 z-10 bg-[var(--accent-white)] p-2 mt-4 rounded-[20px] border-[var(--main-color)] border-solid border-2`}>
          <div className="flex justify-center items-center my-8">
            <div className="flex space-x-4 px-4">
              <div className="relative">
                <p>From</p>
                <DatePicker
                  selected={startDate}
                  value={moment(startDate).format('MM/YYYY')}
                  onChange={handleStartDateChange}
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                  className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                />
                {!isDateValid() && (
                  <p className="validation-error mt-2">
                    End time can&apos;t precede the start time
                  </p>
                )}
              </div>
              <div className="relative">
                <p>To</p>
                <DatePicker
                  selected={endDate}
                  value={moment(endDate).format('MM/YYYY')}
                  onChange={handleEndDateChange}
                  showMonthYearPicker
                  dateFormat="MM/yyyy"
                  className="border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:border-blue-500"
                />
              </div>
            </div>
          </div>
          <div className="w-full text-center">
            <CustomButton
              disabled={!isDateValid()}
              className="mb-2 px-4 !h-9"
              color="Orange"
              onClick={handleUpdate}>
              Update
            </CustomButton>
          </div>
        </div>
      )}
    </>
  );
};

export default MonthPicker;
