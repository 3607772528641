/* eslint-disable @typescript-eslint/consistent-type-assertions */
import {
  SET_USER_PAYLOAD,
  CREATE_USER,
  CREATE_USER_LOADING,
  CREATE_USER_FAILED,
  UPDATE_USER_LOADING,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  CLEAR_USER_STATE,
  SET_USER_DATA,
} from 'redux/types';

const initialState = {
  userPayload: undefined,
  loading: false,
  response: null,
  newUser: null,
  errors: null,
};
export const usersReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_PAYLOAD:
      if (action.payload === undefined || action.payload === null) {
        return {
          ...state,
          userPayload: undefined,
        };
      } else {
        return {
          ...state,
          userPayload: action.payload,
        };
      }
    case CREATE_USER_LOADING:
      return {...state, loading: action.loading};
    case CREATE_USER:
      return {...state, response: action.payload, newUser: action.payload, userPayload: undefined};
    case CREATE_USER_FAILED:
      return {...state, errors: action.payload.response.data.statusMessage};
    case UPDATE_USER_LOADING:
      return {...state, loading: action.loading};
    case UPDATE_USER:
      return {
        ...state,
        response: action.payload,
        newUser: action.payload,
        userPayload: undefined,
      };
    case SET_USER_DATA:
      return {
        ...state,
        response: null,
        newUser: action.payload,
        userPayload: undefined,
      };
    case UPDATE_USER_FAILED:
      return {...state, errors: action.payload.response?.data.statusMessage};
    case CLEAR_USER_STATE:
      return {...state, response: null, errors: null, userPayload: undefined, loading: false};
    default:
      return state;
  }
};
