import {Col, Row} from 'react-bootstrap';
import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import UserListTable from './components/UserSetupTable/UserListTable';
import styles from './styles.module.css';
import ParkingDropDown from 'utils/dropdowns/ParkingDropDown';

const index = () => {
  return (
    <div id={styles.employeeContainer}>
      <MainViewContainer>
        <Row className="special-row" style={{alignItems: 'end'}}>
          <Col className="flex justify-end">
            <HeaderDate orange />
          </Col>
        </Row>
        <Row style={{marginBottom: '25px'}}>
          <Col>
            <ParkingDropDown />
          </Col>
        </Row>
        <Row>
          <UserListTable />
        </Row>
      </MainViewContainer>
    </div>
  );
};

export default index;
