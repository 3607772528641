import api from 'api';
import {updateWalletEndpoint} from 'api/apisURL';

interface Payload {
  userId: string;
  balance: number;
  oldBalance: number;
}

export const updateWalletService = async (payload: Payload) => {
  const {data} = await api.put(updateWalletEndpoint(), payload);
  return data;
};
