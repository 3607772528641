import {Action} from 'redux';
import {RootState} from 'redux/rootReducers';
import {ThunkDispatch} from 'redux-thunk';

export type ThunkDispatchType = ThunkDispatch<RootState, null, Action<string>>;

export const SET_USER_PAYLOAD = 'SET_USER_PAYLOAD';
export const RESET_USER_PAYLOAD = 'RESET_USER_PAYLOAD';

export const GET_SELECTED_USER = 'GET_SELECTED_USER';
export const ASSIGN_USER = 'ASSIGN_USER';
export const RESET_USER = 'RESET_USER';
export const EDIT_USER_ROLE = 'EDIT_USER_ROLE';
export const GET_REALTIME_USER = 'GET_REALTIME_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const USER_LOADING = 'USER_LOADING';
// Language State
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';

export const CREATE_USER_LOADING = 'CREATE_USER_LOADING';
export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const UPDATE_USER_LOADING = 'UPDATE_USER_LOADING';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_USER_DATA = 'SET_USER_DATA';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const CLEAR_USER_STATE = 'CLEAR_USER_STATE';
export const AUTH_LOADING = 'AUTH_LOADING';
export const LOG_IN = 'LOG_IN';
export const SET_USER = 'SET_USER';
export const LOG_IN_FAILED = 'LOG_IN_FAILED';
export const TRY_AGAIN = 'TRY_AGAIN';
export const LOG_OUT = 'LOG_OUT';
export const RESET_AUTH = 'RESET_AUTH';
export const RESET_ERROR = 'RESET_ERROR';

export const GET_EMPLOYEE = 'GET_EMPLOYEE';
export const GET_EMPLOYEE_FAILURE = 'GET_EMPLOYEE_FAILURE';
export const GET_EMPLOYEE_LOADING = 'GET_EMPLOYEE_LOADING';
export const CREATE_EMPLOYEE = 'CREATE_EMPLOYEE';
export const CREATE_EMPLOYEE_LOADING = 'CREATE_EMPLOYEE_LOADING';
export const CREATE_EMPLOYEE_FAILURE = 'CREATE_EMPLOYEE_FAILURE';
export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_LOADING = 'UPDATE_EMPLOYEE_LOADING';
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE';
export const SET_EMPLOYEE_ROW = 'SET_EMPLOYEE_ROW';
export const CLEAR_EMPLOYEE_SETUP = 'CLEAR_EMPLOYEE_SETUP';

export const PARKING_LOADING = 'PARKING_LOADING';
export const GET_PARKING = 'GET_PARKING';
export const GET_PARKING_FAILURE = 'GET_PARKING_FAILURE';
export const CREATE_PARKING = 'CREATE_PARKING';
export const CREATE_PARKING_LOADING = 'CREATE_PARKING_LOADING';
export const CREATE_PARKING_FAILURE = 'CREATE_PARKING_FAILURE';
export const CLEAR_PARKING_STATE = 'CLEAR_PARKING_STATE';

export const RESERVERD_LOADING = 'RESERVERD_LOADING';
export const GET_RESERVERD = 'GET_RESERVERD';
export const GET_RESERVERD_FAILURE = 'GET_RESERVERD_FAILURE';

export const GET_SESSIONS = 'GET_SESSIONS';
export const GET_SESSIONS_FAILURE = 'GET_SESSIONS_FAILURE';
export const GET_SESSIONS_LOADING = 'GET_SESSIONS_LOADING';
export const GET_POWEROFF_SESSIONS = 'GET_POWEROFF_SESSIONS';
export const GET_POWEROFF_SESSIONS_FAILURE = 'GET_POWEROFF_SESSIONS_FAILURE';
export const GET_POWEROFF_SESSIONS_LOADING = 'GET_POWEROFF_SESSIONS_LOADING';
export const GET_FILTERED_ROW_LOADING = 'GET_FILTERED_ROW_LOADING';
export const GET_FILTERED_ROW = 'GET_FILTERED_ROW';
export const GET_FILTERED_ROW_FAILURE = 'GET_FILTERED_ROW_FAILURE';

export const UPDATE_SESSION_LOADING = 'UPDATE_SESSION_LOADING';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_SESSION_FAILURE = 'UPDATE_SESSION_FAILURE';
export const CREATE_SESSION_LOADING = 'CREATE_SESSION_LOADING';
export const CREATE_SESSION = 'CREATE_SESSION';
export const CREATE_SESSION_FAILURE = 'CREATE_SESSION_FAILURE';
export const CLEAR_SESSION_ERROR = 'CLEAR_SESSION_ERROR';

export const SEND_OTP = 'SEND_OTP';
export const SEND_OTP_FAILED = 'SEND_OTP_FAILED';
export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';
export const FORGET_PASSWORD_FAILED = 'FORGET_PASSWORD_FAILED';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = ' CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = ' CHANGE_PASSWORD_FAILED';
export const CHANGE_PASSWORD_LOADING = ' CHANGE_PASSWORD_LOADING';

export const GET_UNREGISTERED = 'GET_UNREGISTERED';
export const GET_UNREGISTERED_FAILED = 'GET_UNREGISTERED_FAILED';
export const GET_UNREGISTERED_LOADING = 'GET_UNREGISTERED_LOADING';
export const CREATE_UNREGISTERED = 'CREATE_UNREGISTERED';
export const CREATE_UNREGISTERED_FAILED = 'CREATE UNREGISTERED_FAILED';
export const CREATE_UNREGISTERED_LOADING = 'CREATE UNREGISTERED_LOADING';
export const RESET_UNREGISTERED = 'RESET_UNREGISTERED';

export const PHONE_NOT_REGISTERED = 'PHONE_NOT_REGISTERED';
export const PHONE_REGISTERED = 'PHONE_REGISTERED';
export const GET_RUNNING_PHONE_NUMBER = 'GET_RUNNING_PHONE_NUMBER';
export const GET_RUNNING_PHONE_NUMBER_NOT_EXISTS = 'GET_RUNNING_PHONE_NUMBER_NOT_EXISTS';

export const PARKING_ROW_SETUP_SELECTED = 'PARKING_ROW_SETUP_SELECTED';
export const RESET_PARKING_SETUP_ROW = 'RESET_PARKING_SETUP_ROW';
export const GET_PARKING_SETUP = 'GET_PARKING_SETUP';
export const GET_PARKING_SETUP_LOADING = 'GET_PARKING_SETUP_LOADING';
export const GET_PARKING_SETUP_FAILED = 'GET_PARKING_SETUP_FAILED';
export const SET_PARKING_ID = 'SET_PARKING_ID';
export const RESET_PARKING_ID = 'RESET_PARKING_ID';

export const RESET_ERROR_SUCCESS = 'RESET_ERROR_SUCCESS';

export const SELECT_PARKING = 'SELECT_PARKING';
export const CREATE_BUTTON_CLICKED = 'CREATE_BUTTON_CLICKED';

export const SEARCH = 'SEARCH';
export const FINISH_SEARCH = 'FINISH_SEARCH';
export const GET_FILTERED_DATA = 'GET_FILTERED_DATA';
export const GET_FILTERED_DATA_LOADING = 'GET_FILTERED_DATA_LOADING';

export const RESET_SET_PARKING_AS_FULL = 'RESET_SET_PARKING_AS_FULL';
export const SET_PARKING_AS_FULL = 'SET_PARKING_AS_FULL';
export const SET_PARKING_AS_FULL_LOADING = 'SET_PARKING_AS_FULL_LOADING';
export const SET_PARKING_AS_FULL_FAILED = 'SET_PARKING_AS_FULL_FAILED';

export const RESET_SET_PARKING_AS_FREE = 'RESET_SET_PARKING_AS_FREE';
export const SET_PARKING_AS_FREE = 'SET_PARKING_AS_FREE';
export const SET_PARKING_AS_FREE_LOADING = 'SET_PARKING_AS_FREE_LOADING';
export const SET_PARKING_AS_FREE_FAILED = 'SET_PARKING_AS_FREE_FAILED';

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';
export const GET_ORGANIZATIONS_FAILURE = 'GET_ORGANIZATIONS_FAILURE';
export const GET_ORGANIZATIONS_LOADING = 'GET_ORGANIZATIONS_LOADING';

export const CHECK_CONNECTION = 'CHECK_CONNECTION';

export const CASH_PAYMENT = 'CASH_PAYMENT';
export const CASH_PAYMENT_LOADING = 'CASH_PAYMENT_LOADING';
export const CASH_PAYMENT_FAILED = 'CASH_PAYMENT_FAILED';
export const CLEAR_CASH_PAYMENT = 'CLEAR_CASH_PAYMENT';

export const CREATE_PACKAGE = 'CREATE_PACKAGE';
export const CREATE_PACKAGE_LOADING = 'CREATE_PACKAGE_LOADING';
export const CREATE_PACKAGE_FAILED = 'CREATE_PACKAGE_FAILED';
export const SELECT_PACKAGE_PARKING = 'SELECT_PACKAGE_PARKING';
export const CLEAR_PACKAGE_PARKING = 'CLEAR_PACKAGE_PARKING';
export const CLEAR_PACKAGE_ERROR = 'CLEAR_PACKAGE_ERROR';

export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_PACKAGES_LOADING = 'GET_PACKAGES_LOADING';
export const GET_PACKAGES_FAILED = 'GET_PACKAGES_FAILED';
export const CLEAR_PACKAGES = 'CLEAR_PACKAGES';

export const GET_PACKAGE_CATEGORY = 'GET_PACKAGE_CATEGORY';
export const GET_PACKAGE_CATEGORY_LOADING = 'GET_PACKAGE_CATEGORY_LOADING';
export const GET_PACKAGE_CATEGORY_FAILED = 'GET_PACKAGE_CATEGORY_FAILED';

export const GET_ALL_PACKAGE_CATEGORY = 'GET_ALL_PACKAGE_CATEGORY';
export const GET_ALL_PACKAGE_CATEGORY_LOADING = 'GET_ALL_PACKAGE_CATEGORY_LOADING';
export const GET_ALL_PACKAGE_CATEGORY_FAILED = 'GET_ALL_PACKAGE_CATEGORY_FAILED';

export const ASSIGN_PACKAGE = 'ASSIGN_PACKAGE';
export const ASSIGN_PACKAGE_LOADING = 'ASSIGN_PACKAGE_LOADING';
export const ASSIGN_PACKAGE_FAILED = 'ASSIGN_PACKAGE_FAILED';
export const CLEAR_ASSIGN_PACKAGE_ERROR = 'CLEAR_ASSIGN_PACKAGE_ERROR';
export const CLEAR_ASSIGN_PACKAGE = 'CLEAR_ASSIGN_PACKAGE';

export const GET_USERS_BY_PACKAGE = 'GET_USERS_BY_PACKAGE';
export const GET_USERS_BY_PACKAGE_LOADING = 'GET_USERS_BY_PACKAGE_LOADING';
export const GET_USERS_BY_PACKAGE_FAILED = 'GET_USERS_BY_PACKAGE_FAILED';

export const DELETE_ASSIGN_USER = 'DELETE_ASSIGN_USER';
export const DELETE_ASSIGN_USER_LOADING = 'DELETE_ASSIGN_USER_LOADING';
export const DELETE_ASSIGN_USER_FAILED = 'DELETE_ASSIGN_USER_FAILED';
export const CREATE_PACKAGE_CATEGORY = 'CREATE_PACKAGE_CATEGORY';
export const CREATE_PACKAGE_CATEGORY_LOADING = 'CREATE_PACKAGE_CATEGORY_LOADING';
export const CREATE_PACKAGE_CATEGORY_FAILED = 'CREATE_PACKAGE_CATEGORY_FAILED';
export const UPDATE_PACKAGE_CATEGORY = 'UPDATE_PACKAGE_CATEGORY';
export const UPDATE_PACKAGE_CATEGORY_LOADING = 'UPDATE_PACKAGE_CATEGORY_LOADING';
export const UPDATE_PACKAGE_CATEGORY_FAILED = 'UPDATE_PACKAGE_CATEGORY_FAILED';
export const DELETE_PACKAGE_CATEGORY = 'DELETE_PACKAGE_CATEGORY';
export const DELETE_PACKAGE_CATEGORY_LOADING = 'DELETE_PACKAGE_CATEGORY_LOADING';
export const DELETE_PACKAGE_CATEGORY_FAILED = 'DELETE_PACKAGE_CATEGORY_FAILED';
export const CLEAR_PACKAGE_CATEGORY_STATE = 'CLEAR_PACKAGE_CATEGORY_STATE';

export const DELETE_PACKAGE = 'DELETE_PACKAGE';
export const DELETE_PACKAGE_LOADING = 'DELETE_PACKAGE_LOADING';
export const DELETE_PACKAGE_FAILED = 'DELETE_PACKAGE_FAILED';
export const CLEAR_DELETE_PACKAGE = 'CLEAR_DELETE_PACKAGE';

export const UPDATE_PACKAGE = 'UPDATE_PACKAGE';
export const UPDATE_PACKAGE_LOADING = 'UPDATE_PACKAGE_LOADING';
export const UPDATE_PACKAGE_FAILED = 'UPDATE_PACKAGE_FAILED';
export const CLEAR_UPDATE_PACKAGE = 'CLEAR_UPDATE_PACKAGE';

export const ORGANIZATION_SELECTED = 'ORGANIZATION_SELECTED';

export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_FAILURE = 'GET_INVOICE_FAILURE';
export const GET_INVOICE_LOADING = 'GET_INVOICE_LOADING';

export const GET_PROCESS_CHART_DATA = 'GET_PROCESS_CHART_DATA';
export const GET_PROCESS_CHART_DATA_FAILURE = 'GET_PROCESS_CHART_DATA_FAILURE';
export const GET_PROCESS_CHART_DATA_LOADING = 'GET_PROCESS_CHART_DATA_LOADING';

export const UPDATE_PROCESS_SLOTS = 'UPDATE_PROCESS_SLOTS';
export const UPDATE_PROCESS_SLOTS_FAILURE = 'UPDATE_PROCESS_SLOTS_FAILURE';
export const UPDATE_PROCESS_SLOTS_LOADING = 'UPDATE_PROCESS_SLOTS_LOADING';

export const UPDATE_PROCESS_AMOUNT = 'UPDATE_PROCESS_AMOUNT';
export const UPDATE_PROCESS_AMOUNT_FAILURE = 'UPDATE_PROCESS_AMOUNT_FAILURE';
export const UPDATE_PROCESS_AMOUNT_LOADING = 'UPDATE_PROCESS_AMOUNT_LOADING';

export const GET_PACKAGE_CHART_DATA = 'GET_PACKAGE_CHART_DATA';
export const GET_PACKAGE_CHART_DATA_FAILURE = 'GET_PACKAGE_CHART_DATA_FAILURE';
export const GET_PACKAGE_CHART_DATA_LOADING = 'GET_PACKAGE_CHART_DATA_LOADING';

export const UPDATE_PACKAGE_SLOTS = 'UPDATE_PACKAGE_SLOTS';
export const UPDATE_PACKAGE_SLOTS_FAILURE = 'UPDATE_PACKAGE_SLOTS_FAILURE';
export const UPDATE_PACKAGE_SLOTS_LOADING = 'UPDATE_PACKAGE_SLOTS_LOADING';

export const UPDATE_PACKAGE_AMOUNT = 'UPDATE_PACKAGE_AMOUNT';
export const UPDATE_PACKAGE_AMOUNT_FAILURE = 'UPDATE_PACKAGE_AMOUNT_FAILURE';
export const UPDATE_PACKAGE_AMOUNT_LOADING = 'UPDATE_PACKAGE_AMOUNT_LOADING';

export const GET_PAYMENT_CHART_DATA = 'GET_PAYMENT_CHART_DATA';
export const GET_PAYMENT_CHART_DATA_FAILURE = 'GET_PAYMENT_CHART_DATA_FAILURE';
export const GET_PAYMENT_CHART_DATA_LOADING = 'GET_PAYMENT_CHART_DATA_LOADING';

export const INVOICE_SUCCESS = 'INVOICE_SUCCESS';
export const INVOICE_FAILURE = 'INVOICE_FAILURE';
export const INVOICE_LOADING = 'INVOICE_LOADING';

export const GET_INVOICES = 'GET_INVOICES';
export const GET_INVOICES_LOADING = 'GET_INVOICES_LOADING';
export const GET_RECEIPTS = 'GET_RECEIPT';
export const GET_RECEIPTS_LOADING = 'GET_RECEIPT_LOADING';
export const GET_TRANSACTIONS = 'GET_TRANSACTIONS';
export const GET_TRANSACTIONS_LOADING = 'GET_TRANSACTIONS_LOADING';
export const GET_UTILITES = 'GET_UTILITES';
export const GET_UTILITES_LOADING = 'GET_UTILITES_LOADING';
export const GET_REVENUES = 'GET_REVENUES';
export const GET_REVENUES_LOADING = 'GET_REVENUES_LOADING';

export const GET_SHIFT = 'GET_SHIFT';
export const GET_SHIFT_LOADING = 'GET_SHIFT_LOADING';
export const GET_SHIFT_ERROR = 'GET_SHIFT_ERROR';

export const GET_PROFILE_TYPES = 'GET_PROFILE_TYPES';
export const GET_PROFILE_TYPES_LOADING = 'GET_PROFILE_TYPES_LOADING';
export const GET_PROFILE_TYPES_ERROR = 'GET_PROFILE_TYPES_ERROR';

export const GET_EMPLOYEE_TRANSACTIONS = 'GET_EMPLOYEE_TRANSACTIONS';
export const GET_EMPLOYEE_TRANSACTIONS_BY_DATE = 'GET_EMPLOYEE_TRANSACTIONS_BY_DATE';
export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS';
