import './styles.css';

import AuthConatiner from 'components/AuthConatiner/AuthConatiner';
import AuthHeading from 'components/AuthHeading/AuthHeading';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {arrowLeft} from 'assets/icons';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import {useDispatch, useSelector} from 'react-redux';
import {resetError, sendOTP} from 'redux/actions/authAction';
import {RootState} from 'redux/rootReducers';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {OTP_PATH} from 'routes/paths';
import {useEffect, useState} from 'react';
import ResponseModal from 'components/ResponseModal/ResponseModal';

const FotgetPassword = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const {loading, error} = useSelector((state: RootState) => state.authReducer);
  const [responseModalIsOpen, setResponseModalIsOpen] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .matches(/^[0-9]+$/, t('Phone number is not valid')!)
        .max(9, t('Phone number must be 9 digits')!)
        .min(9, t('Phone number must be 9 digits')!)
        .required('Required')
        .trim(),
    }),
    onSubmit: (values: any, {resetForm}: any) => {
      resetForm();
      dispatch(sendOTP(966 + values.phoneNumber, () => navigate(OTP_PATH)));
    },
  });

  const disableButton = !(formik.dirty && formik.isValid);

  useEffect(() => {
    if (error) {
      setResponseModalIsOpen(true);
    }
  }, [error]);

  return (
    <AuthConatiner>
      <div className="custom-container">
        <div className="custom-content">
          <CustomIcon className="cursor-pointer" href={arrowLeft} onClick={() => navigate(-1)} />
          <AuthHeading />
          <p className="forget-pass-text">Enter your phone number assigned to your account</p>
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="containPhoneCode">
              <span>+966</span>
              <CustomInput
                value={formik.values.phoneNumber}
                onChange={e => {
                  formik.setFieldValue('phoneNumber', e.target.value.trim());
                }}
                onBlur={formik.handleBlur}
                type="text"
                name="phoneNumber"
                placeHolder={t('Phone number')}
                error={!!formik.errors.phoneNumber}
              />
            </div>
            <p className="error-message">
              {formik.errors.phoneNumber && formik.touched.phoneNumber && formik.errors.phoneNumber}
            </p>
            <CustomLoader size={40} loading={loading}>
              <CustomButton
                className="mt-4"
                color="Orange"
                type="submit"
                disabled={disableButton}
                px="130px"
                height="56px">
                {t('Continue')}
              </CustomButton>
            </CustomLoader>
          </form>
        </div>
      </div>
      {responseModalIsOpen && (
        <ResponseModal
          error
          message={error}
          onClose={() => {
            setResponseModalIsOpen(false);
            dispatch(resetError());
          }}
        />
      )}
    </AuthConatiner>
  );
};

export default FotgetPassword;
