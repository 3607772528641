import api from 'api';
import {getUtilitiesEndpoint} from 'api/apisURL';

export const getUtilitesService = async (parkingId: string, date?: {from: string; to: string}) => {
  const payload = {
    filters: [] as object[],
  };

  if (date) {
    payload.filters.push({
      fieldName: 'checkInDate',
      operator: 'between',
      values: [date.from, date.to],
    });
  }

  const response = await api.post(getUtilitiesEndpoint(parkingId), payload);
  return response;
};
