import {
  CLEAR_PACKAGE_ERROR,
  CLEAR_PACKAGE_PARKING,
  CREATE_PACKAGE,
  CREATE_PACKAGE_FAILED,
  CREATE_PACKAGE_LOADING,
  SELECT_PACKAGE_PARKING,
} from 'redux/types';

const initialState = {
  response: '',
  error: '',
  loading: false,
  packageParkingName: '',
  packageParkingId: '',
};

export const createPackageReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CREATE_PACKAGE:
      return {...state, response: action.payload};
    case CREATE_PACKAGE_LOADING:
      return {...state, loading: action.payload};
    case CREATE_PACKAGE_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case SELECT_PACKAGE_PARKING:
      return {
        ...state,
        packageParkingName: action.payload.name,
        packageParkingId: action.payload.id,
      };
    case CLEAR_PACKAGE_PARKING:
      return {...state, packageParkingName: '', packageParkingId: ''};
    case CLEAR_PACKAGE_ERROR:
      return {...state, error: '', response: ''};
    default:
      return state;
  }
};
