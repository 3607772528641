import {GET_PROFILE_TYPES, GET_PROFILE_TYPES_ERROR, GET_PROFILE_TYPES_LOADING} from 'redux/types';

const initialState = {
  profiles: null,
  loading: false,
  errors: null,
};

export const getProfileTypesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PROFILE_TYPES_LOADING: {
      return {...state, loading: action.payload};
    }
    case GET_PROFILE_TYPES:
      return {
        ...state,
        profiles: action.payload,
      };
    case GET_PROFILE_TYPES_ERROR:
      return {...state, errors: action.error};

    default:
      return state;
  }
};
