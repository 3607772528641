import {
  CHECK_CONNECTION,
  CREATE_UNREGISTERED,
  CREATE_UNREGISTERED_FAILED,
  CREATE_UNREGISTERED_LOADING,
  GET_UNREGISTERED,
  GET_UNREGISTERED_FAILED,
  GET_UNREGISTERED_LOADING,
  PHONE_NOT_REGISTERED,
  PHONE_REGISTERED,
  RESET_UNREGISTERED,
} from 'redux/types';

const initialState = {
  loading: false,
  error: '',
  currentPage: 1,
  totalPages: 1,
  users: [],
  registeredUser: {},
  newUser: {},
};

export const unregisteredReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_UNREGISTERED:
      return {
        ...state,
        users: action.payload[0].users,
        currentPage: action.payload[0].currentPage,
        totalPages: action.payload[0].totalPages,
      };
    case GET_UNREGISTERED_LOADING:
      return {...state, loading: action.payload};
    case GET_UNREGISTERED_FAILED:
      return {...state, error: action.payload};
    case CREATE_UNREGISTERED:
      return {...state, newUser: action.payload};
    case CREATE_UNREGISTERED_LOADING:
      return {...state, loading: action.payload};
    case CREATE_UNREGISTERED_FAILED:
      return {...state, error: action.payload.response.data.statusMessage};
    case RESET_UNREGISTERED:
      return {...state, error: '', registeredUser: null};
    case PHONE_NOT_REGISTERED:
      return {...state, error: action.payload};
    case PHONE_REGISTERED:
      return {...state, registeredUser: action.payload.registeredUser, error: action.payload.error};
    case CHECK_CONNECTION:
      return {...state, error: action.payload};
    default:
      return state;
  }
};
