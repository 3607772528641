import {
  CLEAR_SESSION_ERROR as CLEAR_SESSION,
  CREATE_SESSION,
  CREATE_SESSION_FAILURE,
  CREATE_SESSION_LOADING,
  GET_RUNNING_PHONE_NUMBER,
  GET_RUNNING_PHONE_NUMBER_NOT_EXISTS,
  PHONE_NOT_REGISTERED,
  PHONE_REGISTERED,
  UPDATE_SESSION,
  UPDATE_SESSION_FAILURE,
  UPDATE_SESSION_LOADING,
} from 'redux/types';

const initialState = {
  loading: false,
  registeredError: null,
  unregisteredError: null,
  createdSession: null,
  updatedSession: null,
  registeredUser: {},
  unregisteredUser: {},
  response: null,
};

export const sessionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case CREATE_SESSION_LOADING:
      return {...state, loading: action.payload};
    case CREATE_SESSION:
      return {...state, createdSession: action.payload, response: action.payload};
    case CREATE_SESSION_FAILURE:
      return {...state, registeredError: action.payload.response.data.statusMessage};
    case UPDATE_SESSION_LOADING:
      return {...state, loading: action.payload};
    case UPDATE_SESSION:
      return {...state, updatedSession: action.payload, response: action.payload};
    case UPDATE_SESSION_FAILURE:
      return {...state, registeredError: action.payload.response.data.statusMessage};
    case CLEAR_SESSION:
      return {...state, registeredError: null, unregisteredError: null, response: null};
    case PHONE_NOT_REGISTERED:
      return {...state, registeredError: action.payload};
    case PHONE_REGISTERED:
      return {
        ...state,
        registeredUser: action.payload.registeredUser,
        registeredError: action.payload.error,
      };
    case GET_RUNNING_PHONE_NUMBER_NOT_EXISTS:
      return {...state, unregisteredError: action.payload};
    case GET_RUNNING_PHONE_NUMBER:
      return {
        ...state,
        unregisteredUser: action.payload.unregisteredUser,
        unregisteredError: action.payload.error,
      };
    default:
      return state;
  }
};
