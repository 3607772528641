import 'react-datepicker/dist/react-datepicker.css';
import {Box, CircularProgress, Grid, Typography} from '@mui/material';
import {FC, useEffect, useState} from 'react';
import {FieldArray, FormikProvider, useFormik} from 'formik';
import {
  clearParkingState,
  createParkingAction,
  getParkingSetup,
} from 'redux/actions/parkingSetupAction';
import {
  gateObject,
  handleRenderGates,
  parkingSetupValidatioSchema,
} from '../parkingSetupFormikUtlis';
import {useDispatch, useSelector} from 'react-redux';
import CusomCheckedSelect from 'components/CusomCheckedSelect/CusomCheckedSelect';
import CustomButton from 'components/CustomButton/CustomButton';
import {EditParkingSetupModalProps} from 'model/types';
import GateRow from './GateRow/GateRow';
import {LoadingButton} from '@mui/lab';
import {RootState} from 'redux/rootReducers';
import {getOrganiztionsAction} from 'redux/actions/getOrganizationsAction';
import {useOnline} from 'hooks/useOnline';
import './styles.css';
import {useTranslation} from 'react-i18next';

const EditParkingSetupModal: FC<EditParkingSetupModalProps> = ({
  handleClose,
  setResponseEdit = () => {},
}) => {
  const dispatch: any = useDispatch();
  const {organizations} = useSelector((state: RootState) => state.getOrganizationReducer);
  const {isOnline} = useOnline();
  const {newParking, selectedRowValue, error, loading, currentPage} = useSelector(
    (state: RootState) => state.parkingSetupReducer,
  );
  const {t} = useTranslation();

  const editedOrgainozation = organizations.find(
    (org: {id: string}) => org?.id === selectedRowValue?.Organization_ID,
  );
  const selectedOrgName = organizations?.find(
    (org: {id: string}) => org.id === editedOrgainozation?.id,
  );
  const [organizationName, setOrganizationName] = useState<string | undefined>(
    selectedOrgName?.name,
  );
  const [emergencyCountError, setEmergencyCountError] = useState<string>();

  useEffect(() => {
    dispatch(getOrganiztionsAction());
  }, []);

  const formik = useFormik({
    initialValues: {
      Organization_ID: editedOrgainozation?.id ?? undefined,
      city: selectedRowValue?.city ?? '',
      name: selectedRowValue?.name ?? '',
      gate_count: selectedRowValue?.gate_count ?? '',
      capacity: selectedRowValue?.capacity ?? '',
      emergency_count: selectedRowValue?.emergency_count ?? 0,
      gates: selectedRowValue?.gates ?? '',
      id: selectedRowValue?.id ?? '',
    },
    enableReinitialize: true,
    validationSchema: parkingSetupValidatioSchema,
    onSubmit: values => {
      dispatch(
        createParkingAction({
          ...values,
          emergency_count: values.emergency_count === '' || null ? 0 : values.emergency_count,
        }),
      );
    },
  });

  useEffect(() => {
    setOrganizationName(selectedOrgName?.name);
  }, [organizations, selectedOrgName]);

  useEffect(() => {
    if (formik.values.capacity < formik.values.emergency_count && formik.values.emergency_count) {
      setEmergencyCountError('Emergency count must be less than capacity');
    } else setEmergencyCountError('');
  }, [formik.values.emergency_count]);

  useEffect(() => {
    if (newParking) {
      handleCloseModal();
      setResponseEdit(true);
      dispatch(getParkingSetup(currentPage));
    }
  }, [newParking]);

  const handleCloseModal = () => {
    handleClose();
    formik.resetForm();
    dispatch(clearParkingState());
  };

  return (
    <FormikProvider value={formik}>
      <form onSubmit={formik.handleSubmit} className="parking-modal-container" id="parking-setup">
        <Box className="form-container">
          <Grid
            container
            className="grid-container"
            columnSpacing={{xs: 1, md: '95px'}}
            rowSpacing="14px">
            <Grid item xs={12}>
              <Box className="employee-grid-item">
                <Typography className="head-section">{t('Parking Setup')}</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="employee-grid-item">
                <label className="input-label">{t('Organization name')}</label>
                <input
                  value={organizationName}
                  name="city"
                  id="city"
                  className="employee-input"
                  placeholder={`${t('Enter Organization name')}`}
                  disabled
                />
                {/* <p className="validation-error">
                  {formik.errors.Organization_ID &&
                    formik.touched.Organization_ID &&
                    (formik.errors.Organization_ID as string)}
                </p> */}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="employee-grid-item">
                <label className="input-label">{t('City')}</label>
                <input
                  value={formik.values.city}
                  name="city"
                  id="city"
                  className="employee-input"
                  onChange={formik.handleChange}
                  placeholder={`${'  '}`}
                  disabled
                />
                <p className="validation-error">
                  {formik.errors.city && formik.touched.city && (formik.errors.city as string)}
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="employee-grid-item">
                <label className="input-label" htmlFor="name">
                  {t('Parking name')}
                </label>
                <input
                  value={formik.values.name}
                  name="name"
                  id="name"
                  className="employee-input"
                  onChange={formik.handleChange}
                  placeholder={`${t('Enter parking name')}`}
                />
                <p className="validation-error">
                  {formik.errors.name && formik.touched.name && (formik.errors.name as string)}
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="employee-grid-item">
                <label className="input-label">{t('Total no. of gates')}</label>
                <input
                  value={formik.values.gate_count}
                  name="gate_count"
                  id="gate_count"
                  className="employee-input"
                  disabled={true}
                  onChange={(e: any) => {
                    handleRenderGates(e, formik.values, formik.setFieldValue);
                  }}
                  placeholder={`{t("Enter gates number")}`}
                />
                <p className="validation-error">
                  {formik.errors.gate_count &&
                    formik.touched.gate_count &&
                    (formik.errors.gate_count as string)}
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="employee-grid-item">
                <label className="input-label">{t('Capacity')}</label>
                <input
                  type="number"
                  value={formik.values.capacity}
                  name="capacity"
                  id="capacity"
                  className="employee-input"
                  onChange={formik.handleChange}
                  placeholder="Enter parking capacity"
                />
                <p className="validation-error">
                  {formik.errors.capacity &&
                    formik.touched.capacity &&
                    (formik.errors.capacity as string)}
                </p>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="employee-grid-item">
                <label className="input-label">{t('Emergency count')}</label>
                <input
                  type="number"
                  name="emergency_count"
                  value={formik.values.emergency_count}
                  id="emergency_count"
                  className="employee-input"
                  onChange={formik.handleChange}
                  placeholder={`${t('Enter emergency coun')}`}
                />
                <p className="validation-error">
                  {formik.errors.emergency_count && formik.touched.emergency_count
                    ? (formik.errors.emergency_count as string)
                    : emergencyCountError && emergencyCountError}
                </p>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box className="employee-grid-item">
                <span className="line"></span>
                <Typography className="head-section">{t('Gate Setup')}</Typography>
              </Box>
            </Grid>
            <FieldArray name="gates">
              {({push, remove}) => {
                return (
                  <>
                    {formik.values?.gates &&
                      formik.values?.gates?.map((_: any, index: number) => (
                        <GateRow
                          formik={formik}
                          index={index}
                          remove={remove}
                          key={index}
                          names={`gates.${index}`}
                        />
                      ))}
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          margin: '15px 0',
                        }}>
                        <CustomButton
                          onClick={() => {
                            push(gateObject);
                            formik.setFieldValue('gate_count', formik.values.gates.length + 1);
                          }}
                          width="50%"
                          height="60px"
                          borderRadius="20px">
                          {t('Add new gate')}
                        </CustomButton>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box
                        sx={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                        }}>
                        <CusomCheckedSelect
                          placeholder={`${t('Set unavailable gates')}`}
                          width="50%"
                          height="60px"
                          background="#F79E1B"
                          color="#fff"
                          borderRadius="20px"
                          formik={formik}
                          values={formik?.values?.gates}
                          onChange={formik.handleChange}
                          names="gates"
                        />
                      </Box>
                    </Grid>
                  </>
                );
              }}
            </FieldArray>
          </Grid>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            {error && (
              <p className="validation-error" style={{height: '25px'}}>
                {error}
              </p>
            )}
            {!isOnline && (
              <p className="validation-error" style={{height: '25px'}}>
                {t('You are offline')}
              </p>
            )}
            <LoadingButton
              type="submit"
              loading={loading}
              className="popup-loading-button"
              loadingIndicator={<CircularProgress />}
              disabled={!isOnline || !!emergencyCountError}>
              {t('Save')}
            </LoadingButton>
          </Box>
        </Box>
      </form>
    </FormikProvider>
  );
};

export default EditParkingSetupModal;
