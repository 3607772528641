import {ReportTable} from 'model/Reports/Report';

export const toggleButtons: Array<{name: string; type: ReportTable}> = [
  {
    name: 'Invoice',
    type: 'invoice',
  },
  {
    name: 'Receipt',
    type: 'receipt',
  },
  {
    name: 'Transaction log',
    type: 'transaction',
  },
  {
    name: 'Utilization per park',
    type: 'utilization',
  },
  {
    name: 'Revenue analysis',
    type: 'revenue',
  },
];
