import {
  CLEAR_PARKING_STATE,
  CREATE_PARKING,
  CREATE_PARKING_FAILURE,
  CREATE_PARKING_LOADING,
  GET_PARKING,
  GET_PARKING_FAILURE,
  PARKING_LOADING,
  RESERVERD_LOADING,
  GET_RESERVERD,
  GET_RESERVERD_FAILURE,
} from 'redux/types';

const initialState = {
  parkings: undefined,
  loading: false,
  fetchLoading: false,
  error: '',
  newParking: null,
  reserverdSlots: null,
  fetchReservedSlotsLoading: false,
  reserverSlotsErrors: '',
};

export const parkingReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case PARKING_LOADING:
      return {...state, fetchLoading: action.loading};
    case GET_PARKING:
      return {...state, parkings: action.payload};
    case GET_PARKING_FAILURE:
      return {...state, error: action.payload};
    case CREATE_PARKING:
      return {...state, newParking: action.payload};
    case GET_RESERVERD:
      return {...state, reserverdSlots: action.payload};
    case GET_RESERVERD_FAILURE:
      return {...state, reserverSlotsErrors: action.payload};
    case RESERVERD_LOADING:
      return {...state, fetchReservedSlotsLoading: action.loading};
    case CREATE_PARKING_LOADING:
      return {...state, loading: action.loading};
    case CREATE_PARKING_FAILURE:
      return {...state, error: action.payload.response.data.statusMessage};
    case CLEAR_PARKING_STATE:
      return {...state, newParking: null, error: '', loading: false, parkings: undefined};
    default:
      return state;
  }
};
