import {
  SET_EMPLOYEE_ROW,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_LOADING,
  CLEAR_EMPLOYEE_SETUP,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_LOADING,
  CREATE_EMPLOYEE,
} from 'redux/types';
import {EmployeeTableTypes} from 'model/types';
import {createUserService} from 'services/user/createUserService';
import {editUserService} from 'services/user/editUserService';

export const setEmployeeRow = (selectedRowValue: EmployeeTableTypes | null) => {
  return {type: SET_EMPLOYEE_ROW, payload: {selectedRowValue}};
};

export const createEmployee = (data: any, onCreate: VoidFunction) => async (dispatch: any) => {
  dispatch({type: CREATE_EMPLOYEE_LOADING, loading: true});
  try {
    const response = await createUserService(data);
    dispatch({type: CREATE_EMPLOYEE_LOADING, loading: false});
    dispatch({type: CREATE_EMPLOYEE, payload: response.data});
    onCreate();
  } catch (error) {
    dispatch({type: CREATE_EMPLOYEE_FAILURE, payload: error});
    dispatch({type: CREATE_EMPLOYEE_LOADING, loading: false});
  }
};

export const updateEmployee = (data: any, onEdit: VoidFunction) => async (dispatch: any) => {
  dispatch({type: UPDATE_EMPLOYEE_LOADING, loading: true});
  try {
    const response = await editUserService(data);
    dispatch({type: UPDATE_EMPLOYEE, payload: response.data});
  } catch (error) {
    dispatch({type: UPDATE_EMPLOYEE_FAILURE, payload: error});
  } finally {
    dispatch({type: UPDATE_EMPLOYEE_LOADING, loading: false});
    onEdit();
  }
};
export const clearEmployeeState = () => (dispatch: any) => {
  dispatch({type: CLEAR_EMPLOYEE_SETUP});
};
