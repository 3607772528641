import JsPdf from 'jspdf';
import html2canvas from 'html2canvas';
import {RefObject, useRef} from 'react';

export const usePdfGenerator = (type: string, ref?: RefObject<HTMLTableElement>) => {
  const pdfRef = useRef<HTMLDivElement>(ref ? (ref.current as HTMLTableElement) : null);

  const currentRef = ref ?? pdfRef;

  const generatePDF = async () => {
    if (!currentRef.current) {
      throw new Error('PDF reference is not set.');
    }

    const pdf = new JsPdf('l', 'mm', 'a4');
    const input = currentRef.current;
    const canvas = await html2canvas(input, {scale: ref ? 2 : 10});
    const imgData = canvas.toDataURL('image/png');
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = canvas.width;
    const imgHeight = canvas.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    const imgScaledWidth = imgWidth * ratio;
    const imgScaledHeight = imgHeight * ratio;
    const marginLeft = (pdfWidth - imgScaledWidth) / 2;
    const marginTop = (pdfHeight - imgScaledHeight) / 2;
    pdf.addImage(imgData, 'PNG', marginLeft, marginTop, imgScaledWidth, imgScaledHeight);
    pdf.save(`${type}.pdf`);
  };

  return {pdfRef, generatePDF};
};
