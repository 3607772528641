import {Pie} from 'react-chartjs-2';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducers';

import Styles from './ProcessAmountPieChart.styles.module.css';
import {useTranslation} from 'react-i18next';
import {FC} from 'react';

interface Props {
  datePickerUpdated: boolean;
}

export const ProcessAmountPieChart: FC<Props> = ({datePickerUpdated}) => {
  const {processChartData, filteredProcessAmount} = useSelector(
    (state: RootState) => state.chartReducer,
  );

  const reqularAmount: number = datePickerUpdated
    ? filteredProcessAmount.Regular?.second ?? 0
    : processChartData?.Regular?.second ?? 0;
  const unregisteredAmount: number = datePickerUpdated
    ? filteredProcessAmount?.Unregistered?.second ?? 0
    : processChartData?.Unregistered?.second ?? 0;

  const {t} = useTranslation();

  const regularPercentage =
    isNaN(reqularAmount) || reqularAmount === 0 || reqularAmount === undefined
      ? 0
      : ((reqularAmount / (reqularAmount + unregisteredAmount)) * 100).toFixed(2);

  const unregisterPerscentage =
    isNaN(unregisteredAmount) || unregisteredAmount === 0 || unregisteredAmount === undefined
      ? 0
      : reqularAmount === 0
      ? '100'
      : ((unregisteredAmount / (reqularAmount + unregisteredAmount)) * 100).toFixed(2);

  const chartData = {
    labels: ['Registered', 'UnRegistered'],
    datasets: [
      {
        data: [reqularAmount, unregisteredAmount],
        backgroundColor: ['#FEB700', '#4DC37C'],
        hoverBackgroundColor: ['#FEB700', '#4DC37C'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 0,
      },
    },
  };

  const totalAmount = (reqularAmount + unregisteredAmount).toFixed(2);

  return (
    <div className={Styles.processAmount}>
      <h2 className={Styles.processAmountTitle}>{t('Process types / Total amount')}</h2>
      <div className={Styles.chartContainer}>
        <Pie data={chartData} options={chartOptions} height={200} />
      </div>
      <div className={Styles.footer}>
        <p>
          {t('Total amount')}: {totalAmount} {t('SR')}
        </p>
        <div className={Styles.label}>
          <span>
            <span className="circle" style={{backgroundColor: '#FEB700'}} />
            {t('Registered')}
          </span>
          <span>{regularPercentage}%</span>
        </div>
        <div className={Styles.label}>
          <span>
            <span className="circle" style={{backgroundColor: '#4DC37C'}} />
            {t('UnRegistered')}
          </span>
          <span>{unregisterPerscentage}%</span>
        </div>
      </div>
    </div>
  );
};
