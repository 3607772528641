import * as Yup from 'yup';
import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import CustomButton from 'components/CustomButton/CustomButton';
import {LIGHT_GREY} from 'constants/colors';
import {RootState} from 'redux/rootReducers';
import {SessionsTableTypes} from 'model/types';
import SingleDropdown from 'utils/dropdowns/SingleDropdown';
import {cashPayment} from 'redux/actions/cashPaymentAction';
import {getParking} from 'redux/actions/parkingAction';
import moment from 'moment';
import styles from './EndSessionModal.module.css';
import {useFormik} from 'formik';
import './styles.css';

interface Props {
  rowData: SessionsTableTypes;
  setReceiptNumber: Dispatch<SetStateAction<number | undefined>>;
  SetReceiptObject: Dispatch<SetStateAction<any>>;
  handleDone: VoidFunction;
}

const EndSessionModal: FC<Props> = ({rowData, handleDone, setReceiptNumber, SetReceiptObject}) => {
  const dispatch: any = useDispatch();
  const {amount} = useSelector((state: RootState) => state.invoiceReducer);

  const formatDateTime = (dateTime: string) => {
    const amountDate = moment(dateTime);
    return amountDate.isValid() ? amountDate.format('YYYY-MM-DD HH:mm:ss') : '--';
  };

  setInterval(() => {
    setFieldValue('checkOutDateTime', moment.utc().local().format('YYYY-MM-DD HH:mm:ss'));
  }, 1000);

  const {values, isValid, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit} =
    useFormik({
      initialValues: {
        paymentType: 'Cash',
        paymentref: '',
        receivedAmount: 0,
        exchange: 0,
        dueAmount: amount?.invoiceMount ?? '--',
        carPlate: rowData.carNumber ?? '--',
        checkInDateTime: formatDateTime(rowData?.checkInDate),
        checkOutDateTime: moment.utc().local().format('YYYY-MM-DD HH:mm:ss'),
      },
      validationSchema: Yup.object().shape({
        receivedAmount: Yup.number().when('paymentType', {
          is: (paymentType: string) => paymentType === 'Cash',
          then: Yup.number()
            .required('Received amount is required')
            .min(-1, "Received amount can't be negative number")
            .test(
              'lessThanDue',
              'Received amount must be greater than or equal to due amount',
              function (value) {
                const dueAmount = this.resolve(Yup.ref('dueAmount'));
                return value! >= Number(dueAmount);
              },
            )
            .test('decimalPlaces', 'Only two decimal places are allowed', function (value) {
              if (value === undefined || value === null) return true;
              return /^[0-9]+(\.[0-9]{1,2})?$/.test(value.toString());
            }),
        }),
      }),
      onSubmit: () => {
        SetReceiptObject((prevObject: any) => ({
          ...prevObject,
          checkOutDateTime: moment.utc().local().format('YYYY-MM-DD HH:mm:ss'),
        }));
        dispatch(
          cashPayment(
            values.paymentType === 'Cash'
              ? {
                  type: 'CASH',
                  reservationId: rowData.id,
                  amount: values.dueAmount,
                  receivedAmount: values.receivedAmount,
                }
              : {
                  type: 'CREDIT',
                  reservationId: rowData.id,
                  paymentref: values.paymentref,
                  amount: values.dueAmount,
                },
            handleDone,
          ),
        );
      },
    });

  useEffect(() => {
    dispatch(getParking(rowData.parkingId!));
  }, []);

  useEffect(() => {
    setReceiptNumber(Number(values.paymentref));
  }, [values.paymentref]);

  return (
    <form id="modal" className={styles.modal} onSubmit={handleSubmit}>
      <div>
        <div className={styles.inputGroup}>
          <div className={styles.inputContainer}>
            <label htmlFor="paymentType">Payment type</label>
            <SingleDropdown
              parking
              placeholder="Payment Method"
              options={[
                {value: 'Cash', label: 'Cash'},
                {value: 'Credit card', label: 'Credit card'},
              ]}
              value={values.paymentType}
              height="60px"
              width="16vw"
              borderRadius={20}
              backgroundColor={LIGHT_GREY}
              color="black"
              padding="0 8%"
              blackArrow
              handleDropdownSelect={(option, id) => {
                setFieldValue('paymentType', id);
              }}
            />
          </div>
          {values.paymentType === 'Credit card' ? (
            <div className={styles.inputContainer}>
              <label htmlFor="paymentref">Payment receipt number</label>
              <input
                type="text"
                name="paymentref"
                value={values.paymentref}
                onChange={handleChange}
              />
            </div>
          ) : (
            <div className={styles.inputContainer}>
              <label htmlFor="dueAmount">Due amount</label>
              <input disabled type="text" name="dueAmount" value={values.dueAmount + ' SR'} />
            </div>
          )}
        </div>
        {values.paymentType === 'Cash' && (
          <div className={styles.inputGroup}>
            <div className={`${styles.inputContainer} relative`}>
              <label htmlFor="receivedAmount">Received amount</label>
              <input
                type="number"
                name="receivedAmount"
                value={values.receivedAmount}
                onChange={e => {
                  handleChange(e);
                  SetReceiptObject((prevObject: any) => ({
                    ...prevObject,
                    receivedAmount: e.target.value,
                  }));
                }}
                onBlur={handleBlur}
              />
              <span className="absolute top-1/2 right-[37%]">SR</span>
              {errors.receivedAmount && touched.receivedAmount && (
                <p className="validation-error">{errors.receivedAmount}</p>
              )}
            </div>
            <div className={styles.inputContainer}>
              <label htmlFor="exchange">Exchange</label>
              <input
                disabled
                type="text"
                name="exchange"
                value={
                  values.receivedAmount > values.dueAmount
                    ? `${values.receivedAmount - values.dueAmount} SR`
                    : ''
                }
              />
            </div>
          </div>
        )}

        <div className={styles.inputGroup}>
          <div className={styles.inputContainer}>
            <label htmlFor="carPlate">Car plate</label>
            <input disabled type="text" name="carPlate" value={values.carPlate} />
          </div>
          {values.paymentType === 'CREDIT CARD' ? (
            <div className={styles.inputContainer}>
              <label htmlFor="dueAmount">Due amount</label>
              <input disabled type="text" name="dueAmount" value={values.dueAmount + ' SR'} />
            </div>
          ) : (
            <div className={styles.inputContainer}>
              <label htmlFor="checkInDateTime">Check in date - time</label>
              <input
                disabled
                type="text"
                name="checkInDateTime"
                value={moment.utc(values.checkInDateTime).local().format('DD MMM, YYYY/ h:mm a')}
              />
            </div>
          )}
        </div>
        <div className={styles.inputGroup}>
          {values.paymentType === 'CREDIT CARD' && (
            <div className={styles.inputContainer}>
              <label htmlFor="checkInDateTime">Check in date - time</label>
              <input disabled type="text" name="checkInDateTime" value={values.checkInDateTime} />
            </div>
          )}
          <div className={styles.inputContainer}>
            <label htmlFor="checkOutDateTime">Check out date - time</label>
            <input
              disabled
              type="text"
              name="checkOutDateTime"
              value={moment(values.checkOutDateTime).format('DD MMM, YYYY/ h:mm a')}
            />
          </div>
        </div>
        <CustomButton color="Orange" px="20%" disabled={!isValid} type="submit">
          Pay now
        </CustomButton>
      </div>
    </form>
  );
};

export default EndSessionModal;
