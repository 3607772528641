import {Box} from '@mui/material';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import ProfileAvatar from 'components/ProfileAvatar/ProfileAvatar';
import ProfileInfo from 'components/ProfileInfo/ProfileInfo';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducers';

import './styles.css';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {useEffect, useState} from 'react';
import {clearUserState} from 'redux/actions/usersAction';
import PasswordModel from './passwordModel/passwordModel';

const ProfileAdmin = () => {
  const dispatch: any = useDispatch();
  const {response, errors, newUser} = useSelector((state: RootState) => state.usersReducer);
  const [updatingResponse, setUpdatingResponse] = useState<boolean>(false);
  const [passModel, setPassModel] = useState<boolean>(false);
  const [image, setImage] = useState<File | string | null>(null);

  useEffect(() => {
    if (response || errors) {
      setUpdatingResponse(true);
    }
  }, [response, errors]);

  useEffect(() => {
    if (newUser?.profilePicture) {
      setImage(newUser?.profilePicture);
    }
  }, [newUser]);

  return (
    <div className="profile-container">
      <MainViewContainer>
        <Box
          className="profile-box"
          sx={{
            mt: 10,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Box className="my-0 mx-auto">
            <ProfileAvatar image={image} setImage={setImage} />
          </Box>
          <ProfileInfo setResetPassModel={setPassModel} image={image} />
        </Box>
      </MainViewContainer>
      {passModel && (
        <PasswordModel
          openModal={passModel}
          handleClose={() => {
            setPassModel(false);
          }}
        />
      )}
      {updatingResponse && (
        <ResponseModal
          onClose={() => {
            dispatch(clearUserState());
            setUpdatingResponse(false);
          }}
          message={errors ?? 'Profile has been updated'}
          error={errors}
        />
      )}
    </div>
  );
};

export default ProfileAdmin;
