import './styles.css';

import * as Yup from 'yup';

import {
  Delete,
  activeConfirm,
  activeEdit,
  disabledConfirm,
  disabledEdit,
  disabledTrash,
} from 'assets/icons';
import {Field, FormikProvider, useFormik} from 'formik';
import React, {Dispatch, FC, SetStateAction, useEffect, useRef} from 'react';
import {
  createPackageCategory,
  deletePackageCategory,
  updatePackageCategory,
} from 'redux/actions/packagecategoryAction';
import {useDispatch, useSelector} from 'react-redux';

import {Box} from '@mui/material';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import InfromingTextBox from 'components/InfromingTextBox/InfromingTextBox';
import {RootState} from 'redux/rootReducers';
import {getPackageCategoriesAction} from 'redux/actions/getPackageCategoriesAction';
import packageCategoryType from 'model/packageCategoryType';
import {useOuterClick} from 'hooks/useOuterHook';
import {useTranslation} from 'react-i18next';

interface initialValuesProp {
  id: string;
  name: string;
  type: 'employee' | 'default' | 'normal';
  active?: boolean;
  new?: boolean;
}
interface props {
  initialValues: initialValuesProp;
  onRemove: () => void;
  categories: packageCategoryType[];
  onEdit: (id: string) => void;
  setSuccessMSG: Dispatch<SetStateAction<string | null>>;
  setCategoryResponse: Dispatch<SetStateAction<boolean>>;
  index: number;
  deActive: (id: string) => void;
}

const CategoryItem: FC<props> = ({
  initialValues,
  onRemove,
  categories,
  onEdit,
  setSuccessMSG,
  setCategoryResponse,
  index,
  deActive,
}) => {
  const {t} = useTranslation();

  const dispatch: any = useDispatch();
  const {errors} = useSelector((state: RootState) => state.PackageCategoryReducer);

  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    if (initialValues.active) {
      inputRef.current?.focus();
    }
  }, [initialValues]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string()
        .required(t('Category name is required')!)
        .min(2, t('Category name must be more than 2 characters')!)
        .max(30, t('Category name must be less than 30 characters')!),
    }),
    onSubmit: values => {
      submitHandler(values);
    },
  });
  const confirmActive = formik.dirty && formik.values.name.trim() !== '' && initialValues.active;
  const submitHandler = (values: initialValuesProp) => {
    if (initialValues.new) {
      dispatch(
        createPackageCategory(
          {
            name: values.name.toLowerCase().trim(),
            type: values.type,
          },
          () => {
            if (!errors) {
              dispatch(getPackageCategoriesAction());
              setSuccessMSG(t('Package category has been created'));
            }
            setCategoryResponse(true);
          },
        ),
      );
    } else {
      dispatch(
        updatePackageCategory(
          values.id,
          {
            name: values.name.toLowerCase().trim(),
            type: values.type,
          },
          () => {
            if (!errors) {
              dispatch(getPackageCategoriesAction());
              setSuccessMSG(t('Package category has been updated'));
            }
            setCategoryResponse(true);
          },
        ),
      );
    }
  };

  const nameHandleChange = (e: string) => {
    formik.handleChange(e);
  };

  const handleCategoryChange = () => {
    if (formik.values.type === 'default') {
      formik.setFieldValue('type', 'normal');
    } else {
      formik.setFieldValue('type', 'default');
    }
  };

  const handleCategoryTypeChange = () => {
    if (formik.values.type === 'employee') {
      formik.setFieldValue('type', 'normal');
    } else {
      formik.setFieldValue('type', 'employee');
    }
  };

  const innerRef = useOuterClick(() => {
    if (formik.values.active === true && !formik.values.new) {
      deActive(initialValues.id);
    }
  });

  // const exceptionRef = useRef(null);
  // const innerRef = useOuterClick(() => {
  //   if (formik.values.active === true && !formik.values.new) {
  //     deActive(initialValues.id);
  //   }
  // }, [exceptionRef]);

  // if (
  //   (categories.length <= 1 && initialValues.active && !initialValues.new) ??
  //   initialValues.type !== 'normal'
  // ) {
  //   console.log(true);
  // } else {
  //   console.log(false);
  // }

  return (
    <FormikProvider value={formik}>
      <form id="package-config-card" ref={innerRef} onSubmit={formik.handleSubmit}>
        <Box className="box-num-1">
          <Box className="profile-name">
            <label htmlFor="profile-name">{t('Package category name')}</label>
            <Field
              value={formik.values.name}
              name="name"
              className="package-input"
              placeholder={t('Start typing to save data...')}
              onChange={nameHandleChange}
              innerRef={inputRef}
              disabled={!initialValues.active}
            />
            <p className="validation-error">
              {formik.errors.name && formik.touched.name && formik.errors.name}
            </p>
          </Box>
          <Box className="employee-check">
            <Field
              name="employee"
              type="checkbox"
              checked={formik.values.type === 'employee'}
              onChange={handleCategoryTypeChange}
              disabled={
                !initialValues.active || (initialValues.type !== 'normal' && !initialValues.new)
              }
              className={`${!initialValues.active ? 'cursor-default' : 'cursor-pointer'}`}
            />
            <label htmlFor="employee">{t('Employee')}</label>
          </Box>
          <Box className="employee-check">
            <Field
              name="type"
              type="checkbox"
              checked={formik.values.type === 'default'}
              onChange={handleCategoryChange}
              disabled={
                !initialValues.active || (initialValues.type !== 'normal' && !initialValues.new)
              }
              className={`${!initialValues.active ? 'cursor-default' : 'cursor-pointer'}`}
            />
            <label htmlFor="employee">{t('Default category')}</label>
          </Box>
        </Box>
        {/* <Box className="box-num-2" ref={exceptionRef}> */}
        <Box className="box-num-2">
          <InfromingTextBox text="edit">
            <CustomIcon
              href={
                // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
                (categories.length - 1 === index && initialValues.active) || initialValues.active
                  ? disabledEdit
                  : activeEdit
              }
              height={36}
              width={36}
              className={`${
                (categories.length - 1 !== index && !initialValues.active) || !initialValues.active
                  ? 'cursor-pointer'
                  : ''
              }`}
              onClick={
                categories.length - 1 === index && initialValues.active
                  ? () => {}
                  : () => {
                      onEdit(initialValues.id);
                    }
              }
            />
          </InfromingTextBox>
          <InfromingTextBox text="delete">
            <CustomIcon
              href={
                (initialValues.active && !initialValues.new) ?? initialValues.type !== 'normal'
                  ? disabledTrash
                  : Delete
              }
              height={36}
              width={36}
              onClick={
                (initialValues.active && !initialValues.new) ?? initialValues.type !== 'normal'
                  ? () => {}
                  : initialValues.new
                  ? () => {
                      onRemove();
                    }
                  : () => {
                      dispatch(
                        deletePackageCategory(initialValues.id, () => {
                          !errors && setSuccessMSG(t('Package category has been deleted'));
                          setCategoryResponse(true);
                          dispatch(getPackageCategoriesAction());
                        }),
                      );
                    }
              }
              className={`${
                (initialValues.active && !initialValues.new) ?? initialValues.type !== 'normal'
                  ? 'cursor-default'
                  : 'cursor-pointer'
              }`}
            />
          </InfromingTextBox>
          <InfromingTextBox text="save">
            <button type="submit" className="disabled-action-btn" disabled={!confirmActive}>
              <CustomIcon
                href={confirmActive ? activeConfirm : disabledConfirm}
                height={68}
                width={68}
              />
            </button>
          </InfromingTextBox>
        </Box>
      </form>
    </FormikProvider>
  );
};

export default CategoryItem;
