import {
  GET_PACKAGE_CATEGORY,
  GET_PACKAGE_CATEGORY_LOADING,
  GET_PACKAGE_CATEGORY_FAILED,
} from 'redux/types';
interface GetPackageCategoriesState {
  packageCategories: [];
  loading: boolean;
  error: string;
  totalPages: number;
  totalRecords: number;
  currentPage: number;
}

interface GetPackageCategoriesAction {
  type: string;
  payload: any;
}

const initialState: GetPackageCategoriesState = {
  packageCategories: [],
  loading: false,
  error: '',
  totalPages: 0,
  totalRecords: 0,
  currentPage: 1,
};

export const getPackageCategoriesReducer = (
  state = initialState,
  action: GetPackageCategoriesAction,
): GetPackageCategoriesState => {
  switch (action.type) {
    case GET_PACKAGE_CATEGORY:
      return {
        ...state,
        packageCategories: action.payload.PackageCategories as [],
        totalPages: action.payload.totalPages,
        currentPage: action.payload.currentPage,
      };
    case GET_PACKAGE_CATEGORY_LOADING:
      return {...state, loading: action.payload as boolean};
    case GET_PACKAGE_CATEGORY_FAILED:
      return {...state, error: action.payload as string};
    default:
      return state;
  }
};
