import {GET_ORGANIZATIONS, GET_ORGANIZATIONS_FAILURE, GET_ORGANIZATIONS_LOADING} from 'redux/types';

const initialState = {
  organizations: [],
  loading: false,
  error: null,
};

export const getOrganizationReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_ORGANIZATIONS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case GET_ORGANIZATIONS:
      return {
        ...state,
        organizations: action.payload,
      };
    case GET_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
