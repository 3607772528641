import './styles.css';

import React, {FC} from 'react';

interface props {
  children: React.ReactNode;
}

const AuthConatiner: FC<props> = ({children}) => {
  return (
    <div className="auth-background">
      <div className="overlay">{children}</div>
    </div>
  );
};

export default AuthConatiner;
