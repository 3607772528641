import {ReportTable} from 'model/Reports/Report';

export const getReportFilters = (table: ReportTable) => {
  switch (table) {
    case 'invoice':
      return ['Invoice number', 'Contact name', 'Phone number', 'Car plate'];
    case 'receipt':
      return ['Receipt number', 'Contact name', 'Phone number', 'Car plate'];
    case 'transaction':
      return ['Car plate', 'Contact name', 'Package name', 'Phone number', 'Status'];
    case 'utilization':
      return ['Month', 'Count of cars'];
    case 'revenue':
      return ['Month', 'Package name', 'Total amount'];
  }
};
