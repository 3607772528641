import {Box, Table, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';
import {Dispatch, FC, SetStateAction} from 'react';

import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import EmployeeBody from './components/EmployeeBody.tsx/EmployeeBody';
import InfromingTextBox from 'components/InfromingTextBox/InfromingTextBox';
import {RootState} from 'redux/rootReducers';
import {YELLOW} from 'constants/colors';
import {addSquare} from 'assets/icons';
import {getEmployee} from 'redux/actions/getEmployeeAction';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

interface props {
  setOpenEmployeeModal: Dispatch<SetStateAction<boolean>>;
}

const EmployeeSetupTable: FC<props> = ({setOpenEmployeeModal}) => {
  const {t} = useTranslation();
  const {currentLanguage} = useSelector((state: RootState) => state.changeLanguageReducer);
  const isArabic = currentLanguage === 'ar';

  const {selectedParking} = useSelector((state: RootState) => state.parkingSetupReducer);
  const {currentPage, totalPages} = useSelector((state: RootState) => state.getEmployeeReducer);
  const handleCreateEmployee = () => {
    if (selectedParking?.id) {
      setOpenEmployeeModal(true);
    }
  };
  return (
    <Box>
      <TableContainer
        sx={{
          height: 500,
          borderRadius: 4,
          pr: isArabic ? 0 : 2,
          pl: isArabic ? 2 : 0,
        }}>
        <Table className="sessions-table" sx={{fontFamily: 'Poppins', height: '100%'}}>
          <TableHead sx={{position: 'sticky', top: 0, whiteSpace: 'nowrap'}}>
            <TableRow sx={{background: YELLOW}}>
              <TableCell></TableCell>
              <TableCell className="table-head">{t('Employee name')}</TableCell>
              <TableCell className="table-head">{t('Employee email')}</TableCell>
              <TableCell className="table-head">{t('Employee phone no')}</TableCell>
              <TableCell className="table-head">{t('Car plate')}</TableCell>
              <TableCell className="table-head">{t('Start date')}</TableCell>
              <TableCell className="table-head">{t('Profile')}</TableCell>
              <TableCell className="table-head">{t('Gate Employee')}</TableCell>
              <TableCell className="table-head">{t('Status')}</TableCell>
              <TableCell>
                <InfromingTextBox text={t('Add new employee')}>
                  <CustomIcon
                    href={addSquare}
                    className="cursor-pointer"
                    onClick={handleCreateEmployee}
                  />
                </InfromingTextBox>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <EmployeeBody />
          <CustomTablePagination
            totalPages={totalPages}
            currentPage={currentPage}
            filters={[selectedParking?.id]}
            dispatchFunc={getEmployee}
          />
        </Table>
      </TableContainer>
    </Box>
  );
};

export default EmployeeSetupTable;
