import {GET_SHIFT, GET_SHIFT_ERROR, GET_SHIFT_LOADING} from 'redux/types';

import {Dispatch} from 'redux';
import {getShiftsService} from 'services/shift/getShiftService';

export const getShiftAction =
  (
    parkingId: string,
    empId?: string,
    pageNumber?: number,
    pageSize?: number,
    onShift?: () => void,
  ) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_SHIFT_LOADING, payload: true});
      const response = await getShiftsService(parkingId, empId, pageNumber, pageSize);
      if (empId) {
        const activeShift = response.data[0];
        dispatch({type: GET_SHIFT, payload: activeShift});
      } else {
        const allShifts = response.data[0];
        dispatch({type: GET_SHIFT, payload: allShifts});
      }

      onShift?.();
    } catch (err) {
      dispatch({type: GET_SHIFT_ERROR, payload: err});
    } finally {
      dispatch({type: GET_SHIFT_LOADING, payload: false});
    }
  };
