import {PackageCategoryReducer} from './reducers/PackageCategoryReducer';
import {assignPackageReducer} from './reducers/assignPackageReducer';
import {authReducer} from './reducers/authReducers';
import {cashPaymentReducer} from './reducers/cashPaymentReducer';
import {chartReducer} from './reducers/chartReducer';
import {combineReducers} from 'redux';
import {createPackageReducer} from './reducers/createPackageReducer';
import {deleteAssignUserReducer} from './reducers/deleteAssignUserReducer';
import {deletePackageReducer} from './reducers/deletePackageReducer';
import {employeeReducer} from './reducers/employeeReducer';
import {getEmployeeReducer} from './reducers/getEmployeeReducer';
import {getInvoiceReducer} from './reducers/getInvoiceReducer';
import {getOrganizationReducer} from './reducers/getOrganizationReducer';
import {getPackageCategoriesReducer} from './reducers/getPackageCategoriesReducer';
import {getPackageReducer} from './reducers/getPackageReducers';
import {getSessionsReducer} from './reducers/getSessionsReducer';
import {getUsersByPackageReducer} from './reducers/getUsersByPackageReducer';
import {getUsersReducer} from './reducers/getUsersReducer';
import {invoiceReducer} from './reducers/invoiceReducer';
import {organizationReducer} from './reducers/organizationReducer';
import {parkingReducer} from './reducers/parkingReducer';
import {parkingSetupReducer} from './reducers/parkingSetupReducer';
import {reportsReducer} from './reducers/reportsReducer';
import {searchReducer} from './reducers/searchReducer';
import {sessionsReducer} from './reducers/sessionsReducer';
import {setParkingAsFullReducer} from './reducers/setParkingAsFullReducer';
import {getShiftReducer} from './reducers/getShiftReducer';
import {unregisteredReducer} from './reducers/unregisteredReducer';
import {updatePackageReducer} from './reducers/updatePackageReducer';
import {usersReducer} from './reducers/usersReducer';
import {getProfileTypesReducer} from './reducers/getProfileTypesReducer';
import {employeeTransactionsReducer} from './reducers/employeeTransactionsReducer';
import {changeLanguageReducer} from './reducers/LanguageReducers/changeLanguageReducer';

export const appReducer = combineReducers({
  authReducer,
  getUsersReducer,
  usersReducer,
  getEmployeeReducer,
  parkingReducer,
  getSessionsReducer,
  unregisteredReducer,
  sessionsReducer,
  parkingSetupReducer,
  setParkingAsFullReducer,
  searchReducer,
  getOrganizationReducer,
  cashPaymentReducer,
  employeeReducer,
  getPackageReducer,
  createPackageReducer,
  getPackageCategoriesReducer,
  assignPackageReducer,
  getUsersByPackageReducer,
  deleteAssignUserReducer,
  PackageCategoryReducer,
  deletePackageReducer,
  updatePackageReducer,
  organizationReducer,
  getInvoiceReducer,
  chartReducer,
  invoiceReducer,
  reportsReducer,
  getShiftReducer,
  getProfileTypesReducer,
  employeeTransactionsReducer,
  changeLanguageReducer,
});

export const rootReducers = (state: any, action: any) => {
  if (action.type === 'LOG_OUT') {
    return {...appReducer(undefined, action), changeLanguageReducer: state.changeLanguageReducer};
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducers>;
