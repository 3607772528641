import React, {useEffect, useState} from 'react';
import QRCode from 'qrcode';
import {Invoice} from 'model/Reports/Invoice';
import moment from 'moment';

interface Props {
  invoiceData?: Invoice;
}

const DetailsQRCode: React.FC<Props> = ({invoiceData}) => {
  const [qrCodeText, setQRCodeText] = useState('');
  const [qrCodeImage, setQRCodeImage] = useState('');

  const generateQRCode = async () => {
    if (qrCodeText) {
      try {
        const qrCodeDataURL = await QRCode.toDataURL(qrCodeText);
        setQRCodeImage(qrCodeDataURL);
      } catch (error) {}
    }
  };

  useEffect(() => {
    setQRCodeText(
      `https://parking-staging.tarqia.net:12803/invoice-scanned/${
        invoiceData?.invoiceRef ?? 'null'
      }/${
        moment.utc(invoiceData?.invoiceCreationDate).local().isValid()
          ? moment.utc(invoiceData?.invoiceCreationDate).local().format()
          : 'null'
      }/${invoiceData?.phone ?? 'null'}/${invoiceData?.carNumber ?? 'null'}/${
        invoiceData?.invoiceMount ?? 'null'
      }`,
    );
  }, []);
  useEffect(() => {
    generateQRCode();
  }, [qrCodeText]);

  return (
    <div style={{border: 'solid 1px #B9B9B980', borderRadius: '20px', padding: '3px'}}>
      {qrCodeImage && <img src={qrCodeImage} className="w-32 h-32" alt="QR Code" />}
    </div>
  );
};

export default DetailsQRCode;
