import {
  CLEAR_PARKING_STATE,
  CREATE_PARKING,
  CREATE_PARKING_FAILURE,
  CREATE_PARKING_LOADING,
  GET_PARKING,
  GET_PARKING_FAILURE,
  PARKING_LOADING,
  RESERVERD_LOADING,
  GET_RESERVERD,
  GET_RESERVERD_FAILURE,
} from 'redux/types';

import {Dispatch} from 'redux';
import {createParkingService} from 'services/parking/createParkingService';
import {getParkingService} from 'services/parking/getParkingService';
import {updateParkingService} from 'services/parking/updateParkingService';
import {getReservedSlotsService} from 'services/parking/getReservedSlotsService';

export const getParking = (parkingId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: PARKING_LOADING, loading: true});
    const res = await getParkingService(parkingId);
    dispatch({type: GET_PARKING, payload: res.data[0].parkings});
  } catch (error) {
    dispatch({type: GET_PARKING_FAILURE, errors: error});
  } finally {
    dispatch({type: PARKING_LOADING, loading: false});
  }
};

export const getReservedSlotsAction = (parkingId: string) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: RESERVERD_LOADING, loading: true});
    const res = await getReservedSlotsService(parkingId);
    dispatch({type: GET_RESERVERD, payload: res.data});
  } catch (error) {
    dispatch({type: GET_RESERVERD_FAILURE, errors: error});
  } finally {
    dispatch({type: RESERVERD_LOADING, loading: false});
  }
};

export const createParkingAction = (data: any, update?: boolean) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: CREATE_PARKING_LOADING, payload: true});
    if (update) {
      const res = await updateParkingService(data);
      dispatch({type: CREATE_PARKING, payload: res.data});
    } else {
      const res = await createParkingService(data);
      dispatch({type: CREATE_PARKING, payload: res.data});
    }
  } catch (error) {
    dispatch({type: CREATE_PARKING_FAILURE, payload: error});
  } finally {
    dispatch({type: CREATE_PARKING_LOADING, payload: false});
  }
};

export const clearParkingState = () => (dispatch: Dispatch) => {
  dispatch({type: CLEAR_PARKING_STATE, payload: null});
};
