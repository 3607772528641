import 'react-datepicker/dist/react-datepicker.css';

import * as Yup from 'yup';

import {Box, CircularProgress, Grid} from '@mui/material';
import {FC, useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {GREY, WHITE, YELLOW} from 'constants/colors';
import {clearEmployeeState, createEmployee, updateEmployee} from 'redux/actions/employeeAction';
import {useDispatch, useSelector} from 'react-redux';

import {LoadingButton} from '@mui/lab';
import Popup from 'components/Popup/Popup';
import ReactDatePicker from 'react-datepicker';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import SingleDropdown from 'utils/dropdowns/SingleDropdown';
import {getProfileTypesAction} from 'redux/actions/getProfileTypesAction';
import moment from 'moment';
import {useOnline} from 'hooks/useOnline';
import {emailRegExpValidation} from 'utils/generalValidations';
import {useTranslation} from 'react-i18next';
import CustomDialog from './CustomDialog';

interface initialValuesProps {
  name: string;
  packageId?: string;
  packageName?: string;
  phone: string;
  profile: {name: string; id: string};
  email: string;
  startDate: any;
  carNumber: string;
  status: boolean;
  nationalId: string;
  isGateEmployee: boolean;
}

interface EmployeeSetupProps {
  handleClose: () => void;
  openEmployeeModal: boolean;
  parkingId: string | undefined;
}

const EmployeeSetupModal: FC<EmployeeSetupProps> = ({
  handleClose,
  openEmployeeModal,
  parkingId,
}) => {
  const {t} = useTranslation();

  const dispatch: any = useDispatch();
  const {selectedRowValue, newEmployee, loading, errors} = useSelector(
    (state: RootState) => state.employeeReducer,
  );
  const {profiles} = useSelector((state: RootState) => state.getProfileTypesReducer);
  const {isOnline} = useOnline();
  const [EmployeeResponse, setEmployeeResponse] = useState<boolean>(false);
  const [sucessMSG, setSuccessMSG] = useState<string | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const getProfilesOptions = (inputArray: Array<{name: any; id: any}>) => {
    return inputArray?.map((item: {name: any; id: any}) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  const [initialValues, setInitialValues] = useState<initialValuesProps>({
    name: '',
    packageName: '',
    packageId: '',
    phone: '',
    profile: {name: '', id: ''},
    startDate: moment(new Date()).format('DD / MM / YYYY'),
    carNumber: '',
    email: '',
    nationalId: '',
    isGateEmployee: false,
    status: true,
  });
  const validationSchema = () => {
    return Yup.object({
      name: Yup.string()
        .required(t('Required')!)
        .max(30, t('Name must be less then 30 characters')!),
      startDate: Yup.string().nullable().required(t('Required')!),
      profile: Yup.object().shape({
        id: Yup.string().required(t('Required')!),
      }),
      phone: Yup.string()
        .matches(/^[0-9]+$/, t('Phone number is not valid')!)
        .max(9, t('Phone number must be 9 digits')!)
        .min(9, t('Phone number must be 9 digits')!)
        .required(t('Required')!)
        .trim(),
      carNumber: Yup.string()
        .required(t('Required')!)
        .matches(/^(?:\d{1}|\d{2}|\d{3}|\d{4})[A-Z]{3}$/, t('Invalid car plate number')!),
      email: Yup.string()
        .matches(emailRegExpValidation, t('Must be a valid email')!)
        .max(255)
        .required(t('Required')!)
        .trim(),
      nationalId: Yup.string()
        .matches(/^\d+$/, t('National id must be an integer without spaces')!)
        .max(10, t("National id can't be greater than 10 digits")!)
        .min(10, t("National id can't be less than 10 digits")!)
        .required(t('Required')!)
        .typeError(t('Please enter a valid integer')!),
    });
  };

  const removeCodeCountry = (phone: string) => {
    const newPhoneNumber = phone.substring(3);
    return newPhoneNumber;
  };

  useEffect(() => {
    if (selectedRowValue) {
      setInitialValues({
        ...initialValues,
        name: selectedRowValue?.name ?? '',
        packageName: selectedRowValue?.packageName ?? '',
        phone: removeCodeCountry(selectedRowValue?.phone ?? ''),
        profile: {name: selectedRowValue?.profileName ?? '', id: selectedRowValue?.profileId ?? ''},
        startDate: selectedRowValue?.startDate ?? new Date(),
        nationalId: selectedRowValue?.nationalId ?? '',
        carNumber: selectedRowValue?.carNumber ?? '',
        email: selectedRowValue?.email ?? '',
        isGateEmployee: selectedRowValue?.isGateEmployee ?? false,
        status: selectedRowValue?.status === 'Active',
      });
    } else {
      setInitialValues({
        ...initialValues,
        name: '',
        packageName: '',
        phone: '',
        profile: {
          name: '',
          id: '',
        },
        startDate: new Date(),
        carNumber: '',
        email: '',
        nationalId: '',
        isGateEmployee: false,
        status: true,
      });
    }
  }, [selectedRowValue]);
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (values, actions) => {
      if (selectedRowValue) {
        dispatch(
          updateEmployee(
            {
              id: selectedRowValue.id,
              name: values.name,
              email: values.email.trim(),
              profileId: values.profile.id,
              profileName: values.profile.name,
              carNumber: values.carNumber,
              nationalId: values.nationalId,
              startDate: values.startDate,
              status: values.status ? 'Active' : 'InActive',
              isGateEmployee: values.isGateEmployee,
            },
            () => {
              setSuccessMSG(t('Employee has been updated successfully'));
            },
          ),
        );
      } else {
        dispatch(
          createEmployee(
            {
              parkingId,
              name: values.name,
              profileId: values.profile.id,
              profileName: values.profile.name,
              phone: 966 + values.phone.trim(),
              startDate: values.startDate,
              isGateEmployee: values.isGateEmployee,
              carNumber: values.carNumber.trim(),
              email: values.email.trim(),
              nationalId: values.nationalId,
              userType: 'Employee',
              status: values.status ? 'Active' : 'InActive',
            },
            () => {
              setSuccessMSG(t('Employee has been created successfully'));
            },
          ),
        );
      }
    },
  });

  useEffect(() => {
    if (newEmployee) {
      setEmployeeResponse(true);
      handleCloseModal();
    } else if (errors) {
      setEmployeeResponse(true);
    }
  }, [newEmployee, errors]);

  useEffect(() => {
    dispatch(getProfileTypesAction());
  }, []);

  const handleCloseModal = () => {
    handleClose();
    dispatch(clearEmployeeState());
    formik.resetForm();
  };

  return (
    <>
      <Popup
        open={openEmployeeModal}
        handleCloseModal={handleCloseModal}
        popupTitle={`${selectedRowValue ? t('Edit') : t('Create')} ${t('employee account')}`}>
        <div className="h-[440px] lg:h-[540px] overflow-auto">
          <form onSubmit={formik.handleSubmit} className="modal-container">
            <Box className="form-container">
              <Grid
                container
                id="user-employee-setup"
                className="employee-form"
                columnSpacing={{xs: 1, md: '95px'}}
                rowSpacing="14px"
                mt="7px">
                <Grid item xs={12} md={6}>
                  <Box className="employee-grid-item">
                    <label className="input-label">{t('Employee name')}</label>
                    <input
                      value={formik.values.name}
                      name="name"
                      id="name"
                      className="normal-popup-input"
                      onChange={formik.handleChange}
                      placeholder={t('Enter Employee Name')!}
                    />
                    <p className="validation-error">
                      {formik.errors.name && formik.touched.name && formik.errors.name}
                    </p>
                  </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Box className="employee-grid-item">
                    <label className="input-label">{t('Phone number')}</label>
                    <div className="containPhoneCode">
                      <span>+966</span>
                      <input
                        value={formik.values.phone}
                        name="phone"
                        id="phone"
                        disabled={selectedRowValue}
                        style={selectedRowValue && {color: GREY}}
                        className="normal-popup-input"
                        onChange={e => {
                          formik.setFieldValue('phone', e.target.value.trim());
                        }}
                        placeholder={t('Enter phone number')!}
                      />
                    </div>
                    <p className="validation-error">
                      {formik.errors.phone && formik.touched.phone && formik.errors.phone}
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="employee-grid-item">
                    <label className="input-label" htmlFor="profile">
                      {t('Profile')}
                    </label>
                    <SingleDropdown
                      fontSize="14px"
                      width="260px !important"
                      padding="0 35px 0 20px"
                      placeholder={t('Select profile')}
                      handleDropdownSelect={(name, id) => {
                        formik.setFieldValue('profile', {name, id});
                      }}
                      options={getProfilesOptions(profiles)}
                      textField
                      vip
                      parking
                      value={formik.values.profile?.name}
                    />
                    <p className="validation-error">
                      {formik.errors.profile?.id &&
                        formik.touched.profile?.id &&
                        formik.errors.profile?.id}
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="employee-grid-item">
                    <label className="input-label">{t('Email address')}</label>
                    <input
                      value={formik.values.email}
                      name="email"
                      id="email"
                      className="normal-popup-input"
                      onChange={formik.handleChange}
                      placeholder={t('Enter Email address')!}
                    />
                    <p className="validation-error">
                      {formik.errors.email && formik.touched.email && formik.errors.email}
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="employee-grid-item">
                    <label className="input-label">{t('Car plate')}</label>
                    <input
                      value={formik.values.carNumber}
                      name="carPlate"
                      id="carPlate"
                      className="normal-popup-input"
                      onChange={e => {
                        formik.setFieldValue('carNumber', e.target.value.trim());
                      }}
                      placeholder="Enter car number"
                    />
                    <p className="validation-error">
                      {formik.errors.carNumber &&
                        formik.touched.carNumber &&
                        formik.errors.carNumber}
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="employee-grid-item">
                    <label className="input-label">{t('National ID')}</label>
                    <input
                      value={formik.values.nationalId}
                      name="nationalId"
                      id="nationalId"
                      className="normal-popup-input"
                      onChange={formik.handleChange}
                      placeholder={t('Enter national id')!}
                    />
                    <p className="validation-error">
                      {formik.errors.nationalId &&
                        formik.touched.nationalId &&
                        formik.errors.nationalId}
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box className="employee-grid-item">
                    <label className="input-label" htmlFor="startDate">
                      {t('Start date')}
                    </label>
                    <ReactDatePicker
                      className="normal-popup-input"
                      placeholderText={t('Select start date')!}
                      value={
                        formik.values.startDate
                          ? moment(formik.values.startDate).format('DD / MM / YYYY')
                          : ''
                      }
                      onChange={date => {
                        formik.setFieldValue('startDate', date);
                      }}
                      selected={formik.values.startDate ? new Date(formik.values.startDate) : null}
                      dateFormat="dd-MM-yyyy"
                    />
                    <p className="validation-error">
                      {formik.errors.startDate && formik.touched.startDate && (
                        // eslint-disable-next-line @typescript-eslint/no-base-to-string
                        <span>{formik.errors.startDate.toString()}</span>
                      )}
                    </p>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                    <input
                      id="isGateEmployee"
                      type="checkbox"
                      name="isGateEmployee"
                      className="regular cursor-pointer"
                      style={{border: `solid 1px ${YELLOW}`, background: `${WHITE}`}}
                      checked={formik.values.isGateEmployee}
                      onChange={e => {
                        if (selectedRowValue?.isGateEmployee && formik.values.isGateEmployee) {
                          setOpenDialog(true);
                        } else {
                          formik.handleChange(e);
                        }
                      }}
                    />
                    <label className="input-label" htmlFor="isGateEmployee">
                      {t('Gate employee')}
                    </label>
                  </Box>
                </Grid>
                {selectedRowValue && (
                  <Grid item xs={12} md={6}>
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                      <input
                        id="status"
                        type="checkbox"
                        name="status"
                        className="regular cursor-pointer"
                        style={{border: `solid 1px ${YELLOW}`, background: `${WHITE}`}}
                        checked={formik.values.status}
                        onChange={formik.handleChange}
                      />
                      <label className="input-label" htmlFor="status">
                        {t('Active')}
                      </label>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                {errors && <p className="validation-error">{errors}</p>}
                {!isOnline && (
                  <p className="validation-error" style={{marginTop: '-9px', marginBottom: '15px'}}>
                    You are offline
                  </p>
                )}
                <LoadingButton
                  type="submit"
                  loading={loading}
                  className="popup-loading-button"
                  loadingIndicator={<CircularProgress />}
                  disabled={!isOnline}>
                  {t('Save')}
                </LoadingButton>
              </Box>
            </Box>
          </form>
        </div>
      </Popup>
      {EmployeeResponse && (
        <ResponseModal
          onClose={() => {
            setEmployeeResponse(false);
            dispatch(clearEmployeeState());
          }}
          message={errors ?? sucessMSG}
          error={errors}
        />
      )}
      {openDialog && (
        <CustomDialog
          open={openDialog}
          handleClose={() => {
            setOpenDialog(false);
          }}
          confirmedFunc={() => {
            formik.setFieldValue('isGateEmployee', false);
          }}
        />
      )}
    </>
  );
};

export default EmployeeSetupModal;
