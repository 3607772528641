import * as Yup from 'yup';

import {gateObjectProp} from 'model/types';

export const parkingSetupValidatioSchema = Yup.object({
  Organization_ID: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  name: Yup.string().required('Required').min(5, 'Too short'),
  gate_count: Yup.number()
    .integer('Value must be an integer')
    .moreThan(1, 'Must be greater than 1')
    .positive('Must be a positive number')
    .required('Required'),
  capacity: Yup.number()
    .integer('Capacity must be an integer')
    .moreThan(1, 'Must be greater than 1')
    .required('Required')
    .positive('Must be a positive number'),
  emergency_count: Yup.number().integer('Must be an integer').moreThan(-1, 'Invalid number'),
  gates: Yup.array(
    Yup.object({
      streetData: Yup.string().required('Required'),
      gateName: Yup.string().required('Required'),
    }),
  ),
});

export const gateObject: gateObjectProp = {streetData: '', gateName: '', id: '', isActive: true};

export const handleRenderGates = (
  e: {target: {value: number}},
  values: any,
  setFieldValue: any,
) => {
  setFieldValue('gate_count', e.target.value);
  const gatesValues: gateObjectProp[] = [gateObject];
  if (e.target.value > 1) {
    for (let i = 1; i < e.target.value; i++) {
      gatesValues.push(gateObject);
    }
  }
  setFieldValue('gates', gatesValues);
};
