export const filterByProperty = <T extends Record<string, any>>(
  property: keyof T,
  array: T[],
  searchValue: string,
) => {
  return array.filter(item => {
    const trimmedSearchValue = searchValue.trim();

    const value = item[property];

    if (value === null || value === undefined) {
      return false;
    }

    const stringValue = value.toString();

    if (typeof stringValue === 'string') {
      if (property === 'phone') {
        const normalizedSearchValue = trimmedSearchValue.startsWith('+')
          ? trimmedSearchValue.slice(1)
          : trimmedSearchValue;

        const normalizedValue = stringValue.startsWith('+') ? stringValue.slice(1) : stringValue;

        return normalizedValue.toLowerCase().startsWith(normalizedSearchValue?.toLowerCase());
      } else {
        return stringValue.toLowerCase().startsWith(trimmedSearchValue?.toLowerCase());
      }
    }
    return false;
  });
};
