export const loginEndPoint = () => {
  return '/auth/login';
};

export const xEndpoint = () => {
  return `xEndpoint`;
};

export const createParkingEndPoint = () => {
  return '/parkings';
};
export const getParkingEndPoint = () => {
  return `${createParkingEndPoint()}/v2/list`;
};

export const getReservedSlotsFreeEndPoint = (parkingId: string) => {
  return `parkings/v1/parkingSummary/${parkingId}`;
};

export const setParkingFreeEndPoint = (id: string) => {
  return `/parkings/v1/available/${id}`;
};

export const UpdateusersEndPoint = () => {
  return 'users/UpdateProfile';
};
export const usersEndPoint = () => {
  return '/users';
};

export const getUsersEndPoint = () => {
  return `users/list`;
};
export const sessionEndPoint = () => {
  return '/gw/reservations/v1';
};
export const getCurrentSessionsEndPoint = () => {
  return `/gw/reservations/v1/reservations/list`;
};

export const sendOTPEndpoint = () => {
  return '/auth/sendOTP';
};

export const verifyOTPEndpoint = () => {
  return '/auth/verifyOTP';
};

export const forgetPasswordEndpoint = () => {
  return '/auth/forgetPassword';
};
export const changePassworEndpoint = () => {
  return '/users/changePassword/';
};

export const getOrganiztionsEndPoint = () => {
  return '/organization/list';
};

export const paymentEndPoint = () => {
  return '/payments/v1/payCash';
};

export const createPackageEndpoint = () => {
  return '/parkings/v1/packages';
};

export const getPackagesEndpoint = (parkingId: string) => {
  return `/gw/parkings/v1/packages/by-parking-id/${parkingId}`;
};

export const getPackageCategoiresEndpoint = () => {
  return '/parkings/v1/package-categories/list';
};

export const getAllPackageCategories = () => {
  return 'parkings/v1/package-categories/all';
};

export const assignPackageEndpoint = (pkgId: string) => {
  return `/parkings/v1/packages/assign-users/${pkgId}`;
};

export const getAllUsersByPackageIdEndpoint = (pkgId: string) => {
  return `/gw/parkings/v1/packages/users/by-package-id/${pkgId} `;
};

export const deleteAssignedUserEndpoint = (pkgId: string) => {
  return `/parkings/v1/packages/assign-users/${pkgId} `;
};

export const createPackageCategoryEndpoint = () => {
  return '/parkings/v1/package-categories';
};

export const updatePackageCategoryEndpoint = (parkingId: string) => {
  return `/parkings/v1/package-categories/${parkingId}`;
};

export const packageWithIdEndpoint = (pkgId: string) => {
  return `/parkings/v1/packages/${pkgId}`;
};

export const deleteReservationEndpoint = (id: string) => {
  return `/reservations/${id}`;
};

export const getInvoiceEndPoint = (id: string) => {
  return `/reservations/${id}/invoice`;
};

export const chartEndPoint = () => {
  return '/gw/reservations/v1/report';
};

export const chartPkgEndPoint = () => {
  return '/gw/reservations/v1/report/package';
};
export const patmentMethodCahrtEndPoint = () => {
  return '/gw/invoice/v1/list/report';
};
export const invoiceEndPoint = () => {
  return 'reservations/checkout';
};

export const getInvoicesEndpoint = () => {
  return '/invoice/v1/list';
};

export const getTransactionsEndpoint = () => {
  return '/gw/reservations/v1/reservations/transactionLog';
};

export const getUtilitiesEndpoint = (parkingId: string) => {
  return `/gw/reservations/v1/parking/utility/${parkingId}`;
};

export const getRevenuesEndpoint = () => {
  return `/gw/reservations/v1/report/package/revenue`;
};

export const getStartEndShiftEndpoint = () => {
  return '/shift/v1';
};

export const getShiftsEndpoint = () => {
  return '/shift/v1/list';
};

export const getProfileTypesEndpoint = () => {
  return '/users/v1/profiles';
};

export const getWalletEndpoint = (id: string) => {
  return `/gw/payments/v1/wallet/${id}`;
};

export const updateWalletEndpoint = () => {
  return '/gw/payments/v1/wallet';
};

export const transactionsEndpoint = () => {
  return 'gw/payments/v1/wallet/transactions';
};

export const getWalletTransactionsEndpoint = () => {
  return `/wallet/v1/transactions/list`;
};
