import {Box} from '@mui/material';
import {FC, ReactNode} from 'react';
import {Container} from 'react-bootstrap';

interface props {
  children: ReactNode;
  className?: string;
  mt?: string;
}

const MainViewContainer: FC<props> = ({children, className, mt}) => {
  return (
    <Box
      className={className}
      sx={{
        px: '2%;',
        py: {xs: '24px', md: '34px'},
        marginTop: `${mt ?? '0'}`,
      }}>
      <Container fluid id="main-view-container" style={{padding: 0}}>
        {children}
      </Container>
    </Box>
  );
};

export default MainViewContainer;
