import {Navigate, Outlet} from 'react-router-dom';

import {FC} from 'react';
import {RouteProps} from 'routes';
import {START_SHIFT_PATH} from './paths';

const ShiftRoutes: FC<RouteProps> = ({shifts}) => {
  return shifts!.length > 0 ? <Outlet /> : <Navigate to={START_SHIFT_PATH} />;
};

export default ShiftRoutes;
