import './styles.css';
import * as yup from 'yup';
import {Col, Container, Row} from 'react-bootstrap';
import {Delete, arrowLeft, search as searchIcon} from 'assets/icons';
import {
  assignPackage,
  clearAssignPackage,
  deleteAssignUser,
  getUsersByPackage,
} from 'redux/actions/packageActions';
import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box} from '@mui/system';
import {CircularProgress} from '@mui/material';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import InfromingTextBox from 'components/InfromingTextBox/InfromingTextBox';
import {LoadingButton} from '@mui/lab';
import {PackageContext} from '..';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import {Typography} from 'antd';
import {YELLOW} from 'constants/colors';
import {getUsersAction} from 'redux/actions/getUsersAction';
import {useFormik} from 'formik';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import {useTranslation} from 'react-i18next';

const PackagesAndUsersTable = () => {
  const {t} = useTranslation();

  const dispatch: any = useDispatch();

  const [searched, setSearching] = useState(false);
  const [searchigError, setSearchigError] = useState<string>('');
  const [responseModal, setResponseModal] = useState<boolean>(false);
  const [deletAssignresponseModal, setDeletAssignResponseModal] = useState<boolean>(false);
  const [deletedUser, setDeletedUser] = useState<string | null>(null);

  const {currentLanguage} = useSelector((state: RootState) => state.changeLanguageReducer);
  const usersState = useSelector((state: RootState) => state.getUsersReducer);
  const {error, response} = useSelector((state: RootState) => state.assignPackageReducer);
  const {error: deleteAssignError, response: deleteAssignRespose} = useSelector(
    (state: RootState) => state.deleteAssignUserReducer,
  );
  const {loading, users} = useSelector((state: RootState) => state.getUsersByPackageReducer);

  const {mypackage, setPackage} = useContext(PackageContext)!;

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    resetForm,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      userPhone: '',
      username: '',
      userId: '',
      nationalId: '',
      empId: '',
    },
    validationSchema: yup.object({
      userPhone: yup
        .string()
        .matches(/^[0-9]+$/, 'Phone number is not valid')
        .max(9, 'Phone number must be at most 9 digits')
        .required('Required')
        .trim(),
    }),
    onSubmit: () => {
      dispatch(
        assignPackage(mypackage.pkg.id, {
          toBeAddedUserIds: [
            {
              nationalId: values.nationalId,
              userId: values.userId,
              // empId: values.empId,
              fromDt: null,
              toDt: null,
            },
          ],
        }),
      );
      resetForm();
    },
  });

  useEffect(() => {
    if (response) {
      setResponseModal(true);
      dispatch(getUsersByPackage(mypackage.pkg.id));
    } else if (error) {
      setResponseModal(true);
    }
  }, [response, error]);

  useEffect(() => {
    if (deleteAssignRespose) {
      setDeletAssignResponseModal(true);
      dispatch(getUsersByPackage(mypackage.pkg.id));
    } else if (deleteAssignError) {
      setDeletAssignResponseModal(true);
    }
  }, [deleteAssignRespose, deleteAssignError]);

  useEffect(() => {
    dispatch(getUsersByPackage(mypackage.pkg.id));
  }, []);

  useEffect(() => {
    if (searched) {
      const userExists = users.some((obj: any) => obj.phone === values.userPhone);
      if (usersState.users.length === 0 || userExists) {
        userExists ? setSearchigError('User already assigned') : setSearchigError('No user found');
        setFieldValue('username', '');
        setFieldValue('userId', '');
        setFieldValue('nationalId', '');
        setFieldValue('empId', '');
      } else {
        setFieldValue('username', usersState.users[0]?.name);
        setFieldValue('userId', usersState.users[0]?.id);
        setFieldValue('nationalId', usersState.users[0]?.nationalId);
        setFieldValue('empId', usersState.users[0]?.empId);
        setSearchigError('');
      }
      setSearching(false);
    }
  }, [usersState.users]);

  useEffect(() => {
    if (values.userPhone === '') {
      setFieldValue('username', '');
      setFieldValue('userId', '');
      setFieldValue('empId', '');
      setSearchigError('');
    }
  }, [values.userPhone]);

  return (
    <div id="packeges-users-table">
      <Col md={12} lg={5}>
        <table className="first-table custom-table-container">
          <thead className="head-container">
            <tr
              className={`custom-table-head frist-head ${
                currentLanguage === 'ar' && '!rounded-tl-none !rounded-tr-2xl'
              }`}
              style={{backgroundColor: '#000', color: '#fff'}}>
              <th className={`table-tag ${currentLanguage === 'ar' && 'pr-14'}`}>
                <CustomIcon
                  href={arrowLeft}
                  height={19}
                  className={`cursor-pointer ${currentLanguage === 'ar' && 'ml-4 rotate-180'}`}
                  onClick={() => setPackage(undefined)}
                />
                <Typography>{`Assigned users for ${mypackage?.pkg.name}`}</Typography>
              </th>
            </tr>
          </thead>
          <thead className="head-container">
            <tr className="custom-table-head" style={{backgroundColor: `${YELLOW}`}}>
              <th></th>
              <th>{t('User phone number')}</th>
              <th>{t('User national ID')}</th>
              <th>{t('Username')}</th>
              <th></th>
            </tr>
          </thead>
          <tbody
            className={`custom-table-body ${
              users?.length === 0 && '!flex justify-center items-center'
            }`}>
            <CustomLoader size={80} loading={loading}>
              {users.length === 0 ? (
                <p className="text-[var(--disabled-grey)]">
                  {t('No user has been assigned to that package yet')}
                </p>
              ) : (
                users.map((user: any) => {
                  return (
                    <tr className="user-row" key={user.userId}>
                      <td></td>
                      <td>{'+' + user.phone ?? '--'}</td>
                      <td>{user.nationalId ?? '--'}</td>
                      <td>{user.userName ?? '--'}</td>
                      <td className="cursor-pointer delete">
                        <InfromingTextBox text="Unassign">
                          <CustomIcon
                            className="ml-3"
                            href={Delete}
                            width={32}
                            height={32}
                            onClick={() => {
                              setDeletedUser(user.userId);
                            }}
                          />
                        </InfromingTextBox>
                      </td>
                    </tr>
                  );
                })
              )}
            </CustomLoader>
          </tbody>
        </table>
      </Col>
      <Col md={12} lg={7}>
        <table className="custom-table-container second-table">
          <thead className="head-container">
            <tr
              className={`custom-table-head frist-head ${
                currentLanguage === 'ar' && '!rounded-tr-none !rounded-tl-2xl'
              }`}
              style={{backgroundColor: '#000'}}>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <thead className="head-containe">
            <tr className="custom-table-head" style={{backgroundColor: `${YELLOW}`}}>
              <th className="table2-head">{t('Assign users to package')}</th>
            </tr>
          </thead>
          <tbody className="custom-table-body">
            <Container className="form-container">
              <form onSubmit={handleSubmit}>
                <Row className="my-row">
                  <Col xs={12} md={6} className="my-column">
                    <Box className="input-box">
                      <label className="label">{t('User phone no.')}</label>
                      <div className="w-full relative">
                        <div className="containPhoneCode">
                          <span>+966</span>
                          <input
                            className="input search-input"
                            id="userPhone"
                            value={values.userPhone}
                            onChange={e => {
                              setFieldValue('userPhone', e.target.value);
                              setSearchigError('');
                            }}
                            onBlur={handleBlur}
                          />
                        </div>
                        <CustomIcon
                          href={searchIcon}
                          className={`cursor-pointer search-icon ${
                            values.userPhone.length >= 9 && 'search-active'
                          }`}
                          onClick={() => {
                            if (errors.userPhone) {
                              return;
                            } else {
                              dispatch(getUsersAction(undefined, values.userPhone.trim()));
                              setSearching(true);
                            }
                          }}
                        />
                      </div>
                      <p className="validation-error">
                        {errors.userPhone && touched.userPhone && errors.userPhone}
                      </p>
                    </Box>
                  </Col>
                  <Col xs={12} md={6} className="my-column">
                    <Box className="input-box">
                      <label className="label">{t('User name')}</label>
                      <input
                        id="username"
                        value={values.username}
                        onChange={handleChange}
                        className="input"
                        disabled
                      />
                    </Box>
                  </Col>
                </Row>
                <Row className="my-row">
                  <Col xs={12} md={6} className="my-column">
                    <Box className="input-box">
                      <label className="label">{t('User national ID')}</label>
                      <input
                        className="input"
                        id="nationalId"
                        value={values.nationalId ?? values.empId ?? '--'}
                        onChange={handleChange}
                        disabled
                      />
                    </Box>
                  </Col>
                </Row>
                <Row className="button-row">
                  <Col
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    <p className="validation-error">{searchigError}</p>
                    <LoadingButton
                      sx={{marginTop: 3}}
                      type="submit"
                      disabled={!values.username}
                      className="popup-loading-button"
                      loadingIndicator={<CircularProgress />}>
                      {t('Assign')}
                    </LoadingButton>
                  </Col>
                </Row>
              </form>
            </Container>
          </tbody>
        </table>
      </Col>
      <CustomDialog
        question="Are you sure of unassigning this package for this user?"
        open={deletedUser !== null}
        handleClose={() => {
          setDeletedUser(null);
        }}
        confirmedFunc={() => {
          dispatch(
            deleteAssignUser(mypackage.pkg.id, deletedUser, () =>
              dispatch(getUsersByPackage(mypackage.pkg.id)),
            ),
          );
        }}
      />

      {responseModal && (
        <ResponseModal
          message={!error ? 'Assigned package successfully!' : error}
          error={error}
          onClose={() => {
            setResponseModal(false);
            dispatch(clearAssignPackage());
          }}
        />
      )}
      {deletAssignresponseModal && (
        <ResponseModal
          message={
            !deleteAssignError
              ? 'user is unassigned successfully for the package'
              : deleteAssignError.statusMessage
          }
          error={deleteAssignError}
          onClose={() => {
            setDeletAssignResponseModal(false);
            dispatch(clearAssignPackage());
          }}
        />
      )}
    </div>
  );
};

export default PackagesAndUsersTable;
