import {
  GET_PROCESS_CHART_DATA,
  GET_PROCESS_CHART_DATA_FAILURE,
  GET_PROCESS_CHART_DATA_LOADING,
  GET_PACKAGE_CHART_DATA,
  GET_PACKAGE_CHART_DATA_FAILURE,
  GET_PACKAGE_CHART_DATA_LOADING,
  GET_PAYMENT_CHART_DATA_LOADING,
  GET_PAYMENT_CHART_DATA,
  GET_PAYMENT_CHART_DATA_FAILURE,
  UPDATE_PROCESS_SLOTS_LOADING,
  UPDATE_PROCESS_SLOTS,
  UPDATE_PROCESS_SLOTS_FAILURE,
  UPDATE_PROCESS_AMOUNT,
  UPDATE_PACKAGE_SLOTS,
  UPDATE_PACKAGE_AMOUNT,
  UPDATE_PROCESS_AMOUNT_LOADING,
  UPDATE_PROCESS_AMOUNT_FAILURE,
  UPDATE_PACKAGE_SLOTS_LOADING,
  UPDATE_PACKAGE_AMOUNT_LOADING,
  UPDATE_PACKAGE_SLOTS_FAILURE,
  UPDATE_PACKAGE_AMOUNT_FAILURE,
} from 'redux/types';

const initialState = {
  processChartData: null,
  processChartLoading: false,
  processChartError: '',
  filteredProcessSlots: '',
  filteredProcessAmount: '',
  packageChartData: null,
  packageChartLoading: false,
  packageChartError: '',
  filteredPackageSlots: '',
  filteredPackageAmount: '',
  paymentChartData: null,
  paymentChartLoading: false,
  paymentChartError: '',
};

export const chartReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_PROCESS_CHART_DATA_LOADING:
      return {...state, processChartLoading: action.payload};
    case GET_PROCESS_CHART_DATA:
      return {
        ...state,
        processChartData: action.payload.result,
      };
    case GET_PROCESS_CHART_DATA_FAILURE:
      return {...state, processChartError: action.payload};
    case UPDATE_PROCESS_SLOTS_LOADING:
    case UPDATE_PROCESS_AMOUNT_LOADING:
      return {...state, processChartLoading: action.payload};
    case UPDATE_PROCESS_SLOTS:
      return {...state, filteredProcessSlots: action.payload.result};
    case UPDATE_PROCESS_SLOTS_FAILURE:
    case UPDATE_PROCESS_AMOUNT_FAILURE:
      return {...state, processChartError: action.payload};
    case UPDATE_PROCESS_AMOUNT:
      return {...state, filteredProcessAmount: action.payload.result};
    case UPDATE_PACKAGE_SLOTS_LOADING:
    case UPDATE_PACKAGE_AMOUNT_LOADING:
      return {...state, packageChartLoading: action.payload};
    case UPDATE_PACKAGE_SLOTS:
      return {...state, filteredPackageSlots: action.payload.result};
    case UPDATE_PACKAGE_SLOTS_FAILURE:
    case UPDATE_PACKAGE_AMOUNT_FAILURE:
      return {...state, packageChartError: action.payload};
    case UPDATE_PACKAGE_AMOUNT:
      return {...state, filteredPackageAmount: action.payload.result};
    case GET_PACKAGE_CHART_DATA_LOADING:
      return {...state, packageChartLoading: action.payload};
    case GET_PACKAGE_CHART_DATA:
      return {
        ...state,
        packageChartData: action.payload.result,
      };
    case GET_PACKAGE_CHART_DATA_FAILURE:
      return {...state, packageChartError: action.payload};
    case GET_PAYMENT_CHART_DATA_LOADING:
      return {...state, paymentChartLoading: action.payload};
    case GET_PAYMENT_CHART_DATA:
      return {
        ...state,
        paymentChartData: action.payload.result,
      };
    case GET_PAYMENT_CHART_DATA_FAILURE:
      return {...state, paymentChartError: action.payload};
    default:
      return state;
  }
};
