import {
  EMPLOYEE_SETUP_PATH,
  EMPLOYEE_WALLET_PATH,
  LOGIN_PATH,
  PACKAGE_CATEGORY_PATH,
  PACKAGE_HOME_PATH,
  PARKING_SETUP_PATH,
  PROFILE_ADMIN_PATH,
  REPORTS_PATH,
  SESSIONS_PATH,
  USER_SETUP_PATH,
} from 'routes/paths';
import {Link, useNavigate} from 'react-router-dom';

import {Box} from '@mui/material';
import {logoutAction} from 'redux/actions/authAction';
import {useDispatch} from 'react-redux';
import { useTranslation } from 'react-i18next';

const Items = () => {
  const {t} = useTranslation();

  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatch(logoutAction());
    navigate(LOGIN_PATH);
  };
  return (
    <Box className="Nav-links">
      <Link
        className={`${location.pathname === PARKING_SETUP_PATH && 'active'} link-item`}
        to={PARKING_SETUP_PATH}>
        {t("parking-setup")}
      </Link>
      <Link
        className={`${location.pathname === EMPLOYEE_WALLET_PATH && 'active'} link-item`}
        to={EMPLOYEE_WALLET_PATH}>
        {t('gate-emp-wallet')}
      </Link>
      <Link
        className={`${location.pathname === PACKAGE_HOME_PATH && 'active'} link-item`}
        to={PACKAGE_HOME_PATH}>
        {t('package-home')}
      </Link>
      <Link
        className={`${location.pathname === PACKAGE_CATEGORY_PATH && 'active'} link-item`}
        to={PACKAGE_CATEGORY_PATH}>
          {t('package-category')}
      </Link>
      <Link
        className={`${location.pathname === SESSIONS_PATH && 'active'} link-item`}
        to={SESSIONS_PATH}>
        {t('sessions')}
      </Link>
      <Link
        className={`${location.pathname === EMPLOYEE_SETUP_PATH && 'active'} link-item`}
        to={EMPLOYEE_SETUP_PATH}>
        {t('employee-list')}
      </Link>
      <Link
        className={`${location.pathname === USER_SETUP_PATH && 'active'} link-item`}
        to={USER_SETUP_PATH}>
        {t('user-list')}
      </Link>
      <Link
        className={`${location.pathname === REPORTS_PATH && 'active'} link-item`}
        to={REPORTS_PATH}>
        {t('reports')}
      </Link>
      <Link
        className={`${location.pathname === PROFILE_ADMIN_PATH && 'active'} link-item`}
        to={PROFILE_ADMIN_PATH}>
        {t('profile')}
      </Link>
      <Link className="link-item" onClick={handleLogout}>
        {t('Logout')}
      </Link>
    </Box>
  );
};

export default Items;
