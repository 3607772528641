import {Autocomplete, Pagination, TextField} from '@mui/material';
import {FC, useEffect, useRef, useState} from 'react';
import {arrowDown, whiteDownArrow} from 'assets/icons';
import {useDispatch, useSelector} from 'react-redux';

import {RootState} from 'redux/rootReducers';
import {getAllPackageCategories} from 'redux/actions/packagecategoryAction';
import {getPackages} from 'redux/actions/packageActions';
import {getParkingSetup} from 'redux/actions/parkingSetupAction';
import styles from 'utils/dropdowns/Dropdown.module.css';
import {useOnline} from 'hooks/useOnline';
import {useOutsideClick} from 'hooks/useOutsideClick';
import {useTranslation} from 'react-i18next';

interface Props {
  pagination?: boolean;
  placeholder: string;
  value: string | undefined;
  options: any[];
  handleDropdownSelect?: (option: string, id?: any) => void;
  handleCategorySelect?: (name: string, id: string, type: string) => void;
  handlePageChange?: (page: number) => void;
  disabled?: boolean;
  payment?: boolean;
  vip?: boolean;
  parking?: boolean;
  textField?: boolean;
  height?: string;
  width?: string;
  emptyText?: string;
  packageCategory?: boolean;
  vipBooking?: boolean;
  packages?: boolean;
  tableId?: string;
  backgroundColor?: string;
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  borderRadius?: number;
  padding?: string;
  margin?: string;
  blackArrow?: boolean;
  parkingId?: string;
  search?: boolean;
  onEmpty?: () => void;
}

const SingleDropdown: FC<Props> = ({
  pagination,
  placeholder,
  value,
  options,
  handleDropdownSelect,
  handleCategorySelect,
  handlePageChange,
  disabled,
  vip,
  parking,
  textField,
  width,
  height = '54px',
  emptyText,
  packageCategory,
  vipBooking,
  packages,
  tableId,
  backgroundColor,
  color,
  fontWeight,
  borderRadius = 112,
  padding,
  margin,
  blackArrow,
  parkingId,
  fontSize,
  search,
  onEmpty,
}) => {
  const {i18n} = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const {currentPage, totalPages} = useSelector((state: RootState) => state.parkingSetupReducer);
  const dispatch: any = useDispatch();
  const divRef = useRef<HTMLDivElement>(null);
  const {isOnline} = useOnline();

  const currentLanguage = i18n.language;

  useOutsideClick(divRef, () => {
    setIsOpen(false);
  });

  const defaultProps = {
    options,
    getOptionLabel: (option: any) => option?.label ?? 'Not found',
  };

  const handleDisabled = () => {
    if (disabled) setIsOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      if (pagination) dispatch(getParkingSetup());
      if (packageCategory) dispatch(getAllPackageCategories());
      if (packages) parkingId ? dispatch(getPackages(parkingId)) : dispatch(getPackages(tableId!));
    }
  }, [isOpen]);

  if (search) {
    return (
      <div className="relative dropdown-container" style={{width}}>
        <Autocomplete
          {...defaultProps}
          ref={divRef}
          open={isOpen}
          onChange={(event, newValue: any) => {
            if (isOnline) {
              handleDropdownSelect?.(newValue?.label, newValue?.value);
            }
          }}
          renderInput={params => (
            <TextField
              {...params}
              onMouseDown={e => {
                if (isOnline) {
                  setIsOpen(!isOpen);
                }
              }}
              onChange={e => {
                if (isOnline) {
                  setIsOpen(true);
                  if (!e.target.value) {
                    onEmpty?.();
                  }
                }
              }}
              placeholder={placeholder}
              sx={{
                fontFamily: 'Poppins',
              }}
            />
          )}
        />
        <img
          src={blackArrow ? arrowDown : whiteDownArrow}
          alt="arrow"
          className={`absolute ${
            currentLanguage === 'en' ? 'right-[8%]' : 'left-[8%]'
          } top-[45%] pointer-events-none ${isOpen && styles.rotateIcon}`}
          onClick={() => {
            setIsOpen(!isOpen);
            handleDisabled();
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        width,
        height,
        backgroundColor,
        color,
        fontWeight,
        borderRadius,
        paddingLeft: '22px',
        padding,
        margin,
      }}
      ref={divRef}
      onClick={() => {
        setIsOpen(!isOpen);
        handleDisabled();
      }}
      className={`${parking && styles.parkingDropdown} ${textField && styles.textField} ${
        vip && styles.vipDropdown
      } ${styles.dropdownContainer} cursor-pointer`}>
      {textField ? (
        <input
          style={{fontSize}}
          disabled={disabled}
          readOnly
          className={disabled ? 'cursor-default' : 'cursor-pointer'}
          type="text"
          value={value}
          placeholder={placeholder}
          onClick={() => {
            setIsOpen(true);
            handleDisabled();
          }}
        />
      ) : (
        <p className={!value ? styles.noValue : ''}>{!value ? placeholder : value}</p>
      )}
      <img
        src={blackArrow ? arrowDown : whiteDownArrow}
        alt="arrow"
        className={`${disabled ? 'cursor-default' : 'cursor-pointer'} ${
          isOpen && styles.rotateIcon
        } ${i18n.language === 'ar' && 'left-[8%] !right-auto'}`}
        onClick={() => {
          setIsOpen(!isOpen);
          handleDisabled();
        }}
      />
      <div className={`${styles.dropdownMenu} ${isOpen && styles.dropdownOpened}`}>
        <ul className="h-40 overflow-y-auto overflow-x-hidden mr-1">
          {options?.length === 0 || options === undefined || options === null ? (
            <li>{emptyText}</li>
          ) : (
            options?.map((option: any, index) => {
              return (
                <li
                  key={index}
                  className="cursor-pointer"
                  onClick={() => {
                    setIsOpen(false);
                    parking
                      ? handleDropdownSelect!(option.label, option.value)
                      : vipBooking
                      ? handleDropdownSelect!(option.name, option.id)
                      : packageCategory
                      ? handleCategorySelect!(option.name, option.id, option.type)
                      : vipBooking
                      ? handleDropdownSelect!(option.name, option.id)
                      : packages
                      ? handleDropdownSelect!(option.pkg.name, option.pkg.id)
                      : handleDropdownSelect!(option);
                  }}>
                  {parking
                    ? option.label
                    : vipBooking
                    ? option.name ?? 'No parking name found'
                    : packageCategory
                    ? option.name
                    : packages
                    ? option.pkg.name
                    : vipBooking
                    ? option.name ?? 'No parking name found'
                    : option}
                </li>
              );
            })
          )}
        </ul>
        {pagination && (
          <Pagination
            count={totalPages}
            page={currentPage}
            boundaryCount={2}
            onClick={e => e.stopPropagation()}
            onChange={(e, value) => handlePageChange!(value)}
            size="small"
            sx={{display: 'flex', justifyContent: 'center', pt: 2}}
          />
        )}
      </div>
    </div>
  );
};

export default SingleDropdown;
