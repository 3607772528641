import './styles.css';

import {ChangeEvent, FC, useRef, useState} from 'react';

import {Field} from 'formik';
import styles from 'utils/dropdowns/Dropdown.module.css';
import {useOutsideClick} from 'hooks/useOutsideClick';
import {whiteDownArrow} from 'assets/icons';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/rootReducers';

interface Props {
  hidden?: boolean;
  icon?: string;
  background?: string;
  className?: string;
  values: any[];
  formik?: any;
  fieldName?: string;
  width?: string;
  height?: string;
  name?: string;
  value?: Array<string | number>;
  onChange?: any;
  borderRadius?: string | number;
  color?: string;
  names: string;
  placeholder?: string;
}

const CustomSelect: FC<Props> = ({
  hidden,
  icon = whiteDownArrow,
  background = 'white',
  className,
  values,
  formik,
  width = '80%',
  height,
  name,
  value = [],
  onChange,
  borderRadius = 4,
  color,
  names,
  placeholder,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const { currentLanguage } = useSelector((state: RootState) => state.changeLanguageReducer);


  useOutsideClick(ref, () => {
    setIsOpen(false);
  });

  return (
    <div
      ref={ref}
      onClick={() => {
        setIsOpen(!isOpen);
      }}
      style={{
        color,
        background,
        borderRadius,
        height,
        width,
        fontSize: className === 'days-toggle' ? 12 : 16,
        transition: 'background-color 0.5s ease',
      }}
      className={`${styles.dropdownContainer} ${currentLanguage==='en'?"":"flex flex-row-reverse"} ${styles.textField} cursor-pointer`}>
      <input
        disabled={true}
        className={`cursor-pointer drop-input`}
        placeholder={placeholder}
        onClick={() => {
          setIsOpen(true);
        }}
      />
      <img
        // style={{filter: 'brightness(1)'}}
        src={icon}
        alt="whiteArrow"
        className={`'cursor-default'  ${isOpen && styles.rotateIcon}`}
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      />
      <div
        className={`${styles.dropdownMenu} ${isOpen && styles.dropdownOpened}`}
        onClick={e => e.stopPropagation()}>
        {values ? (
          values?.map((object, index) => {
            return (
              <ul key={index}>
                <li>
                  <div className={styles.checkboxContainer}>
                    <Field
                      type="checkbox"
                      className="cursor-pointer gate-check regular"
                      name={`${names}.${index}.isActive`}
                      value={object.isActive}
                      checked={!object.isActive}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        formik.setFieldValue(`${names}.${index}.isActive`, !e.target.checked)
                      }
                    />
                    <p>{object.gateName}</p>
                  </div>
                </li>
              </ul>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
