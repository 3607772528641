import {
  GET_INVOICES,
  GET_INVOICES_LOADING,
  GET_RECEIPTS,
  GET_RECEIPTS_LOADING,
  GET_REVENUES,
  GET_REVENUES_LOADING,
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_LOADING,
  GET_UTILITES,
  GET_UTILITES_LOADING,
} from 'redux/types';

import {Dispatch} from 'redux';
import {getInvoicesService} from 'services/reports/getInvoicesService';
import {getRevenuesService} from 'services/reports/getRevenuesService';
import {getTransactionsService} from 'services/reports/getTransactionsService';
import {getUtilitesService} from 'services/reports/getUtilitiesService';
import {getReceiptsService} from 'services/reports/getReceiptsService';
import {Utility} from 'model/Reports/Utility';

export const getInvoices =
  (pageNumber: number, date?: {from: string; to: string}, getAll?: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_INVOICES_LOADING, payload: true});
      const response = await getInvoicesService(pageNumber, date, getAll);
      dispatch({type: GET_INVOICES, payload: response.data});
    } finally {
      dispatch({type: GET_INVOICES_LOADING, payload: false});
    }
  };

export const getReceipts =
  (pageNumber: number, date?: {from: string; to: string}, getAll?: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_RECEIPTS_LOADING, payload: true});
      const response = await getReceiptsService(pageNumber, date, getAll);
      dispatch({type: GET_RECEIPTS, payload: response.data});
    } finally {
      dispatch({type: GET_RECEIPTS_LOADING, payload: false});
    }
  };

export const getTransactions =
  (pageNumber: number, date?: {from: string; to: string}, getAll?: boolean) =>
  async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_TRANSACTIONS_LOADING, payload: true});
      const response = await getTransactionsService(pageNumber, date, getAll);
      dispatch({type: GET_TRANSACTIONS, payload: response.data});
    } finally {
      dispatch({type: GET_TRANSACTIONS_LOADING, payload: false});
    }
  };

export const getUtilities =
  (parkingId: string, date?: {from: string; to: string}) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_UTILITES_LOADING, payload: true});
      const response = await getUtilitesService(parkingId, date);
      const utilites: Utility[] = [];

      const sortedYears = Object.entries(response.data.result).sort(
        ([yearA], [yearB]) => parseInt(yearB) - parseInt(yearA),
      );

      sortedYears.forEach(([year, months]) => {
        const typedMonths: Record<string, number> = months as Record<string, number>;

        for (const month in typedMonths) {
          const count = typedMonths[month];
          const formattedMonth = month.charAt(0) + month.slice(1).toLowerCase();
          utilites.push({year, month: formattedMonth, count});
        }
      });

      dispatch({type: GET_UTILITES, payload: utilites});
    } finally {
      dispatch({type: GET_UTILITES_LOADING, payload: false});
    }
  };

export const getRevenues =
  (parkingId: string, date?: {from: string; to: string}) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_REVENUES_LOADING, payload: true});
      const response = await getRevenuesService(parkingId, date);
      dispatch({type: GET_REVENUES, payload: response.data.result});
    } finally {
      dispatch({type: GET_REVENUES_LOADING, payload: false});
    }
  };
