import {GET_INVOICE, GET_INVOICE_LOADING, GET_INVOICE_FAILURE} from 'redux/types';

const initialState = {
  invoice: null,
  loading: false,
  error: null,
};

export const getInvoiceReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_INVOICE_LOADING: {
      return {...state, fetchLoading: action.payload};
    }
    case GET_INVOICE:
      return {
        ...state,
        invoice: action.payload,
      };

    case GET_INVOICE_FAILURE:
      return {...state, errors: action.error};

    default:
      return state;
  }
};
