import {patmentMethodCahrtEndPoint} from 'api/apisURL';

import api from '../../api';

export const PaymentChartService = async (
  // parkingId: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
) => {
  const payload: any = {
    strt: startDate,
    end: endDate,

    // filters: [
    //   {

    //   },
    //   {
    //     fieldName: 'parkingId',
    //     operator: 'like',
    //     values: [parkingId],
    //   },
    // ],
  };
  const response = await api.post(patmentMethodCahrtEndPoint(), payload);
  return response;
};
