import {Invoice} from 'model/Reports/Invoice';
export const getInvoiceCells = (report: Invoice) => {
  const {invoiceRef, status, name, phone, carNumber} = report;

  return [
    invoiceRef ? `'${invoiceRef}'` : '--',
    status ?? '--',
    name ?? '--',
    phone ? '\u00A0+' + phone : '--',
    carNumber ?? '--',
  ];
};
