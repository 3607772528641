import {useEffect, useRef} from 'react';

export const useOuterClick = (callback: any) => {
  const innerRef = useRef<any>();
  const callbackRef = useRef<any>();

  useEffect(() => {
    callbackRef.current = callback;
  });

  useEffect(() => {
    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);

    function handleClick(e: any) {
      if (innerRef.current && callbackRef.current && !innerRef.current.contains(e.target)) {
        callbackRef.current(e);
      }
    }
  }, []);

  return innerRef;
};

// import {useEffect, useRef} from 'react';

// type Ref = React.MutableRefObject<any>;

// export const useOuterClick = (callback: (e: MouseEvent) => void, exceptions?: Ref[]) => {
//   const innerRef = useRef<any>();
//   const callbackRef = useRef<any>();

//   useEffect(() => {
//     callbackRef.current = callback;
//   });

//   useEffect(() => {
//     document.addEventListener('click', handleClick);
//     return () => document.removeEventListener('click', handleClick);

//     function handleClick(e: MouseEvent) {
//       if (
//         innerRef.current &&
//         callbackRef.current &&
//         !innerRef.current.contains(e.target) &&
//         !exceptions?.some(exception => exception.current.contains(e.target))
//       ) {
//         callbackRef.current(e);
//       }
//     }
//   }, [exceptions]);

//   return innerRef;
// };
