import './styles.css';

import {Col, Row} from 'react-bootstrap';
import {
  clearPackageParking,
  clearPackageSetup,
  clearPackages,
  getPackages,
} from 'redux/actions/packageActions';
import {createContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import CustomButton from 'components/CustomButton/CustomButton';
import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import PackageModal from './PackageTable/components/PackageModal/PackageModal';
import PackageTable from './PackageTable/PackageTable';
import PackagesAndUsersTable from './PackagesAndUsersTable/PackagesAndUsersTable';
import {PackagesTableTypes} from 'model/types';
import ParkingDropDown from 'utils/dropdowns/ParkingDropDown';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import {Stack} from '@mui/material';
import {useTranslation} from 'react-i18next';

interface MyContextType {
  mypackage: any;
  setPackage: (value: any) => void;
}

export const PackageContext = createContext<MyContextType>({
  mypackage: undefined,
  setPackage: () => {},
});

const PackagesHome = () => {
  const {t} = useTranslation();

  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [createResponseModal, setCreateResponseModal] = useState<boolean>(false);
  const [selectedPackage, setSelectedPackage] = useState<PackagesTableTypes | undefined>(undefined);
  const [packageModal, setPackageModal] = useState<'Create Package'>();

  const {selectedParking} = useSelector((state: RootState) => state.parkingSetupReducer);
  const {response, error} = useSelector((state: RootState) => state.createPackageReducer);
  const dispatch: any = useDispatch();

  useEffect(() => {
    if (response || error) {
      setCreateResponseModal(true);
      if (response) dispatch(getPackages(selectedParking?.id ?? ''));
    }
  }, [response, error]);

  useEffect(() => {
    dispatch(clearPackages());
    dispatch(clearPackageParking());
  }, []);

  useEffect(() => {
    if (selectedParking) {
      dispatch(getPackages(selectedParking?.id ?? ''));
    }
  }, [selectedParking, selectedPackage]);

  const setPackage = (value: PackagesTableTypes | undefined) => {
    setSelectedPackage(value);
  };

  const tableSelected = () => {
    if (selectedPackage !== undefined) {
      return <PackagesAndUsersTable />;
    } else {
      return <PackageTable parking={selectedParking} />;
    }
  };

  return (
    <PackageContext.Provider value={{mypackage: selectedPackage, setPackage}}>
      <div id="assign-packages">
        <MainViewContainer>
          <Row className="special-row align-items-center justify-content-between">
            <Col xs={6}>
              {selectedPackage === undefined && (
                <Stack
                  flexDirection="row"
                  className="role"
                  gap={2}
                  alignItems="center"
                  position="relative">
                  <ParkingDropDown />
                  <CustomButton
                    height="56px"
                    width="245px"
                    disabled={!selectedParking?.id}
                    onClick={() => setPackageModal('Create Package')}>
                    {t('Create new package')}
                  </CustomButton>
                </Stack>
              )}
            </Col>
            <Col xs={3} className="card-btn-container header-user justify-content-end">
              <HeaderDate orange />
            </Col>
          </Row>
          <Row>{tableSelected()}</Row>
        </MainViewContainer>
        {createResponseModal && (
          <ResponseModal
            error={error || !response}
            message={!error ? t('Package created successfully!') : error}
            onClose={() => {
              setCreateResponseModal(false);
              setIsSubmitted(false);
              setTimeout(() => dispatch(clearPackageSetup()));
            }}
          />
        )}
        {packageModal && (
          <PackageModal
            handleClose={() => setPackageModal(undefined)}
            open={!!packageModal}
            popupTitle={packageModal}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            parking={selectedParking}
          />
        )}
      </div>
    </PackageContext.Provider>
  );
};

export default PackagesHome;
