import {Navigate, Outlet} from 'react-router-dom';

import {CURRENT_SESSION_PATH} from './paths';
import {FC} from 'react';
import {RouteProps} from 'routes';

const NoShiftRoutes: FC<RouteProps> = ({shifts}) => {
  return shifts?.length === 0 ? <Outlet /> : <Navigate to={CURRENT_SESSION_PATH} />;
};

export default NoShiftRoutes;
