import {Dispatch, FC, SetStateAction} from 'react';
import './styles.css';
import {Page} from '..';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducers';

interface Props {
  currentPage: Page;
  setCurrentPage: Dispatch<SetStateAction<Page>>;
}

const EmployeeWalletTitle: FC<Props> = ({currentPage, setCurrentPage}) => {
  const {t} = useTranslation();
  const {currentLanguage} = useSelector((state: RootState) => state.changeLanguageReducer);
  const isArabic = currentLanguage === 'ar';

  return (
    <div id="employee-wallet-title">
      <div
        dir="ltr"
        onClick={() => setCurrentPage('transaction history')}
        className={`table-header cursor-pointer ${
          currentPage === 'wallet adjustment' ? 'bg-black' : '!bg-[var(--main-color)]'
        }`}>
        <span
          onClick={e => {
            e.stopPropagation();
            setCurrentPage('wallet adjustment');
          }}
          className="absolute z-10 left-10 top-[25%] cursor-pointer">
          {t('Wallet adjustment')}
        </span>
        <span
          className={`absolute ${
            isArabic
              ? 'sm:right-[29%] md:right-[34%] lg:right-[39%] xl:right-[44%]'
              : 'sm:right-[20%] md:right-[25%] lg:right-[30%] xl:right-[35%]'
          } top-[25%] cursor-pointer`}>
          {t('Transaction history')}
        </span>
        <div
          onClick={e => {
            e.stopPropagation();
            setCurrentPage('wallet adjustment');
          }}
          className={`wallet-adjustment cursor-pointer ${
            currentPage === 'wallet adjustment' ? 'bg-[var(--main-color)]' : 'bg-black'
          }`}
        />
      </div>
    </div>
  );
};

export default EmployeeWalletTitle;
