import {
  SET_EMPLOYEE_ROW,
  UPDATE_EMPLOYEE,
  UPDATE_EMPLOYEE_FAILURE,
  UPDATE_EMPLOYEE_LOADING,
  CLEAR_EMPLOYEE_SETUP,
  CREATE_EMPLOYEE_FAILURE,
  CREATE_EMPLOYEE_LOADING,
  CREATE_EMPLOYEE,
} from 'redux/types';
const initialState = {
  selectedRowValue: null,
  loading: false,
  newEmployee: null,
  errors: null,
};

export const employeeReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_EMPLOYEE_ROW:
      return {
        ...state,
        selectedRowValue: action.payload.selectedRowValue,
      };
    case CREATE_EMPLOYEE_LOADING:
      return {...state, loading: action.loading};
    case CREATE_EMPLOYEE:
      return {...state, newEmployee: action.payload};
    case CREATE_EMPLOYEE_FAILURE:
      return {...state, errors: action.payload.response.data.statusMessage};
    case UPDATE_EMPLOYEE_LOADING:
      return {...state, loading: action.loading};
    case UPDATE_EMPLOYEE:
      return {...state, newEmployee: action.payload, selectedRowValue: null};
    case UPDATE_EMPLOYEE_FAILURE:
      return {...state, errors: action.payload.response.data.statusMessage};
    case CLEAR_EMPLOYEE_SETUP:
      return {...state, newEmployee: null, errors: null, loading: false};
    default:
      return state;
  }
};
