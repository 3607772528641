import CalendarPicker from './CalendarPicker/CalendarPicker';
import DropdownSearch from './DropdownSearch/DropdownSearch';
import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import {ReportTable} from 'model/Reports/Report';
import {useEffect, useRef, useState} from 'react';
import {excel} from 'assets/icons';
import {useDownloadExcel} from 'react-export-table-to-excel';
import ReportsTable from './ReportsTable/ReportsTable';
import {
  getInvoices,
  getReceipts,
  getRevenues,
  getTransactions,
  getUtilities,
} from 'redux/actions/reportsAction';
import {getParking} from 'redux/actions/parkingAction';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'redux/rootReducers';
import {ThunkDispatchType} from 'redux/types';
import {filterByProperty} from 'utils/reports/filterByProperty';
import {Invoice} from 'model/Reports/Invoice';
import {Transaction} from 'model/Reports/Transaction';
import {Revenue} from 'model/Reports/Revenue';
import {getReportFilters} from 'utils/reports/getReportFilters';
import {toggleButtons} from 'constants/reports/toggleButtons';
import styles from './index.module.css';
import {useTranslation} from 'react-i18next';
import {useOutsideClick} from 'hooks/useOutsideClick';
import MonthPicker from './MonthPicker/MonthPicker';

const Reports = () => {
  const {t} = useTranslation();

  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [dropdownValue, setDropdownValue] = useState<string>(t('Invoice number')!);
  const [selectedTable, setSelectedTable] = useState<ReportTable>('invoice');
  const [showDownloadOptions, setShowDownloadOptions] = useState<boolean>(false);
  const [datePickerUpdated, setDatePickerUpdated] = useState<boolean>(false);
  const [invoiceModalOpen, setInvoiceModalOpen] = useState<boolean>(false);
  const [receiptModalOpen, setReceiptModalOpen] = useState<boolean>(false);
  const {
    invoices,
    receipts,
    invoiceLoading,
    receiptLoading,
    transactionLoading,
    transactions,
    utilites,
    revenues,
    invoiceCurrentPage,
    receiptCurrentPage,
    transactionCurrentPage,
  } = useSelector((state: RootState) => state.reportsReducer);
  const {user} = useSelector((state: RootState) => state.authReducer);
  const dispatch: ThunkDispatchType = useDispatch();

  const isLoading = invoiceLoading || receiptLoading || transactionLoading;

  const tableRef = useRef<HTMLTableElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const {onDownload} = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: selectedTable,
    sheet: selectedTable,
  });

  const downloadOptionsRef = useRef<HTMLDivElement>(null);

  useOutsideClick(downloadOptionsRef, () => setShowDownloadOptions(false));

  useEffect(() => {
    dispatch(getInvoices(invoiceCurrentPage));
    dispatch(getParking(user.parkingId));
  }, []);

  const filterInvoices = () => {
    switch (dropdownValue) {
      case t('Invoice number'):
        return filterByProperty<Invoice>('invoiceRef', invoices, searchValue!);
      case t('Contact name'):
        return filterByProperty<Invoice>('name', invoices, searchValue!);
      case t('Phone number'):
        return filterByProperty<Invoice>('phone', invoices, searchValue!);
      case t('Car plate'):
        return filterByProperty<Invoice>('carNumber', invoices, searchValue!);
      default:
        return [];
    }
  };

  const filterReceipts = () => {
    switch (dropdownValue) {
      case t('Receipt number'):
        return filterByProperty<Invoice>('receiptRef', receipts, searchValue!);
      case t('Contact name'):
        return filterByProperty<Invoice>('name', receipts, searchValue!);
      case t('Phone number'):
        return filterByProperty<Invoice>('phone', receipts, searchValue!);
      case t('Car plate'):
        return filterByProperty<Invoice>('carNumber', receipts, searchValue!);
      default:
        return [];
    }
  };

  const filterTransactions = () => {
    switch (dropdownValue) {
      case t('Contact name'):
        return filterByProperty<Transaction>('contactName', transactions, searchValue!);
      case t('Package name'):
        return filterByProperty<Transaction>('packageName', transactions, searchValue!);
      case t('Phone number'):
        return filterByProperty<Transaction>('phone', transactions, searchValue!);
      case t('Car plate'):
        return filterByProperty<Transaction>('carNumber', transactions, searchValue!);
      case t('Status'):
        return filterByProperty<Transaction>('status', transactions, searchValue!);
      default:
        return [];
    }
  };

  const filterRevenues = () => {
    switch (dropdownValue) {
      case t('Month'):
        return filterByProperty<Revenue>('month', revenues, searchValue!);
      case t('Package name'):
        return filterByProperty<Revenue>('packageName', revenues, searchValue!);
      case t('Total amount'):
        return filterByProperty<Revenue>('revenue', revenues, searchValue!);
      default:
        return [];
    }
  };

  const handleToggle = (type: ReportTable) => {
    const reportFilters = getReportFilters(type);
    setSelectedTable(type);
    setSearchValue(null);
    setDropdownValue(reportFilters[0]);
    setDatePickerUpdated(false);

    switch (type) {
      case 'invoice':
        dispatch(getInvoices(invoiceCurrentPage));
        break;
      case 'receipt':
        dispatch(getReceipts(receiptCurrentPage));
        break;
      case 'transaction':
        dispatch(getTransactions(transactionCurrentPage));
        break;
      case 'utilization':
        dispatch(getUtilities(user.parkingId));
        break;
      case 'revenue':
        dispatch(getRevenues(user.parkingId));
        break;
    }
  };

  const renderToggleButtons = () => {
    return (
      <div className="grid grid-cols-5 !gap-4">
        {toggleButtons.map(({name, type}) => {
          return (
            <div
              key={type}
              className={`${styles.toggleButton} ${selectedTable === type && styles.selected}`}
              onClick={() => handleToggle(type)}>
              {t(name)}
            </div>
          );
        })}
      </div>
    );
  };

  const renderTable = () => {
    switch (selectedTable) {
      case 'invoice':
        return (
          <ReportsTable
            searchValue={searchValue!}
            tableContainerRef={tableContainerRef}
            tableRef={tableRef}
            invoiceModalOpen={invoiceModalOpen}
            setInvoiceModalOpen={setInvoiceModalOpen}
            type="invoice"
            headNames={[
              t('Invoice number'),
              t('Status'),
              t('Customer name'),
              t('Phone number'),
              t('Car plate'),
            ]}
            data={searchValue ? filterInvoices() : invoices}
          />
        );
      case 'receipt':
        return (
          <ReportsTable
            searchValue={searchValue!}
            tableContainerRef={tableContainerRef}
            tableRef={tableRef}
            receiptModalOpen={receiptModalOpen}
            setReceiptModalOpen={setReceiptModalOpen}
            type="receipt"
            headNames={[t('Receipt number'), t('Contact name'), t('Phone number'), t('Car plate')]}
            data={searchValue ? filterReceipts() : receipts}
          />
        );
      case 'transaction':
        return (
          <ReportsTable
            searchValue={searchValue!}
            tableContainerRef={tableContainerRef}
            tableRef={tableRef}
            type="transaction"
            headNames={[
              t('Car plate'),
              t('Contact name'),
              t('Package name'),
              t('Phone number'),
              t('Status'),
            ]}
            data={searchValue ? filterTransactions() : transactions}
          />
        );
      case 'utilization':
        return (
          <ReportsTable
            searchValue={searchValue!}
            tableContainerRef={tableContainerRef}
            tableRef={tableRef}
            type="utilization"
            headNames={[t('Year'), t('Month'), t('Count of cars')]}
            data={utilites}
          />
        );
      case 'revenue':
        return (
          <ReportsTable
            searchValue={searchValue!}
            tableContainerRef={tableContainerRef}
            tableRef={tableRef}
            type="revenue"
            headNames={[t('Year'), t('Month'), t('Package name'), t('Total amount')]}
            data={searchValue ? filterRevenues() : revenues}
          />
        );
    }
  };

  const handleExcel = () => {
    setShowDownloadOptions(!showDownloadOptions);
  };

  const handleDownloadAllPages = () => {
    switch (selectedTable) {
      case 'invoice':
        !datePickerUpdated && dispatch(getInvoices(1, undefined, true));
        break;
      case 'receipt':
        !datePickerUpdated && dispatch(getReceipts(1, undefined, true));
        break;
      case 'transaction':
        !datePickerUpdated && dispatch(getTransactions(1, undefined, true));
    }
  };

  const handleDownloadSinglePage = () => {
    switch (selectedTable) {
      case 'invoice':
        !datePickerUpdated && dispatch(getInvoices(invoiceCurrentPage));
        break;
      case 'receipt':
        !datePickerUpdated && dispatch(getReceipts(receiptCurrentPage));
        break;
      case 'transaction':
        !datePickerUpdated && dispatch(getTransactions(transactionCurrentPage));
    }
  };
  return (
    <div id="Reports" className="overflow-hidden">
      <MainViewContainer>
        <div className="flex justify-end">
          <HeaderDate orange />
        </div>
        <div className="mt-20 mb-4 relative flex justify-between items-center">
          {selectedTable !== 'utilization' ? (
            <DropdownSearch
              dropdownValue={dropdownValue}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              selectedTable={selectedTable}
              setDropdownValue={setDropdownValue}
            />
          ) : (
            <div className="h-16" />
          )}
          <div className="flex gap-3 relative" ref={downloadOptionsRef}>
            <div
              className="bg-black h-16 w-16 flex items-center justify-center rounded-xl cursor-pointer"
              onClick={() =>
                selectedTable !== 'utilization' && selectedTable !== 'revenue'
                  ? handleExcel()
                  : onDownload()
              }>
              <img src={excel} alt="excel" />
            </div>

            {showDownloadOptions && (
              <ul className="download-options absolute top-20 z-10 bg-white border rounded-2xl">
                <li
                  className={`px-3 py-4 ${!isLoading && 'cursor-pointer'}`}
                  onClick={() => {
                    onDownload();
                  }}>
                  Download current page
                </li>
                <hr />
                <li
                  className={`px-3 py-4 ${!isLoading && 'cursor-pointer'}`}
                  onClick={() => {
                    !isLoading && onDownload();
                    handleDownloadSinglePage();
                  }}
                  onMouseEnter={handleDownloadAllPages}
                  onMouseLeave={handleDownloadSinglePage}>
                  Download all pages
                </li>
              </ul>
            )}
            {selectedTable === 'revenue' || selectedTable === 'utilization' ? (
              <MonthPicker
                type={selectedTable}
                datePickerUpdated={datePickerUpdated}
                setDatePickerUpdated={setDatePickerUpdated}
              />
            ) : (
              <CalendarPicker
                type={selectedTable}
                datePickerUpdated={datePickerUpdated}
                setDatePickerUpdated={setDatePickerUpdated}
              />
            )}
          </div>
        </div>
        <>
          {renderToggleButtons()}
          {renderTable()}
        </>
      </MainViewContainer>
    </div>
  );
};

export default Reports;
