import * as Yup from 'yup';
import {Box, Stack, TextField} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {FC} from 'react';
import {LIGHT_GREY} from 'constants/colors';
import Popup from 'components/Popup/Popup';
import ReactDatePicker from 'react-datepicker';
import {RootState} from 'redux/rootReducers';
import {SetParkingAsFull as SetParkingAsFullType} from 'model/setParkingAsFull';
import moment from 'moment';
import {setParkingAsFull} from 'redux/actions/setParkingAsFullAction';
import {useFormik} from 'formik';
import {useOnline} from 'hooks/useOnline';
import {useTranslation} from 'react-i18next';
import './styles.css';

interface Props {
  handleClose: VoidFunction;
  handleResponseOpen: VoidFunction;
  open: boolean;
  selectedParking: SetParkingAsFullType;
}

const SetParkingAsFull: FC<Props> = ({handleClose, handleResponseOpen, open, selectedParking}) => {
  console.log('selectedParking', selectedParking);
  const dispatch: any = useDispatch();
  const {loading} = useSelector((state: RootState) => state.setParkingAsFullReducer);
  const {isOnline} = useOnline();
  const {t} = useTranslation();

  const formik: any = useFormik({
    initialValues: {
      startDate: moment().format('MMM D, YYYY'),
      startTime: moment().format('h:mm A'),
      endDate: moment().format('MMM D, YYYY'),
      endTime: moment().format('h:mm A'),
      reason: selectedParking?.isFullReason ?? '',
    },
    validationSchema: Yup.object().shape({
      reason: Yup.string().required('Required'),
      endDate: Yup.string().test(
        'end-date-less-than-start-time',
        'End date cannot precede the start date',
        (value, {parent}) => {
          return (
            moment(value).isAfter(moment(parent.startDate)) ||
            moment(value).isSame(moment(parent.startDate))
          );
        },
      ),
      endTime: Yup.string()
        .test(
          'end-time-equals-start-time',
          'End time and start time must be different',
          (value, {parent}) => {
            if (parent.startDate === parent.endDate) return value !== parent.startTime;
            return true;
          },
        )
        .test(
          'end-time-greater-than-start-time',
          'End time must follow the Start time',
          (value, {parent}) => {
            if (parent.startDate === parent.endDate)
              return moment(value, 'hh:mm A').isAfter(moment(parent.startTime, 'hh:mm A'));
            return true;
          },
        ),
    }),
    onSubmit: () => {
      const startdDate = formik.values.startDate + ' ' + formik.values.startTime;
      const endDate = formik.values.endDate + ' ' + formik.values.endTime;
      dispatch(
        setParkingAsFull({
          id: selectedParking.id,
          gates: selectedParking.gates,
          isFullReason: formik.values.reason,
          fullFrom: moment(startdDate, 'MMM D, YYYY HH:mm A').utc().local(),
          fullTo: moment(endDate, 'MMM D, YYYY HH:mm A').utc().local(),
        }),
      );
      handleClose();
      handleResponseOpen();
    },
  });

  const filterDate = (date: Date, dateSelected: string) => {
    const selectedDate = dateSelected;
    if (!selectedDate) {
      return true;
    }
    const selectedMonth = moment(selectedDate).toDate().getMonth();
    const selectedYear = moment(selectedDate).toDate().getFullYear();
    const currentMonth = date.getMonth();
    const currentYear = date.getFullYear();
    return selectedMonth === currentMonth && selectedYear === currentYear;
  };

  const updateDate = (date: Date, field: string) => {
    formik.setFieldValue(field, moment(date).format('MMM D, YYYY'));
  };

  const minTime = moment(formik.values.startDate).isSame(moment(), 'day')
    ? new Date()
    : moment().startOf('day').toDate();

  return (
    <Popup handleCloseModal={handleClose} open={open} popupTitle={`${t('Parking availability')}`}>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        id="set-parking-full"
        sx={{px: 5, py: 3, overflowY: 'auto', height: '100%'}}>
        <Stack direction="row">
          <Box width="75%">
            <label htmlFor="start-date">{t('Start date')}</label>
            <ReactDatePicker
              className="date-picker"
              value={formik.values.startDate}
              selected={moment(formik.values.startDate).toDate()}
              filterDate={date => filterDate(date, formik.values.startDate)}
              onMonthChange={date => updateDate(date, 'startDate')}
              onChange={date => updateDate(date!, 'startDate')}
              minDate={new Date()}
            />
          </Box>
          <Box className="time-field">
            <label htmlFor="start-time">{t('Start time')}</label>
            <ReactDatePicker
              className="date-picker"
              value={formik.values.startTime.toString()}
              onChange={date => {
                formik.setFieldValue('startTime', moment(date).format('h:mm A'));
              }}
              showTimeSelectOnly
              showTimeSelect
              minTime={minTime}
              maxTime={moment().endOf('day').toDate()}
            />
          </Box>
        </Stack>
        <Stack direction="row" sx={{my: 2}}>
          <Box width="75%">
            <label htmlFor="end-date">{t('End date')}</label>
            <ReactDatePicker
              className="date-picker"
              value={formik.values.endDate}
              selected={moment(formik.values.endDate).toDate()}
              filterDate={date => filterDate(date, formik.values.endDate)}
              onMonthChange={date => updateDate(date, 'endDate')}
              onChange={date => updateDate(date!, 'endDate')}
              minDate={new Date()}
            />
            {formik.errors.endDate && <p className="error">{formik.errors.endDate}</p>}
          </Box>
          <Box className="time-field">
            <label htmlFor="end-time">{t('End time')}</label>
            <ReactDatePicker
              className="date-picker"
              value={formik.values.endTime}
              onChange={date => {
                formik.setFieldValue('endTime', moment(date).format('h:mm A'));
              }}
              showTimeSelectOnly
              showTimeSelect
            />
            {formik.errors.endTime && <p className="error">{formik.errors.endTime}</p>}
          </Box>
        </Stack>
        <Box>
          <label htmlFor="reason">{t('Reason')}</label>
          <TextField
            placeholder={`${t('Start typing, for example ( Cleaning day - Fixing day - etc)...')}`}
            value={formik.values.reason}
            name="reason"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            multiline
            sx={{
              background: LIGHT_GREY,
              borderRadius: 4,
              width: '100%',
              height: '15vh',
            }}
          />
          {formik.errors.reason && <p className="error">{formik.errors.reason}</p>}
        </Box>
        <Box width="100%" textAlign="center" mt="4vh">
          {!isOnline && <p className="red">Check your internet connection!</p>}
          <CustomLoader loading={loading} size={40} center>
            <CustomButton
              disabled={!formik.isValid || !isOnline}
              width="20vw"
              type="submit"
              color="Orange">
              {t('Mark as unavailable')}
            </CustomButton>
          </CustomLoader>
        </Box>
      </Box>
    </Popup>
  );
};

export default SetParkingAsFull;
