import api from 'api';
import {getUsersEndPoint} from 'api/apisURL';

interface Filters {
  active?: boolean;
}

export const getGateEmployeesService = async (filters?: Filters) => {
  const payload = {
    pageSize: 100000,
    pageNumber: 1,
    filters: [
      {
        fieldName: 'isGateEmployee',
        operator: 'like',
        values: ['true'],
      },
    ],
  };

  if (filters?.active) {
    payload.filters.push({
      fieldName: 'status',
      operator: 'eq',
      values: ['ACTIVE'],
    });
  }

  const {data} = await api.post(getUsersEndPoint(), payload);
  return data;
};
