import './styles.css';

import {FC, useEffect} from 'react';
import {arrowLeft, arrowRight, whiteArrowRight} from 'assets/icons';
import {useDispatch, useSelector} from 'react-redux';

import {Col} from 'react-bootstrap';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import {ParkingSetup} from 'model/parkingSetup';
import {RootState} from 'redux/rootReducers';
import {getParkingSetup} from 'redux/actions/parkingSetupAction';
import { useTranslation } from 'react-i18next';

interface props {
  choosenId: string;
  setChoosenId: React.Dispatch<React.SetStateAction<string | null>>;
  setTable: React.Dispatch<React.SetStateAction<any>>;
}

const MultipleParkingTable: FC<props> = ({choosenId, setChoosenId, setTable}) => {
  const dispatch: any = useDispatch();
  const {t} = useTranslation();
  const { currentLanguage } = useSelector((state: RootState) => state.changeLanguageReducer);


  const {parkingsSetup, loading, totalPages, currentPage} = useSelector(
    (state: RootState) => state.parkingSetupReducer,
  );
  useEffect(() => {
    dispatch(getParkingSetup(currentPage));
  }, []);

  const selectdParking = parkingsSetup.filter((parking: {id: string}) => {
    return parking.id === choosenId;
  });

  return (
    <div id="multiple-parkings-table">
      <Col md={12} lg={7}>
        <table className="first-table custom-table-container">
          <thead className="head-container">
            <tr className={`custom-table-head ${currentLanguage==='en'?'rounded-tl-14 rounded-tr-o':'rounded-tl-0 rounded-tr-14'}` } >
              <th></th>
              <th>
                <CustomIcon
                  href={arrowLeft}
                  height={19}
                  className={`cursor-pointer ${currentLanguage==='en'?'':'rotate-180'}`}
                  onClick={() => {
                    setTable('');
                  }}
                />
              </th>
              <th>{t("Organization name")}</th>
              <th>{t("City")}</th>
              <th>{t("Emergency count")}</th>
              <th>{t("Parking name")}</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody className="custom-table-body">
            <CustomLoader size={80} loading={loading}>
              {parkingsSetup.map((parking: ParkingSetup) => {
                return (
                  <tr
                    key={parking.id}
                    onClick={() => {
                      setChoosenId(parking.id);
                    }}
                    className="cursor-pointer">
                    <td></td>
                    <td className={choosenId === parking.id ? 'selected-row' : ''}></td>
                    <td className={choosenId === parking.id ? 'selected-row' : ''}>
                      {parking.organizationName ?? '--'}
                    </td>
                    <td className={choosenId === parking.id ? 'selected-row' : ''}>
                      {parking.city ?? '--'}
                    </td>
                    <td className={choosenId === parking.id ? 'selected-row' : ''}>
                      {parking.emergency_count ?? '--'}
                    </td>
                    <td className={choosenId === parking.id ? 'selected-row' : ''}>
                      {parking.name ?? '--'}
                    </td>
                    <td
                      className={`multiTableRow ${choosenId === parking.id ? 'selected-row' : ''} ${currentLanguage==='en'?'rounded-tl-14 rounded-tr-o':'rounded-tl-0 rounded-tr-14'}`}>
                      <CustomIcon                   className={`cursor-pointer ${currentLanguage==='en'?'':'rotate-180'}`}
 href={choosenId === parking.id ? whiteArrowRight : arrowRight} />
                    </td>
                    <td></td>
                  </tr>
                );
              })}
            </CustomLoader>
          </tbody>
          <CustomTablePagination
            totalPages={totalPages}
            currentPage={currentPage}
            dispatchFunc={getParkingSetup}
          />
        </table>
      </Col>
      <Col md={12} lg={5}>
        <table className="custom-table-container second-table">
          <thead className="head-container border-none">
            <tr className={`custom-table-head ${currentLanguage==='en'?'rounded-tl-0 rounded-tr-14':'rounded-tl-lg'}` }  >
              <th></th>
              <th></th>
              <th>{t("Gate data")}</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody className="custom-table-body">
            <tr>
              <td></td>
              <td></td>
              <td>{t("Gate name")}</td>
              <td>{t("Gate availability")}</td>
              <td>{t("Gate street name")}</td>
              <td></td>
              <td></td>
            </tr>
            <CustomLoader size={80} loading={loading}>
              {selectdParking[0]?.gates?.map((gate: any) => {
                return (
                  <tr key={gate?.id}>
                    <td></td>
                    <td></td>
                    <td>{gate?.gateName}</td>
                    <td>{gate?.isActive ? 'Available' : 'Unavailable'}</td>
                    <td>{gate?.streetData}</td>
                    <td></td>
                    <td></td>
                  </tr>
                );
              })}
            </CustomLoader>
          </tbody>
        </table>
      </Col>
    </div>
  );
};

export default MultipleParkingTable;
