import './styles.css';

import * as Yup from 'yup';

import {CURRENT_SESSION_PATH, FORGET_PATH, HOME_PATH} from 'routes/paths';
import {Link, useNavigate} from 'react-router-dom';
import {login, resetAuth, resetError} from 'redux/actions/authAction';
import {useDispatch, useSelector} from 'react-redux';

import AuthConatiner from 'components/AuthConatiner/AuthConatiner';
import AuthHeading from 'components/AuthHeading/AuthHeading';
import CustomButton from 'components/CustomButton/CustomButton';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {FormValues} from 'model/formValues';
import {RootState} from 'redux/rootReducers';
import {ThunkDispatchType} from 'redux/types';
import {getShiftAction} from 'redux/actions/getShiftAction';
import store from 'redux/store';
import {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {setUser} from 'redux/actions/usersAction';

const Login = () => {
  const currentLanguageCodeCookie = Cookies.get('i18next') === 'ar' ? 'ar' : 'en';
  const {t} = useTranslation();
  const navigate = useNavigate();
  const dispatch: ThunkDispatchType = useDispatch();
  const {user, loading, error} = useSelector((state: RootState) => state.authReducer);
  const [responseModalIsOpen, setResponseModalIsOpen] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      phone: '',
      password: '',
    },
    validationSchema: Yup.object({
      phone: Yup.string()
        .matches(/^[0-9]+$/, 'Phone number is not valid')
        .max(9)
        .required('Required')
        .trim(),
      password: Yup.string().min(4, t('Short password')!).required(t('Required')!),
    }),
    onSubmit: (values: FormValues) => {
      store.dispatch(
        login({
          phone: 966 + values.phone,
          password: values.password,
          onError: () => setResponseModalIsOpen(true),
        }),
      );
    },
  });

  const disableButton = !(formik.values && formik.isValid);

  const handleUser = () => {
    if (user.isGateEmployee) {
      dispatch(
        getShiftAction(user.parkingId, user.id, undefined, undefined, () => {
          navigate(CURRENT_SESSION_PATH);
        }),
      );
    } else {
      dispatch(setUser(user));
      navigate(HOME_PATH);
    }
  };

  useEffect(() => {
    dispatch(resetAuth());
  }, []);

  useEffect(() => {
    if (user) {
      handleUser();
    }
  }, [user]);

  return (
    <AuthConatiner>
      <div className="custom-container">
        <div className="custom-content">
          <AuthHeading />
          <form className="form" onSubmit={formik.handleSubmit}>
            <div className="containPhoneCode">
              <span>+966</span>
              <CustomInput
                value={formik.values.phone}
                onChange={e => {
                  formik.setFieldValue('phone', e.target.value.trim());
                }}
                onBlur={formik.handleBlur}
                type="text"
                name="phone"
                placeHolder={t('Phone number')}
                error={!!formik.errors.phone}
              />
            </div>
            <p className="error-message">
              {formik.errors.phone && formik.touched.phone && formik.errors.phone}
            </p>
            <CustomInput
              margin="10px 0 0 0"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="password"
              name="password"
              placeHolder={t('Password')}
              _direction={`${currentLanguageCodeCookie === 'en' ? 'right' : 'left'}`}
              error={
                !!(formik.errors.password && formik.touched.password && formik.errors.password)
              }
            />
            <div className="forget-pass-container">
              <span className="forget-pass">
                <Link to={FORGET_PATH}>{t('Forget password')}</Link>
              </span>
            </div>
            <p className="error-message">
              {formik.errors.password && formik.touched.password && formik.errors.password}
            </p>
            <CustomLoader size={40} loading={loading}>
              <CustomButton
                type="submit"
                margin="50px"
                color="Orange"
                width="80%"
                px="151px"
                height="56px"
                disabled={disableButton}>
                {t('Login')}
              </CustomButton>
            </CustomLoader>
          </form>
        </div>
      </div>
      {responseModalIsOpen && (
        <ResponseModal
          error
          message={error}
          onClose={() => {
            setResponseModalIsOpen(false);
            dispatch(resetError());
          }}
        />
      )}
    </AuthConatiner>
  );
};

export default Login;
