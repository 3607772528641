import {
  GET_PACKAGE_CATEGORY,
  GET_PACKAGE_CATEGORY_LOADING,
  GET_PACKAGE_CATEGORY_FAILED,
  ThunkDispatchType,
} from 'redux/types';
import {getPackageCategoriesService} from 'services/package/PackageCategory';

export const getPackageCategoriesAction =
  (page?: number) => async (dispatch: ThunkDispatchType) => {
    try {
      dispatch({type: GET_PACKAGE_CATEGORY_LOADING, payload: true});
      const response = await getPackageCategoriesService(page);
      dispatch({type: GET_PACKAGE_CATEGORY, payload: response.data[0] });
    } catch (error) {
      dispatch({type: GET_PACKAGE_CATEGORY_FAILED, payload: error});
    } finally {
      dispatch({type: GET_PACKAGE_CATEGORY_LOADING, payload: false});
    }
  };
