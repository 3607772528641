import CustomButton from 'components/CustomButton/CustomButton';
import {FC} from 'react';
import Popup from 'components/Popup/Popup';
import {RootState} from 'redux/rootReducers';
import moment from 'moment';
import styles from './ReceiptModal.module.css';
import {usePdfGenerator} from 'hooks/usePdfGenerator';
import {useSelector} from 'react-redux';
interface Props {
  receiptNumber: number | undefined;
  handleClose: () => void;
  isOpen: boolean;
  checkedSessionRow: any;
  receiptObject?: any;
}

const ReceiptModal: FC<Props> = ({
  receiptNumber,
  handleClose,
  isOpen,
  checkedSessionRow,
  receiptObject,
}) => {
  const {amount} = useSelector((state: RootState) => state.invoiceReducer);
  const {pdfRef, generatePDF} = usePdfGenerator('Receipt');
  const {parkings} = useSelector((state: RootState) => state.parkingReducer);

  const checkIn = moment(checkedSessionRow?.checkInDate);

  function calculateDuration(start: string, end: string): string {
    const startDateTime = new Date(start);
    const endDateTime = new Date(end);

    const durationMilliseconds = endDateTime.getTime() - startDateTime.getTime();
    const minutes = Math.floor(durationMilliseconds / (1000 * 60)) % 60;
    const hours = Math.floor(durationMilliseconds / (1000 * 60 * 60)) % 24;
    const days = Math.floor(durationMilliseconds / (1000 * 60 * 60 * 24));
    // const months =
    //   endDateTime.getMonth() -
    //   startDateTime.getMonth() +
    //   12 * (endDateTime.getFullYear() - startDateTime.getFullYear());

    // return `${months} months, ${days} days, ${hours} hours, ${minutes} minutes`;
    return `${days} days, ${hours} hours, ${minutes} minutes`;
  }

  return (
    <Popup handleCloseModal={handleClose} open={isOpen} popupTitle="Receipt details">
      <div
        ref={pdfRef}
        className="w-4/6 flex flex-col items-center px-4 !py-3 mx-auto my-[3vh] !border border-solid border-[var(--disabled-grey)] rounded-xl">
        <p className="w-full text-center font-bold ml-10 mb-2">
          {`#${receiptNumber} ${parkings?.length > 0 ? parkings[0]?.name : ''}`}
        </p>
        <hr className="w-full text-[var(--disabled-grey)] !border border-solid" />
        <div className="text-sm flex justify-between w-full !p-3">
          <ul className={`whitespace-nowrap font-bold ${styles.listTitles}`}>
            <li>Contact name</li>
            <li>Phone number</li>
            <li>Duration</li>
            <li>Payment method</li>
            <li>Check in date - time</li>
            <li>Check out date - time</li>
          </ul>
          <ul className={`whitespace-nowrap ${styles.listValues}`}>
            <li>{checkedSessionRow?.contactName ?? '--'}</li>
            <li>{checkedSessionRow?.phone ?? '--'}</li>
            <li>
              {calculateDuration(
                checkIn.format('YYYY-MM-DD HH:mm:ss'),
                receiptObject.checkOutDateTime,
              )}
            </li>
            <li>Cash</li>
            <li>{checkIn.isValid() ? checkIn.format('YYYY-MM-DD HH:mm:ss') : '--'}</li>
            <li>{receiptObject.checkOutDateTime}</li>
          </ul>
        </div>
        <hr className="w-full text-[var(--disabled-grey)] border-2 border-dashed" />
        <div className="text-sm flex justify-between w-full !px-3 py-2">
          <ul className={`whitespace-nowrap font-bold ${styles.listTitles}`}>
            <li>Due amount</li>
            <li>Received</li>
            {/* <li>Exchange</li> */}
            <li>Served by</li>
          </ul>
          <ul className={`whitespace-nowrap ${styles.listValues}`}>
            <li className="font-bold">{amount?.invoiceMount} SR</li>
            <li>{receiptObject.receivedAmount} SR</li>
            {/* <li>{receiptObject.receivedAmount - amount?.invoiceMount} SR</li> */}
            <li>Gate employee</li>
          </ul>
        </div>
        <p className="text-sm my-2 italic font-bold">Thank you for using our park</p>
      </div>
      <div className="w-full flex justify-center">
        <CustomButton
          className="w-1/3"
          color="Orange"
          onClick={() => {
            generatePDF();
          }}>
          Print
        </CustomButton>
      </div>
    </Popup>
  );
};
export default ReceiptModal;
