import {
  GET_FILTERED_ROW,
  GET_FILTERED_ROW_FAILURE,
  GET_POWEROFF_SESSIONS,
  GET_SESSIONS,
  GET_SESSIONS_FAILURE,
  GET_SESSIONS_LOADING,
} from 'redux/types';

import {Dispatch} from 'redux';
import {getCurrentSessions} from 'services/reservation/getcurrentSessions';
import {getPowerOffSessions} from 'services/reservation/getPowerOffSessions';

export const getSessionsAction =
  (page?: number, parkingId?: string, status?: string[]) => async (dispatch: Dispatch) => {
    try {
      dispatch({type: GET_SESSIONS_LOADING, payload: true});
      const response = await getCurrentSessions(page, parkingId, undefined, status);
      dispatch({type: GET_SESSIONS, payload: response.data});
    } catch (error) {
      dispatch({type: GET_SESSIONS_FAILURE, error});
    } finally {
      dispatch({type: GET_SESSIONS_LOADING, payload: false});
    }
  };

export const getPowerOffSessionsAction = (page: number) => async (dispatch: Dispatch) => {
  try {
    dispatch({type: GET_SESSIONS_LOADING, payload: true});
    await getPowerOffSessions(page).then((response: any) => {
      dispatch({type: GET_POWEROFF_SESSIONS, payload: response.data[0]});
    });
  } catch (error) {
    dispatch({type: GET_SESSIONS_FAILURE, error});
  } finally {
    dispatch({type: GET_SESSIONS_LOADING, payload: false});
  }
};

export const getFilteredReservationsRow =
  (otp: string, status?: string[], page?: number) => async (dispatch: Dispatch) => {
    const response = await getCurrentSessions(page, undefined, otp, status);
    if (response.data[0].reservations.length === 0 && otp.length > 0) {
      dispatch({
        type: GET_FILTERED_ROW_FAILURE,
        error: 'There is no pre-booking reservation for this OTP number',
      });
    } else {
      dispatch({type: GET_FILTERED_ROW, payload: response.data[0].reservations[0], error: ''});
    }
  };
