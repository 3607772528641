import './styles.css';

import {TableBody, TableCell, TableRow} from '@mui/material';
import {useDispatch, useSelector} from 'react-redux';

import CustomCheckbox from 'components/CustomCheckBox/CustomCheckBox';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {FC} from 'react';
import InfromingTextBox from 'components/InfromingTextBox/InfromingTextBox';
import {ParkingSetup} from 'model/parkingSetup';
import {RootState} from 'redux/rootReducers';
import {arrowRight} from 'assets/icons';
import moment from 'moment';
import {parkingSetupRowSelected} from 'redux/actions/parkingSetupAction';

interface props {
  setChoosenId: React.Dispatch<React.SetStateAction<string | null>>;
  setTable: React.Dispatch<React.SetStateAction<string | null>>;
}

const ParkingSetupBody: FC<props> = ({setChoosenId, setTable}) => {
  const dispatch: any = useDispatch();
  const {parkingsSetup, loading, selectedRowValue} = useSelector(
    (state: RootState) => state.parkingSetupReducer,
  );
  const {currentLanguage} = useSelector((state: RootState) => state.changeLanguageReducer);

  const handelChecked = (parkingSetup: ParkingSetup) => {
    if (selectedRowValue?.id === parkingSetup?.id) {
      dispatch(parkingSetupRowSelected(null));
    } else {
      dispatch(parkingSetupRowSelected(parkingSetup));
    }
  };

  return (
    <TableBody sx={{height: parkingsSetup?.length > 3 ? 500 : 0}}>
      <CustomLoader size={80} loading={loading}>
        {parkingsSetup?.length === 0 || parkingsSetup === undefined ? (
          <TableRow className="empty-row">
            <TableCell colSpan={12} className="no-data">
              No Data
            </TableCell>
          </TableRow>
        ) : (
          parkingsSetup.map((parkingSetup: ParkingSetup) => {
            return (
              <TableRow
                key={parkingSetup.id}
                className={`${selectedRowValue?.id === parkingSetup?.id && 'selected-table-row'}`}>
                <TableCell></TableCell>
                <TableCell>{parkingSetup?.organizationName ?? '--'}</TableCell>
                <TableCell>{parkingSetup?.city ?? '--'}</TableCell>
                <TableCell>{parkingSetup?.emergency_count ?? '--'}</TableCell>
                <TableCell>
                  <InfromingTextBox text={`${parkingSetup.isFullReason}`}>
                    <p
                      className={`parking-date-cell ${
                        selectedRowValue?.id === parkingSetup?.id && 'hovered-date-time'
                      }`}>
                      {parkingSetup.fullFrom
                        ? `${moment.utc(parkingSetup.fullFrom).local().format('D MMM h:mm A')} to ${
                            parkingSetup.fullTo
                              ? moment.utc(parkingSetup.fullTo).local().format('D MMM h:mm A')
                              : ''
                          }`
                        : ''}
                    </p>
                  </InfromingTextBox>
                </TableCell>
                <TableCell>{parkingSetup?.name ?? '--'}</TableCell>
                <TableCell>{parkingSetup?.capacity ?? '--'}</TableCell>
                <TableCell>{parkingSetup?.gate_count ?? '--'}</TableCell>
                <TableCell>
                  <CustomCheckbox
                    className="regular"
                    checkClick={() => {
                      handelChecked(parkingSetup);
                    }}
                    isChecked={selectedRowValue === parkingSetup}
                  />
                </TableCell>
                <TableCell>
                  <CustomIcon
                    href={arrowRight}
                    className={`cursor-pointer ${currentLanguage === 'ar' && 'rotate-180'}`}
                    onClick={() => {
                      setChoosenId(parkingSetup.id);
                      setTable('expanded');
                    }}
                  />
                </TableCell>
                <TableCell />
              </TableRow>
            );
          })
        )}
      </CustomLoader>
    </TableBody>
  );
};

export default ParkingSetupBody;
