import './styles.css';

import {Pagination, TableCell, TableFooter} from '@mui/material';

import {FC} from 'react';
import {LIGHT_GREY} from 'constants/colors';
import {useDispatch} from 'react-redux';

interface props {
  isTable?: boolean;
  totalPages: number;
  currentPage: number;
  filters?: any;
  dispatchFunc?: any;
  onChange?: (currentPage: number) => void;
  bgColor?: string;
  currentSession?: boolean;
}

const CustomTablePagination: FC<props> = ({
  isTable = true,
  totalPages,
  currentPage,
  dispatchFunc,
  filters,
  onChange,
  bgColor = LIGHT_GREY,
  currentSession,
}) => {
  const dispatch: any = useDispatch();
  const handleChange = (event: any, value: number) => {
    onChange?.(value);
    if (!dispatchFunc) {
      return;
    }
    if (filters) {
      currentSession
        ? dispatch(dispatchFunc(value, ...filters))
        : dispatch(dispatchFunc(...filters, value));
    } else {
      dispatch(dispatchFunc(value));
    }
  };
  return (
    <>
      {isTable ? (
        <TableFooter
          id="pagination-row"
          sx={{position: 'sticky', bottom: '-1px', backgroundColor: bgColor}}>
          <TableCell colSpan={20}>
            <div dir="ltr">
              <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handleChange}
                className="flex justify-center  pagination-container"
              />
            </div>
          </TableCell>
        </TableFooter>
      ) : (
        <div id="pagination-row" className="no-table">
          <Pagination
            sx={{position: 'sticky', bottom: '-1px', pt: 4}}
            count={totalPages}
            page={currentPage}
            onChange={handleChange}
            className="flex justify-center pagination-container"
          />
        </div>
      )}
    </>
  );
};

export default CustomTablePagination;
