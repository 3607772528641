import CustomButton from 'components/CustomButton/CustomButton';
import {FC} from 'react';
import {Invoice} from 'model/Reports/Invoice';
import Popup from 'components/Popup/Popup';
import {RootState} from 'redux/rootReducers';
import moment from 'moment';
import {usePdfGenerator} from 'hooks/usePdfGenerator';
import {useSelector} from 'react-redux';
import {download, print} from 'assets/icons';
import {useTranslation} from 'react-i18next';
import './styles.css';
import DetailsQRCode from './DetailsQRCode/DetailsQRCode';

interface Props {
  handleClose: () => void;
  handleViewReceipt?: () => void;
  isOpen: boolean;
  reports?: boolean;
  invoiceData?: Invoice;
}

const InvoiceModal: FC<Props> = ({
  handleClose,
  handleViewReceipt,
  isOpen,
  reports,
  invoiceData,
}) => {
  const {t, i18n} = useTranslation();

  const {pdfRef, generatePDF} = usePdfGenerator('Invoice');
  const {parkings} = useSelector((state: RootState) => state.parkingReducer);

  const handlePrint = () => window.print();

  const checkIn = moment.utc(invoiceData?.checkInDate).local();
  const checkOut = moment.utc(invoiceData?.invoiceCreationDate).local();

  const getDuration = () => {
    if (checkIn.isValid() && checkOut.isValid()) {
      const duration = moment.duration(checkOut.diff(checkIn));

      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes() - hours * 60);
      const seconds = Math.floor(duration.asSeconds() - (hours * 60 * 60 + minutes * 60));

      const formattedDuration = `${hours}hrs ${minutes}min ${seconds}sec`;
      return formattedDuration;
    }
    return '--';
  };
  const statusName = (status?: string) => {
    if (status === 'UNINVOICED') {
      return 'Unpaid';
    } else return 'Paid';
  };
  return (
    <Popup handleCloseModal={handleClose} open={isOpen} popupTitle={t('Invoice Details')}>
      <div
        ref={pdfRef}
        className="print-container w-3/5 flex flex-col items-center px-3 !py-2 mx-auto my-[4vh] !border border-solid border-[var(--disabled-grey)] rounded-xl">
        <p className="w-full text-center font-bold ml-10 mb-2">
          {parkings?.[0].organizationName} - {parkings?.[0].name}
        </p>
        <hr className="w-full text-[var(--disabled-grey)] !border border-solid" />
        <div className="list-container text-sm flex justify-between w-full mt-[4%] !p-3">
          <ul className="whitespace-nowrap font-bold list-titles">
            <li>{t('Invoice number')}</li>
            <li>{t('Status')}</li>

            <li>{t('Contact name')}</li>
            <li>{t('Phone number')}</li>
            <li>{t('Duration')}</li>
            <li>{t('Check in date - time')}</li>
            <li>{t('Check out date - time')}</li>
            <li>{t('Due amount')}</li>
          </ul>
          <hr className="h-[30vh] text-[var(--disabled-grey)] !border border-solid mt-2" />
          <ul className="whitespace-nowrap list-values">
            <li>{invoiceData?.invoiceRef ?? '--'}</li>
            <li>{statusName(invoiceData?.status)}</li>

            <li>{invoiceData?.name ?? '--'}</li>
            <li>{invoiceData?.phone ? '+' + invoiceData.phone : '--'}</li>
            <li>{getDuration()}</li>
            <li>{checkIn.isValid() ? checkIn.format('DD MMM, YYYY - h:mm a') : '--'}</li>
            <li>{checkOut.isValid() ? checkOut.format('DD MMM, YYYY - h:mm a') : '--'}</li>
            <li>
              <div className="flex justify-between gap-2">
                <p>{invoiceData?.invoiceMount ?? '--'} SR</p>
                <DetailsQRCode invoiceData={invoiceData} />
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="invoice-button w-full flex flex-col items-center gap-3">
        {reports ? (
          <>
            <CustomButton
              className={`w-1/3 ${i18n.language === 'ar' && 'gap-2'}`}
              startIcon={<img src={print} alt="print" />}
              color="Orange"
              onClick={handlePrint}>
              {t('Print')}
            </CustomButton>
            <CustomButton
              startIcon={<img src={download} alt="download" />}
              className={`w-1/3 border !border-[var(--main-color)] bg-white !text-[var(--main-color)] ${
                i18n.language === 'ar' && 'gap-2'
              }`}
              color="Orange"
              onClick={() => {
                generatePDF();
              }}>
              {t('Download as a PDF')}
            </CustomButton>
          </>
        ) : (
          <CustomButton className="w-1/3" color="Orange" onClick={handleViewReceipt}>
            Pay now
          </CustomButton>
        )}
      </div>
    </Popup>
  );
};

export default InvoiceModal;
