import {Utility} from 'model/Reports/Utility';

export const getUtilityCells = (report: Utility) => {
  const {year, month, count} = report;

  return [
    year ?? '--',
    month ? month.charAt(0).toUpperCase() + month.slice(1).toLowerCase() : '--',
    count ?? '--',
  ];
};
