import './styles.css';

import {Box, Table, TableCell, TableContainer, TableHead, TableRow} from '@mui/material';

import CustomTablePagination from 'components/CustomTablePagination/CustomTablePagination';
import {FC} from 'react';
import PackageTableBody from './components/PackageTableBody';
import {Parking} from 'model/servicesModels';
import {RootState} from 'redux/rootReducers';
import {YELLOW} from 'constants/colors';
import {useSelector} from 'react-redux';
import {useTranslation} from 'react-i18next';

interface Props {
  parking: Parking | undefined;
}

const PackageTable: FC<Props> = ({parking}) => {
  const {t} = useTranslation();
  const {currentLanguage} = useSelector((state: RootState) => state.changeLanguageReducer);
  const isArabic = currentLanguage === 'ar';

  const {loading, packages} = useSelector((state: RootState) => state.getPackageReducer);

  return (
    <Box>
      <TableContainer
        sx={{
          height: 500,
          borderRadius: 4,
          paddingRight: packages.length > 4 ? (isArabic ? 0 : 2) : 0,
          paddingLeft: packages.length > 4 ? (isArabic ? 2 : 0) : 0,
          overflowY: `${loading && 'hidden'}`,
        }}>
        <Table className="sessions-table" sx={{fontFamily: 'Poppins'}}>
          <TableHead sx={{position: 'sticky', top: 0, whiteSpace: 'nowrap'}}>
            <TableRow sx={{background: YELLOW}}>
              <TableCell />
              <TableCell className="table-head">{t('Package category')}</TableCell>
              <TableCell className="table-head">{t('Package name')}</TableCell>
              <TableCell className="table-head">{t('Package validity time')}</TableCell>
              <TableCell className="table-head">{t('Status')}</TableCell>
              <TableCell className="table-head">{t('Dedicated count of slots')}</TableCell>
              <TableCell className="table-head">Used slots</TableCell>
              <TableCell className="table-head">{t('No. of assigned users')}</TableCell>
              <TableCell />
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <PackageTableBody parking={parking} />
          <CustomTablePagination totalPages={1} currentPage={1} dispatchFunc={() => {}} />
        </Table>
      </TableContainer>
    </Box>
  );
};
export default PackageTable;
