import {
  CURRENT_SESSION_PATH,
  EMPLOYEE_WALLET_PATH,
  HOME_PATH,
  LOGIN_PATH,
  PROFILE_GATE_PATH,
} from 'routes/paths';
import {Nav, Navbar} from 'react-bootstrap';
import {home, logout, profileLight, tarqiaNavLogo} from 'assets/icons';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import {RootState} from 'redux/rootReducers';
import {logoutAction} from 'redux/actions/authAction';

import './styles.css';
import {useTranslation} from 'react-i18next';
// import Cookies from 'js-cookie';
import {useEffect} from 'react';
// import {changeLanguageAction} from 'redux/actions/LanguageActions/changeLanguageAction';
//

const UpperNavbar = () => {
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const {user} = useSelector((state: RootState) => state.authReducer);
  const {t} = useTranslation();
  const {currentLanguage} = useSelector((state: RootState) => state.changeLanguageReducer);

  const handleLogout = () => {
    dispatch(logoutAction());
    navigate(LOGIN_PATH);
  };

  const getScreenName = () => {
    const path = location.pathname;
    if (path === EMPLOYEE_WALLET_PATH) {
      return 'Gate Employee Wallet';
    }
    const screenName = path === '/' ? 'Home' : path.split('/').pop().replace('-', ' ') || '';
    const capitalizedWords = screenName
      .split(' ')
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1));

    return capitalizedWords.join(' ');
  };

  const languageHelper = (pathname: string) => {
    if (pathname !== CURRENT_SESSION_PATH && getScreenName()) {
      switch (pathname) {
        case '/':
          return t('home');

        case '/parking-setup':
          return t('parking-setup');

        case '/employee-wallet':
          return t('employee-wallet');

        case '/package-category':
          return t('package-category');

        case '/sessions':
          return t('sessions');
        case '/employee-list':
          return t('employee-list');
        case '/user-list':
          return t('user-list');
        case '/reports':
          return t('reports');
        case '/profile':
          return t('profile');
        case '/package-home':
          return t('package-home');

        default:
          return 'Unknown game choice';
      }
    } else {
      return pathname;
    }
  };

  // const {i18n} = useTranslation();
  const currentLanguageCodeCookie = currentLanguage ?? 'en';
  const currentLanguageCodeLocalStorage = currentLanguage;
  const directionCookie = currentLanguageCodeCookie === 'ar' ? 'rtl' : 'ltr';
  const directionLocalStorage = currentLanguageCodeLocalStorage === 'ar' ? 'rtl' : 'ltr';
  useEffect(() => {
    document.body.dir = directionCookie || directionLocalStorage;
  }, [directionCookie, directionLocalStorage]);

  return (
    <Navbar className="upper-nav" expand="lg" variant="dark">
      <Container fluid className="navbar-container">
        <Navbar.Brand className="d-flex align-items-end mr-0">
          <CustomIcon href={tarqiaNavLogo} width={88} height={44} />
        </Navbar.Brand>
        <span className="screenTitle">
          {/* {languageHelper(location.pathname) !== CURRENT_SESSION_PATH && getScreenName()} */}
          {languageHelper(location.pathname)}
        </span>
        <Navbar.Toggle />
        <Navbar.Collapse className="justify-content-end ww">
          <Nav className="gap-20 links-group">
            <Nav.Link id="profile">
              {/* <Dropdown align="end">
                <Dropdown.Toggle id="dropdown-basic">
                  <div className="d-flex flex-row items-center gap-2">
                    <CustomIcon className="navbar-items" href={langIcon} />
                    {`${currentLanguage === 'ar' ? `${t('Arabic')}` : 'English'}`}
                    <CustomIcon
                      className="navbar-items"
                      href={downOrangeArrow}
                      width={17}
                      height={9}
                      margin="0 0 0 8px"
                    />
                  </div>{' '}
                </Dropdown.Toggle>
                <Dropdown.Menu className="lang-dropdown-container">
                  <Dropdown.Item
                    href="#action-1"
                    onClick={() => {
                      if (currentLanguage === 'ar') {
                        dispatch(changeLanguageAction('en'));
                      } else {
                        dispatch(changeLanguageAction('ar'));
                      }
                    }}>
                    {`${currentLanguage === 'ar' ? 'English' : 'Arabic'}`}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown> */}
            </Nav.Link>
            {user.isGateEmployee ? (
              <Nav.Link id="profile" onClick={() => navigate(PROFILE_GATE_PATH)}>
                <CustomIcon className="navbar-items" href={profileLight} />
              </Nav.Link>
            ) : (
              <Nav.Link id="landing" onClick={() => navigate(HOME_PATH)}>
                <CustomIcon className="navbar-items" href={home} />
              </Nav.Link>
            )}
            <Nav.Link id="landing" onClick={handleLogout}>
              <CustomIcon className="navbar-items" href={logout} />
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default UpperNavbar;
