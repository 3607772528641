import {
  GET_FILTERED_ROW,
  GET_FILTERED_ROW_FAILURE,
  GET_FILTERED_ROW_LOADING,
  GET_POWEROFF_SESSIONS,
  GET_SESSIONS,
  GET_SESSIONS_FAILURE,
  GET_SESSIONS_LOADING,
} from 'redux/types';

const initialState = {
  sessions: [],
  filteredSession: null,
  totalPages: 0,
  totalRecords: 0,
  currentPage: 1,
  powerOffSessions: [],
  powerOffCurrentPage: 1,
  powerOffTotalPages: 0,
  fetchLoading: false,
  filteredLoading: false,
  filteredError: '',
  fetChErrors: '',
};

export const getSessionsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_SESSIONS_LOADING: {
      return {...state, fetchLoading: action.payload};
    }
    case GET_SESSIONS:
      return {
        ...state,
        sessions: action.payload.reservations,
        totalPages: action.payload.totalPages,
        records: action.payload.totalRecords,
        currentPage: action.payload.currentPage,
      };
    case GET_POWEROFF_SESSIONS:
      return {
        ...state,
        powerOffSessions: action.payload.reservations,
        powerOffTotalPages: action.payload.totalPages,
        powerOffCurrentPage: action.payload.currentPage,
        updatedSession: null,
        createdSession: null,
      };
    case GET_SESSIONS_FAILURE:
      return {...state, errors: action.error};
    case GET_FILTERED_ROW_LOADING:
      return {...state, filteredLoading: action.payload};
    case GET_FILTERED_ROW:
      return {...state, filteredSession: action.payload, filteredError: action.error};
    case GET_FILTERED_ROW_FAILURE:
      return {...state, filteredError: action.error, filteredSession: null};
    default:
      return state;
  }
};
