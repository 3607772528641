import {Dispatch, FC, SetStateAction, useRef, useState} from 'react';
import {arrowDownLight, search} from 'assets/icons';

import {ReportTable} from 'model/Reports/Report';
import {getReportFilters} from 'utils/reports/getReportFilters';
import styles from './DropdownSearch.module.css';
import {useTranslation} from 'react-i18next';
import {useOutsideClick} from 'hooks/useOutsideClick';
import {
  getInvoices,
  getReceipts,
  getRevenues,
  getTransactions,
  getUtilities,
} from 'redux/actions/reportsAction';
import {useDispatch, useSelector} from 'react-redux';
import {ThunkDispatchType} from 'redux/types';
import {RootState} from 'redux/rootReducers';

interface Props {
  dropdownValue: string;
  setDropdownValue: Dispatch<SetStateAction<string>>;
  searchValue: string | null;
  setSearchValue: Dispatch<SetStateAction<string | null>>;
  selectedTable: ReportTable;
}

const DropdownSearch: FC<Props> = ({
  dropdownValue,
  setDropdownValue,
  searchValue,
  setSearchValue,
  selectedTable,
}) => {
  const {t, i18n} = useTranslation();

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  const dropdownRef = useRef<HTMLDivElement>(null);

  const dispatch: ThunkDispatchType = useDispatch();

  const {user} = useSelector((state: RootState) => state.authReducer);

  const {invoiceCurrentPage, receiptCurrentPage, transactionCurrentPage} = useSelector(
    (state: RootState) => state.reportsReducer,
  );

  useOutsideClick(dropdownRef, () => setDropdownOpen(false));

  const handleSearchFilterClick = (filterName: string) => {
    setDropdownValue(filterName);
    setDropdownOpen(false);
  };

  const renderDropdownItem = (filterName: string) => {
    return (
      <li
        className="cursor-pointer"
        key={filterName}
        onClick={() => handleSearchFilterClick(filterName)}>
        {filterName}
      </li>
    );
  };

  const handleSearchFocus = () => {
    if (searchValue) {
      return;
    }

    switch (selectedTable) {
      case 'invoice':
        dispatch(getInvoices(1, undefined, true));
        break;
      case 'receipt':
        dispatch(getReceipts(1, undefined, true));
        break;
      case 'transaction':
        dispatch(getTransactions(1, undefined, true));
        break;
      case 'utilization':
        dispatch(getUtilities(user.parkingId));
        break;
      case 'revenue':
        dispatch(getRevenues(user.parkingId));
        break;
    }
  };

  const handleSearchBlur = () => {
    if (searchValue) {
      return;
    }

    switch (selectedTable) {
      case 'invoice':
        dispatch(getInvoices(invoiceCurrentPage));
        break;
      case 'receipt':
        dispatch(getReceipts(receiptCurrentPage));
        break;
      case 'transaction':
        dispatch(getTransactions(transactionCurrentPage));
        break;
      case 'utilization':
        dispatch(getUtilities(user.parkingId));
        break;
      case 'revenue':
        dispatch(getRevenues(user.parkingId));
        break;
    }
  };

  return (
    <div
      ref={dropdownRef}
      className="flex items-center h-16 border-solid border-2 rounded-xl pr-14">
      <div
        className={`flex relative ${
          i18n.language === 'ar' && '-right-14'
        } bg-black text-white justify-between items-center gap-3 px-4 h-16 rounded-xl w-52`}>
        <p className="whitespace-nowrap">{dropdownValue}</p>
        <img
          className="cursor-pointer"
          src={arrowDownLight}
          alt="arrow"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        />
        {dropdownOpen && (
          <ul
            className={`w-full bg-white text-black absolute left-0 top-16 z-[10000] px-3 py-0 rounded-2xl border-2 border-[var(--disabled-grey)] divide-y ${styles.dropdownList}`}>
            {getReportFilters(selectedTable).map(filterName => renderDropdownItem(t(filterName)))}
          </ul>
        )}
      </div>
      <div id="search" className="flex items-center relative">
        <input
          className="ml-3 w-[8vw] focus:outline-none"
          type="text"
          placeholder={t('Search')!}
          value={searchValue ?? ''}
          onChange={e => setSearchValue(e.target.value)}
          onFocus={handleSearchFocus}
          onBlur={handleSearchBlur}
        />
        <img className="absolute -right-10" src={search} alt="search" />
      </div>
    </div>
  );
};

export default DropdownSearch;
