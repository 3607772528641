import {useState, useEffect} from 'react';

export const useCountdown = (timer: number, onCountdownEnd?: VoidFunction) => {
  const [countdown, setCountdown] = useState(timer);
  const [isCountingDown, setIsCountingDown] = useState(false);

  useEffect(() => {
    setIsCountingDown(true);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isCountingDown) {
      intervalId = setInterval(() => {
        if (countdown === 0) {
          onCountdownEnd?.();
          clearInterval(intervalId);
          setIsCountingDown(false);
        } else {
          setCountdown(countdown - 1);
        }
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [countdown, isCountingDown]);

  const resetCountdown = () => {
    setCountdown(timer);
    setIsCountingDown(true);
  };

  return {countdown, resetCountdown};
};
