import * as Yup from 'yup';

import {BLACK, WHITE} from 'constants/colors';
import {Box, CircularProgress} from '@mui/material';
import {FC, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {EndShift} from 'model/EndShift';
import {LoadingButton} from '@mui/lab';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import SingleDropdown from 'utils/dropdowns/SingleDropdown';
import {StartShift} from 'model/StartShift';
import Styles from './ShiftCard.module.css';
import {ThunkDispatchType} from 'redux/types';
import {endShiftService} from 'services/shift/endShiftService';
import {getParkingSetup} from 'redux/actions/parkingSetupAction';
import {getShiftAction} from 'redux/actions/getShiftAction';
import moment from 'moment';
import {startShiftService} from 'services/shift/startShiftService';
import {useFormik} from 'formik';
import {useMutation} from 'react-query';

interface ShiftCardProps {
  type: 'start' | 'end';
}

const ShiftCard: FC<ShiftCardProps> = ({type}) => {
  const {user} = useSelector((state: RootState) => state.authReducer);
  const {shifts} = useSelector((state: RootState) => state.getShiftReducer);

  const dispatch: ThunkDispatchType = useDispatch();
  const [shiftResponse, setٍShiftResponse] = useState<boolean>(false);
  const [Message, setMessage] = useState<string>('');
  const [error, seterror] = useState<boolean>(false);
  const {parkingsSetup} = useSelector((state: RootState) => state.parkingSetupReducer);

  const activeGateOptions = parkingsSetup[0]?.gates.reduce(
    (gates: Array<{label: string}>, obj: {isActive: boolean; gateName: string}) => {
      if (obj.isActive) {
        gates.push({label: obj.gateName});
      }
      return gates;
    },
    [],
  );

  const shiftMutationService = async (shiftData: StartShift | EndShift) => {
    if (type === 'start') {
      return await startShiftService(shiftData as StartShift);
    } else {
      return await endShiftService(shiftData as EndShift);
    }
  };

  const {mutate} = useMutation(shiftMutationService, {
    onSuccess: () => {
      seterror(false);
      type === 'start'
        ? setMessage('Shift has been started successfully')
        : setMessage('Shift has been ended successfully');
      setٍShiftResponse(true);
      setTimeout(() => {
        dispatch(getShiftAction(user.parkingId, user.id));
      }, 2000);
    },
    onError: () => {
      setٍShiftResponse(true);
      setMessage('Gate or user has running shift');
      seterror(true);
    },
  });

  const getShiftType = () => {
    const hour = moment(new Date()).hour();

    if (hour >= 8 && hour < 16) {
      return 'Morning';
    } else if (hour >= 16 || hour < 0) {
      return 'Evening';
    } else {
      return 'Night';
    }
  };

  const formik: any = useFormik({
    initialValues: {
      date: moment(new Date()).format('MMM DD, YYYY - HH:mm:ss A'),
      gate: type === 'end' ? shifts[0].gateNumber : '',
      type: 'Morning',
      cash: '',
    },
    validationSchema: Yup.object().shape({
      date: Yup.string().required('Required'),
      gate: Yup.string().required('Required'),
      cash: Yup.number()
        .min(0, `${type === 'start' ? 'Starting' : 'Ending'} cash balance can't be negative number`)
        .required('Required'),
    }),
    onSubmit: values => {
      type === 'start'
        ? mutate({
            gateNumber: values.gate,
            startAmount: Number(values.cash),
            startDate: moment.utc(new Date()).local().format('YYYY-MM-DD[T]HH:mm:ss'),
            type: getShiftType(),
          })
        : mutate({
            empId: shifts[0].empId,
            id: shifts[0].id,
            parkId: shifts[0].parkId,
            startAmount: shifts[0].startAmount,
            startDate: shifts[0].startDate,
            gateNumber: shifts[0].gateNumber,
            type: shifts[0].type,
            endAmount: Number(values.cash),
            endDate: moment.utc(new Date()).local().format('YYYY-MM-DD[T]HH:mm:ss'),
            active: false,
          });
    },
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      formik.setFieldValue('date', moment().format('MMM DD, YYYY - HH:mm:ss A'));
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [formik]);

  useEffect(() => {
    if (type === 'start') {
      formik.setFieldValue('shift', getShiftType());
    }
    dispatch(getParkingSetup());
  }, []);

  return (
    <div className={Styles.shiftCard}>
      <div className={Styles.cardHaed}>{type === 'start' ? 'Start' : 'End'} shift</div>
      <form onSubmit={formik.handleSubmit}>
        <div className={Styles.cardBody}>
          <Box className={Styles.item}>
            <label>Shift type</label>
            <input
              type="text"
              className={Styles.input}
              name="shift"
              value={type === 'start' ? formik.values.shift : shifts[0].type}
              disabled
            />
          </Box>
          <Box className={Styles.item}>
            <Box className={Styles.item}>
              <label>Gate name</label>
              {type === 'start' ? (
                <SingleDropdown
                  parking
                  placeholder="Select a gate"
                  options={activeGateOptions}
                  value={formik.values.gate}
                  handleDropdownSelect={(value, label) => {
                    formik.setFieldValue('gate', value);
                  }}
                  backgroundColor={WHITE}
                  color={BLACK}
                  blackArrow
                  borderRadius={14}
                  search
                />
              ) : (
                <input type="text" disabled className={Styles.input} value={shifts[0].gateNumber} />
              )}
              <p className="validation-error">
                {formik.errors.gate && formik.touched.gate && formik.errors.gate}
              </p>
            </Box>
          </Box>
          <Box className={Styles.item}>
            <label>{type === 'start' ? 'Starting' : 'Ending'} date and time</label>
            <input type="text" disabled className={Styles.input} value={formik.values.date} />
          </Box>
          <Box className={Styles.item} style={{position: 'relative'}}>
            <label>{type === 'start' ? 'Starting' : 'Closing'} cash balance</label>
            <input
              type="number"
              className={Styles.input}
              placeholder={`${
                type === 'start'
                  ? 'Enter the starting cash balance'
                  : 'Enter the closing cash balance'
              }`}
              name="cash"
              value={formik.values.cash}
              onChange={e => {
                const {value} = e.target;
                const regex = /^\d*(\.\d{0,2})?$/; // Regex to allow at most 2 digits after the decimal point
                if (value === '' || regex.test(value)) {
                  formik.setFieldValue('cash', value);
                }
              }}
            />
            <span className={Styles.srSpan}>SR</span>
            <p className="validation-error">
              {formik.errors.cash && formik.touched.cash && formik.errors.cash}
            </p>
          </Box>
          <LoadingButton
            type="submit"
            className={Styles.submitButton}
            loadingIndicator={<CircularProgress />}>
            {type === 'start' ? 'Start' : 'End'}
          </LoadingButton>
        </div>
      </form>
      {shiftResponse && (
        <ResponseModal onClose={() => setٍShiftResponse(false)} message={Message} error={error} />
      )}
    </div>
  );
};

export default ShiftCard;
