import './styles.css';

import AuthConatiner from 'components/AuthConatiner/AuthConatiner';
import AuthHeading from 'components/AuthHeading/AuthHeading';
import CustomButton from 'components/CustomButton/CustomButton';
import OTPInput from 'otp-input-react';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {resetError, sendOTP, verifyOTP} from 'redux/actions/authAction';
import {useNavigate} from 'react-router-dom';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {RootState} from 'redux/rootReducers';
import {arrowLeft} from 'assets/icons';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import {RESET_PASSWORD_PATH} from 'routes/paths';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {useCountdown} from 'hooks/useCountdown';
import moment from 'moment';

const OTP = () => {
  const {countdown, resetCountdown} = useCountdown(300);
  const [OTP, setOTP] = useState('');
  const [responseModalIsOpen, setResponseModalIsOpen] = useState<boolean>(false);
  const {t} = useTranslation();
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const {loading, phoneNumber, error} = useSelector((state: RootState) => state.authReducer);

  const handleSubmite = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(verifyOTP(OTP, phoneNumber, () => navigate(RESET_PASSWORD_PATH)));
  };

  const handleResendOtp = () => {
    dispatch(sendOTP(phoneNumber));
    resetCountdown();
  };

  const disabledBtn = !(OTP.length < 4);

  useEffect(() => {
    if (error) {
      setResponseModalIsOpen(true);
    }
  }, [error]);

  return (
    <AuthConatiner>
      <div className="custom-container">
        <div className="custom-content">
          <CustomIcon className="cursor-pointer" href={arrowLeft} onClick={() => navigate(-1)} />
          <AuthHeading />
          <p className="otp-text">
            A message has been sent with an OTP code, please enter the code below
          </p>
          <form className="form custom-container" onSubmit={e => handleSubmite(e)}>
            <div className="otp-container">
              <OTPInput
                className="otp-input"
                value={OTP}
                onChange={setOTP}
                autoFocus
                OTPLength={4}
                otpType="number"
                disabled={false}
              />
            </div>
            <CustomLoader size={40} loading={loading} mt={4}>
              <CustomButton
                color="Orange"
                px="130px"
                type="submit"
                disabled={!disabledBtn}
                height="56px"
                margin="37px">
                {t('Continue')}
              </CustomButton>
            </CustomLoader>
            {countdown < 1 ? (
              <p className="text-white mt-3 cursor-pointer" onClick={handleResendOtp}>
                Resend code
              </p>
            ) : (
              <p className="text-white mt-3">
                {moment(moment.duration(countdown, 'seconds').asMilliseconds()).format('mm:ss')}
              </p>
            )}
          </form>
        </div>
      </div>
      {responseModalIsOpen && (
        <ResponseModal
          error
          message={error}
          onClose={() => {
            setResponseModalIsOpen(false);
            dispatch(resetError());
          }}
        />
      )}
    </AuthConatiner>
  );
};

export default OTP;
