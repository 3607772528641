import './styles.css';

import * as Yup from 'yup';

import {useDispatch, useSelector} from 'react-redux';
import AuthConatiner from 'components/AuthConatiner/AuthConatiner';
import AuthHeading from 'components/AuthHeading/AuthHeading';
import CustomButton from 'components/CustomButton/CustomButton';
import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import CustomInput from 'components/CustomInput/CustomInput';
import CustomLoader from 'components/CustomLoader/CustomLoader';
import {RootState} from 'redux/rootReducers';
import {arrowLeft} from 'assets/icons';
import {forgetPassword} from 'redux/actions/authAction';
import {useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Cookies from 'js-cookie';
import {useState} from 'react';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {LOGIN_PATH} from 'routes/paths';

const ResetPassword = () => {
  const currentLanguageCodeCookie = Cookies.get('i18next') === 'ar' ? 'ar' : 'en';
  const {t} = useTranslation();
  const [responseModalIsOpen, setResponseModalIsOpen] = useState<boolean>(false);
  const {phoneNumber, loading} = useSelector((state: RootState) => state.authReducer);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  const {values, handleChange, handleSubmit, handleBlur, isValid, errors, touched} = useFormik({
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, t('Password must be at least 8 characters')!)
        .required('Password is required'),
    }),
    onSubmit: (values: any) =>
      dispatch(forgetPassword(values.password, phoneNumber, () => setResponseModalIsOpen(true))),
  });

  return (
    <AuthConatiner>
      <div className="custom-container">
        <div className="custom-content">
          <CustomIcon className="cursor-pointer" href={arrowLeft} onClick={() => navigate(-1)} />
          <AuthHeading />
          <p className="forget-pass-text">Please enter a new password to reset your account.</p>
          <form className="form reset-password" onSubmit={handleSubmit}>
            <CustomInput
              margin="10px 0 0 0"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              type="password"
              name="password"
              placeHolder={t('Enter new password')}
              _direction={`${currentLanguageCodeCookie === 'en' ? 'right' : 'left'}`}
              error={!!(errors.password && touched.password && errors.password)}
            />
            {errors.password && touched.password && (
              <p className="error-message">{errors.password as string}</p>
            )}
            <CustomLoader loading={loading} size={40}>
              <CustomButton
                className="!mt-12"
                width="80%"
                color="Orange"
                type="submit"
                disabled={!isValid}>
                {t('Save')}
              </CustomButton>
            </CustomLoader>
          </form>
        </div>
      </div>
      {responseModalIsOpen && (
        <ResponseModal
          message="Your new password is created successfully.You can now login using it"
          onClose={() => {
            setResponseModalIsOpen(false);
            navigate(LOGIN_PATH);
          }}
        />
      )}
    </AuthConatiner>
  );
};

export default ResetPassword;
