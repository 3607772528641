import {Chart as ChartJS, registerables} from 'chart.js';
import {arrowDown, blackCalendar, greenDor, orangeDot} from 'assets/icons';

import {CustomIcon} from 'components/CustomIcon/CustomIcon';
import {Line} from 'react-chartjs-2';
import LineChartDatePicker from './LineChartDatePicker/LineChartDatePicker';
import {RootState} from 'redux/rootReducers';
import Styles from './lineChart.module.css';
import {useSelector} from 'react-redux';
import {t} from 'i18next';

const LineChart = () => {
  const {paymentChartData} = useSelector((state: RootState) => state.chartReducer);
  const calculateTotal = () => {
    let total = 0;

    // Iterate over payment methods
    for (const method in paymentChartData) {
      // Iterate over months for each payment method
      for (const month in paymentChartData[method]) {
        total += paymentChartData[method][month];
      }
    }

    return total;
  };

  const finalSum = calculateTotal();
  console.log(finalSum);
  function getMonthValues(data: any): number[] {
    const months = [
      'JANUARY',
      'FEBRUARY',
      'MARCH',
      'APRIL',
      'MAY',
      'JUNE',
      'JULY',
      'AUGUST',
      'SEPTEMBER',
      'OCTOBER',
      'NOVEMBER',
      'DECEMBER',
    ];

    const monthValues: number[] = [0];

    if (data === null || data === undefined) {
      monthValues.push(...Array(12).fill(0));
      return monthValues;
    }

    for (const month of months) {
      if (Object.prototype.hasOwnProperty.call(data, month)) {
        monthValues.push(data[month]);
      } else {
        monthValues.push(0);
      }
    }

    return monthValues;
  }

  ChartJS.register(...registerables);
  const data = {
    labels: [
      '',
      `${t('January')}`,
      `${t('February')}`,
      `${t('March')}`,
      `${t('April')}`,
      `${t('May')}`,
      `${t('June')}`,
      `${t('July')}`,
      `${t('August')}`,
      `${t('September')}`,
      `${t('October')}`,
      `${t('November')}`,
      `${t('December')}`,
    ],
    datasets: [
      {
        label: 'Online payment',
        data: getMonthValues(paymentChartData?.ONLINE),
        borderColor: '#FEB700',
        fill: false,
        pointBackgroundColor: '#FEB700',
        tension: 0.3,
      },
      {
        label: 'Cash payment',
        data: getMonthValues(paymentChartData?.CASH),
        borderColor: '#0A6EBD',
        fill: false,
        pointBackgroundColor: '#0A6EBD',
        tension: 0.3,
      },
      {
        label: 'Credit card',
        data: getMonthValues(paymentChartData?.CARD),
        borderColor: '#08D110',
        fill: false,
        pointBackgroundColor: '#08D110',
        tension: 0.3,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        beginAtZero: false,
        grid: {
          display: false,
        },

        ticks: {
          offset: true,
          color: '#000',
          font: {
            weight: 700,
            size: 14,
          },
        },
      },
      y: {
        grid: {
          drawBorder: false,
        },
        ticks: {
          color: '#000',
          callback: (value: string) => (parseInt(value) / 1000).toString() + 'K ' + `${t('SR')}`,
          font: {
            weight: 700,
            size: 14,
          },
        },
        min: 0,
        max: 6000,
        stepSize: 1000,
      },
    },
  };

  return (
    <div className={Styles.lineChartContainer}>
      <div
        style={{
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '18px',
        }}>
        <span
          style={{
            display: 'block',
            whiteSpace: 'nowrap',
            marginBottom: '10px',
            fontSize: '15px',
            fontWeight: 'bold',
            padding: '0 15px',
          }}>
          {t('Payment methods / total amount')}
        </span>
        <div dir="ltr" className={Styles.calender}>
          <LineChartDatePicker
            color="white"
            startIcon={<CustomIcon href={blackCalendar} width={20} height={20} />}
            endIcon={<CustomIcon href={arrowDown} width={17} height={9} />}
          />
        </div>
      </div>
      <div className={Styles.chartHeader}>
        <div className={Styles.totalCount}>
          <h3>
            {finalSum.toFixed(2)} {t('SR')}
          </h3>
        </div>
        <div className={Styles.labels}>
          <span>
            <CustomIcon href={orangeDot} width={8} height={8} />
            {t('Online payment')}
          </span>
          <span>
            <CustomIcon href={greenDor} width={8} height={8} />
            {t('Credit card')}
          </span>
          <span>
            <span
              style={{
                display: 'inline-block',
                backgroundColor: '#0A6EBD',
                width: '8px',
                height: '8px',
                borderRadius: '50%',
              }}
            />
            {t('Cash payment')}
          </span>
        </div>
      </div>
      <Line options={options} data={data} height={130} />
    </div>
  );
};

export default LineChart;
