import api from '../../api';
import {chartEndPoint, chartPkgEndPoint} from 'api/apisURL';

export const PackageAndProcesChartService = async (
  parkingId: string | undefined,
  startDate: string | undefined,
  endDate: string | undefined,
  status: 'package' | 'process',
  type?: 'checkInDate' | 'checkOutDate',
) => {
  const payload: any = {
    filters: [
      {
        fieldName: 'parkingId',
        operator: 'like',
        values: [parkingId],
      },
    ],
  };
  startDate &&
    payload.filters.push({
      fieldName: type,
      operator: 'between',
      values: [startDate, endDate],
    });
  const response = await api.post(
    status === 'package' ? chartPkgEndPoint() : chartEndPoint(),
    payload,
  );
  return response;
};
