import {Col, Row} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';

import {Box} from '@mui/material';
import CustomButton from 'components/CustomButton/CustomButton';
import EditParkingSetupModal from './components/EditParkingSetupModal';
import HeaderDate from 'components/HeaderDate/HeaderDate';
import MainViewContainer from 'components/MainViewContainer/MainViewContainer';
import MultipleParkingTable from './components/MultipleParkingTable/MultipleParkingTable';
import ParkingSetupTable from './components/ParkingSetupTable/ParkingSetupTable';
import Popup from 'components/Popup/Popup';
import ResponseModal from 'components/ResponseModal/ResponseModal';
import {RootState} from 'redux/rootReducers';
import SetParkingAsFull from 'components/SetParkingAsFull/SetParkingAsFull';
import {resetSelectedParkingSetupRow} from 'redux/actions/parkingSetupAction';
import {useState} from 'react';
import {setParkingAsFree} from 'redux/actions/setParkingAsFullAction';
import {useTranslation} from 'react-i18next';

const ParkingSetup = () => {
  const dispatch: any = useDispatch();
  const parkingState = useSelector((state: RootState) => state.parkingSetupReducer);
  const parkingAsFullState = useSelector((state: RootState) => state.setParkingAsFullReducer);
  const [choosenId, setChoosenId] = useState<any>(null);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [openASFull, setOpenASFull] = useState<boolean>(false);
  const [responseEdit, setResponseEdit] = useState<boolean>(false);
  const [responseParkingFull, setResponseParkingFull] = useState<boolean>(false);
  const [table, setTable] = useState('');
  const {t} = useTranslation();

  const handleOpenEditModal = () => {
    setEditModal(true);
  };

  const handleCloseEditModal = () => {
    setEditModal(false);
    dispatch(resetSelectedParkingSetupRow());
  };

  const handleClick = () => {
    if (parkingState.selectedRowValue?.isFullReason) {
      dispatch(setParkingAsFree(parkingState.selectedRowValue.id, setResponseParkingFull(true)));
    } else {
      setOpenASFull(true);
    }
  };

  const tableSelected = () => {
    if (table === 'expanded') {
      return (
        <MultipleParkingTable
          choosenId={choosenId}
          setChoosenId={setChoosenId}
          setTable={setTable}
        />
      );
    } else {
      return (
        <ParkingSetupTable
          responseModal={responseParkingFull || responseEdit}
          setChoosenId={setChoosenId}
          setTable={setTable}
        />
      );
    }
  };

  return (
    <Box id="parking-setup">
      <MainViewContainer>
        <Row className="special-row">
          <Col className="d-flex gap-3 align-items-center">
            <CustomButton
              width="10vw"
              onClick={handleOpenEditModal}
              disabled={!parkingState.selectedRowValue}>
              {t('Edit')}
            </CustomButton>
            <CustomButton
              width="14vw"
              disabled={!parkingState.selectedRowValue && !parkingState.selectedRowValue?.isFull}
              onClick={handleClick}>
              {t('Set parking as')}
              {parkingState.selectedRowValue?.isFullReason ? `${t('available')}` : `${t('close')}`}
            </CustomButton>
          </Col>
          <Col className="d-flex justify-content-end">
            <HeaderDate orange />
          </Col>
        </Row>
        <Row>{tableSelected()}</Row>
      </MainViewContainer>
      <Popup
        open={editModal}
        handleCloseModal={handleCloseEditModal}
        popupTitle={`${t('Edit Parking')}`}>
        <EditParkingSetupModal
          setResponseEdit={setResponseEdit}
          handleClose={handleCloseEditModal}
        />
      </Popup>
      {responseEdit && (
        <ResponseModal
          onClose={() => setResponseEdit(false)}
          message={!parkingState.error ? 'Parking edited successfully!' : parkingState.error}
          error={parkingState.error}
        />
      )}
      {responseParkingFull && !parkingAsFullState.loading && (
        <ResponseModal
          onClose={() => setResponseParkingFull(false)}
          message={
            !parkingAsFullState.error
              ? `Set parking as ${
                  parkingAsFullState.updatedData.fullTo ? 'full' : 'free'
                } successfully!`
              : parkingAsFullState.error
          }
          error={parkingAsFullState.error}
        />
      )}
      {openASFull && (
        <SetParkingAsFull
          handleClose={() => setOpenASFull(false)}
          handleResponseOpen={() => setResponseParkingFull(true)}
          open={openASFull}
          selectedParking={parkingState.selectedRowValue}
        />
      )}
    </Box>
  );
};

export default ParkingSetup;
